import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { timer } from 'rxjs';
import {
  pluck,
  map,
  tap,
  filter,
  take,
  switchMap,
  catchError,
} from 'rxjs/operators';
import { withTranslation } from 'react-i18next';
import { Elements } from '@stripe/react-stripe-js';
import { logException } from '../../../../logger';
import { QUOTE_STATUSES } from '../../../../constants';
import observableRequest from '../../../../../lib/requestHelpers';
import CheckoutStripe from './CheckoutStripe';
import CheckoutError from './CheckoutError';
import { getOrganisationFromTransaction } from '../../../../../lib/transactionHelpers';
import {
  getPaymentErrorFromQuote,
  getQuoteStatusFromQuote,
  getHasAmountToPayFromQuote,
} from '../../../../../lib/checkoutHelpers';
import ServerErrors from '../../molecules/ServerErrors';
import Card from '../../atoms/Card';

class Checkout extends Component {
  // Checkout documentation can be found at the link below
  // https://gitlab.com/hokodo/handbook/-/wikis/Customer-ui-Checkout

  state = {
    ready: [],
    paymentMethods: {},
    hasPaymentMethods: false,
    hasAmountToPay: true,
    stripeKey: null,
    errors: [],
    paymentError: false,
    isFetching: false,
  };

  pollingInterval = 3000;

  pollingRetries = 10;

  componentDidMount() {
    const { transaction, token } = this.props;
    const { quote } = transaction;
    const { status } = quote;

    // this check is required in case props
    // cause the component to be remounted
    if (status === QUOTE_STATUSES.offered) {
      this.fetchPublishableKey(quote, token);
    }

    // get payment methods
    this.fetchPaymentMethods();
  }

  setReadyItem() {
    this.setState(state => ({ ready: [...state.ready, 1] }));
  }

  setErrorItem(error) {
    this.setState(state => ({
      errors: [...state.errors, error],
    }));
  }

  setPaymentError(e) {
    const {
      transaction: {
        quote: { id },
      },
    } = this.props;
    logException({ message: `Payment Error ${id}`, value: e });
    this.setState({ paymentError: true });
  }

  onCheckoutSuccess = () => {
    const {
      transaction: {
        quote: { id },
      },
      queryString,
      token,
      push,
      fetchPolicy,
      fetchPolicySuccess,
      // fetchPolicyFailure,
      onPollingSuccess,
      // onPollingFailure,
    } = this.props;

    const { hasAmountToPay } = this.state;

    this.setState({ isFetching: true });

    if (hasAmountToPay) {
      let count = 0;

      const onSuccess =
        onPollingSuccess ||
        (payload => {
          fetchPolicySuccess(payload);
          push(`/policy${queryString}`);
        });

      // const onFailure =
      //   onPollingFailure ||
      //   (payload => {
      //     fetchPolicyFailure(payload);
      //   });

      timer(0, this.pollingInterval)
        .pipe(
          tap(() => {
            count += 1;
          }),
          switchMap(() =>
            observableRequest({
              url: `quotes/${id}/?expand=policy`,
              token,
            }).pipe(
              pluck('response'),
              map(response => response),
              catchError(error => {
                // does not stop polling.
                logException(error);
              }),
            ),
          ),
        )
        .pipe(
          filter(response => {
            // problematic scenario:
            // a failed 3D secure challenge is followed by a successful 3D secure challenge
            // the success start polling, however when polled, contorta will respond with
            // a non null payment_error from the initial failed payment.
            // hack pt.1
            // while we wait for a better api response solution, we will not stop polling on a
            // non null payment_error but continue polling until either a success response
            // (i.e. non null response.policy) or max_retries is reached.
            return (
              !!response.policy ||
              // !!getPaymentErrorFromQuote(response) ||
              count >= this.pollingRetries
            );
          }),
        )
        .pipe(take(1))
        .subscribe(response => {
          this.setState({ isFetching: false });
          const paymentError = getPaymentErrorFromQuote(response);
          const status = getQuoteStatusFromQuote(response);

          if (paymentError) {
            // hack pt.2
            // check for response.policy before setting error
            if (response.policy) {
              onSuccess(response.policy);
            } else {
              this.setPaymentError(paymentError);
            }
            return;
          }

          switch (status) {
            case QUOTE_STATUSES.accepted:
              // has policy
              onSuccess(response.policy);
              break;
            case QUOTE_STATUSES.offered:
              if (response.policy) {
                // it's possible that contorta will respond with
                // this status but actually have a non null policy
                onSuccess(response.policy);
              } else {
                // we hit maximum polling retries
                this.setPaymentError({
                  message: `max retries: ${this.pollingRetries}`,
                });
              }
              break;

            default:
              // any other status means manual intervention may be required
              this.setPaymentError({
                message: `unexpected quote status: ${status}`,
              });
              break;
          }
        });

      return;
    }

    // nothing to pay so fall back to synchronous fetchPolicy
    fetchPolicy({ quoteId: id });
  };

  fetchPublishableKey(quote, token) {
    if (getHasAmountToPayFromQuote(quote)) {
      observableRequest({
        url: `quotes/${quote.id}/stripe_payments/setup`,
        token,
        config: {
          method: 'POST',
        },
      })
        .pipe(pluck('response'))
        .subscribe(
          response => {
            this.setState(
              {
                stripeKey: response.publishable_key,
              },
              () => {
                this.setReadyItem();
              },
            );
          },
          error => {
            this.setErrorItem(error.response ? error.response : error);
          },
        );
      return;
    }

    this.setState(
      {
        hasAmountToPay: false,
      },
      () => {
        this.setReadyItem();
      },
    );
  }

  fetchPaymentMethods() {
    const { transaction, token } = this.props;

    const orgId = getOrganisationFromTransaction(transaction);

    if (!orgId) {
      this.setReadyItem();
      return;
    }

    observableRequest({
      url: `organisations/${orgId}/stripe_payment_methods/`,
      token,
    })
      .pipe(pluck('response'))
      .subscribe(
        response => {
          this.setState(
            {
              hasPaymentMethods: true,
              paymentMethods: response,
            },
            () => {
              this.setReadyItem();
            },
          );
        },
        () => {
          // Temporarily allow all 500s to pass, backend bug.
          this.setState(
            {
              hasPaymentMethods: false,
              paymentMethods: {},
            },
            () => {
              this.setReadyItem();
            },
          );
        },
      );
  }

  render() {
    const { t, transaction, fetchPolicy, token, ...rest } = this.props;
    const {
      stripeKey,
      hasPaymentMethods,
      paymentMethods,
      hasAmountToPay,
      paymentError,
      errors,
      ready,
      isFetching,
    } = this.state;

    const {
      quote: { id: quoteId },
    } = transaction;

    return paymentError ? (
      <Card title={t('title')}>
        <CheckoutError quoteId={quoteId} />
      </Card>
    ) : errors.length ? (
      <Card title={t('title')}>
        {errors.map((e, i) => (
          <ServerErrors key={i} errors={e} /> // eslint-disable-line react/no-array-index-key
        ))}
      </Card>
    ) : ready.length === 2 ? (
      <Elements stripe={window.Stripe(stripeKey)}>
        <CheckoutStripe
          title={t('title')}
          transaction={transaction}
          onCheckoutSuccess={this.onCheckoutSuccess}
          hasAmountToPay={hasAmountToPay}
          hasPaymentMethods={hasPaymentMethods}
          paymentMethods={paymentMethods}
          apiFetching={isFetching}
          acceptedCards={{ text: t('acceptedCardsTitle'), level: 4 }}
          {...rest}
        />
      </Elements>
    ) : null;
  }
}

Checkout.propTypes = {
  transaction: PropTypes.shape({
    id: PropTypes.string,
    quote: PropTypes.shape({
      id: PropTypes.string,
      total_price: PropTypes.string,
      status: PropTypes.string,
    }),
    owner: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  fetchPolicy: PropTypes.func.isRequired,
  fetchPolicySuccess: PropTypes.func.isRequired,
  fetchPolicyFailure: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  onPollingSuccess: PropTypes.func,
  onPollingFailure: PropTypes.func,
  queryString: PropTypes.string,
  t: PropTypes.func.isRequired,
  platformUserId: PropTypes.string,
};

Checkout.defaultProps = {
  onPollingSuccess: null,
  onPollingFailure: null,
  queryString: null,
  platformUserId: null,
};

export default withTranslation('checkout')(Checkout);
