import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthFormBox, Heading, ServerErrors, NotFound } from '@hokodo/core';
import ChoosePassword from './ChoosePassword';
import ResetPassword from './ResetPassword';

const Component = ({ match, serverErrors, ...rest }) => {
  const { t } = useTranslation('auth');

  return (
    <Switch>
      <Route
        exact
        path={`${match.path}/:uid/:token`}
        render={matchProps => <ChoosePassword {...rest} {...matchProps} />}
      />
      <Route
        exact
        path={`${match.path}/password-changed`}
        render={() => (
          <AuthFormBox>
            <Heading centered level={5}>
              {t('confirmReset.successTitle')}
            </Heading>
            <p>{t('confirmReset.successDescription')}</p>
          </AuthFormBox>
        )}
      />
      <Route
        exact
        path={`${match.path}/check-email`}
        render={() => (
          <AuthFormBox>
            <Heading centered level={5}>
              {t('reset.heading')}
            </Heading>
            <p>{t('reset.instructStep2')}</p>
          </AuthFormBox>
        )}
      />
      <Route
        exact
        path={`${match.path}/error`}
        render={() => (
          <AuthFormBox>
            <Heading centered level={5}>
              {t('reset.heading')}
            </Heading>
            {serverErrors && <ServerErrors errors={serverErrors} />}
            <p>{t('confirmReset.retryLinkText1')}</p>
            <p>
              {t('confirmReset.retryLinkText2')}{' '}
              <Link to={match.path}>{t('confirmReset.retryLinkText3')}</Link>{' '}
              {t('confirmReset.retryLinkText4')}
            </p>
          </AuthFormBox>
        )}
      />
      <Route path={match.path} render={() => <ResetPassword {...rest} />} />
      <Route component={NotFound} />
    </Switch>
  );
};

Component.propTypes = {
  match: PropTypes.object.isRequired,
  serverErrors: PropTypes.object,
};

Component.defaultProps = {
  serverErrors: null,
};

export default Component;
