import SegmentDataManager from './segmentDataManager';
import { botDetected } from './segmentDataManagerHelpers';
/**
 * If action has correct signature, push data to Segment.
 * @param {object} config (see example below)
 * @returns {Function} middleware
 *  Note:
 *    1. 'method' refers to the Segment method to call.
 *        - Accepted values include: identify, track, page, group
 *    2. if dataValue is null, no data will be pushed to Segment
 *
 * config = {
 *  actions: [
 *    {
 *      type: ['IMPORT_KASHFLOW_INVOICES_SUCCESS', 'OAUTH_SYNCHRONISE_SUCCESS'],
 *      dataKey: 'invoices_synced',
 *      dataValue: true,
 *      method: identify,
 *    },
 *    {
 *      type: 'FETCH_ALL_COLLECTIONS_SUCCESS',
 *      dataKey: 'number_of_recent_invoices',
 *      dataValue: (action, store) => action.payload.value || store.getState().value,
 *      method: identify,
 *    },
 *    {
 *      type: 'STRIPE_CHECKOUT_ERROR',
 *      dataKey: 'Payment Failure',
 *      dataValue: {msg: 'Invalid card details'},
 *      method: track,
 *    },
 *  ],
 * }
 *
 */

const segmentMiddleware = config => {
  const catchActions = config.actions.reduce(
    (acc, item) => {
      const { type, dataKey, dataValue, method } = item;
      if (Array.isArray(type)) {
        type.forEach(t => {
          acc.allTypes.push(t);
          acc.byType[t] = { dataKey, dataValue, method };
        });
        return acc;
      }
      if (typeof type === 'string') acc.allTypes.push(type);
      acc.byType[type] = { dataKey, dataValue, method };
      return acc;
    },
    { byType: {}, allTypes: [] },
  );

  const {
    PACKAGE_NAME,
    PACKAGE_VERSION,
    REPORTING_ENV,
    SEGMENT_ACTIVE,
    SEGMENT_KEY,
  } = process.env;

  const segmentDataManager = new SegmentDataManager({
    app: { name: PACKAGE_NAME, version: PACKAGE_VERSION },
    segmentKey: SEGMENT_KEY,
    segmentEnv: REPORTING_ENV,
  });

  if (botDetected()){
    return () => next => action => next(action);
  }
  return store => next =>
    SEGMENT_ACTIVE
      ? action => {
          if (catchActions.allTypes.includes(action.type)) {
            segmentDataManager.syncUserDataWithSegment(
              catchActions.byType[action.type],
              action,
              store,
            );
          }
          next(action);
        }
      : action => {
          next(action);
        };

};

export default segmentMiddleware;
