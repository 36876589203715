import React from 'react';
import PropTypes from 'prop-types';
import Context from '../../../Context';

const SupportLink = ({ children }) => (
  <Context.Consumer>
    {({ applicationContext: { SUPPORT_CONTACT } }) => (
      <a
        href={`mailto:${SUPPORT_CONTACT.email}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children || SUPPORT_CONTACT.email}
      </a>
    )}
  </Context.Consumer>
);

SupportLink.propTypes = {
  children: PropTypes.node,
};

SupportLink.defaultProps = {
  children: null,
};

export default SupportLink;
