export const extend = (...args) => {
  const merge = (target, source) => {
    const isObject = obj => obj && typeof obj === 'object';

    if (!isObject(target) || !isObject(source)) {
      return source;
    }

    Object.keys(source).forEach(key => {
      const targetValue = target[key];
      const sourceValue = source[key];
      /* eslint-disable no-param-reassign */
      if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
        target[key] = targetValue.concat(sourceValue);
      } else if (isObject(targetValue) && isObject(sourceValue)) {
        target[key] = merge(Object.assign({}, targetValue), sourceValue);
      } else {
        target[key] = sourceValue;
      }
      /* eslint-enable no-param-reassign */
    });

    return target;
  };

  return args.reduce((acc, obj) => merge(acc, obj), {});
};

export default {
  extend,
};
