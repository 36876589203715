import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import { colors } from '@styles/theme';
import { APPLICATION_CONSTANTS, Button, Tooltip } from '@hokodo/core';
import { formatLocaleCurrency } from '@hokodo/core/lib/currencyHelpers';
import { formatLocaleDate } from '@hokodo/core/lib/datetimeHelpers';

const { QUOTE_STATUSES, TRANSACTION_STATUSES } = APPLICATION_CONSTANTS;

const TRANSACTION_CTA = {
  protect: 'protect',
  protected: 'protected',
  not_eligible: 'not_eligible',
  match_debtor: 'match_debtor',
  incomplete: 'incomplete',
  paid: 'paid',
  get_quote: 'get_quote',
};

const getActionType = (transaction, lang) => {
  const { t } = useTranslation('invoice');

  const timeNow = new Date().getTime();

  // check the transaction has enough information to quote
  const requiredFields = [
    'net_amount',
    'currency',
    'issue_date',
    'due_date',
    'debtor',
    'creditor',
  ];
  const isComplete = requiredFields.every(key => !!transaction[key]);

  /* Basic Rules */
  // do we already have a policy?
  if (transaction.policy) return { cta: TRANSACTION_CTA.protected };

  // has this transaction been paid?
  if (transaction.status === TRANSACTION_STATUSES.paid)
    return { cta: TRANSACTION_CTA.paid };

  // is the invoice due date passed?
  const dueDate = transaction.due_date ? new Date(transaction.due_date) : null;
  if (dueDate && dueDate.getTime() - timeNow < 0)
    return {
      cta: TRANSACTION_CTA.not_eligible,
      tip: t('line.tooltips.notEligibleDate', {
        today: formatLocaleDate(lang, new Date()),
        due_date: formatLocaleDate(lang, new Date(dueDate)),
      }),
    };

  /* Already Quoted Rules */
  if (transaction.quote) {
    const isQuoteValid = transaction.quote.valid_until
      ? new Date(transaction.quote.valid_until).getTime() - timeNow > 0
      : false;

    // quote offered and has not expired
    if (transaction.quote.status === QUOTE_STATUSES.offered && isQuoteValid)
      return { cta: TRANSACTION_CTA.protect };

    // quote offered but has expired
    if (transaction.quote.status === QUOTE_STATUSES.offered && !isQuoteValid)
      return { cta: TRANSACTION_CTA.get_quote };

    // api notifies quote has expired
    if (transaction.quote.status === QUOTE_STATUSES.expired)
      return { cta: TRANSACTION_CTA.get_quote };

    // quote is declined
    if (transaction.quote.status === QUOTE_STATUSES.declined)
      return {
        cta: TRANSACTION_CTA.not_eligible,
        tip: transaction.quote.rejection_reason
          ? transaction.quote.rejection_reason.detail || ''
          : '',
      };
  }

  /* Imported Invoice Specific Rules */
  // transaction.source will be truthy (containing a string) if this invoice was imported from an integration
  if (transaction.source) {
    // imported invoices may not have matched debtors but _should_ be otherwise complete.
    // catch this case so it doesn't fall through to the incomplete test
    if (!transaction.debtor)
      return {
        cta: TRANSACTION_CTA.match_debtor,
        tip: t('line.tooltips.matchDebtor'),
      };
  }

  /* 
  if we are here: 
    - transaction has not been quoted
    - may not have complete information
  */
  return isComplete
    ? { cta: TRANSACTION_CTA.get_quote }
    : {
        cta: TRANSACTION_CTA.incomplete,
        tip: t('line.tooltips.incomplete'),
      };
};

const ActionButton = ({ transaction, getQuote, className, lang, ...rest }) => {
  const { t } = useTranslation('invoice');

  const { id: transactionId, quote, isFetching } = transaction;

  const onClick = event => {
    event.stopPropagation();
    getQuote({ transactionId });
  };

  const { cta, tip } = getActionType(transaction, lang);

  let element;

  switch (cta) {
    case TRANSACTION_CTA.protect:
      element = (
        <Link to={`/quote/${transactionId}`} role="button">
          <Button {...rest} className={className} color="primary">
            {t('line.buttons.protectFor', {
              price: formatLocaleCurrency(
                lang,
                quote.currency,
                quote.total_price,
                false,
              ),
            })}
          </Button>
        </Link>
      );
      break;

    case TRANSACTION_CTA.protected:
      element = (
        <span
          css={css`
            display: flex;
            justify-content: center;
            font-weight: 600;
            color: ${colors.secondary};
          `}
          className={className}
        >
          {t('line.buttons.protected')}
        </span>
      );
      break;

    case TRANSACTION_CTA.match_debtor:
      element = (
        <Button {...rest} className={className} color="secondary" disabled fake>
          {t('line.buttons.protect')}
        </Button>
      );
      break;

    case TRANSACTION_CTA.incomplete:
      element = (
        <Button {...rest} className={className} color="secondary" disabled fake>
          {t('line.buttons.incomplete')}
        </Button>
      );
      break;

    case TRANSACTION_CTA.not_eligible:
      element = (
        <Button {...rest} className={className} color="secondary" disabled fake>
          {t('line.buttons.notEligible')}
        </Button>
      );
      break;

    case TRANSACTION_CTA.paid:
      element = (
        <Button {...rest} className={className} color="secondary" disabled fake>
          {t('line.buttons.paid')}
        </Button>
      );
      break;

    case TRANSACTION_CTA.get_quote:
      element = (
        <Button
          {...rest}
          color="secondary"
          onClick={onClick}
          className={`${className} ${isFetching ? 'spinner' : ''}`}
          disabled={isFetching}
        >
          {t('line.buttons.getQuote')}
        </Button>
      );
      break;

    default:
      element = null;
  }

  return tip ? <Tooltip element={element}>{tip}</Tooltip> : element;
};

ActionButton.propTypes = {
  transaction: PropTypes.object.isRequired,
  getQuote: PropTypes.func.isRequired,
  className: PropTypes.string,
  lang: PropTypes.string.isRequired,
};

ActionButton.defaultProps = {
  className: '',
};

export default ActionButton;
