import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import { padding } from '@styles/theme';
import DropdownMenu from '@components/DropdownMenu';

const Option = ({ optionValue, setPageSize }) => {
  const onClick = event => {
    event.preventDefault();
    setPageSize({ pageSize: optionValue });
  };

  return (
    <li>
      <a href="#" role="button" onClick={onClick}>
        {optionValue}
      </a>
    </li>
  );
};

Option.propTypes = {
  optionValue: PropTypes.number.isRequired,
  setPageSize: PropTypes.func.isRequired,
};

const PageSize = ({ pageSize, ...rest }) => {
  const options = [5, 10, 25, 50, 100];

  const { t } = useTranslation('dashboard');

  return (
    <div
      css={css`
        display: flex;
      `}
    >
      <span>{t('pageSize')}</span>
      <span>&nbsp;</span>
      <DropdownMenu text={String(pageSize)} selfClosing>
        <ul
          css={css`
            padding: ${padding.menu};
          `}
        >
          {options.map(option => (
            <Option key={option} optionValue={option} {...rest} />
          ))}
        </ul>
      </DropdownMenu>
    </div>
  );
};

PageSize.propTypes = {
  pageSize: PropTypes.number.isRequired,
};

export { PageSize as default, Option };
