import { connect } from 'react-redux';
import {
  selectTransaction,
  selectIsFetching,
} from '../../../../redux/modules/transaction';
import PortalLayout from './PortalLayout';

export default connect(state => ({
  transaction: selectTransaction(state),
  isFetching: selectIsFetching(state),
}))(PortalLayout);
