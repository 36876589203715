import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { colors } from '@styles/theme';
import { Icon } from '@hokodo/core';

const LinkText = ({ children, iconType }) => (
  <span
    css={css`
      display: flex;
      align-items: center;
      text-transform: uppercase;
      font-weight: 600;
    `}
  >
    {iconType && (
      <span
        css={css`
          margin-right: 0.25rem;
          background-color: ${colors.secondary};
          color: ${colors.white};
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 24px;
          height: 24px;
        `}
      >
        <Icon type={iconType} size={18} />
      </span>
    )}
    {children}
  </span>
);

LinkText.propTypes = {
  iconType: PropTypes.string,
  children: PropTypes.node.isRequired,
};

LinkText.defaultProps = {
  iconType: null,
};

export default LinkText;
