import React from 'react';
import PropTypes from 'prop-types';
import Kashflow from '@components/integrations/Kashflow';
import Quickbooks from '@components/integrations/Quickbooks';
import Xero from '@components/integrations/Xero';

const ModalContent = ({ platform }) => {
  switch (platform) {
    case 'kashflow':
      return <Kashflow />;
    case 'quickbooks':
      return <Quickbooks />;
    case 'xero':
      return <Xero />;
    default:
      return <div>Unknown platform requested</div>;
  }
};

ModalContent.propTypes = {
  platform: PropTypes.string.isRequired,
};

export default ModalContent;
