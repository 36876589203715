import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { colors, zIndex, radius, shadow } from '@styles/theme';
import { Icon } from '@hokodo/core';

const Modal = ({ modal: { content, active, isLoader }, hideModal }) => {
  const onClick = () => {
    hideModal();
  };

  const common = css`
    position: relative;
    top: 10vh;
    margin: auto;
    border-radius: ${radius.modal};
    box-shadow: ${shadow.default};
    background-color: ${colors.white};
  `;

  return active ? (
    <div
      css={css`
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: ${zIndex.modal};
        background-color: ${colors.screen};
      `}
    >
      {isLoader ? (
        <div
          css={css`
            ${common}
            width: 30vw;
            padding: 2rem;
            text-align: center;
          `}
        >
          {content}
          <div
            css={css`
              width: 20px;
              height: 20px;
              position: relative;
              margin: 1rem auto 0;
            `}
            className="spinner"
          />
        </div>
      ) : (
        <div
          css={css`
            ${common}
            max-width: 920px;
            width: 90vw;
            height: 80vh;
            overflow-y: auto;
          `}
        >
          <div
            css={css`
              position: absolute;
              display: inline-block;
              top: 1rem;
              right: 1rem;
              cursor: pointer;
            `}
            onClick={onClick}
            role="button"
            tabIndex={0}
          >
            <Icon type="x" size={24} />
          </div>
          {content}
        </div>
      )}
    </div>
  ) : null;
};

Modal.propTypes = {
  modal: PropTypes.shape({
    active: PropTypes.bool,
    content: PropTypes.node,
    isLoader: PropTypes.bool,
  }).isRequired,
  hideModal: PropTypes.func.isRequired,
};

export default Modal;
