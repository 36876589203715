import React from 'react';
import { of, concat } from 'rxjs';
import { map, mergeMap, switchMap, catchError } from 'rxjs/operators';
import { ofType, combineEpics } from 'redux-observable';
import { selectSessionAuthToken } from '@redux/modules/session';
import { showFlash } from '@redux/modules/ui/flash';
import Notification from '@components/integrations/common/Notification';
import * as actions from './actions';

const importKashflowEpic = (action$, store$, { observableRequest }) =>
  action$.pipe(
    ofType(actions.IMPORT_KASHFLOW_INVOICES),
    switchMap(({ payload }) => {
      const token = selectSessionAuthToken(store$.value);
      return observableRequest({
        url: 'integrations/kashflow/synchronize/',
        token,
        config: {
          method: 'POST',
          body: payload,
        },
      }).pipe(
        mergeMap(({ response }) =>
          of(
            actions.importKashflowInvoicesSuccess(response),
            showFlash({ content: <Notification />, type: 'success' }),
          ),
        ),
        catchError(({ response }) =>
          concat(
            of(
              actions.importKashflowInvoicesFailure(response),
              showFlash({
                content: <Notification error={response.detail} />,
                type: 'failure',
              }),
            ),
          ),
        ),
      );
    }),
  );

const oauthSynchroniseEpic = (action$, store$, { observableRequest }) =>
  action$.pipe(
    ofType(actions.OAUTH_SYNCHRONISE),
    switchMap(({ payload }) => {
      const token = selectSessionAuthToken(store$.value);
      return observableRequest({
        url: `integrations/${payload.platform}/synchronize/`,
        token,
        config: { method: 'POST' },
      }).pipe(
        mergeMap(({ response }) => {
          return response.error && response.error === 'no-credentials'
            ? of(actions.oauthConnect(payload))
            : of(
                actions.oauthSynchroniseSuccess(response),
                showFlash({
                  content: <Notification success />,
                  type: 'success',
                }),
              );
        }),
        catchError(({ response }) =>
          concat(
            of(
              actions.oauthSynchroniseFailure(response),
              showFlash({
                content: (
                  <Notification
                    error={response ? response.detail : 'Unspecified error.'}
                  />
                ),
                type: 'failure',
              }),
            ),
          ),
        ),
      );
    }),
  );

const oauthConnectEpic = (action$, store$, { observableRequest }) =>
  action$.pipe(
    ofType(actions.OAUTH_CONNECT),
    switchMap(({ payload }) => {
      const token = selectSessionAuthToken(store$.value);
      return observableRequest({
        url: `integrations/${payload.platform}/connect/`,
        token,
        config: {
          method: 'POST',
        },
      }).pipe(
        map(({ response }) => {
          if (response.request_uri) window.location.href = response.request_uri;
          return actions.oauthConnectSuccess();
        }),
        catchError(({ response }) => of(actions.oauthConnectFailure(response))),
      );
    }),
  );

export default combineEpics(
  importKashflowEpic,
  oauthSynchroniseEpic,
  oauthConnectEpic,
);
