import React from 'react';
import PropTypes from 'prop-types';
import { pluck } from 'rxjs/operators';
import { observableRequest } from '@hokodo/core';

/* eslint-disable react/no-this-in-sfc */
export default (WrapComponent, routeProps) => {
  class WithUserLookup extends React.Component {
    constructor(props) {
      super(props);
      const {
        location: { search },
      } = props;
      const params = new URLSearchParams(search);
      const query = params.get('q');
      this.state = {
        user: null,
        complete: !query,
        query,
      };
    }

    componentDidMount() {
      const { query } = this.state;
      if (query) this.fetchUser(query);
    }

    fetchUser = query => {
      observableRequest({
        url: `auth/registration/customer/?q=${query}`,
      })
        .pipe(pluck('response'))
        .subscribe(this.onSuccess, this.onError);
    };

    onSuccess = response => {
      this.setState({
        user: response,
        complete: true,
      });
    };

    onError = () => {
      this.setState({ complete: true });
    };

    render() {
      const { complete, user } = this.state;
      return complete ? <WrapComponent user={user} /> : null;
    }
  }

  WithUserLookup.propTypes = {
    location: PropTypes.shape({
      search: PropTypes.string,
    }).isRequired,
  };

  return <WithUserLookup {...routeProps} />;
};
