import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import Context from '../../../Context';

const Textarea = ({ placeholder, name, onChange, value, disabled }) => (
  <Context.Consumer>
    {({ theme: { padding, radius, border } }) => (
      <textarea
        css={css`
          border: ${border.default};
          border-radius: ${radius.default};
          padding: ${padding.input};
          font: inherit;
          width: 100%;
          height: 5rem;

          ::placeholder {
            color: #999;
          }
        `}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
        value={value}
      />
    )}
  </Context.Consumer>
);

Textarea.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool,
};

Textarea.defaultProps = {
  name: '',
  placeholder: '',
  value: '',
  onChange: f => f,
  disabled: false,
};

export default Textarea;
