import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { zip } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  observableRequest,
  CompanyField,
  MessageBox,
  Button,
  ServerErrors,
} from '@hokodo/core';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import { Formik, Form, Field, FieldArray } from 'formik';

const CompanyMatch = ({
  unmatchedMemberships: unmatched,
  token,
  fetchUser,
}) => {
  const [validationErrors, setValidationErrors] = useState(null);

  const [patchErrors, setPatchErrors] = useState([]);

  const { t } = useTranslation('integrations');

  return (
    <div>
      {patchErrors.map(errors => (
        <ServerErrors errors={errors} />
      ))}

      <Formik
        initialValues={{
          companies: unmatched.map(
            ({
              organisation: {
                id: organisationId,
                company_name: name,
                debtor_country: country,
                debtor_regnum: regnum,
              },
            }) => ({ match: null, name, country, regnum, organisationId }),
          ),
        }}
        onSubmit={({ companies }, { setSubmitting }) => {
          if (companies.filter(c => !c.match).length) {
            setValidationErrors(t('common.companyMatchValidationError'));
            setSubmitting(false);
            return;
          }

          setValidationErrors(null);

          const ops = companies.map(c =>
            observableRequest({
              url: `organisations/${c.organisationId}`,
              token,
              config: {
                method: 'PATCH',
                body: { company: c.match.id },
              },
            }).pipe(
              // success should map to a redux action to update user.memberships - however
              // the success response is an organisation object with non-expandable company
              // key (co-id only), therefore is not suitable to update user.memberships
              // as we require state to always have complete company objects
              catchError(({ response }) => {
                setPatchErrors([...patchErrors, response]);
              }),
            ),
          );

          // refetch user to update user.memberships state with expanded companies
          zip(...ops).subscribe(() => fetchUser());
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            {validationErrors && (
              <MessageBox type="error">{validationErrors}</MessageBox>
            )}
            <FieldArray
              name="companies"
              render={() =>
                unmatched.map(
                  ({ organisation: { id, company_name } }, index) => (
                    <div
                      key={id}
                      css={css`
                        margin-bottom: 2rem;
                      `}
                    >
                      <p
                        css={css`
                          > span {
                            font-weight: 600;
                          }
                        `}
                        dangerouslySetInnerHTML={{
                          __html: t('common.companyMatchDescription', {
                            interpolation: { escapeValue: false },
                            company: company_name || '(Un-named company)',
                          }),
                        }}
                      />
                      <Field
                        name={`companies.${index}`}
                        component={CompanyField}
                      />
                    </div>
                  ),
                )
              }
            />

            <Button type="submit" disabled={isSubmitting} block>
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

CompanyMatch.propTypes = {
  unmatchedMemberships: PropTypes.array.isRequired,
  token: PropTypes.string.isRequired,
  fetchUser: PropTypes.func.isRequired,
};

export default CompanyMatch;
