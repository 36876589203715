import { connect } from 'react-redux';
import {
  fetchCollection,
  selectView,
  selectViewQuery,
  selectPageSize,
} from '@redux/modules/transactions';
import { selectUserOrganisation } from '@redux/modules/user';
import View from './View';

export default connect(
  (state, props) => ({
    userOrganisation: selectUserOrganisation(state),
    data: selectView(state, props),
    query: selectViewQuery(state, props),
    pageSize: selectPageSize(state),
  }),
  {
    fetchCollection,
  },
)(View);
