import { connect } from 'react-redux';
import {
  fetchClaim,
  createClaim,
  updateClaim,
  selectClaim,
  setFormStep,
} from '@redux/modules/claim';
import { hideModal } from '@redux/modules/ui/modal';
import Claim from './Claim';

export default connect(
  state => ({
    claim: selectClaim(state),
  }),
  {
    fetchClaim,
    createClaim,
    updateClaim,
    hideModal,
    setFormStep,
  },
)(Claim);
