import * as Yup from 'yup';
import { PACKAGE_NAME } from '../src/environment';

export const getDefaultCompanySearchCountry = () => {
  return PACKAGE_NAME === 'sparkasse-customer' ? 'DE' : 'GB';
};

export const getCompanySearchCountries = () => {
  return PACKAGE_NAME === 'sparkasse-customer'
    ? ['DE', 'GB', 'FR']
    : ['GB', 'FR'];
};

export const computeCustomerRegistrationData = (data) => {
  const regData = data;
  if (PACKAGE_NAME === 'sparkasse-customer') {
    regData.meta_data = "meta_data" in Object.keys(regData)
      ? { ...regData.meta_data, partner: 'sparkasse', country: 'DE'}
      : { partner: 'sparkasse', country: 'DE' };
  }
  return regData;
};

export const getClaimsFormsAcceptedCollectionsTermsSchema = (messages) => {
  if (PACKAGE_NAME === 'sparkasse-customer'){
    return Yup.bool();
  }
  return Yup.bool().oneOf([true], messages.required);
};

export default {
  getClaimsFormsAcceptedCollectionsTermsSchema,
  getDefaultCompanySearchCountry,
  getCompanySearchCountries,
  computeCustomerRegistrationData
};
