import { ENVIRONMENT_CONSTANTS } from '@hokodo/core';

const {
  NODE_ENV,
  ROOT_API,
  STANDALONE_API,
  AUTHORIZATION_KEY,
  SUPPORTED_CURRENCIES,
  AVAILABLE_CONTEXTS,
  EXPERIMENTAL_FEATURES,
  MULTI_LANGUAGE_ENABLED,
  DEFAULT_CONTEXT,
  PACKAGE_NAME,
  PACKAGE_VERSION,
  history,
  isDev,
  isProd,
} = ENVIRONMENT_CONSTANTS;

export {
  NODE_ENV,
  ROOT_API,
  STANDALONE_API,
  AUTHORIZATION_KEY,
  EXPERIMENTAL_FEATURES,
  SUPPORTED_CURRENCIES,
  MULTI_LANGUAGE_ENABLED,
  AVAILABLE_CONTEXTS,
  DEFAULT_CONTEXT,
  PACKAGE_NAME,
  PACKAGE_VERSION,
  history,
  isDev,
  isProd,
};
