import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { css } from '@emotion/core';
import { useTranslation } from 'react-i18next';
import Button from '../../atoms/Button';
import Context from '../../../Context';
import { getSymbolFromCurrency } from '../../../../../lib/currencyHelpers';

const CheckoutButtons = ({ quote, onSubmit, isFetching, termsChecked }) => {
  const { t } = useTranslation('checkout');

  return (
    <Context.Consumer>
      {({ queryString, applicationContext: { CHECKOUT_LEGALS }, theme: { colors} }) => (
        <div
          css={css`
            padding: 1rem 0;
              > div {
                display: grid;
                grid-template-columns: 1fr 1fr;
                > div {
                  margin: 0 2rem;
                }
              }
          `}
        >
          <div>
            <div>
              <Link to={`/${queryString}`}>
                <Button
                  block
                  outline
                  css={css`
                    border-radius: 1.5rem;
                    border: solid 1px ${colors.revampSecondary};
                    background-color: ${colors.white};
                    color: ${colors.revampSecondary};
                 `}
                >
                  {t('prevButtonText')}
                </Button>
              </Link>
            </div>
            <div>
              <Button
                block
                css={css`
                  border-radius: 1.5rem;
                  border: solid 1px ${colors.revampCTA};
                  background-color: ${colors.revampCTA};
                  color: ${colors.white};
                `}
                onClick={onSubmit}
                className={isFetching ? 'spinner' : ''}
                disabled={isFetching || (CHECKOUT_LEGALS.accept && !termsChecked)}
              >
                {t('nextButtonText', {
                  currency: getSymbolFromCurrency(quote.currency),
                  amount: quote.total_price,
                })}
              </Button>
            </div>
          </div>
        </div>
      )}
    </Context.Consumer>
  );
};

CheckoutButtons.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  termsChecked: PropTypes.bool.isRequired,
  quote: PropTypes.shape({
    total_price: PropTypes.string,
    currency: PropTypes.string,
  }).isRequired,
};

export default CheckoutButtons;
