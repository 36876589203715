import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';

const PromotionQuoteSummaryWatermark = () => {
  const { t } = useTranslation('quoteSummary');
  return (
    <div
      css={css`
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      `}
    >
      <span
        id="promotions-quote-summary-watermark"
        css={css`
          width: auto !important;
          color: red;
          font-size: 4rem;
          font-family: 'Stardos Stencil', sans-serif;
          transform: rotate(-25deg);
          opacity: 0.6;
          border: solid 3px red;
          max-width: 10em;
          text-align: center;
          padding: 0 1rem;
        `}
      >
        {t('watermark')}
      </span>
    </div>
  );
};

export default PromotionQuoteSummaryWatermark;
