import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { colors, zIndex, radius, padding, shadow } from '@styles/theme';
import { Icon } from '@hokodo/core';

class Flash extends Component {
  state = {
    messages: this.props.messages,
  };

  static getDerivedStateFromProps = props => ({
    messages: props.messages || {},
  });

  componentDidUpdate() {
    const { messages } = this.state;
    Object.keys(messages).forEach(messageId => {
      if (messages[messageId].timeout) {
        this.handleSetTimeout(messageId);
      }
    });
  }

  handleSetTimeout(id) {
    this.props.timeoutFlash(id);
    setTimeout(() => {
      this.handleDismiss(id);
    }, 3000);
  }

  handleDismiss(id) {
    this.props.hideFlash(id);
    setTimeout(() => {
      this.handleDelete(id);
    }, 1000);
  }

  handleDelete(id) {
    this.props.deleteFlash(id);
  }

  renderMessages() {
    const { messages } = this.state;

    return Object.keys(messages).map(messageId => {
      const { id, type, content, dismissable, visible } = messages[messageId];

      return (
        <button
          css={css`
            position: relative;
            border: 1px solid;
            border-radius: ${radius.default};
            width: 100%;
            min-height: 3rem;
            padding: ${padding.flash};
            margin-bottom: .5rem; 
            box-shadow: ${shadow.default};
            font: inherit;
            cursor: ${dismissable ? 'pointer' : 'default'};
            text-align: left;
            
            ${
              visible
                ? `
              opacity: 1;
              transition: opacity 0s;
            `
                : `
              pointer-events: none;
              opacity: 0;
              transition: opacity 1s;
            `
            }

            ${type === 'success' &&
              `
              background-color: ${colors.success.bg}; 
              color: ${colors.success.text}; 
              border-color: ${colors.success.border};
            `}
            ${(type === 'failure' || type === 'error') &&
              `
              background-color: ${colors.error.bg}; 
              color: ${colors.error.text}; 
              border-color: ${colors.error.border};
            `}
            ${type === 'warning' &&
              `
              background-color: ${colors.warning.bg}; 
              color: ${colors.warning.text}; 
              border-color: ${colors.warning.border};
            `}
            ${type === 'info' &&
              `
              background-color: ${colors.info.bg}; 
              color: ${colors.info.text}; 
              border-color: ${colors.info.border};
            `}
          `}
          key={id}
          type="button"
          onClick={
            dismissable
              ? () => {
                  this.handleDismiss(id);
                }
              : f => f
          }
        >
          {content}
          {dismissable && (
            <span
              css={css`
                position: absolute;
                top: 2px;
                right: 2px;
              `}
            >
              <Icon type="x" />
            </span>
          )}
        </button>
      );
    });
  }

  render() {
    return (
      <div
        css={css`
          position: absolute;
          top: 0.5rem;
          right: 0.5rem;
          width: 250px;
          z-index: ${zIndex.flash};
        `}
      >
        {this.renderMessages()}
      </div>
    );
  }
}

Flash.propTypes = {
  messages: PropTypes.object,
  deleteFlash: PropTypes.func.isRequired,
  hideFlash: PropTypes.func.isRequired,
  timeoutFlash: PropTypes.func.isRequired,
};

Flash.defaultProps = {
  messages: {},
};

export default Flash;
