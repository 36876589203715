import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { useTranslation } from 'react-i18next';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { deMultispace } from '../../../../../lib/stringHelpers';
import Checkbox from '../../atoms/Checkbox';
import Context from '../../../Context';

const CardPaymentForm = props => {
  const { t } = useTranslation('checkout');
  const {
    fieldValues,
    setFieldValue,
    errors,
    allowSave,
    allowCancel,
    onCancel,
  } = props;

  const onCardnameChange = event => {
    setFieldValue({
      cardname: deMultispace(event.target.value).replace(
        /^[\s\uFEFF\xA0]|[^A-Za-zÀ-ž.-\s\uFEFF\xA0]/g,
        '',
      ),
    });
  };

  const onPostcodeChange = event => {
    setFieldValue({
      postcode: deMultispace(event.target.value).replace(
        /^[\s\uFEFF\xA0]|[^A-Za-z0-9À-ž.-\s\uFEFF\xA0]/g,
        '',
      ),
    });
  };

  const onSaveCardDetailsChange = () => {
    setFieldValue({
      saveCardDetails: !fieldValues.saveCardDetails,
    });
  };

  return (
    <Context.Consumer>
      {({ theme: { radius, padding, colors } }) => {
        const styles =
          props.styles ||
          css`
            > div {
              margin: 1rem 0;
            }

            label {
              display: block;
              margin-bottom: 0.5rem;
              font-weight: 600;
            }

            .stripe-input {
              border: 1px solid #999;
              border-radius: ${radius.default};
              padding: ${padding.input};
              font-size: inherit;
              width: 100%;
              background-color: ${colors.white};

              ::placeholder {
                color: currentColor;
              }

              &.hasError {
                ::placeholder {
                  color: red;
                }
              }
            }
          `;
        return (
          <div
            css={css`
              ${styles}
            `}
          >
            {allowCancel && (
              <div
                css={css`
                  text-align: right;
                `}
              >
                <a
                  href="#"
                  role="button"
                  onClick={event => {
                    event.preventDefault();
                    onCancel();
                  }}
                >
                  {t('payment.cancelAddCard')}
                </a>
              </div>
            )}

            <div>
              <label htmlFor="cardname">{t('payment.labelCardName')}</label>
              <input
                name="cardname"
                maxLength="255"
                placeholder={t('payment.labelCardName')}
                // value={fieldValues.cardname}
                onChange={onCardnameChange}
                className={`stripe-input ${
                  errors.fieldErrors && errors.fieldErrors.cardname
                    ? 'hasError'
                    : ''
                }`}
              />
            </div>

            <div>
              <label htmlFor="postcode">{t('payment.labelPostcode')}</label>
              <input
                name="postcode"
                maxLength="255"
                placeholder={t('payment.labelPostcode')}
                value={fieldValues.postcode}
                onChange={onPostcodeChange}
                className={`stripe-input ${
                  errors.fieldErrors && errors.fieldErrors.postcode
                    ? 'hasError'
                    : ''
                }`}
              />
            </div>

            <div>
              <label htmlFor="cardnumber">{t('payment.labelCCN')}</label>
              <CardNumberElement className="stripe-input" />
            </div>

            <div>
              <label htmlFor="exp-date">{t('payment.labelExp')}</label>
              <CardExpiryElement className="stripe-input" />
            </div>

            <div>
              <label htmlFor="cvc">{t('payment.labelCVC')}</label>
              <CardCvcElement className="stripe-input" />
            </div>

            {allowSave && (
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  label {
                    margin: 0 0 0 0.5rem;
                  }
                `}
              >
                <Checkbox
                  checked={fieldValues.saveCardDetails}
                  onChange={onSaveCardDetailsChange}
                />
                <label htmlFor="saveCardDetails">{t('payment.saveCard')}</label>
              </div>
            )}
          </div>
        );
      }}
    </Context.Consumer>
  );
};

CardPaymentForm.propTypes = {
  styles: PropTypes.object,
  fieldValues: PropTypes.shape({
    cardname: PropTypes.string,
    postcode: PropTypes.string,
    saveCardDetails: PropTypes.bool,
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  allowSave: PropTypes.bool,
  allowCancel: PropTypes.bool,
  onCancel: PropTypes.func,
};

CardPaymentForm.defaultProps = {
  styles: null,
  allowSave: false,
  allowCancel: false,
  onCancel: f => f,
};

export default CardPaymentForm;
