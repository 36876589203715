import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import Context from '../../../Context';


const SectionHeader = ({ content }) => {

  return (
    <Context.Consumer>
      {({ theme: { colors } }) => (
        <header
          css={css`
            margin: 4rem;
          `}
        >
          <div
            css={css`
              text-align: center;
              color: ${colors.accent};
              font-weight: 600;
              font-size: 22px;
            `}
          >
            {content}
          </div>
        </header>
      )}
    </Context.Consumer>
  );
};

SectionHeader.propTypes = {
  content: PropTypes.string,
};

SectionHeader.defaultProps = {
  content: "",
};

export default SectionHeader;
