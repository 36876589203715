import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Dashboard from '@components/Dashboard';
import ModalContent from '../ModalContent';

const OauthReturn = ({
  platform,
  showFlash,
  showModal,
  history,
  location: { search },
}) => {
  useEffect(() => {
    const error = new URLSearchParams(search).get('error');
    showFlash({
      content: error
        ? `Unable to connect to ${platform.toUpperCase()}`
        : `Connected to ${platform.toUpperCase()}`,
      type: error ? 'error' : 'info',
      timeout: true,
    });
    if (!error) showModal({ content: <ModalContent platform={platform} /> });
    history.replace('/');
  }, []);

  return <Dashboard />;
};

OauthReturn.propTypes = {
  platform: PropTypes.oneOf(['quickbooks', 'xero']).isRequired,
  showFlash: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default OauthReturn;
