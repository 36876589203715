/* eslint-disable import/prefer-default-export */
import * as Sentry from '@sentry/browser';

const { REPORTING_ENV, SENTRY_DSN } = process.env;

const SENTRY_ACTIVE =
  process.env.SENTRY_ACTIVE &&
  process.env.SENTRY_ACTIVE.toLocaleUpperCase() === 'TRUE';

if (SENTRY_ACTIVE) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: REPORTING_ENV,
  });
}

export const logException = error => {
  if (SENTRY_ACTIVE) {
    Sentry.captureException(error);
  } else {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};
