import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { css } from '@emotion/core';
import { colors, radius } from '@styles/theme';
import { Context, Heading, Accordion, Icon } from '@hokodo/core';
import { faqContent } from '@localisation';

const Help = () => {
  const { t } = useTranslation('help');

  return (
    <Context.Consumer>
      {({
        lang,
        applicationContext: {
          SUPPORT_CONTACT,
          COLLECTION_PERC,
          SPECIMEN_POLICY,
          FAQ_URL
        },
      }) => {
        const faqItems = faqContent[lang].map(({ title, body }) => ({
          title,
          body: (
            <ReactMarkdown
              source={body
                .replace(/{{COLLECTION_PERC}}/, COLLECTION_PERC)
                .replace(/{{SPECIMEN_POLICY}}/, SPECIMEN_POLICY)
                .replace(/{{FAQ_URL}}/, FAQ_URL)}
            />
          ),
        }));

        return (
          <Fragment>
            <div
              css={css`
                background-color: ${colors.white};
                border-radius: ${radius.default};
                padding: 2rem;
                margin-bottom: 2rem;
              `}
            >
              <Heading level={1}>{t('title')}</Heading>

              <div>
                {[...Array(4).keys()].map(key => (
                  <div key={`help-card-${key}`}>
                    <Heading level={5} color={colors.primary}>
                      {t('step', { step: String(key + 1) })}
                    </Heading>
                    <Heading level={4}>{t(`cards.${key}.title`)}</Heading>
                    <p>{t(`cards.${key}.description`, { COLLECTION_PERC })}</p>
                  </div>
                ))}
                <p>
                  <sup>(1) </sup>
                  {t('subscript')}
                </p>
              </div>
            </div>

            <div
              css={css`
                background-color: ${colors.white};
                border-radius: ${radius.default};
                padding: 2rem;
              `}
            >
              <Heading level={5} color={colors.primary}>
                {t('faq.title')}
              </Heading>
              <Heading level={1}>{t('faq.description')}</Heading>

              <div
                css={css`
                  margin: 2rem 0;

                  ul {
                    margin-left: 2rem;
                  }
                  li {
                    position: relative;
                    padding-left: 1rem;
                    margin-bottom: 1rem;
                    line-height: 1.5;
                    ::before {
                      content: '';
                      position: absolute;
                      display: block;
                      background-color: ${colors.primary};
                      border: 1px solid ${colors.primary};
                      border-radius: 50%;
                      width: 0.5rem;
                      height: 0.5rem;
                      left: 0;
                      top: 0.35rem;
                    }
                    > ul {
                      margin-top: 1rem;
                      li {
                        ::before {
                          background-color: transparent;
                        }
                      }
                    }
                  }
                `}
              >
                <Accordion items={faqItems} />
              </div>

              <div
                css={css`
                  a {
                    display: inline-flex;
                    &:first-of-type {
                      margin-right: 2rem;
                    }
                    i {
                      margin-right: 0.25rem;
                    }
                  }
                `}
              >
                <a
                  href={`mailto:${SUPPORT_CONTACT.email}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon type="mail" />
                  {SUPPORT_CONTACT.email}
                </a>

                <a href={`tel:${SUPPORT_CONTACT.phone}`}>
                  <Icon type="phone" />
                  {SUPPORT_CONTACT.phone}
                </a>
              </div>
            </div>
          </Fragment>
        );
      }}
    </Context.Consumer>
  );
};

export default Help;
