import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import Input from '../../atoms/Input';
import Label from '../../atoms/Label';

const TextField = ({ label, placeholder, form, field, disabled, onFocus }) => {
  const { name, value = '', onBlur, onChange } = field;
  const { errors, touched } = form;

  return (
    <div
      css={css`
        margin-bottom: 1rem;
      `}
    >
      {label && <Label text={label} htmlFor={name} />}
      <Input
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        disabled={disabled}
        onFocus={onFocus}
      />
      {errors[name] && touched[name] && (
        <div className="error">{errors[name]}</div>
      )}
    </div>
  );
};

TextField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.object,
    touched: PropTypes.object,
  }).isRequired,
  disabled: PropTypes.bool,
  onFocus: PropTypes.func,
};

TextField.defaultProps = {
  label: '',
  placeholder: '',
  disabled: false,
  onFocus: f => f,
};

export default TextField;
