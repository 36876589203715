/* eslint-disable max-len */
export default [
  {
    title: 'What does Hokodo cover?',
    body: `
We protect you against non-payment of a valid invoice, either because the customer is insolvent or because they won’t pay.
    
We will not protect you against:
    
* Non-payment of a disputed invoice: you will need to settle the dispute with your customer before we will consider a claim.
* Damage or loss of goods in transit.
* Activity by a government which prevents your invoice being paid such as war, requisition of property, currency controls, etc.
* Claims which are the result of fraud or dishonesty by your company.
* Claims which would require us to breach sanctions or anti-money laundering regulations.
    `,
  },
  {
    title: 'How does it work?',
    body: `
* Once an invoice falls due, you will attempt collection as usual.
* If the customer still hasn’t paid after 45 days, you hand the invoice to our specialist collections partner, who will collect the invoice on your behalf.
* If the collections partner is successful, they will pay you the collected funds less a collections fee of {{COLLECTION_PERC}}% + VAT. They may be able to collect this collections fee from your customer (optional), in which case you will receive the full balance.
* If the collections partner is unable to collect the invoice within 60 days, then we will pay you 90% of the net value of the invoice.
    `,
  },
  {
    title: 'Is Hokodo a debt collector?',
    body: `
* Debt collection is provided by our collections partner. It is compulsory to use our collections partner if you want to be able to make a claim. If you do not wish to use our collections partner then you should not purchase this invoice protection cover.
* Once your invoice reaches 45 days overdue, you will be able to hand the invoice to our collections partner. However, you do not need to do so immediately if you don’t wish to: you have a 30-day window during which you can continue to collect yourself (i.e. up to 75 days overdue). But if you have not handed the invoice to our partner by the end of this 30-day window then you will not be able to make a claim.
* Once you have handed the invoice to our collections partner, a collections fee of {{COLLECTION_PERC}}% + VAT will be due on any money they collect.
* If the collections partner is unable to collect the invoice within 60 days, we will pay you the protected amount (90% of the invoice net value).
    `,
  },
  {
    title: 'What happens if a customer disputes the invoice?',
    body: `
* Sometimes customers dispute the validity of an invoice they have been sent. This can happen for a variety of reasons. For example, they may say the goods/services supplied were defective or of poor quality; or they may say they didn't order the goods or services being invoiced.
* In this case, you will need to resolve this dispute with your customer before we can consider your claim for an unpaid invoice: we will put your claim on hold in the mean time.
* Once you have resolved the dispute (either through negotiation or through legal means), and agreed with your customer the amount due, your cover will restart.
* If your customer still fails to pay you the agreed amount, then we will settle your claim (up to 90% of the original invoice net value, or the amount agreed to be due, whichever is lower).
    `,
  },
  {
    title: 'What am I signing up for?',
    body: `
* You must provide us with a valid invoice which has been issued within 30 days of the supply of the goods and/or services. This must be supported by evidence that the buyer confirmed purchase, such as a purchase order, e-mail confirmation, and/or a binding contract.
  * If you only received verbal confirmation of the order, then you can still purchase Invoice Protection. However, we will be unable to pay a claim for your invoice if your customer disputes having placed the order.
       
* You must confirm that to the best of your knowledge the customer is not insolvent or unable to pay the invoice at the time you purchase the protection, and that the customer does not have any other outstanding invoices with you which are already more than 45 days overdue.  
  * We do not expect you to undertake any additional investigation of your customer's solvency; but if in your regular course of business you discover that your customer is likely to be unable to pay your invoice then you will not be able to subsequently purchase Hokodo's Invoice Protection cover.
          
* You must take reasonable steps to minimise bad debt. This includes not extending the payment terms beyond the original invoice, and not accepting payment plans from your customers.

* You must comply with Hokodo’s reasonable requests including assigning your unpaid invoices to another party (subrogating the debt) as part of our claims settlement process.
    `,
  },
  {
    title: 'Who underwrites my insurance policy?',
    body: `
Your insurance is backed by Lloyd’s of London’s Channel Syndicate, a unit of SCOR Global P&C, the non-life segment of the SCOR Group.
    `,
  },
  {
    title: 'What happens to the VAT on my invoice?',
    body: `
* Hokodo covers 90% of your invoice net of VAT
* If your invoice included VAT on top of the net amount you’re owed, you’re usually able to claim VAT Bad Debt Relief from HMRC. Your accountant will be able to advise you on how to do this
    `,
  },
  {
    title:
      'Do I need a purchase order or other confirmation from the customer in order to buy Invoice Protection?',
    body: `
Hokodo doesn't require written confirmation for you to buy Invoice Protection. However, in the event that the buyer disputes having placed the order, then without written confirmation you won't be covered. But so long as the buyer doesn't dispute the order, then lack of written confirmation isn't a problem.
    `,
  },
  {
    title: 'Please give me more legal information about Hokodo.',
    body: `
Hokodo Services Ltd:

* is a company registered in England, with registered address 20-22 Wenlock Road, London, N1 7GU, and Companies House registration number 11351988
* is an appointed representative of Innovative Risk Ltd. Innovative Risk Ltd is authorised and regulated by the Financial Conduct Authority with FCA registration number: 609155
* has no holding representing 10% or more of the voting rights or capital in our insurance carrier and neither does our insurance carrier have a holding representing 10% or more of the voting rights or capital in us
* acts for and on behalf of the underwriters named on our policy documents
    `,
  },
  {
    title: 'How does Hokodo get paid?',
    body: `
* Hokodo Services arranges the policy with the insurer on your behalf. You do not pay us a fee for doing this. We receive commission from the insurer which is a percentage of the total premium.
* We also receive a profit commission based on the underwriting profitability of the business we arrange.
    `,
  },
  {
    title: 'Still want more details?',
    body: `
* Download a Policy specimen: [Specimen Policy]({{SPECIMEN_POLICY}})
* Contact us via the email or telephone number below
    `,
  },
];
