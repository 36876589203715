import { of } from 'rxjs';
import { map, mergeMap, switchMap, catchError } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import { push } from 'connected-react-router';
import {
  selectSessionAuthToken,
  selectSessionQuery,
} from '../session/selectors';
import * as actions from './actions';

export const fetchTransactionEpic = (action$, store$, { observableRequest }) =>
  action$.pipe(
    ofType(actions.FETCH_TRANSACTION),
    switchMap(({ payload }) => {
      const token = selectSessionAuthToken(store$.value);
      const queryString = selectSessionQuery(store$.value);
      const { transactionId } = payload;
      return observableRequest({
        url: `transactions/${transactionId}/?expand=quote,policy,debtor,creditor,owner`,
        token,
      }).pipe(
        mergeMap(({ response }) => {
          const { quote, policy, ...rest } = response;
          if (policy)
            return of(
              actions.fetchTransactionSuccess(response),
              push(`/policy${queryString}`),
            );

          if (quote && quote.rejection_reason)
            return of(
              actions.fetchTransactionSuccess(response),
              push(`/declined${queryString}`),
            );

          if (quote && quote.valid_until) {
            if (new Date(quote.valid_until).getTime() > new Date().getTime())
              return of(actions.fetchTransactionSuccess(response));
          }

          return of(
            actions.fetchTransactionSuccess({
              quote: null,
              policy: null,
              ...rest,
            }),
            actions.fetchQuote({ transactionId }),
          );
        }),
        catchError(({ response }) =>
          of(actions.fetchTransactionFailure(response)),
        ),
      );
    }),
  );

export const updateTransactionEpic = (action$, store$, { observableRequest }) =>
  action$.pipe(
    ofType(actions.UPDATE_TRANSACTION),
    switchMap(({ payload }) => {
      const token = selectSessionAuthToken(store$.value);
      const { transactionId, ...rest } = payload;
      return observableRequest({
        url: `transactions/${transactionId}/`,
        token,
        config: {
          method: 'PATCH',
          body: rest,
        },
      }).pipe(
        map(() => actions.fetchTransaction({ transactionId })),
        catchError(({ response }) =>
          of(actions.updateTransactionFailure(response)),
        ),
      );
    }),
  );

export const fetchQuoteEpic = (action$, store$, { observableRequest }) =>
  action$.pipe(
    ofType(actions.FETCH_QUOTE),
    switchMap(({ payload }) => {
      const queryString = selectSessionQuery(store$.value);
      const token = selectSessionAuthToken(store$.value);
      const { transactionId } = payload;
      return observableRequest({
        url: 'quotes/',
        token,
        config: {
          method: 'POST',
          body: {
            transaction: transactionId,
          },
        },
      }).pipe(
        mergeMap(({ response }) => {
          const { rejection_reason } = response;
          if (rejection_reason)
            return of(
              actions.fetchQuoteSuccess(response),
              push(`/declined${queryString}`),
            );

          // and now we need to fully rehydrate:
          // - quote.client.country (required to set the locale context)
          // - transaction.debtor
          // - transaction.client
          // - transaction.owner
          return of(actions.fetchTransaction({ transactionId }));
        }),
        catchError(({ response }) => of(actions.fetchQuoteFailure(response))),
      );
    }),
  );

export const fetchExpiredQuoteEpic = (action$, store$, { observableRequest }) =>
  action$.pipe(
    ofType(actions.FETCH_EXPIRED_QUOTE),
    switchMap(({ payload }) => {
      const queryString = selectSessionQuery(store$.value);
      const token = selectSessionAuthToken(store$.value);
      const { transactionId } = payload;
      return observableRequest({
        url: 'quotes/',
        token,
        config: {
          method: 'POST',
          body: {
            transaction: transactionId,
          },
        },
      }).pipe(
        mergeMap(({ response }) => {
          const { rejection_reason } = response;
          if (rejection_reason)
            return of(
              actions.fetchQuoteSuccess(response),
              push(`/declined${queryString}`),
            );

          return of(
            actions.fetchQuoteSuccess(response),
            push(`/checkout${queryString}`),
          );
        }),
        catchError(({ response }) => of(actions.fetchQuoteFailure(response))),
      );
    }),
  );

export const fetchPolicyEpic = (action$, store$, { observableRequest }) =>
  action$.pipe(
    ofType(actions.FETCH_POLICY),
    switchMap(({ payload }) => {
      const token = selectSessionAuthToken(store$.value);
      const queryString = selectSessionQuery(store$.value);
      const { quoteId } = payload;
      return observableRequest({
        url: 'policies/',
        token,
        config: {
          method: 'POST',
          body: {
            quote: quoteId,
          },
        },
      }).pipe(
        mergeMap(({ response }) =>
          of(
            actions.fetchPolicySuccess(response),
            push(`/policy${queryString}`),
          ),
        ),
        catchError(({ response }) => of(actions.fetchPolicyFailure(response))),
      );
    }),
  );

export default combineEpics(
  fetchTransactionEpic,
  updateTransactionEpic,
  fetchExpiredQuoteEpic,
  fetchQuoteEpic,
  fetchPolicyEpic,
);
