import { createPrefix, createAction } from '@hokodo/core/lib/actionHelpers';

const prefix = createPrefix('user');

export const FETCH_USER = prefix('FETCH_USER');
export const FETCH_USER_SUCCESS = prefix('FETCH_USER_SUCCESS');
export const FETCH_USER_FAILURE = prefix('FETCH_USER_FAILURE');

export const fetchUser = createAction(FETCH_USER);
export const fetchUserSuccess = createAction(FETCH_USER_SUCCESS);
export const fetchUserFailure = createAction(FETCH_USER_FAILURE);

export const UPDATE_USER_PROFILE = prefix('UPDATE_USER_PROFILE');
export const UPDATE_USER_PROFILE_SUCCESS = prefix(
  'UPDATE_USER_PROFILE_SUCCESS',
);
export const UPDATE_USER_PROFILE_FAILURE = prefix(
  'UPDATE_USER_PROFILE_FAILURE',
);

export const updateUserProfile = createAction(UPDATE_USER_PROFILE);
export const updateUserProfileSuccess = createAction(
  UPDATE_USER_PROFILE_SUCCESS,
);
export const updateUserProfileFailure = createAction(
  UPDATE_USER_PROFILE_FAILURE,
);

export const SET_CURRENT_MEMBERSHIP = prefix('SET_CURRENT_MEMBERSHIP');
export const setCurrentMembership = createAction(SET_CURRENT_MEMBERSHIP);

export const SET_USER_PROFILE_METADATA = prefix('SET_USER_PROFILE_METADATA');
export const setUserProfileMetadata = createAction(SET_USER_PROFILE_METADATA);

export const SET_HAS_PURCHASED_POLICY = prefix('SET_HAS_PURCHASED_POLICY');
export const setHasPurchasedPolicy = createAction(SET_HAS_PURCHASED_POLICY);
