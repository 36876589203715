import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import Heading from '../../atoms/Heading';
import Context from '../../../Context';
import Card from '../../atoms/Card';
import { formatLocaleCurrency } from '../../../../../lib/currencyHelpers';
import { formatLocaleDate } from '../../../../../lib/datetimeHelpers';
import { selectTransaction } from '../../../../redux/modules/transaction';
import ProtectionTimeline from '../ProtectionTimeline';

const Policy = () => {
  const { t } = useTranslation('policy');
  const transaction = useSelector(state => selectTransaction(state));

  return transaction ? (
    <Context.Consumer>
      {({ lang, theme: { breakpoints, padding, colors }, applicationContext: { CANCELLATION_WINDOW } }) => {
        const { policy, quote } = transaction;

        return (
          <Fragment>
            {ReactDOM.createPortal(
              <div
                css={css`
                  text-align: center;
                  background: ${colors.white};
                  padding: 2rem 0;
                  margin-bottom: 2rem;
                `}
              >
                <Heading level={3}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t('title'),
                    }}
                  />
                </Heading>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t('subtitle', {
                      interpolation: { escapeValue: false },
                      email: '', // Backend dev required
                    }),
                  }}
                  css={css`
                    font-size: 18px;
                    em {
                      font-weight: 600;
                    }
                  `}
                />
              </div>,
              document.querySelector('#add_header'),
            )}

            <div
              css={css`
                display: grid;

                @media (min-width: ${breakpoints.lg}) {
                  grid-template-columns: 1fr 1fr;
                }

                > div {
                  padding: 0 ${padding.default};
                }
              `}
            >
              <div>
                <Card title={t('protectionDescription')}>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t('policyNumber', {
                        interpolation: { escapeValue: false },
                        policy: policy.number,
                      }),
                    }}
                    css={css`
                      font-size: 20px;
                      font-weight: 600;
                      em {
                        display: inline-block;
                        font-weight: 700;
                        margin-left: 1.5rem;
                      }
                    `}
                  />
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t('purchaseDate', {
                        interpolation: { escapeValue: false },
                        date: formatLocaleDate(lang, new Date(policy.created)),
                      }),
                    }}
                    css={css`
                      em {
                        display: inline-block;
                        margin-left: 1.5rem;
                      }
                    `}
                  />
                  <ul
                    css={css`
                      list-style-type: disc;
                      padding-left: 1rem;
                      li > div {
                        display: flex;
                        > span {
                          width: 50%;
                        }
                      }
                    `}
                  >
                    <li>
                      <div>
                        <span>{t('line1')}</span>
                        <span>{`${
                          transaction.debtor
                            ? transaction.debtor.name
                            : transaction.debtor_name
                        } ${transaction.number}`}</span>
                      </div>
                    </li>
                  </ul>
                </Card>
              </div>

              <div>
                <Card title={t('paymentDescription')}>
                  <p
                    css={css`
                      font-size: 18px;
                      font-weight: 600;
                    `}
                  >
                    {t('paymentMethod')}
                  </p>
                  <div
                    css={css`
                      margin-bottom: 2rem;
                      padding-bottom: 2rem;
                      border-bottom: 2px dashed rgba(165, 165, 165, 0.5);
                      > div {
                        padding: 1.5rem 1rem;
                        background: rgba(0, 70, 254, 0.05);
                      }
                    `}
                  >
                    <div>**** **** **** ****</div>
                  </div>

                  <div
                    css={css`
                      display: flex;
                      text-transform: uppercase;
                      align-items: center;
                      span {
                        font-size: 18px;
                        font-weight: 600;
                        &:last-of-type {
                          display: inline-block;
                          margin-left: 1.5rem;
                          color: ${colors.primary};
                          font-size: 28px;
                          font-weight: 700;
                        }
                      }
                    `}
                  >
                    <span>{t('total')}</span>
                    <span>
                      {formatLocaleCurrency(
                        lang,
                        quote.currency,
                        quote.total_price,
                      )}
                    </span>
                  </div>

                  <div>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: t('tax', {
                          interpolation: { escapeValue: false },
                          tax: formatLocaleCurrency(
                            lang,
                            quote.currency,
                            quote.ipt,
                          ),
                        }),
                      }}
                      css={css`
                        font-size: 14px;
                        em {
                          margin-left: 1.5em;
                        }
                      `}
                    />

                    <p
                      dangerouslySetInnerHTML={{
                        __html: t('cancelation', {
                          interpolation: { escapeValue: false },
                          tax: formatLocaleCurrency(
                            lang,
                            quote.currency,
                            quote.ipt,
                          ),
                          cancellation_window: CANCELLATION_WINDOW
                        }),
                      }}
                      css={css`
                        font-size: 14px;
                        em {
                          font-weight: 600;
                        }
                      `}
                    />
                  </div>
                </Card>
              </div>
            </div>

            <div
              css={css`
                padding: 0 ${padding.default};
              `}
            >
              <ProtectionTimeline policy />
            </div>
          </Fragment>
        );
      }}
    </Context.Consumer>
  ) : null;
};

export default Policy;
