import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import Icon from '../Icon';
import Context from '../../../Context';

class Checkbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = { checked: props.checked };
  }

  handleClick = event => {
    event.preventDefault();
    event.stopPropagation();
    const { disabled, onChange } = this.props;
    if (disabled) return;
    this.setState(
      ({ checked }) => ({ checked: !checked }),
      () => {
        onChange(this.state.checked);
      },
    );
  };

  render() {
    const { tabIndex } = this.props;
    const { checked } = this.state;

    return (
      <Context.Consumer>
        {({ theme: { colors, radius } }) => (
          <div
            css={css`
              display: inline-block;
            `}
            onClick={this.handleClick}
            role="button"
            tabIndex={tabIndex}
          >
            <div
              css={css`
                width: 16px;
                height: 16px;
                border: 1px solid rgba(0, 0, 0, 0.3);
                border-radius: ${radius.default};
                overflow: hidden;
              `}
            >
              <div
                css={css`
                  width: 100%;
                  height: 100%;
                  background: ${checked ? 'rgba(0, 0, 0, .4)' : 'transparent'};
                  position: relative;
                  i {
                    postion: absolute;
                    top: -3px;
                  }
                `}
              >
                {checked && <Icon type="check" color={colors.white} />}
              </div>
            </div>
          </div>
        )}
      </Context.Consumer>
    );
  }
}

Checkbox.propTypes = {
  onChange: PropTypes.func,
  tabIndex: PropTypes.number,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
};

Checkbox.defaultProps = {
  onChange: f => f,
  tabIndex: 0,
  checked: false,
  disabled: false,
};

export default Checkbox;
