import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { getCompanySearchCountries } from "@hokodo/core/lib/portalConfigurationHelpers";
import Context from '../../../../Context';

const LocaleSelectItem = ({ locale, onClick }) => {
  const handleClick = event => {
    event.preventDefault();
    event.stopPropagation();
    onClick(locale);
  };

  return (
    <li className="f32">
      <a href="#" onClick={handleClick}>
        <i className={`${locale.toLowerCase()} flag`} />
      </a>
    </li>
  );
};

LocaleSelectItem.propTypes = {
  locale: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};


const LocaleSelect = ({ onClick }) => (
  <Context.Consumer>
    {({ theme: { zIndex, radius, colors, shadow } }) => {
      const availableLocales = getCompanySearchCountries();
      return (
        <ul
          css={css`
            position: absolute;
            top: 2.75rem;
            z-index: ${zIndex.matchCompany + 1};
            border-radius: ${radius.default};
            background-color: ${colors.white};
            box-shadow: ${shadow.default};
            li {
              padding: 0.5rem;
              .flag {
                transform: scale(0.6);
              }
            }
          `}
        >
          {availableLocales.map(locale => (
            <LocaleSelectItem key={locale} onClick={onClick} locale={locale} />
          ))}
        </ul>
      );
    }}
  </Context.Consumer>
);

LocaleSelect.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export { LocaleSelect, LocaleSelectItem, LocaleSelect as default };
