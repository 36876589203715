import React from 'react';
import { css } from '@emotion/core';

const ViewItemBlank = () => (
  <div
    css={css`
      height: 3.7rem;
      background: #fefefe;
      border-bottom: 1px solid #dee2e6;
    `}
  />
);

export default ViewItemBlank;
