import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading } from '@hokodo/core';
import Transactions from '@components/Transactions';

const Dashboard = () => {
  const { t } = useTranslation('dashboard');

  return (
    <Fragment>
      <Heading level={1}>{t('title')}</Heading>
      <Transactions />
    </Fragment>
  );
};

export default Dashboard;
