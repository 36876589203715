import { createPrefix, createAction } from '../../../../lib/actionHelpers';

const prefix = createPrefix('tracking');

export const TRACK_EVENT = prefix('TRACK_EVENT');
/*
 * trackEvent payload must have the following structure:
 * {
 *   "dataKey": "Some data key",
 *   "dataValue": "Some data value",
 * }
 */
export const trackEvent = createAction(TRACK_EVENT);
