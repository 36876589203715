import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  selectCurrentMembership,
  fetchUser,
  selectUserProfile,
  updateUserProfile,
  selectIsFetching,
} from '@redux/modules/user';
import { selectSessionAuthToken } from '@redux/modules/session';
import UserAccount from './UserAccount';

export default withRouter(
  connect(
    state => ({
      currentMembership: selectCurrentMembership(state),
      profile: selectUserProfile(state),
      isFetching: selectIsFetching(state),
      token: selectSessionAuthToken(state),
    }),
    {
      fetchUser,
      updateUserProfile,
    },
  )(UserAccount),
);
