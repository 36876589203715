import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { useTranslation } from 'react-i18next';
import Checkbox from '../../atoms/Checkbox';
import Context from '../../../Context';
import ProtectedSVG from '../../../../assets/img/protected.svg';

const CheckoutLegals = ({ termsChecked, onTermsCheckboxChange, hasAmountToPay }) => {
  const { t } = useTranslation('checkout');

  return (
    <Context.Consumer>
      {({
        theme: { border },
        applicationContext: {
          FAQ_URL,
          SPECIMEN_POLICY,
          STRIPE_URL,
          CHECKOUT_LEGALS,
          CANCELLATION_WINDOW,
        },
      }) => (
        <div
          css={css`
            margin-bottom: 0 !important;
            > div {
              margin-bottom: 1rem;
            }
          `}
        >
          {CHECKOUT_LEGALS.stripe && hasAmountToPay && (
            <div
              css={css`
                display: flex;
                > img {
                  margin: 0 0.5rem;
                  width: 1.5rem;
                  height: 1.5rem;
                }
              `}
            >
              <img alt="" src={ProtectedSVG} />

              <div
                dangerouslySetInnerHTML={{
                  __html: t('payment.descriptionStripe', {
                    interpolation: { escapeValue: false },
                    stripeUrl: STRIPE_URL,
                  }),
                }}
              />
            </div>
          )}

          {CHECKOUT_LEGALS.cover && (
            <div
              dangerouslySetInnerHTML={{
                __html: t('payment.descriptionCover', {
                  interpolation: { escapeValue: false },
                  specimenUrl: SPECIMEN_POLICY,
                  faqUrl: FAQ_URL,
                  cancellation_window: CANCELLATION_WINDOW,
                }),
              }}
            />
          )}

          {CHECKOUT_LEGALS.accept && (
            <div
              css={css`
                border-top: ${border.default};
                padding-top: 1.5rem;
              `}
            >
              <div
                css={css`
                  display: flex;
                  align-items: flex-start;
                `}
              >
                <div
                  css={css`
                    margin-right: 1rem;
                  `}
                >
                  <Checkbox
                    checked={termsChecked}
                    onChange={() => {
                      onTermsCheckboxChange();
                    }}
                  />
                </div>

                <div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: t('payment.acceptTerms1', {
                        interpolation: { escapeValue: false },
                        specimenUrl: SPECIMEN_POLICY,
                      }),
                    }}
                  />
                  <div
                    css={css`
                      font-size: 0.75rem;
                    `}
                  >
                    {t('payment.acceptTerms2')}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </Context.Consumer>
  );
};

CheckoutLegals.propTypes = {
  termsChecked: PropTypes.bool.isRequired,
  onTermsCheckboxChange: PropTypes.func.isRequired,
  hasAmountToPay: PropTypes.bool,
};

CheckoutLegals.defaultProps = {
  hasAmountToPay: true
};

export default CheckoutLegals;
