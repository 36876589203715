import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { confirmEmail, selectIsFetching } from '@redux/modules/auth';
import Component from './Component';

export default withRouter(
  connect(
    state => ({
      isFetching: selectIsFetching(state),
    }),
    {
      confirmEmail,
    },
  )(Component),
);
