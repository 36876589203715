import { connect } from 'react-redux';
import { deleteFlash, hideFlash, timeoutFlash } from '@redux/modules/ui/flash';
import Flash from './Flash';

export default connect(
  state => ({
    messages: state.ui.flash,
  }),
  {
    deleteFlash,
    hideFlash,
    timeoutFlash,
  },
)(Flash);
