import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Elements } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
import {
  Heading,
  Button,
  ServerErrors,
  usePaymentMethods,
  APPLICATION_CONSTANTS,
  useSetupPaymentIntent,
} from '@hokodo/core';
import AddCardPaymentMethod from './AddCardPaymentMethod';
import SavedPaymentMethods from './SavedPaymentMethods';

const PaymentSettings = ({ membership, token, showModal, hideModal }) => {
  const {
    organisation: { id: organisationId },
    role,
  } = membership;

  const { t } = useTranslation('account');

  const { stripeKey } = useSetupPaymentIntent({ organisationId, token });

  const readonly = role !== APPLICATION_CONSTANTS.ORGANISATION_ROLES.admin;

  const {
    isFetching,
    error,
    paymentMethods,
    addPaymentMethod,
    removePaymentMethod,
    setDefaultPaymentMethod,
  } = usePaymentMethods({ organisationId, token, stripeKey });

  useEffect(() => {
    if (isFetching || error) {
      showModal(
        error
          ? { content: <ServerErrors errors={error} /> }
          : { isLoader: true },
      );
    } else {
      hideModal();
    }
  }, [isFetching]);

  const showAddCardModal = () => {
    showModal({
      content: (
        <Elements stripe={window.Stripe(stripeKey)}>
          <AddCardPaymentMethod
            token={token}
            organisationId={organisationId}
            hideModal={hideModal}
            onAddPaymentMethod={addPaymentMethod}
          />
        </Elements>
      ),
    });
  };

  return paymentMethods ? (
    <section>
      <Heading level={5} underline>
        {t('paymentSettings.title')}
      </Heading>

      <p>{t('paymentSettings.description')}</p>

      <SavedPaymentMethods
        paymentMethods={paymentMethods}
        onDefaultPaymentMethod={setDefaultPaymentMethod}
        onRemovePaymentMethod={removePaymentMethod}
        readonly={readonly}
      />

      {!readonly && stripeKey && (
        <Button onClick={showAddCardModal}>
          {t('paymentSettings.addCard')}
        </Button>
      )}
    </section>
  ) : null;
};

PaymentSettings.propTypes = {
  membership: PropTypes.shape({
    organisation: PropTypes.shape({
      id: PropTypes.string,
    }),
    role: PropTypes.string,
  }).isRequired,
  token: PropTypes.string.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
};

PaymentSettings.defaultProps = {};

export default PaymentSettings;
