import * as actions from './actions';

export const initialState = {
  isFetching: false,
  error: {},
};

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actions.IMPORT_KASHFLOW_INVOICES:
    case actions.OAUTH_SYNCHRONISE:
      return {
        ...state,
        isFetching: true,
        error: {},
      };

    case actions.IMPORT_KASHFLOW_INVOICES_SUCCESS:
    case actions.OAUTH_SYNCHRONISE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: {},
      };

    case actions.IMPORT_KASHFLOW_INVOICES_FAILURE:
    case actions.OAUTH_SYNCHRONISE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };

    default:
      return state;
  }
};

export default reducer;
