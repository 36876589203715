import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import Context from '../../../Context';

const MessageBox = ({ type, children }) => {
  return (
    <Context.Consumer>
      {({ theme: { colors, border, radius, padding } }) => (
        <div
          css={css`
            color: ${colors[type].text};
            border: ${border.default};
            border-color: ${colors[type].border};
            border-radius: ${radius.default};
            padding: ${padding.default};
            margin: 1rem 0;
          `}
        >
          {children}
        </div>
      )}
    </Context.Consumer>
  );
};

MessageBox.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['info', 'warning', 'error', 'success']),
};

MessageBox.defaultProps = {
  type: 'info',
};

export default MessageBox;
