import gbMessages from './en-GB/messages.json';
import gbFaqContent from './en-GB/faq';
import gbProtectCards from './en-GB/protectCards';
import frMessages from './fr-FR/messages.json';
import frFaqContent from './fr-FR/faq';
import frProtectCards from './fr-FR/protectCards';
import deMessages from './de-DE/messages.json';
import deFaqContent from './de-DE/faq';
import deProtectCards from './de-DE/protectCards';

export const messages = {
  'en-GB': gbMessages,
  'fr-FR': frMessages,
  'de-DE': deMessages,
};
export const faqContent = {
  'en-GB': gbFaqContent,
  'fr-FR': frFaqContent,
  'de-DE': deFaqContent,
};
export const protectCards = {
  'en-GB': gbProtectCards,
  'fr-FR': frProtectCards,
  'de-DE': deProtectCards,
};
