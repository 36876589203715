import * as actions from './actions';

export const initialState = {
  isFetching: false,
  errors: {},
};

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actions.AUTH_LOGIN:
    case actions.AUTH_REGISTRATION:
    case actions.AUTH_PASSWORD_RESET:
    case actions.AUTH_PASSWORD_CHOOSE:
      return {
        ...state,
        isFetching: true,
        errors: initialState.errors,
      };

    case actions.AUTH_LOGIN_SUCCESS:
    case actions.AUTH_REGISTRATION_SUCCESS:
    case actions.AUTH_PASSWORD_RESET_SUCCESS:
    case actions.AUTH_PASSWORD_CHOOSE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        errors: initialState.errors,
      };

    case actions.AUTH_LOGIN_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: {
          ...state.errors,
          login: payload,
        },
      };

    case actions.AUTH_REGISTRATION_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: {
          ...state.errors,
          registration: payload,
        },
      };

    case actions.AUTH_PASSWORD_RESET_FAILURE:
    case actions.AUTH_PASSWORD_CHOOSE_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: {
          ...state.errors,
          resetPassword: payload,
        },
      };

    case actions.AUTH_LOGOUT:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
