import { connect } from 'react-redux';
import {
  importKashflowInvoices,
  selectIsFetching,
  selectError,
} from '@redux/modules/integrations';
import Kashflow from './Kashflow';

export default connect(
  state => ({
    isFetching: selectIsFetching(state),
    error: selectError(state),
  }),
  {
    importInvoices: importKashflowInvoices,
  },
)(Kashflow);
