import { connect } from 'react-redux';
import { selectSessionAuthToken } from '@redux/modules/session';
import {
  setHasPurchasedPolicy,
  selectUserOrganisation,
} from '@redux/modules/user';
import { push } from 'connected-react-router';
import Checkout from './Checkout';

export default connect(
  state => ({
    token: selectSessionAuthToken(state),
    organisation: selectUserOrganisation(state),
  }),
  { setHasPurchasedPolicy, push },
)(Checkout);
