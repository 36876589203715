import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  registration,
  selectIsFetching,
  selectRegistrationErrors,
} from '@redux/modules/auth';
import RegisterForm from './Form';

export default withRouter(
  connect(
    state => ({
      isFetching: selectIsFetching(state),
      serverErrors: selectRegistrationErrors(state),
    }),
    {
      registration,
    },
  )(RegisterForm),
);
