import { createBrowserHistory } from 'history';

const {
  NODE_ENV,
  ROOT_API,
  STANDALONE_API,
  AUTHORIZATION_KEY,
  SUPPORTED_CURRENCIES,
  AVAILABLE_CONTEXTS,
  PACKAGE_NAME,
  PACKAGE_VERSION,
} = process.env;

const DEFAULT_CONTEXT = 'GB';

const MULTI_LANGUAGE_ENABLED =
  process.env.MULTI_LANGUAGE_ENABLED &&
  process.env.MULTI_LANGUAGE_ENABLED.toUpperCase() === 'TRUE';

const EXPERIMENTAL_FEATURES =
  process.env.EXPERIMENTAL_FEATURES &&
  process.env.EXPERIMENTAL_FEATURES.toUpperCase() === 'TRUE';

export const isDev = () => NODE_ENV === 'development';
export const isProd = () => NODE_ENV === 'production';

export {
  NODE_ENV,
  ROOT_API,
  STANDALONE_API,
  AUTHORIZATION_KEY,
  EXPERIMENTAL_FEATURES,
  SUPPORTED_CURRENCIES,
  MULTI_LANGUAGE_ENABLED,
  AVAILABLE_CONTEXTS,
  PACKAGE_NAME,
  PACKAGE_VERSION,
  DEFAULT_CONTEXT,
};

export const history = createBrowserHistory();
