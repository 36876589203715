import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import { formatLocaleCurrency } from '../../../../../lib/currencyHelpers';
import Card from '../../atoms/Card';
import Button from '../../atoms/Button';
import Context from '../../../Context';
import { selectTransaction } from '../../../../redux/modules/transaction';

export default () => {
  const transaction = useSelector(state => selectTransaction(state));
  const { t } = useTranslation('declined');
  const getRejectionReason = (trns) => trns? trns.quote.rejection_reason.detail: null;

  return transaction ? (
    <Context.Consumer>
      {({
        lang,
        theme: { colors },
        applicationContext: { SUPPORT_CONTACT, INVOICE_ELIGIBILITY_LIMIT },
      }) => {
        return (
          <div
            css={css`
              max-width: 700px;
              margin: auto;
            `}
          >
            <div
              css={css`
                margin: 2rem 0;
                padding: 0 2rem;
                p {
                  text-align: center;
                  font-size: 20px;
                  font-weight: 600;
                  &:first-of-type {
                    font-size: 28px;
                    font-weight: 700;
                    color: ${colors.accent};
                  };
                  &:nth-of-type(2) {
                    font-size: 20px;
                    font-weight: 500;
                    color: ${colors.accent};
                    padding-bottom: 2rem;
                  }
                }
              `}
            >
              <p>{t('heading')}</p>
              <p>{getRejectionReason(transaction)}</p>
              <p>{t('description')}</p>
            </div>

            <Card title={t('cardTitle')}>
              <div css={css``}>
                <p>{t('cardDescription1')}</p>
                <p>{t('cardDescription2')}</p>
                <ul
                  css={css`
                    list-style-type: disc;
                    padding-left: 1rem;
                    li {
                      font-weight: 600;
                      margin-bottom: 1rem;
                    }
                  `}
                >
                  <li>
                    {t('listItem1', {
                      limit_amount: formatLocaleCurrency(
                        lang,
                        INVOICE_ELIGIBILITY_LIMIT.currency,
                        INVOICE_ELIGIBILITY_LIMIT.amount,
                        false,
                      ),
                    })}
                  </li>
                  <li>{t('listItem2')}</li>
                  <li>{t('listItem3')}</li>
                  <li>{t('listItem4')}</li>
                </ul>
                <p>
                  {t('footer', {
                    support_phone: SUPPORT_CONTACT.phone,
                    support_email: SUPPORT_CONTACT.email,
                  })}
                </p>
              </div>
            </Card>

            <div
              css={css`
                text-align: center;
                padding-top: 1rem;
              `}
            >
              <Button
                css={css`
                  border-radius: 1.5rem;
                  border: solid 1px ${colors.revampCTA};
                  background-color: ${colors.revampCTA};
                  color: ${colors.white};
                  margin-bottom: 2rem;
                `}
                onClick={() => {window.open("about:blank", "_self", "");}}
              >
                {t('button')}
              </Button>
            </div>
          </div>
        );
      }}
    </Context.Consumer>
  ) : null;
};
