import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { css } from '@emotion/core';
import { colors, radius, shadow } from '@styles/theme';
import { protectCards } from '@localisation';
import { Context } from '@hokodo/core';

const HelpCard = ({ cardId }) => (
  <Context.Consumer>
    {({ lang, applicationContext: { COLLECTION_PERC, SPECIMEN_POLICY, FAQ_URL } }) => {
      const { img, text } = protectCards[lang][cardId];

      return (
        <div
          css={css`
            border-radius: ${radius.default};
            box-shadow: ${shadow.default};
            color: ${colors.midGrey};
            background-color: ${colors.white};
            margin-left: 2rem;
          `}
        >
          <img
            css={css`
              width: 100%;
              height: auto;
            `}
            src={`/static/img/${img.src}`}
            alt={img.alt}
          />
          <div
            css={css`
              padding: 1rem 2rem;
              ul {
                list-style-type: disc;
                padding-left: 1rem;
              }
            `}
          >
            <ReactMarkdown
              linkTarget="_blank"
              source={text
                .replace(/{{COLLECTION_PERC}}/, COLLECTION_PERC)
                .replace(/{{SPECIMEN_POLICY}}/, SPECIMEN_POLICY)
                .replace(/{{FAQ_URL}}/, FAQ_URL)
              }
            />
          </div>
        </div>
      );
    }}
  </Context.Consumer>
);

HelpCard.propTypes = {
  cardId: PropTypes.string.isRequired,
};

export default HelpCard;
