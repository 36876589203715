import React from 'react';
import PropTypes from 'prop-types';

const AnchorTrackEvent = ({
  children,
  trackEvent,
  trackingEventName,
  onClick,
  href,
  target,
  rel,
}) => {
  const handleClick = event => {
    trackEvent({
      dataKey: trackingEventName,
      dataValue: true
    });

    if (onClick) onClick(event);
  };

  return (
    <a target={target} rel={rel} href={href} onClick={handleClick}>
      {children}
    </a>
  );
};

AnchorTrackEvent.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
  trackEvent: PropTypes.func.isRequired,
  trackingEventName: PropTypes.string,
  onClick: PropTypes.func,
  target: PropTypes.string,
  rel: PropTypes.string,
};

AnchorTrackEvent.defaultProps = {
  trackingEventName: '',
  onClick: null,
  target: '_self',
  rel: '',
};

export default AnchorTrackEvent;
