/* eslint-disable react/prop-types */
import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { pluck } from 'rxjs/operators';
import { useTranslation } from 'react-i18next';
import {
  useStripe,
  useElements,
  CardNumberElement,
} from '@stripe/react-stripe-js';
import { css } from '@emotion/core';
import Card from '../../atoms/Card';
import Heading from '../../atoms/Heading';
import AcceptedCards from '../../molecules/AcceptedCards';
import ServerErrors from '../../molecules/ServerErrors';
import SelectPaymentMethod from '../../molecules/SelectPaymentMethod';
import CardPaymentMethod from '../../organisms/CardPaymentMethod';
import Context from '../../../Context';
import CheckoutLegals from './CheckoutLegals';
import CheckoutButtons from './CheckoutButtons';
import { createAction } from '../../../../../lib/actionHelpers';
import {
  selectPlatformUser,
  selectSessionAuthToken,
} from '../../../../redux/modules/session/selectors';
import observableRequest from '../../../../../lib/requestHelpers';

const initialState = {
  termsChecked: false,
  stripeFetching: false,
  errors: {
    stripeErrors: null,
    fieldErrors: null,
  },
  fieldValues: {
    cardname: '',
    postcode: '',
    saveCardDetails: false,
  },
  addPaymentMethod: null,
  selectedPaymentMethod: null,
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'SET_TERMS_CHECKED':
      return { ...state, termsChecked: payload };
    case 'SET_STRIPE_FETCHING':
      return { ...state, stripeFetching: payload };
    case 'SET_SELECTED_PAYMENT_METHOD':
      return { ...state, ...payload };
    case 'SET_ERRORS':
      return {
        ...state,
        errors: {
          ...state.errors,
          ...payload,
        },
      };
    case 'SET_FIELD_VALUES':
      return {
        ...state,
        fieldValues: {
          ...state.fieldValues,
          ...payload,
        },
      };
    default:
      return state;
  }
};

const setTermsChecked = createAction('SET_TERMS_CHECKED');
const setStripeFetching = createAction('SET_STRIPE_FETCHING');
const setErrors = createAction('SET_ERRORS');
const setFieldValues = createAction('SET_FIELD_VALUES');
const setSelectedPaymentMethod = createAction('SET_SELECTED_PAYMENT_METHOD');

const CheckoutStripe = props => {
  const {
    transaction,
    heading,
    acceptedCards,
    cardBorder,
    title,
    components,
    onCheckoutSuccess,
    trackStripeCheckoutError,
    serverErrors = {},
    styles,
    hasPaymentMethods,
    paymentMethods,
    apiFetching,
    hasAmountToPay,
  } = props;
  const stripe = useStripe();
  const elements = useElements();
  const { t } = useTranslation('checkout');

  const { quote } = transaction;
  const token = useSelector(state => selectSessionAuthToken(state));
  const platformUserId = useSelector(state => selectPlatformUser(state));

  /*
   * With the launch of online BNPL, the payments method payload has been restructured.
   * The `id` field now represents an internal Hokodo id. The Stripe payment method id is now
   * `remote_id`.
   *  Note: `remote_id` is only included temporarily and the checkout logic will need to be re-written
   */
  const getInternalPaymentMethodId = (pms, remoteId) => {
    const selectedPaymentMethod = pms.results.filter(
      pm => pm.remote_id === remoteId,
    );
    return selectedPaymentMethod ? selectedPaymentMethod[0].id : null;
  };

  initialState.selectedPaymentMethod =
    hasPaymentMethods &&
    paymentMethods.results.length &&
    paymentMethods.results[0].remote_id;

  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
  });
  const ButtonsComponent = components.buttons || CheckoutButtons;
  const LegalsComponent = components.legals || CheckoutLegals;

  const onSuccess = response => {
    setStripeFetching(false);
    onCheckoutSuccess(response);
  };
  const onError = error => {
    const formattedErr = { [error.type]: error.message };
    setStripeFetching(false);
    dispatch(
      setErrors({
        stripeErrors: formattedErr,
      }),
    );
    trackStripeCheckoutError(formattedErr);
  };

  const confirmPaymentIntent = async ({
    payment_method,
    options,
    client_secret,
  }) => {
    const { paymentIntent, error } = await stripe.confirmCardPayment(
      client_secret,
      {
        payment_method,
        ...options,
      },
    );
    if (error) {
      dispatch(setStripeFetching(false));
      onError(error);
    }
    if (paymentIntent) onSuccess({ paymentIntent });
  };

  const onSubmit = async () => {
    if (!hasAmountToPay) onSuccess();
    const { cardname, postcode, saveCardDetails } = state.fieldValues;
    if (
      !state.selectedPaymentMethod &&
      (!cardname.length || !postcode.length)
    ) {
      dispatch(
        setErrors({
          fieldErrors: {
            cardname: cardname.length ? null : t('payment.errorCardname'),
            postcode: postcode.length ? null : t('payment.errorPostcode'),
          },
        }),
      );
      return;
    }

    dispatch(
      setErrors({
        fieldErrors: null,
        stripeErrors: null,
      }),
    );
    dispatch(setStripeFetching(true));

    const cardElement = elements.getElement(CardNumberElement);

    const options = saveCardDetails
      ? {
          setup_future_usage: 'off_session',
        }
      : {};

    // we are using confirmCardPayment with a saved payment method
    // so that, in the case of an always_prompt 3D secure card, we
    // actually get the 3D secure challenge modal from Stripe JS.
    const payment_method = state.selectedPaymentMethod || {
      card: cardElement,
      billing_details: {
        name: cardname,
        address: {
          postal_code: postcode,
        },
      },
    };
    const createdCheckoutIdBody =
      typeof payment_method === 'object'
        ? { user: platformUserId }
        : {
            payment_method: getInternalPaymentMethodId(
              paymentMethods,
              state.selectedPaymentMethod,
            ),
          };

    observableRequest({
      url: `quotes/${quote.id}/stripe_payments/create_checkout_id`,
      token,
      config: {
        method: 'POST',
        body: createdCheckoutIdBody,
      },
    })
      .pipe(pluck('response'))
      .subscribe(
        response => {
          const { client_secret } = response;
          confirmPaymentIntent({ payment_method, options, client_secret });
        },
        error => {
          setErrors(error.response ? error.response : error);
        },
      );
  };

  const onFieldValueChange = value => {
    dispatch(setFieldValues(value));
  };

  const onTermsCheckboxChange = () => {
    dispatch(setTermsChecked(!state.termsChecked));
  };

  const combinedErrors = () => {
    const { fieldErrors = {}, stripeErrors = {} } = state.errors;
    const messages = { ...fieldErrors, ...stripeErrors, ...serverErrors };
    return Object.keys(messages).length ? (
      <ServerErrors errors={messages} />
    ) : null;
  };

  const onPaymentMethodCancel = () => {
    dispatch(
      setSelectedPaymentMethod({
        selectedPaymentMethod: null,
        addPaymentMethod: null,
      }),
    );
  };

  const onPaymentMethodChange = ({ value }) => {
    dispatch(
      setSelectedPaymentMethod({
        selectedPaymentMethod: value || null,
        addPaymentMethod: value ? null : 'card',
      }),
    );
  };

  const paymentMethodElement = ({ theme: { colors } }) => {
    if (!hasAmountToPay)
      return (
        <div
          css={css`
            background: ${colors.accent};
            color: ${colors.white};
            padding: 1rem 0;
            text-align: center;
            font-size: 1.25rem;
            margin: -1rem -1rem 1rem -1rem;
          `}
        >
          {t('disabledPaymentForm')}
        </div>
      );
    if (state.addPaymentMethod)
      return (
        <CardPaymentMethod
          styles={styles}
          fieldValues={state.fieldValues}
          setFieldValue={onFieldValueChange}
          errors={state.errors}
          allowSave
          allowCancel
          onCancel={onPaymentMethodCancel}
        />
      );

    if (hasPaymentMethods && paymentMethods.results.length)
      return (
        <SelectPaymentMethod
          paymentMethods={paymentMethods}
          onChange={onPaymentMethodChange}
        />
      );
    return (
      <CardPaymentMethod
        styles={styles}
        fieldValues={state.fieldValues}
        setFieldValue={onFieldValueChange}
        errors={state.errors}
        allowSave
      />
    );
  };

  return (
    <Context.Consumer>
      {({ theme: { colors }, applicationContext: { CHECKOUT_LEGALS } }) => {
        return (
          <div>
            <Card
              title={title}
              borderColor={cardBorder ? colors.border : null}
              titleBodySeparator={false}
            >
              {heading && (
                <Heading level={heading.level}>{heading.text}</Heading>
              )}

              <div>
                {acceptedCards && hasAmountToPay && (
                  <AcceptedCards
                    headingLevel={acceptedCards.level}
                    headingText={acceptedCards.text}
                  />
                )}

                {combinedErrors()}

                {paymentMethodElement({ theme: { colors } })}

                <LegalsComponent
                  termsChecked={state.termsChecked}
                  onTermsCheckboxChange={onTermsCheckboxChange}
                  hasAmountToPay={hasAmountToPay}
                />
              </div>
              <ButtonsComponent
                quote={quote}
                onSubmit={() => {
                  if (CHECKOUT_LEGALS.accept && !state.termsChecked) return;
                  onSubmit();
                }}
                isFetching={!stripe || state.stripeFetching || apiFetching}
                termsChecked={state.termsChecked}
              />
            </Card>
          </div>
        );
      }}
    </Context.Consumer>
  );
};

CheckoutStripe.propTypes = {
  transaction: PropTypes.shape({
    quote: PropTypes.shape({
      id: PropTypes.string,
      currency: PropTypes.string,
      total_price: PropTypes.string,
    }),
    id: PropTypes.string,
  }).isRequired,
  onCheckoutSuccess: PropTypes.func.isRequired,
  trackStripeCheckoutError: PropTypes.func.isRequired,

  styles: PropTypes.object,
  heading: PropTypes.shape({
    text: PropTypes.string,
    level: PropTypes.number,
  }),
  acceptedCards: PropTypes.shape({
    text: PropTypes.string,
    level: PropTypes.number,
  }),

  cardBorder: PropTypes.bool,
  title: PropTypes.string,
  components: PropTypes.shape({
    buttons: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
    legals: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
  }),

  serverErrors: PropTypes.any,

  paymentMethods: PropTypes.shape().isRequired,
  hasPaymentMethods: PropTypes.bool.isRequired,
  apiFetching: PropTypes.bool.isRequired,
  hasAmountToPay: PropTypes.bool,
};

CheckoutStripe.defaultProps = {
  styles: null,
  heading: null,
  acceptedCards: null,
  cardBorder: null,
  title: null,
  components: {
    buttons: null,
    legals: null,
  },
  serverErrors: null,
  hasAmountToPay: null,
};

export default CheckoutStripe;
