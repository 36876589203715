import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import MessageBox from '../../molecules/MessageBox';

const CheckoutError = ({ quoteId }) => {
  const { t } = useTranslation('checkout');

  return (
    <MessageBox type="error">
      <span
        dangerouslySetInnerHTML={{
          __html: t('paymentError', {
            interpolation: { escapeValue: false },
            quoteId,
          }),
        }}
      />
    </MessageBox>
  );
};

CheckoutError.propTypes = {
  quoteId: PropTypes.string.isRequired,
};

export default CheckoutError;
