import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import { Context, TextField, Heading, Button } from '@hokodo/core';
import { getValidationSchema } from '@hokodo/core/lib/formHelpers';

const UserProfile = ({
  profile,
  isFetching,
  updateUserProfile,
  wrapperStyles,
}) => {
  const { t } = useTranslation('account');

  const getInitialValues = values => {
    const { email = '', full_name = '', job_title = '', phone = '' } = values;

    return {
      email,
      full_name,
      job_title,
      phone,
    };
  };

  return profile ? (
    <Context.Consumer>
      {({ lang }) => (
        <section css={wrapperStyles}>
          <Formik
            initialValues={getInitialValues(profile)}
            validationSchema={getValidationSchema('ContactDetailsSchema', lang)}
            onSubmit={(values, { setSubmitting }) => {
              updateUserProfile(values);
              setSubmitting(false);
            }}
          >
            {({ isSubmitting }) => {
              return (
                <Form>
                  <div
                    css={css`
                      display: flex;
                      justify-content: space-between;
                    `}
                  >
                    <Heading level={5} underline>
                      {t('contactForm.title')}
                    </Heading>
                    <Button type="submit" disabled={isFetching || isSubmitting}>
                      {t('saveButton')}
                    </Button>
                  </div>

                  <div
                    css={css`
                      display: grid;
                      grid-template-columns: 1fr 1fr;
                      grid-column-gap: 2rem;
                    `}
                  >
                    <div>
                      <Field
                        name="full_name"
                        label={t('contactForm.fullName')}
                        component={TextField}
                      />

                      <Field
                        name="job_title"
                        label={t('contactForm.position')}
                        component={TextField}
                      />
                    </div>

                    <div>
                      <Field
                        name="phone"
                        label={t('contactForm.phone')}
                        component={TextField}
                      />

                      <Field
                        name="email"
                        label={t('contactForm.email')}
                        component={TextField}
                        disabled
                      />
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </section>
      )}
    </Context.Consumer>
  ) : null;
};

UserProfile.propTypes = {
  profile: PropTypes.shape({
    email: PropTypes.string,
    full_name: PropTypes.string,
    job_title: PropTypes.string,
    phone: PropTypes.string,
  }),
  isFetching: PropTypes.bool,
  updateUserProfile: PropTypes.func.isRequired,
  wrapperStyles: PropTypes.object,
};

UserProfile.defaultProps = {
  profile: null,
  isFetching: false,
  wrapperStyles: {},
};

export default UserProfile;
