import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Logo from '../../atoms/Logo';
import Heading from '../../atoms/Heading';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error) {
    this.setState({ hasError: true });
    this.props.logException(error);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <main className="container">
          <Logo />
          <Heading level={1}>Something went wrong</Heading>
          <p>
            We were unable to complete the last operation. Please{' '}
            <a
              href="#"
              onClick={event => {
                event.preventDefault();
                window.location.reload();
              }}
            >
              click here
            </a>{' '}
            to reload and try again.
          </p>
        </main>
      );
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  logException: PropTypes.func,
};

ErrorBoundary.defaultProps = {
  logException: e => { console.error(e); }, // eslint-disable-line no-console
};

export default ErrorBoundary;
