import { transformCollectionResponse } from '@hokodo/core/lib/dataTransformers';
import { formatYMD, addDays } from '@hokodo/core/lib/datetimeHelpers';
import * as actions from './actions';

export const initialViewState = {
  isFetching: false,
  errors: {},
  byId: {},
  allIds: [],
  count: 0,
  next: '',
  previous: '',
  orderBy: {
    param: 'issue_date',
    desc: true,
  },
  status: '',
  filters: {},
};

export const views = [
  {
    name: 'recent',
    label: 'filter.recentInvoices',
    filters: { due_date__gte: formatYMD(addDays(new Date(), -30)) },
  },
  {
    name: 'insured',
    label: 'filter.insured',
    status: 'insured',
  },
  {
    name: 'not_insured',
    label: 'filter.notInsured',
    status: 'not_insured',
  },
  {
    name: 'claims',
    label: 'filter.claims',
    status: 'claims',
  },
  { name: 'all', label: 'filter.allInvoices' },
];

export const initialState = {
  activeView: 'recent',
  pageSize: 10,
  views: views.reduce((acc, { name, ...rest }) => {
    acc[name] = {
      name,
      ...initialViewState,
      ...rest,
    };
    return acc;
  }, {}),
};

/*
TODO: determine where best to store error state -
should it be per view, or a single globally scoped
state object?
*/

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    /**
     * global UX and query modifiers.
     * TODO: move to discrete state object for
     * persistence.
     */
    case actions.SET_ACTIVE_VIEW: {
      return {
        ...state,
        activeView: payload.activeView,
      };
    }

    case actions.SET_PAGE_SIZE:
      return {
        ...state,
        pageSize: payload.pageSize,
      };

    /**
     * view query modifiers.
     * TODO: move to discreet state object for
     * persistence.
     */
    case actions.SET_ORDER_BY: {
      const { view, orderBy } = payload;
      return {
        ...state,
        views: {
          ...state.views,
          [view]: {
            ...state.views[view],
            orderBy,
          },
        },
      };
    }

    case actions.SET_FILTERS: {
      const { view, filters } = payload;
      return {
        ...state,
        views: {
          ...state.views,
          [view]: {
            ...state.views[view],
            filters,
          },
        },
      };
    }

    /**
     * view collections
     */
    case actions.FETCH_COLLECTION: {
      const { view } = payload;

      return {
        ...state,
        views: {
          ...state.views,
          [view]: {
            ...state.views[view],
            byId: {},
            allIds: [],
            next: '',
            previous: '',
            isFetching: true,
            errors: {},
          },
        },
      };
    }

    case actions.FETCH_COLLECTION_SUCCESS: {
      const { view, response } = payload;
      const { count, next, previous, results } = response;

      return {
        ...state,
        views: {
          ...state.views,
          [view]: {
            ...state.views[view],
            isFetching: false,
            count,
            next: next ? next.substr(next.indexOf('?')) : '',
            previous: previous ? previous.substr(previous.indexOf('?')) : '',
            ...transformCollectionResponse(results),
          },
        },
      };
    }

    case actions.FETCH_COLLECTION_FAILURE: {
      const { view, response } = payload;

      return {
        ...state,
        views: {
          ...state.views,
          [view]: {
            ...state.views[view],
            isFetching: false,
            errors: {
              collection: response,
            },
          },
        },
      };
    }

    /**
     * get quote
     */
    case actions.GET_QUOTE: {
      const { transactionId } = payload;

      return {
        ...state,
        views: Object.keys(state.views).reduce((acc, key) => {
          const view = state.views[key];
          acc[key] = view.byId[transactionId]
            ? {
                ...view,
                byId: {
                  ...view.byId,
                  [transactionId]: {
                    ...view.byId[transactionId],
                    isFetching: true,
                  },
                },
              }
            : view;
          return acc;
        }, {}),
      };
    }

    case actions.GET_QUOTE_SUCCESS: {
      const { id: quoteId, transaction: transactionId, ...rest } = payload;

      return {
        ...state,
        views: Object.keys(state.views).reduce((acc, key) => {
          const view = state.views[key];
          acc[key] = view.byId[transactionId]
            ? {
                ...view,
                byId: {
                  ...view.byId,
                  [transactionId]: {
                    ...view.byId[transactionId],
                    quote: {
                      id: quoteId,
                      ...rest,
                    },
                    isFetching: false,
                  },
                },
              }
            : view;
          return acc;
        }, {}),
      };
    }

    case actions.GET_QUOTE_FAILURE: {
      // TODO: determine where error object should be saved
      const { transactionId } = payload;

      return {
        ...state,
        views: Object.keys(state.views).reduce((acc, key) => {
          const view = state.views[key];
          acc[key] = view.byId[transactionId]
            ? {
                ...view,
                byId: {
                  ...view.byId,
                  [transactionId]: {
                    ...view.byId[transactionId],
                    isFetching: false,
                  },
                },
              }
            : view;
          return acc;
        }, {}),
      };
    }

    /**
     * fetch item is used to update single transactions in views:
     * - at the end of a claim
     * - when marked as paid
     */
    case actions.FETCH_ITEM: {
      const { transactionId } = payload;

      return {
        ...state,
        views: Object.keys(state.views).reduce((acc, key) => {
          const view = state.views[key];
          acc[key] = view.byId[transactionId]
            ? {
                ...view,
                byId: {
                  ...view.byId,
                  [transactionId]: {
                    ...view.byId[transactionId],
                    isFetching: true,
                  },
                },
              }
            : view;
          return acc;
        }, {}),
      };
    }

    case actions.FETCH_ITEM_SUCCESS: {
      const { id: transactionId } = payload;

      return {
        ...state,
        views: Object.keys(state.views).reduce((acc, key) => {
          const view = state.views[key];
          acc[key] = view.byId[transactionId]
            ? {
                ...view,
                byId: {
                  ...view.byId,
                  [transactionId]: {
                    ...view.byId[transactionId],
                    ...payload,
                    isFetching: false,
                  },
                },
              }
            : view;
          return acc;
        }, {}),
      };
    }

    case actions.FETCH_ITEM_FAILURE: {
      // TODO: determine where error object should be saved
      const { transactionId } = payload;

      return {
        ...state,
        views: Object.keys(state.views).reduce((acc, key) => {
          const view = state.views[key];
          acc[key] = view.byId[transactionId]
            ? {
                ...view,
                byId: {
                  ...view.byId,
                  [transactionId]: {
                    ...view.byId[transactionId],
                    isFetching: false,
                  },
                },
              }
            : view;
          return acc;
        }, {}),
      };
    }

    case actions.UPDATE_MATCHED_DEBTORS: {
      const { transaction: currentTransaction, newDebtor } = payload;

      /*
      debtor_name will contain the value as imported from 
      from the integrated service. This is the _only_ value
      used to match additional transactions. Note that: 
      
        - the UI control will display debtor.name and not
          debtor_name if the debtor is already  'matched'
      
         - QA data may have had debtor 'matched' multiple times
      
      Therfore, do not assume that a page full of 'company a' changed
      to 'company b' will all update. For the same reason, 'company a', 
      'company b' and 'company c' may all change in response to a match 
      if they have the same original debtor_name value. Always
      check the debtor_name value in the transaction before 
      assuming there is a bug.
      */

      const match = currentTransaction.debtor_name;

      /* eslint-disable no-shadow */
      return {
        ...state,
        views: Object.keys(state.views).reduce((acc, key) => {
          const view = state.views[key];
          acc[key] = {
            ...view,
            byId: Object.keys(view.byId).reduce((acc, key) => {
              const t = view.byId[key];

              // Don't update if transaction has a policy
              if (t.policy) return { ...acc, [t.id]: t };

              // check for transactions with the same debtor_name
              if (t.debtor_name === match)
                return {
                  ...acc,
                  [t.id]: { ...t, debtor: newDebtor, quote: null },
                };

              // no match
              return { ...acc, [t.id]: t };
            }, {}),
          };
          return acc;
        }, {}),
      };
      /* eslint-enable no-shadow */
    }

    default:
      return state;
  }
};

export default reducer;
