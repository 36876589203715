import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field } from 'formik';
import { Context, Button, Heading, AuthFormBox, TextField } from '@hokodo/core';
import { getValidationSchema } from '@hokodo/core/lib/formHelpers';

const ResetPassword = ({ passwordReset, isFetching }) => {
  const { t } = useTranslation('auth');

  return (
    <Context.Consumer>
      {({ lang }) => (
        <AuthFormBox>
          <Heading centered level={5}>
            {t('reset.heading')}
          </Heading>
          <p>{t('reset.instructStep1')}</p>
          <Formik
            initialValues={{ email: '' }}
            validationSchema={getValidationSchema('ResetPasswordSchema', lang)}
            onSubmit={(values, { setSubmitting }) => {
              const { email } = values;
              passwordReset({ email });
              setSubmitting(false);
            }}
            render={({ isSubmitting }) => (
              <Form>
                <Field
                  placeholder={t('reset.placeholderEmail')}
                  name="email"
                  component={TextField}
                />

                <Button
                  type="submit"
                  block
                  disabled={isFetching || isSubmitting}
                >
                  Send reset email
                </Button>
              </Form>
            )}
          />
        </AuthFormBox>
      )}
    </Context.Consumer>
  );
};

ResetPassword.propTypes = {
  passwordReset: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
};

ResetPassword.defaultProps = {
  isFetching: false,
};

export default ResetPassword;
