import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { Context, Heading } from '@hokodo/core';
import { formatLocaleCurrency } from '@hokodo/core/lib/currencyHelpers';

const Policy = ({ transaction }) => {
  const { t } = useTranslation('editInvoice');

  const { quote, policy } = transaction;

  return transaction.policy ? (
    <Context.Consumer>
      {({ lang }) => (
        <Fragment>
          <Heading level={4}>{t('purchased.heading')}</Heading>
          <p>{t('purchased.p1', { policy: policy.number })}</p>
          <p>
            {t('purchased.p2', {
              price: formatLocaleCurrency(
                lang,
                quote.currency,
                quote.total_price,
              ),
            })}
          </p>
        </Fragment>
      )}
    </Context.Consumer>
  ) : (
    <Redirect to={`/invoice/${transaction.id}`} />
  );
};

Policy.propTypes = {
  transaction: PropTypes.shape({
    id: PropTypes.string,
    policy: PropTypes.shape({
      number: PropTypes.string,
    }),
    quote: PropTypes.shape({
      total_price: PropTypes.string,
      currency: PropTypes.string,
    }),
  }).isRequired,
};

export default Policy;
