import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Heading, AuthFormBox, ServerErrors, NotFound } from '@hokodo/core';
import ConfirmEmail from './ConfirmEmail';

const Component = ({ match, serverErrors, ...rest }) => {
  const { t } = useTranslation('auth');

  return (
    <Switch>
      <Route
        exact
        path={`${match.path}/:key`}
        render={matchProps => <ConfirmEmail {...rest} {...matchProps} />}
      />
      <Route
        exact
        path={`${match.props}/error`}
        render={() => (
          <AuthFormBox>
            <Heading level={5}>{t('confirmEmail.failureTitle')}</Heading>
            {serverErrors && <ServerErrors errors={serverErrors} />}
            <p>{t('confirmEmail.failureDescription')}</p>
          </AuthFormBox>
        )}
      />
      <Route component={NotFound} />
    </Switch>
  );
};

Component.propTypes = {
  match: PropTypes.object.isRequired,
  serverErrors: PropTypes.object,
};

Component.defaultProps = {
  serverErrors: null,
};

export default Component;
