import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import Heading from '../../atoms/Heading';
import Tooltip from '../../molecules/Tooltip';
import Context from '../../../Context';

const Checklist = () => {
  const { t } = useTranslation('checklist');

  return (
    <Context.Consumer>
      {({ theme: { colors }, applicationContext: { FAQ_URL, SPECIMEN_POLICY } }) => (
        <Fragment>
          <div
            css={css`
              color: ${colors.secondary};
              span {
                font-weight: 600;
              }
            `}
          >
            <Heading level={5}>{t('heading')}</Heading>

            <hr />

            <div>
              <span>
                {t('q1')} <Tooltip>{t('t1')}</Tooltip>
              </span>
            </div>

            <hr />

            <div>
              <span>
                {t('q2')} <Tooltip>{t('t2')}</Tooltip>
              </span>
            </div>

            <hr />

            <div>
              <span>
                {t('q3')} <Tooltip>{t('t3')}</Tooltip>
              </span>
            </div>

            <hr />

            <div>
              <span>
                {t('q4')} <Tooltip>{t('t4')}</Tooltip>
              </span>
            </div>

            <hr />
          </div>

          <Heading level={5}>
            <span
              dangerouslySetInnerHTML={{
                __html: t('beforeBuyingThisPolicy', {
                  interpolation: { escapeValue: false },
                  specimenUrl: SPECIMEN_POLICY,
                  faqUrl: FAQ_URL,
                }),
              }}
            />
          </Heading>
        </Fragment>
      )}
    </Context.Consumer>
  );
};

export default Checklist;
