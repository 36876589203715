import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { Heading } from '@hokodo/core';

const ConnectedList = ({ title, platformMemberships }) => {
  return (
    <div
      css={css`
        padding: 2rem 0;
        ul {
          list-style-type: disc;
          margin-left: 1rem;
        }
        li {
          font-weight: 600;
          margin: 0.5rem 0;
        }
      `}
    >
      <Heading level={5}>{title}</Heading>

      <ul>
        {platformMemberships.map(({ organisation: { id, company } }) => (
          <li key={id}>{company.name}</li>
        ))}
      </ul>
    </div>
  );
};

ConnectedList.propTypes = {
  platformMemberships: PropTypes.array,
  title: PropTypes.string,
};

ConnectedList.defaultProps = {
  platformMemberships: [],
  title: '',
};

export default ConnectedList;
