/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import Context from '../../../Context';

const getColorScheme = (colors, color) => {
  switch (color) {
    case 'secondary':
      return { bg: colors.secondary, co: colors.white };
    case 'tertiary':
      return { bg: colors.tertiary, co: colors.lightGrey };
    default:
      return { bg: colors.primary, co: colors.white };
  }
};

const Button = ({
  size,
  block,
  color,
  disabled,
  outline,
  type,
  children,
  onClick,
  fake,
  ...rest
}) => (
  <Context.Consumer>
    {({ theme: { colors, radius, padding } }) => {
      const colorScheme = getColorScheme(colors, color);

      const styles = css`
        position: relative;
        z-index: inherit;
        display: ${block ? 'block' : 'inline-block'};
        width: ${block ? '100%' : 'auto'};
        font-size: ${size === 'small' ? '0.75rem' : '0.875rem'};
        font-weight: 600;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        user-select: none;
        border: 1px solid ${outline ? colorScheme.bg : 'transparent'};
        padding: ${size === 'small'
          ? `0.15rem ${padding.button}`
          : padding.button};
        line-height: 1.5;
        border-radius: ${radius.default};
        color: ${outline ? colorScheme.bg : colorScheme.co};
        background-color: ${outline ? 'transparent' : colorScheme.bg};
        text-transform: uppercase;
        opacity: ${disabled ? 0.6 : 1};

        &:not(:disabled):not(.disabled) {
          cursor: pointer;
        }
      `;

      // NOTE: Chrome does not capture mouse events for :disabled controls,
      // so we are using a fake "button" span so the tooltip wrapper on the
      // dashboard cta buttons will work.

      return fake ? (
        <span
          tabIndex="0" // eslint-disable-line jsx-a11y/no-noninteractive-tabindex
          css={css`
            ${styles}
          `}
          {...rest}
        >
          {children}
        </span>
      ) : (
        <button
          css={css`
            ${styles}
          `}
          type={type}
          onClick={onClick}
          disabled={disabled}
          {...rest}
        >
          {children}
        </button>
      );
    }}
  </Context.Consumer>
);

Button.propTypes = {
  size: PropTypes.string,
  block: PropTypes.bool,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  outline: PropTypes.bool,
  type: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  onClick: PropTypes.func,
  fake: PropTypes.bool,
};

Button.defaultProps = {
  size: 'normal',
  block: false,
  color: 'primary',
  disabled: false,
  outline: false,
  type: 'button',
  onClick: null,
  fake: false,
};

export default Button;
