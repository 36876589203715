import { css } from '@emotion/core';
import { colors, breakpoints } from './theme';
import reset from './reset';

/**
 * View font samples here:
 * https://fonts.google.com/specimen/Titillium+Web
 */

export default css`
  @import url('https://fonts.googleapis.com/css?family=Titillium+Web:400,600,700&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Stardos+Stencil:wght@700&display=swap');

  ${reset}

  * {
    box-sizing: border-box;
  }

  html {
    font-size: 16px;
    height: 100%;
  }

  body {
    position: relative;
    height: 100%;
    font-size: 0.875rem;
    font-family: 'Titillium Web', sans-serif;
    font-weight: regular;
    color: ${colors.black};
    background-color: #f1f4f6;
    overflow-y: scroll;
    line-height: 1.5;
  }

  p {
    margin: 0 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 600;
    margin: 0 0 1rem;
  }

  h1 {
    font-size: 2rem;
  }
  h2 {
    font-size: 1.5rem;
  }
  h3 {
    font-size: 1.5rem;
  }
  h4 {
    font-size: 1rem;
  }
  h5 {
    font-size: 1rem;
  }
  h6 {
    font-size: 1rem;
  }

  input::placeholder {
    color: #999;
  }

  a {
    color: ${colors.primary};
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }

  a[role='button'] {
    color: ${colors.secondary};
    text-decoration: none;
    &.active {
      color: ${colors.primary};
    }
  }
  a[role='button']:hover {
    text-decoration: none;
  }

  a:not([href]):not([tabindex]) {
    color: ${colors.primary};
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
  }

  a:not([href]):not([tabindex]):hover {
    text-decoration: underline;
  }

  strong {
    font-weight: 600;
  }

  button {
    font-family: inherit;
  }

  hr {
    border-color: ${colors.border};
    margin-bottom: 1rem;
  }

  #app {
    position: relative;
    min-height: 100%;
    padding-bottom: 8rem;
  }

  .container {
    width: 100%;
    margin: auto;

    @media (min-width: ${breakpoints.sm}) {
      max-width: 540px;
    }

    @media (min-width: ${breakpoints.md}) {
      max-width: 720px;
    }

    @media (min-width: ${breakpoints.lg}) {
      max-width: 960px;
    }

    @media (min-width: ${breakpoints.xl}) {
      max-width: 1140px;
    }
  }

  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }
  .spinner:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid ${colors.lightGrey};
    border-top-color: transparent;
    animation: spinner 0.6s linear infinite;
  }

  .error {
    color: ${colors.error.text};
  }
  .warning {
    color: ${colors.warning.text};
  }
`;
