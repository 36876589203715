import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import { border, radius } from '@styles/theme';
import { Link } from 'react-router-dom';
import { Button } from '@hokodo/core';
import PageSize from './PageSize';
import ImportMenu from './ImportMenu';
import ViewTab from './ViewTab';
import View from './View';

const Transactions = ({
  canCreateTransactions,
  userOrganisation,
  views,
  activeView,
}) => {
  const { t } = useTranslation('dashboard');

  return userOrganisation && userOrganisation.id ? (
    <Fragment>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
        `}
      >
        <PageSize />

        <div
          css={css`
            display: grid;
            grid-template-columns: auto auto;
            grid-column-gap: 1rem;
          `}
        >
          {canCreateTransactions && (
            <Link to="/invoice">
              <Button>{t('newInvoice')}</Button>
            </Link>
          )}
          <ImportMenu />
        </div>
      </div>

      <div>
        <ul
          css={css`
            display: flex;
            border-bottom: ${border.default};
            li {
              position: relative;
              bottom: -1px;
              padding: 0.5rem;
              border: ${border.default};
              border-bottom: none;
              border-left: none;
              border-top-left-radius: ${radius.default};
              border-top-right-radius: ${radius.default};
              &:first-of-type {
                border-left: ${border.default};
              }
              &.active {
                border-bottom: 1px solid #f1f4f6;
              }
            }
          `}
        >
          {views.map(({ name, label }) => (
            <ViewTab
              key={`tab-${name}`}
              name={name}
              active={name === activeView}
              label={t(label)}
            />
          ))}
        </ul>

        {views.map(({ name }) => (
          <View
            name={name}
            key={`view-${name}`}
            active={name === activeView}
            organisationId={userOrganisation.id}
          />
        ))}
      </div>
    </Fragment>
  ) : null;
};

Transactions.propTypes = {
  canCreateTransactions: PropTypes.bool,
  userOrganisation: PropTypes.shape({
    id: PropTypes.string,
  }),
  views: PropTypes.array.isRequired,
  activeView: PropTypes.string.isRequired,
};

Transactions.defaultProps = {
  canCreateTransactions: false,
  userOrganisation: null,
};

export default Transactions;
