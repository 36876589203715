import React from 'react';
import { useTranslation } from 'react-i18next';
import OauthSync from '../common/OauthSync';
import logo from './xero.png';
import step1 from './xero1.png';
import step2 from './xero2.png';

const XeroSync = () => {
  const { t } = useTranslation('integrations');

  return (
    <OauthSync platform="xero" logo={logo}>
      <p>{t('xero.connect.step1')}</p>
      <img alt="" src={step1} />
      <p>{t('xero.connect.step2')}</p>
      <img alt="" src={step2} />
      <p>{t('xero.connect.step3')}</p>
    </OauthSync>
  );
};

export default XeroSync;
