/* eslint-disable max-len */
export default [
  {
    title: 'Was deckt Hokodo ab?',
    body: `
    Wir sichern Sie gegen Nicht-Zahlung einer gültigen Rechnung ab, egal ob Ihr Kunde insolvent ist oder seine Rechnung einfach nicht bezahlt. 
    
 Wir können Sie nicht absichern gegen:
    
* Nicht-Zahlung einer strittigen Rechnung. Sie müssen den Streitfall mit Ihrem Kunden klären, bevor Sie einen Anspruch geltend machen können. 
* Beschädigung oder Verlust von Waren beim Transport.
* Aktivitäten und Anordnungen einer Regierung, die die Bezahlung Ihrer Rechnung verhindern, wie z.B. Krieg, Beschlagnahme von Eigentum oder Devisenkontrollen.
* Ansprüche, die das Ergebnis von Betrug oder Unredlichkeit Ihres Unternehmens sind.
* Ansprüche, die einen Verstoß gegen Sanktionen oder Anti-Geldwäsche-Bestimmungen erfordern würden.
    `,
  },
  {
    title: 'Wie funktioniert es?',
    body: `
* Sobald eine Rechnung fällig ist, versuchen Sie wie gewohnt, diese einzuziehen.
* Wenn der Kunde nach 45 Tagen immer noch nicht bezahlt hat, übergeben Sie die Rechnung an unseren spezialisierten Inkassopartner, der die Rechnung in Ihrem Namen einfordert.
* Wenn der Inkassopartner erfolgreich ist, zahlt er Ihnen die Rechnungssumme abzüglich einer Inkassogebühr von {{COLLECTION_PERC}}% + MwSt. aus. Diese Inkassogebühr kann von Ihrem Kunden beglichen werden (optional), in diesem Fall erhalten Sie den vollen Rechnungsbetrag.
* Wenn der Inkassopartner nicht in der Lage ist, den Rechnungsbetrag innerhalb von 60 Tagen zu erhalten, zahlen wir Ihnen 90% Ihres Netto-Rechnungsbetrages. 
    `,
  },
  {
    title: 'Ist Hokodo ein Inkasso-Unternehmen?',
    body: `
* Der Forderungseinzug erfolgt durch unseren Inkassopartner. Wenn Sie einen Anspruch geltend machen wollen, müssen Sie den Service unseres Inkassopartners nutzen. Wenn Sie unseren Inkassopartner nicht nutzen möchten, sollten Sie keine Rechnungsabsicherung abschließen.
* Sobald Ihre Rechnung 45 Tage überfällig ist, können Sie die Rechnung an unseren Inkassopartner übergeben. Sie müssen dies jedoch nicht sofort tun: Sie haben ein 30-Tage-Fenster, in dem Sie die Rechnung selbst weiter einziehen können (d.h. bis zu 75 Tage über dem Fälligkeitsdatum). Wenn Sie jedoch die Rechnung nicht bis zum Ablauf dieses 30-Tage-Fensters an unseren Inkassopartner übergeben haben, können Sie keine Ansprüche mehr geltend machen.
* Sobald Sie die Rechnung unserem Inkassopartner übergeben haben, wird eine Inkassogebühr von {{COLLECTION_PERC}}% + MwSt. auf den eingezogenen Betrag fällig.
* Wenn der Inkassopartner nicht in der Lage ist, die Rechnung innerhalb von 60 Tagen einzuziehen, zahlen wir Ihnen den abgesicherten Betrag (90% des Nettorechnungsbetrages) aus.
    `,
  },
  {
    title: 'Was passiert, wenn mein Kunde eine Rechnung bestreitet?',
    body: `
* Manchmal bestreiten Kunden die Gültigkeit einer Rechnung. Dies kann aus einer Vielzahl von Gründen geschehen. Zum Beispiel könnten sie behaupten, dass die gelieferten Waren/Dienstleistungen mangelhaft oder von schlechter Qualität waren oder, dass sie die in Rechnung gestellten Waren oder Dienstleistungen gar nicht bestellt haben.
* In diesem Fall müssen Sie diesen Streitfall mit Ihrem Kunden selbst lösen, bevor wir Ihren Anspruch auf eine unbezahlte Rechnung prüfen können. Wir werden Ihren Anspruch in der Zwischenzeit zurückstellen.
* Sobald Sie den Streitfall (entweder auf dem Verhandlungsweg oder auf dem Rechtsweg) beigelegt und sich mit Ihrem Kunden auf den fälligen Betrag geeinigt haben, beginnt Ihr Versicherungsschutz wieder. 
* Wenn Ihr Kunde Ihnen immer noch nicht den vereinbarten Betrag bezahlt, werden wir Ihre Ansprüche begleichen (bis zu 90% des ursprünglichen Nettorechnungsbetrags oder des vereinbarten fälligen Betrags, je nachdem, welcher Betrag niedriger ist).
    `,
  },
  {
    title: 'Was sind Bedingungen für Rechnungsabsicherung?',
    body: `
* Sie müssen uns eine gültige Rechnung vorlegen, die innerhalb von 30 Tagen nach der Lieferung der Waren und/oder Dienstleistungen ausgestellt wurde. Dies muss durch einen Nachweis belegt werden, dass der Käufer den Kauf bestätigt hat, z.B. durch eine Bestellung, eine E-Mail-Bestätigung und/oder einen verbindlichen Vertrag. Wenn Sie nur eine mündliche Bestätigung der Bestellung erhalten haben, können Sie dennoch eine Rechnungsabsicherung erwerben. Wir können keine Ansprüche für Rechnungen begleichen, für die Ihr Kunde bestreitet, eine Bestellung aufgegeben zu haben.
       
* Sie müssen bestätigen, dass der Kunde zum Zeitpunkt des Kaufs der Absicherung nach bestem Wissen und Gewissen nicht zahlungsunfähig ist oder nicht in der Lage ist, die Rechnung zu begleichen und der Kunde keine weiteren offenen Rechnungen bei Ihnen hat, die bereits mehr als 45 Tage überfällig sind.  
  * Wir erwarten nicht, dass Sie die Zahlungsfähigkeit Ihres Kunden zusätzlich prüfen. Wenn Sie jedoch im Rahmen Ihrer normalen Geschäftstätigkeit feststellen, dass Ihr Kunde wahrscheinlich nicht in der Lage sein wird, Ihre Rechnung zu bezahlen, können Sie die Rechnungsabsicherung von Hokodo nicht nachträglich erwerben.
          
* Sie müssen angemessene Maßnahmen ergreifen, um Forderungsausfälle auf ein Minimum zu reduzieren. Dazu gehört, die Zahlungsbedingungen nicht über die ursprüngliche Rechnung hinaus zu verlängern und keine Zahlungspläne von Ihren Kunden zu akzeptieren.

* Sie müssen den Bedingungen von Hokodo nachkommen, einschließlich der Abtretung Ihrer unbezahlten Rechnungen an den Inkassopartner (Forderungsübergang) als Teil unseres Schadenregulierungsverfahrens.
    `,
  },
  {
    title: 'Wer sichert die Verischerungspolice ab?',
    body: `
  Ihre Versicherung wird von Lloyd's Brussels, einer Einheit von SCOR Global P&C, dem Nichtlebensversicherungs-Segment der SCOR-Gruppe, gesichert.    `,
  },
  {
    title:
      'Benötige ich eine Bestellung oder eine andere Bestätigung des Kunden, um eine rechnung abzusichern?',
    body: `
  Hokodo benötigt keine schriftliche Bestätigung, damit Sie eine Rechnung absichern können. Falls Ihr Kunde jedoch bestreitet, die Bestellung aufgegeben zu haben, sind Sie ohne schriftliche Bestätigung nicht abgesichert. Solange der Kunde die Bestellung nicht bestreitet, ist das Fehlen einer schriftlichen Bestätigung kein Problem.    `,
  },
  {
    title: 'Ich möchte rechtliche informationen über hokodo.',
    body: `
Hokodo:

* ist eine Vereinfachte Aktiengesellschaft in Frankreich, die im Handels- und Gesellschaftsregister (RCS) unter der Nummer 847 859 352 eingetragen ist und deren Sitz sich in 39 rue de la chaussée d'antin, 75009 Paris befindet
* ist beim einheitlichen Register der Versicherungs-, Bank- und Finanzvermittler (ORIAS) unter der Registrierungsnummer 19001909 als Versicherungs- oder Rückversicherungsmakler (COA) registriert
* hat keine Beteiligung von 10% oder mehr an den Stimmrechten oder dem Kapital an unserem Versicherungsträger und unser Versicherungsträger hat keine Beteiligung von 10% oder mehr der Stimmrechte oder des Kapitals an Hokodo
* handelt für und im Namen der in unseren Policy-Dokumenten genannten Versicherungsträger
    `,
  },
  {
    title: 'Wie wird Hokodo bezahlt?',
    body: `
* Hokodo SAS schließt die Police mit dem Versicherer in Ihrem Namen ab. Sie bezahlen uns dafür keine Gebühr. Wir erhalten vom Versicherer eine Provision, die einen Prozentsatz der Gesamtprämie ausmacht.
* Wir erhalten auch eine Gewinnkommission, die auf der versicherungstechnischen Rentabilität des von uns vermittelten Geschäfts basiert.
    `,
  },
  {
    title: 'Sie wollen noch mehr Details?',
    body: `
* Laden Sie unsere Musterprämie herunter: [Musterprämie]({{SPECIMEN_POLICY}})
* Kontaktieren Sie uns über die unten angegebene E-Mail-Adresse oder Telefonnummer.
    `,
  },
];
