export const getPaymentErrorFromQuote = quote => {
  return quote.payment_error && Object.keys(quote.payment_error).length > 0
    ? quote.payment_error
    : null;
};

export const getHasAmountToPayFromQuote = quote => {
  return !!(quote.total_price && Number(quote.total_price) > 0);
};

export const getQuoteStatusFromQuote = quote => {
  return quote.status;
};

export default {
  getPaymentErrorFromQuote,
  getHasAmountToPayFromQuote,
  getQuoteStatusFromQuote,
};
