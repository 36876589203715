import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { useTranslation } from 'react-i18next';
import Heading from '../../atoms/Heading';
import Context from '../../../Context';

const LoadingScreen = ({ transaction }) => {
  const { t } = useTranslation('loader');

  return transaction && (transaction.quote || transaction.policy) ? null : (
    <Context.Consumer>
      {({ theme: { zIndex, colors } }) => (
        <div
          css={css`
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: ${zIndex.modal};
            background: ${colors.white};
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          `}
        >
          <Heading level={3}>{t('title')}</Heading>
          <p>{t('description')}</p>
        </div>
      )}
    </Context.Consumer>
  );
};

LoadingScreen.propTypes = {
  transaction: PropTypes.shape({
    quote: PropTypes.object,
    policy: PropTypes.object,
  }),
};

LoadingScreen.defaultProps = {
  transaction: null,
};

export default LoadingScreen;
