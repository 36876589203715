import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import observableRequest from '../../lib/requestHelpers';
import sessionModule from './modules/session';
import trackingModule from './modules/tracking';
import transactionModule from './modules/transaction';

import { history, isDev } from '../environment';

const rootEpic = combineEpics(trackingModule.epic, transactionModule.epic);

const rootReducer = combineReducers({
  session: sessionModule.reducer,
  transaction: transactionModule.reducer,
  router: connectRouter(history),
});

const epicMiddleware = createEpicMiddleware({
  dependencies: { observableRequest },
});

const enableHMR = store => {
  if (isDev() && module.hot) {
    module.hot.accept('./index', () => {
      store.replaceReducer(rootReducer);
    });
  }
};

export default (initialState = {}, additionalMiddleware = []) => {
  const composeEnhancers = isDev() ? composeWithDevTools({}) : compose;

  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(
      applyMiddleware(
        epicMiddleware,
        routerMiddleware(history),
        ...additionalMiddleware,
      ),
    ),
  );

  epicMiddleware.run(rootEpic);

  enableHMR(store);

  return { store };
};
