import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { getTransactionStatus } from '@hokodo/core/lib/transactionHelpers';
import Checkout from '@components/Checkout';
import QuotePreCheckout from './QuotePreCheckout';
import QuoteDeclined from './QuoteDeclined';

const Quote = ({ transaction }) => {
  const [checklistComplete, setChecklistComplete] = useState(false);

  const onChecklistComplete = () => {
    setChecklistComplete(true);
  };

  if (transaction) {
    switch (getTransactionStatus(transaction)) {
      case 'protected':
        return <Redirect to={`/policy/${transaction.id}`} />;

      case 'checked':
        return checklistComplete ? (
          <Checkout transaction={transaction} />
        ) : (
          <QuotePreCheckout
            onChecklistComplete={onChecklistComplete}
            transaction={transaction}
          />
        );

      case 'declined':
        return <QuoteDeclined transaction={transaction} />;

      default:
        return <Redirect to={`/invoice/${transaction.id}`} />;
    }
  }

  return null;
};

Quote.propTypes = {
  transaction: PropTypes.object.isRequired,
};

export default Quote;
