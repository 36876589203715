import { createPrefix, createAction } from '@hokodo/core/lib/actionHelpers';

const prefix = createPrefix('auth');

export const AUTH_LOGIN = prefix('AUTH_LOGIN');
export const AUTH_LOGIN_SUCCESS = prefix('AUTH_LOGIN_SUCCESS');
export const AUTH_LOGIN_FAILURE = prefix('AUTH_LOGIN_FAILURE');
export const login = createAction(AUTH_LOGIN);
export const loginSuccess = createAction(AUTH_LOGIN_SUCCESS);
export const loginFailure = createAction(AUTH_LOGIN_FAILURE);

export const AUTH_LOGOUT = prefix('AUTH_LOGOUT');
export const AUTH_LOGOUT_SUCCESS = prefix('AUTH_LOGOUT_SUCCESS');
export const logout = createAction(AUTH_LOGOUT);
export const logoutSuccess = createAction(AUTH_LOGOUT_SUCCESS);

export const AUTH_REGISTRATION = prefix('AUTH_REGISTRATION');
export const AUTH_REGISTRATION_SUCCESS = prefix('AUTH_REGISTRATION_SUCCESS');
export const AUTH_REGISTRATION_FAILURE = prefix('AUTH_REGISTRATION_FAILURE');
export const registration = createAction(AUTH_REGISTRATION);
export const registrationSuccess = createAction(AUTH_REGISTRATION_SUCCESS);
export const registrationFailure = createAction(AUTH_REGISTRATION_FAILURE);

export const AUTH_CONFIRM_EMAIL = prefix('AUTH_CONFIRM_EMAIL');
export const AUTH_CONFIRM_EMAIL_SUCCESS = prefix('AUTH_CONFIRM_EMAIL_SUCCESS');
export const AUTH_CONFIRM_EMAIL_FAILURE = prefix('AUTH_CONFIRM_EMAIL_FAILURE');
export const confirmEmail = createAction(AUTH_CONFIRM_EMAIL);
export const confirmEmailSuccess = createAction(AUTH_CONFIRM_EMAIL_SUCCESS);
export const confirmEmailFailure = createAction(AUTH_CONFIRM_EMAIL_FAILURE);

export const AUTH_PASSWORD_RESET = prefix('AUTH_PASSWORD_RESET');
export const AUTH_PASSWORD_RESET_SUCCESS = prefix(
  'AUTH_PASSWORD_RESET_SUCCESS',
);
export const AUTH_PASSWORD_RESET_FAILURE = prefix(
  'AUTH_PASSWORD_RESET_FAILURE',
);
export const passwordReset = createAction(AUTH_PASSWORD_RESET);
export const passwordResetSuccess = createAction(AUTH_PASSWORD_RESET_SUCCESS);
export const passwordResetFailure = createAction(AUTH_PASSWORD_RESET_FAILURE);

export const AUTH_PASSWORD_CHOOSE = prefix('AUTH_PASSWORD_CHOOSE');
export const AUTH_PASSWORD_CHOOSE_SUCCESS = prefix(
  'AUTH_PASSWORD_CHOOSE_SUCCESS',
);
export const AUTH_PASSWORD_CHOOSE_FAILURE = prefix(
  'AUTH_PASSWORD_CHOOSE_FAILURE',
);
export const passwordConfirm = createAction(AUTH_PASSWORD_CHOOSE);
export const passwordConfirmSuccess = createAction(
  AUTH_PASSWORD_CHOOSE_SUCCESS,
);
export const passwordConfirmFailure = createAction(
  AUTH_PASSWORD_CHOOSE_FAILURE,
);
