import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const ViewTab = ({ setActiveView, name, data, active }) => {
  const { t } = useTranslation('dashboard');

  const { label, count } = data;

  return (
    <li className={active ? 'active' : ''}>
      <a
        href="#"
        role="button"
        onClick={event => {
          event.preventDefault();
          setActiveView({ activeView: name });
        }}
        className={active ? 'active' : ''}
      >
        {`${t(label)} (${count})`}
      </a>
    </li>
  );
};

ViewTab.propTypes = {
  name: PropTypes.string.isRequired,
  setActiveView: PropTypes.func.isRequired,
  data: PropTypes.shape({
    label: PropTypes.string,
    count: PropTypes.number,
  }).isRequired,
  active: PropTypes.bool,
};

ViewTab.defaultProps = {
  active: false,
};

export default ViewTab;
