/* eslint-disable max-len */
export default {
  invoiceProtection: {
    img: {
      src: 'protect-cash.svg',
      alt: 'amount collection',
    },
    text: `
### Garantie CashProtect 
Avec CashProtect, vous êtes protégé contre le risque de non-paiement de vos factures ou de faillite de l’un de vos clients. 
    
Vous êtes couvert à hauteur de 90% du montant HT de votre facture. 
    
`,
  },
  onlyValid: {
    img: {
      src: 'amount-insolvency.svg',
      alt: 'amount insolvency',
    },
    text: `
    ### Créances certaines, liquides et exigibles
    Si votre facture fait l’objet d’un litige, vous devrez régler le régler avec votre client avant que nous puissions traiter votre réclamation.
`,
  },
  proofOfPruchase: {
    img: {
      src: 'buyer-agreement.svg',
      alt: 'buyer insolvent',
    },
    text: `
### Preuve d'achat
Vous aurez besoin d'un bon de commande ou de tout autre document faisant office de confirmation de commande. En cas d’accord verbal entre vous et votre client, vous pouvez procéder à l’achat d’une police d’assurance, cependant, nous ne serons pas en mesure de vous indemniser en cas de litige sur la facture. 
`,
  },
  existingDistress: {
    img: {
      src: 'buyer-insolvent.svg',
      alt: 'amount collection',
    },
    text: `
### Client en difficulté ?
Vous ne devez pas procéder à l’achat de cette police d’assurance si vous savez que votre client : 
- est insolvable ; 
- fait face à des difficultés financières ; 
- ne sera pas en capacité de régler votre facture à échéance ; 
- est déjà débiteur à votre égard du montant d’une facture demeurée impayée plus de 45 jours au-delà de son échéance.
`,
  },
  recentInvoice: {
    img: {
      src: 'date-invoice.svg',
      alt: 'date invoice',
    },
    text: `
### Facture récente 
La facture doit avoir été émise dans les 30 jours suivant la livraison des marchandises ou la réalisation des prestations de services. 

N’oubliez pas de nous transmettre votre facture si celle-ci ne vous a pas été réglée dans les 45 jours suivant la date d’échéance. Nous nous chargerons de collecter le montant qui vous est dû. 
`,
  },
  passOnTime: {
    img: {
      src: 'collection-invoice.svg',
      alt: 'collection invoice insurer',
    },
    text: `
### Attention aux délais
N’oubliez pas de nous transmettre votre facture si celle-ci ne vous a pas été réglée dans les 45 jours suivant la date d’échéance. Votre réclamation ne sera recevable que si elle a bien été effectuée dans les délais impartis, c’est pourquoi nous vous enverrons un email de rappel. 
`,
  },
  carefulDelivery: {
    img: {
      src: 'product-transit.svg',
      alt: 'date invoice',
    },
    text: `
### Soyez prudents 
Nous vous rappelons que vous n’êtes pas couverts contre les pertes ou les dommages subis par les marchandises en transit.
    `,
  },
  weCollectPayments: {
    img: {
      src: 'amount-insolvency.svg',
      alt: 'amount collection',
    },
    text: `
### Nous nous occupons du recouvrement
Transmettez-nous votre facture si celle-ci ne vous a pas été réglée dans les 45 jours suivant la date d’échéance, et nous nous chargerons de collecter le montant qui vous est dû.  

Ce service est mis à la disposition de nos clients à un tarif préférentiel. 
    `,
  },
  readPolicyTerms: {
    img: {
      src: 'buyer-agreement.svg',
      alt: 'amount collection',
    },
    text: `
### Consultez nos conditions générales  

Avant de procéder à votre achat, nous vous recommandons de consulter notre [FAQ]({{FAQ_URL}}) et notre \
[contrat type]({{SPECIMEN_POLICY}}) \
afin de vous assurer que ce produit est adapté à vos besoins. 

Vous pouvez, avec notre accord, annuler la présente police à la condition expresse que la demande d’annulation, mentionnant les motifs de la demande, nous soit adressée au plus tard dans les 48 heures à compter de la date de souscription.
`,
  },
};
