import { connect } from 'react-redux';
import { hideModal } from '@redux/modules/ui/modal';
import { reloadViews } from '@redux/modules/transactions';
import { selectUserOrganisation } from '@redux/modules/user';
import Notification from './Notification';

export default connect(
  state => ({
    userOrganisation: selectUserOrganisation(state),
  }),
  {
    reloadViews,
    hideModal,
  },
)(Notification);
