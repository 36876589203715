import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { colors, shadow, radius, zIndex } from '@styles/theme';
import { Icon, Button } from '@hokodo/core';

const triggerPropTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  activated: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  hideIndicator: PropTypes.bool.isRequired,
};

const LinkTrigger = ({ text, activated, onClick, hideIndicator }) => (
  <a
    href="#"
    role="button"
    onClick={onClick}
    css={css`
      display: flex;
      flex-direction: row;
      align-items: normal;
    `}
  >
    {text}
    {!hideIndicator && (
      <Icon type={activated ? 'chevron-up' : 'chevron-down'} />
    )}
  </a>
);

LinkTrigger.propTypes = triggerPropTypes;

const ButtonTrigger = ({ text, activated, onClick, hideIndicator }) => (
  <Button onClick={onClick}>
    {text}
    {!hideIndicator && (
      <Icon type={activated ? 'chevron-up' : 'chevron-down'} />
    )}
  </Button>
);

ButtonTrigger.propTypes = triggerPropTypes;

const DropdownMenu = ({
  triggerStyle,
  children,
  selfClosing,
  position,
  ...rest
}) => {
  const ref = useRef(null);

  const [activated, setActivated] = useState(false);

  const handleActivateClick = event => {
    event.preventDefault();
    setActivated(!activated);
  };

  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      setActivated(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return (
    <div
      ref={ref}
      css={css`
        position: relative;
      `}
      role="switch"
      tabIndex="0"
      onClick={event => {
        if (selfClosing) handleActivateClick(event);
      }}
    >
      {triggerStyle === 'link' ? (
        <LinkTrigger
          onClick={handleActivateClick}
          activated={activated}
          {...rest}
        />
      ) : (
        <ButtonTrigger
          onClick={handleActivateClick}
          activated={activated}
          {...rest}
        />
      )}
      {activated && (
        <div
          css={css`
            position: absolute;
            ${position === 'right' && 'right: 0;'}
            z-index: ${zIndex.dropdownMenu};
            background-color: ${colors.white};
            border-radius: ${radius.default};
            box-shadow: ${shadow.default};
          `}
        >
          {children}
        </div>
      )}
    </div>
  );
};

DropdownMenu.propTypes = {
  children: PropTypes.node.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  selfClosing: PropTypes.bool,
  triggerStyle: PropTypes.oneOf(['link', 'button']),
  hideIndicator: PropTypes.bool,
  position: PropTypes.string,
};

DropdownMenu.defaultProps = {
  text: 'Open',
  selfClosing: false,
  triggerStyle: 'link',
  hideIndicator: false,
  position: 'left',
};

export default DropdownMenu;
