import * as actions from './actions';

export const initialState = {
  profile: null,
  memberships: [],
  currentMembership: null,
  isFetching: false,
  error: {},
};

export const getMembershipByOrganisation = (memberships, organisation) => {
  return (
    memberships.find(x => x.organisation.id === organisation.id) ||
    memberships[0]
  );
};

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actions.FETCH_USER:
    case actions.UPDATE_USER_PROFILE:
      return {
        ...state,
        isFetching: true,
        error: {},
      };

    case actions.FETCH_USER_SUCCESS: {
      const { memberships, ...profile } = payload;
      const currentMembership =
        profile.meta_data && profile.meta_data.organisation
          ? getMembershipByOrganisation(memberships, {
              id: profile.meta_data.organisation,
            })
          : memberships[0];
      return {
        ...state,
        profile,
        memberships,
        currentMembership,
        isFetching: false,
      };
    }

    case actions.SET_CURRENT_MEMBERSHIP: {
      const { organisation } = payload;

      return {
        ...state,
        currentMembership: getMembershipByOrganisation(
          state.memberships,
          organisation,
        ),
      };
    }

    case actions.UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        profile: payload,
      };

    case actions.FETCH_USER_FAILURE:
    case actions.UPDATE_USER_PROFILE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };

    case actions.SET_HAS_PURCHASED_POLICY:
      return {
        ...state,
        currentMembership: {
          ...state.currentMembership,
          organisation: {
            ...state.currentMembership.organisation,
            has_purchased_policy: true,
          },
        },
      };

    default:
      return state;
  }
};

export default reducer;
