import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import PaginationControl from './PaginationControl';
import FilterControls from './FilterControls';
import ViewHeadings from './ViewHeadings';
import ViewItem from './ViewItem';
import ViewItemBlank from './ViewItemBlank';

const View = ({
  name,
  active,
  pageSize,
  organisationId,
  fetchCollection,
  query,
  data,
}) => {
  const { filters, orderBy, isFetching, collection } = data;

  useEffect(() => {
    fetchCollection({ view: name, query });
  }, [pageSize, filters, orderBy, organisationId]);

  /* eslint-disable react/jsx-indent */
  return active ? (
    <Fragment>
      <ViewHeadings name={name} />

      <FilterControls name={name} />

      <div
        css={css`
          margin-bottom: 1rem;
        `}
      >
        {isFetching ? (
          <div
            css={css`
              position: relative;
            `}
          >
            <div
              css={css`
                height: 20px;
                width: 20px;
                margin: auto;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
              `}
              className="spinner"
            />
            {[...Array(pageSize).keys()].map(key => (
              <ViewItemBlank key={key} active />
            ))}
          </div>
        ) : (
          [...Array(pageSize).keys()].map(key => {
            return collection[key] ? (
              <ViewItem
                key={`${name}-item-${key}`}
                transaction={collection[key]}
              />
            ) : (
              <ViewItemBlank key={key} />
            );
          })
        )}
      </div>

      <PaginationControl name={name} />
    </Fragment>
  ) : null;
};

View.propTypes = {
  name: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  pageSize: PropTypes.number.isRequired,
  query: PropTypes.string.isRequired,
  data: PropTypes.shape({
    collection: PropTypes.array,
    isFetching: PropTypes.bool,
    orderBy: PropTypes.shape({
      param: PropTypes.string,
      desc: PropTypes.bool,
    }),
    filters: PropTypes.object,
  }).isRequired,
  fetchCollection: PropTypes.func.isRequired,
  organisationId: PropTypes.string.isRequired,
};

export default View;
