import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import { Card, Heading } from '@hokodo/core';
import { formatLocaleCurrency } from '@hokodo/core/lib/currencyHelpers';
import { formatLocaleDate } from '@hokodo/core/lib/datetimeHelpers';
import Context from '@hokodo/core/src/components/Context';

const CheckoutSummary = ({ transaction, lang }) => {
  const { t } = useTranslation('quote');

  const {
    net_amount,
    due_date,
    debtor,
    currency: transactionCurrency,
    quote: {
      premium,
      ipt,
      total_price,
      currency: quoteCurrency,
      insured: { currency: insuredCurrency, insured_amount },
    },
  } = transaction;

  const formatCurrency = (cur, num) => formatLocaleCurrency(lang, cur, num);

  return (
    <Context.Consumer>
      {({ applicationContext: { FAQ_URL, SPECIMEN_POLICY } }) => (
        <Card>
          <Heading level={3}>{t('title')}</Heading>

          <div
            css={css`
              > div {
                display: flex;
                justify-content: space-between;
                margin: 0 0 0.5rem;
              }
            `}
          >
            <div>
              <div>{t('net_amount')}</div>
              <div>{formatCurrency(transactionCurrency, net_amount)}</div>
            </div>

            <div>
              <div>{t('insured_amount')}</div>
              <div>{formatCurrency(insuredCurrency, insured_amount)}</div>
            </div>

            <div>
              <div>{t('debtor_name')}</div>
              <div>{debtor.name}</div>
            </div>

            <div>
              <div>{t('due_date')}</div>
              <div>{formatLocaleDate(lang, new Date(due_date))}</div>
            </div>

            <hr />

            <div>
              <div>{t('premium')}</div>
              <div>{formatCurrency(quoteCurrency, premium)}</div>
            </div>

            <div>
              <div>{t('ipt')}</div>
              <div>{formatCurrency(quoteCurrency, ipt)}</div>
            </div>

            <div>
              <div>{t('total_price')}</div>
              <div>{formatCurrency(quoteCurrency, total_price)}</div>
            </div>
          </div>

          <div
            dangerouslySetInnerHTML={{
              __html: t('footer', {
                interpolation: { escapeValue: false },
                specimenUrl: SPECIMEN_POLICY,
                faqUrl: FAQ_URL,
              }),
            }}
          />
        </Card>
      )}
    </Context.Consumer>
  );
};

CheckoutSummary.propTypes = {
  transaction: PropTypes.shape().isRequired,
  lang: PropTypes.string.isRequired,
};

export default CheckoutSummary;
