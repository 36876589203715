import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { messages as coreResources } from '@hokodo/core';
import { extend } from '@hokodo/core/lib/objectHelpers';
import { messages as localResources } from '@localisation';
import { MULTI_LANGUAGE_ENABLED } from '@environment';

const opts = MULTI_LANGUAGE_ENABLED ? {} : { lng: 'en-GB' };

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: extend(coreResources, localResources),
    fallbackLng: 'en-GB',
    interpolation: {
      escapeValue: false,
    },
    debug: true,
    detection: {
      order: ['localStorage', 'navigator'],
      lookupLocalStorage: 'i18nextLng',
      caches: ['localStorage'],
    },
    ...opts,
  });

export default i18n;
