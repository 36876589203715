import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field } from 'formik';
import { css } from '@emotion/core';
import { SUPPORTED_CURRENCIES } from '@environment';
import {
  Context,
  Button,
  TextField,
  SelectField,
  DateField,
  CompanyField,
} from '@hokodo/core';
import { getRegnum } from '@hokodo/core/lib/companyHelpers';
import { getValidationSchema } from '@hokodo/core/lib/formHelpers';
import { formatYMD, addDays } from '@hokodo/core/lib/datetimeHelpers';
import HelpCard from './HelpCard';

const InvoiceForm = ({ transaction, action, isFetching }) => {
  const { t } = useTranslation('editInvoice');

  const [helpCardId, setHelpCardId] = useState('');

  const getInitialValues = values => {
    const {
      debtor = null,
      debtor_name = '',
      debtor_country = '',
      debtor_regnum = '',
      creditor = null,
      creditor_name = '',
      creditor_country = '',
      creditor_regnum = '',
      issue_date = '',
      due_date = '',
      net_amount = '',
      number = '',
      currency = '',
    } = values;

    return {
      debtor: {
        match: debtor || null,
        name: debtor_name,
        country: debtor_country,
        regnum: debtor_regnum,
      },
      creditor: {
        match: creditor || null,
        name: creditor_name,
        country: creditor_country,
        regnum: creditor_regnum,
      },

      issue_date: issue_date ? new Date(issue_date) : new Date(),
      due_date: due_date ? new Date(due_date) : addDays(new Date(), 30),
      net_amount,
      number,
      currency,
    };
  };

  const currencyOptions = SUPPORTED_CURRENCIES.map(c => ({
    label: c,
    value: c,
  }));

  return (
    <Context.Consumer>
      {({ lang }) => (
        <div
          css={css`
            display: grid;
            grid-template-rows: auto;
            grid-template-columns: 7fr 5fr;
          `}
        >
          <Formik
            initialValues={{
              ...getInitialValues(transaction || {}),
              nextStep: '',
            }}
            validationSchema={getValidationSchema('InvoiceSchema', lang)}
            onSubmit={(values, { setSubmitting }) => {
              const {
                issue_date,
                due_date,
                debtor,
                creditor,
                ...rest
              } = values;

              /*
              Note for debtor/creditor objects:
              We should not overwrite existing debtor_* keys,
              so only populate these on create invoice (check
              if props.transaction is null)
              */

              const payload = {
                debtor: debtor.match.id,
                debtor_country: transaction
                  ? debtor.country
                  : debtor.match.country,
                debtor_name: transaction ? debtor.name : debtor.match.name,
                debtor_regnum: transaction
                  ? debtor.regnum
                  : getRegnum(debtor.match.identifiers),
                creditor: creditor.match.id,
                creditor_country: transaction
                  ? creditor.country
                  : creditor.match.country,
                creditor_name: transaction
                  ? creditor.name
                  : creditor.match.name,
                creditor_regnum: transaction
                  ? creditor.regnum
                  : getRegnum(creditor.match.identifiers),

                issue_date: formatYMD(issue_date),
                due_date: formatYMD(due_date),
                ...rest,
              };

              if (transaction) {
                action({
                  transactionId: transaction.id,
                  ...payload,
                });
              } else {
                action(payload);
              }

              setSubmitting(false);
            }}
          >
            {({ isSubmitting, submitForm, setFieldValue }) => {
              return (
                <Form>
                  <Field
                    name="debtor"
                    label={t('add.debtor_name')}
                    onFocus={() => {
                      setHelpCardId('existingDistress');
                    }}
                    component={CompanyField}
                  />

                  <Field
                    name="net_amount"
                    label={t('add.net_amount')}
                    component={TextField}
                    onFocus={() => {
                      setHelpCardId('invoiceProtection');
                    }}
                  />

                  <Field
                    name="currency"
                    label={t('add.currency')}
                    options={currencyOptions}
                    component={SelectField}
                  />

                  <div
                    css={css`
                      display: flex;
                      > div:first-of-type {
                        margin-right: 2rem;
                      }
                    `}
                  >
                    <Field
                      name="issue_date"
                      label={t('add.issue_date')}
                      component={DateField}
                      onFocus={() => {
                        setHelpCardId('recentInvoice');
                      }}
                    />

                    <Field
                      name="due_date"
                      label={t('add.due_date')}
                      component={DateField}
                    />
                  </div>

                  <Field
                    name="number"
                    label={t('add.invoice_number')}
                    component={TextField}
                  />

                  <Field
                    name="creditor"
                    label={t('add.creditor')}
                    component={CompanyField}
                    onFocus={() => {
                      setHelpCardId('readPolicyTerms');
                    }}
                  />

                  <div
                    css={css`
                      display: flex;
                      > button:first-of-type {
                        margin-right: 1rem;
                      }
                    `}
                  >
                    <Button
                      onClick={() => {
                        setFieldValue('nextStep', 'quote', false);
                        submitForm();
                      }}
                      className={isFetching ? 'spinner' : ''}
                      disabled={isSubmitting || isFetching}
                    >
                      {t('add.buttonGetQuote')}
                    </Button>

                    <Button
                      onClick={() => {
                        setFieldValue('nextStep', '', false);
                        submitForm();
                      }}
                      className={isFetching ? 'spinner' : ''}
                      disabled={isSubmitting || isFetching}
                      outline
                    >
                      {t('add.buttonSaveForLater')}
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>

          {helpCardId && <HelpCard cardId={helpCardId} />}
        </div>
      )}
    </Context.Consumer>
  );
};

InvoiceForm.propTypes = {
  transaction: PropTypes.object,
  action: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
};

InvoiceForm.defaultProps = {
  transaction: null,
  isFetching: false,
};

export default InvoiceForm;
