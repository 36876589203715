import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Context,
  APPLICATION_CONSTANTS,
  Tooltip,
  Button,
  Heading,
  Icon,
} from '@hokodo/core';
import { getRegnum } from '@hokodo/core/lib/companyHelpers';
import { formatLocaleCurrency } from '@hokodo/core/lib/currencyHelpers';
import { formatLocaleDate, addDays } from '@hokodo/core/lib/datetimeHelpers';
import { css } from '@emotion/core';
import { breakpoints } from '@styles/theme';
import Claim from '@components/Claim';

const {
  POLICY_STATUSES,
  ACTIVE_COLLECTION_POLICY_STATUSES,
} = APPLICATION_CONSTANTS;

const ViewItemExpanded = ({ transaction, showModal, markPaymentReceived }) => {
  const {
    id,
    policy,
    quote,
    debtor,
    debtor_name,
    contact,
    email,
    issue_date,
    due_date,
    currency,
    source,
  } = transaction;

  const { t } = useTranslation('dashboard');

  const markInvoiceAsPaid = () => {
    markPaymentReceived({ transactionId: id });
  };

  const handleNotifyClick = () => {
    showModal({ content: <Claim transaction={transaction} /> });
  };

  return (
    <Context.Consumer>
      {({ lang, applicationContext: { NONPAYMENT_NOTIFICATION } }) => {
        const formatCurrency = num => formatLocaleCurrency(lang, currency, num);

        const regIdentifierValue = debtor ? getRegnum(debtor.identifiers) : '';

        const policyUrl =
          policy && policy.documents && policy.documents.length
            ? policy.documents[0].url
            : '';
        const earliestNonPaymentNotificationDate = formatLocaleDate(
          'gb',
          addDays(new Date(due_date), NONPAYMENT_NOTIFICATION.earliest),
        );
        const latestNonPaymentNotificationDate = formatLocaleDate(
          'gb',
          addDays(new Date(due_date), NONPAYMENT_NOTIFICATION.latest),
        );

        const allowConfirmPayment =
          policy &&
          !(
            policy.status === POLICY_STATUSES.paid ||
            policy.status === POLICY_STATUSES.paid_distributor
          );

        const allowConfirmPaymentDisabled =
          allowConfirmPayment &&
          ACTIVE_COLLECTION_POLICY_STATUSES.find(x => x === policy.status);

        const allowNotifyNonPayment =
          policy && policy.status === POLICY_STATUSES.late_can_handover;

        const showClaimSection =
          policy &&
          (policy.status === POLICY_STATUSES.active ||
            policy.status === POLICY_STATUSES.late ||
            policy.status === POLICY_STATUSES.late_can_handover);

        return (
          <div
            css={css`
              background-color: #f8f8f8;
              border-bottom: 1px solid #dee2e6;
              padding: 0.5rem 2rem;
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;

                section {
                  padding: 0 1rem;
                  margin-bottom: 3rem;

                  > h5 {
                    border-bottom: 1px solid #dee2e6;
                  }

                  > div {
                    display: flex;
                    padding: 0.5rem 0;
                    border-bottom: 1px solid #dee2e6;
                    > div {
                      padding-right: 1rem;
                    }
                    div:nth-of-type(1) {
                      width: 40%;
                    }
                    div:nth-of-type(2) {
                      width: 60%;
                    }
                  }
                }

                @media (min-width: ${breakpoints.lg}) {
                  flex-direction: row;
                  flex-wrap: wrap;

                  section {
                    width: 50%;
                  }
                }
              `}
            >
              <section>
                <Heading level={5} underline>
                  {t('invoiceExpanded.sectionCustomerDetails.sectionTitle')}
                </Heading>
                <div>
                  <div>
                    {t('invoiceExpanded.sectionCustomerDetails.debtorName')}
                  </div>
                  <div>{debtor ? debtor.name : debtor_name}</div>
                </div>
                <div>
                  <div>
                    {t('invoiceExpanded.sectionCustomerDetails.debtorAddress')}
                  </div>
                  <div>{debtor ? debtor.address : ''}</div>
                </div>
                <div>
                  <div>
                    {t(
                      'invoiceExpanded.sectionCustomerDetails.debtorIdentifiers.registration',
                    )}
                  </div>
                  <div>{regIdentifierValue}</div>
                </div>
                {contact && (
                  <div>
                    <div>
                      {t('invoiceExpanded.sectionCustomerDetails.contact')}
                    </div>
                    <div>{contact}</div>
                  </div>
                )}
                {email && (
                  <div>
                    <div>
                      {t('invoiceExpanded.sectionCustomerDetails.email')}
                    </div>
                    <div>{email}</div>
                  </div>
                )}
              </section>

              <section>
                <Heading level={5} underline>
                  {t('invoiceExpanded.sectionInvoiceDetails.sectionTitle')}
                </Heading>
                <div>
                  <div>
                    {t('invoiceExpanded.sectionInvoiceDetails.issueDate')}
                  </div>
                  <div>{formatLocaleDate('gb', new Date(issue_date))}</div>
                </div>
                <div>
                  <div>
                    {t('invoiceExpanded.sectionInvoiceDetails.dueDate')}
                  </div>
                  <div>{formatLocaleDate('gb', new Date(due_date))}</div>
                </div>
                <div>
                  <div>
                    {t('invoiceExpanded.sectionInvoiceDetails.currency')}
                  </div>
                  <div>{currency}</div>
                </div>
              </section>

              <section>
                <Heading level={5} underline>
                  {t('invoiceExpanded.sectionPolicyDetails.sectionTitle')}
                </Heading>
                {policy && quote ? (
                  <Fragment>
                    <div>
                      <div>
                        {t('invoiceExpanded.sectionPolicyDetails.policyNumber')}
                      </div>
                      <div>{policy.number}</div>
                    </div>
                    <div>
                      <div>
                        {t('invoiceExpanded.sectionPolicyDetails.creationDate')}
                      </div>
                      <div>
                        {formatLocaleDate('gb', new Date(policy.created), true)}
                      </div>
                    </div>
                    <div>
                      <div>
                        {t(
                          'invoiceExpanded.sectionPolicyDetails.insuredAmount',
                        )}
                      </div>
                      <div>
                        {quote.insured_pc_net
                          ? t(
                              'invoiceExpanded.sectionPolicyDetails.insuredAmountValue',
                              {
                                insuredAmount: formatCurrency(
                                  quote.insured.insured_amount,
                                ),
                                insuredPerc: quote.insured_pc_net,
                                invoiceAmount: formatCurrency(
                                  transaction.net_amount,
                                ),
                              },
                            )
                          : t(
                              'invoiceExpanded.sectionPolicyDetails.insuredAmountValue',
                              {
                                insuredAmount: formatCurrency(
                                  quote.insured.insured_amount,
                                ),
                                insuredPerc: quote.insured_pc_gross,
                                invoiceAmount: formatCurrency(
                                  transaction.gross_amount,
                                ),
                              },
                            )}
                      </div>
                    </div>
                    <div>
                      <div>
                        {t('invoiceExpanded.sectionPolicyDetails.cost')}
                      </div>
                      <div>{formatCurrency(quote.total_price)}</div>
                    </div>
                    <div>
                      <div>
                        {t(
                          'invoiceExpanded.sectionPolicyDetails.policyDocument',
                        )}
                      </div>
                      <div>
                        {policyUrl ? (
                          <a
                            target="_blank"
                            href={policyUrl}
                            rel="noopener noreferrer"
                          >
                            <Icon type="file" />
                            PDF
                          </a>
                        ) : (
                          <span>
                            {t(
                              'invoiceExpanded.sectionPolicyDetails.policyDocumentNone',
                            )}
                          </span>
                        )}
                      </div>
                    </div>
                  </Fragment>
                ) : (
                  <p className="pb-2">
                    {t(
                      'invoiceExpanded.sectionPolicyDetails.noPoliciesAssociated',
                    )}
                  </p>
                )}
              </section>

              {showClaimSection ? (
                <section>
                  <Heading level={5} underline>
                    {t('invoiceExpanded.sectionClaimDetails.sectionTitle')}
                  </Heading>
                  <div>
                    <div>{t('invoiceExpanded.sectionClaimDetails.status')}</div>
                    <div className="text-capitalize">-</div>
                  </div>
                  <div>
                    <div>
                      {t(
                        'invoiceExpanded.sectionClaimDetails.earliestNotificationDate',
                      )}
                    </div>
                    <div>{earliestNonPaymentNotificationDate}</div>
                  </div>
                  <div>
                    <div>
                      {t(
                        'invoiceExpanded.sectionClaimDetails.latestNotificationDate',
                      )}
                    </div>
                    <div>{latestNonPaymentNotificationDate}</div>
                  </div>
                </section>
              ) : null}
            </div>

            <div
              css={css`
                display: flex;
                justify-content: center;
                margin-bottom: 2rem;

                button {
                  margin: 0 1rem;
                }
              `}
            >
              {transaction.policy ? (
                <Fragment>
                  {allowConfirmPayment ? (
                    allowConfirmPaymentDisabled ? (
                      // prettier-ignore
                      <Tooltip
                        element={(
                          <Button disabled fake>
                            {t(
                              'invoiceExpanded.sectionClaimDetails.markAsPaid',
                            )}
                          </Button>
                        )}
                      >
                        {t(
                          'invoiceExpanded.sectionClaimDetails.markAsPaidDisabled',
                        )}
                      </Tooltip>
                    ) : (
                      <Button onClick={markInvoiceAsPaid}>
                        {t('invoiceExpanded.sectionClaimDetails.markAsPaid')}
                      </Button>
                    )
                  ) : null}

                  {allowNotifyNonPayment && (
                    <Button onClick={handleNotifyClick}>
                      {t(
                        'invoiceExpanded.sectionClaimDetails.notifyNonPayment',
                      )}
                    </Button>
                  )}
                </Fragment>
              ) : source ? null : (
                <Link to={`/invoice/${id}`}>
                  <Button color="secondary">
                    {t('invoiceExpanded.sectionClaimDetails.editInvoice')}
                  </Button>
                </Link>
              )}
            </div>
          </div>
        );
      }}
    </Context.Consumer>
  );
};

ViewItemExpanded.propTypes = {
  transaction: PropTypes.shape({
    id: PropTypes.string,
    quote: PropTypes.shape({
      total_price: PropTypes.string,
      insured_pc_net: PropTypes.number,
      insured_pc_gross: PropTypes.number,
      insured: PropTypes.shape({
        insured_amount: PropTypes.string,
      }),
    }),
    contact: PropTypes.string,
    email: PropTypes.string,
    number: PropTypes.string,
    issue_date: PropTypes.string,
    due_date: PropTypes.string,
    currency: PropTypes.string,
    net_amount: PropTypes.string,
    gross_amount: PropTypes.string,
    policy: PropTypes.shape({
      number: PropTypes.string,
      created: PropTypes.string,
      status: PropTypes.string,
      documents: PropTypes.array,
    }),
    debtor: PropTypes.shape({
      name: PropTypes.string,
      address: PropTypes.string,
      identifiers: PropTypes.array,
    }),
    debtor_name: PropTypes.string,
    source: PropTypes.string,
  }).isRequired,
  showModal: PropTypes.func.isRequired,
  markPaymentReceived: PropTypes.func.isRequired,
};

export default ViewItemExpanded;
