import { connect } from 'react-redux';
import {
  selectUserMemberships,
  selectUserOrganisation,
  selectUserPlatform,
  setCurrentMembership,
} from '@redux/modules/user';
import MembershipSelect from './MembershipSelect';

export default connect(
  state => ({
    memberships: selectUserMemberships(state),
    userOrganisation: selectUserOrganisation(state),
    userPlatform: selectUserPlatform(state),
  }),
  { setCurrentMembership },
)(MembershipSelect);
