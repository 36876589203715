import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Heading, AuthFormBox } from '@hokodo/core';

const ConfirmEmail = ({ match, isFetching, confirmEmail }) => {
  const { t } = useTranslation('auth');

  useEffect(() => {
    const { key } = match.params;
    confirmEmail({ key });
  });

  return isFetching ? (
    <AuthFormBox>
      <Heading level={5}>{t('confirmEmail.pendingTitle')}</Heading>
      <p>{t('confirmEmail.pendingDescription')}</p>
    </AuthFormBox>
  ) : (
    <AuthFormBox>
      <Heading level={5}>{t('confirmEmail.successTitle')}</Heading>
      <p>{t('confirmEmail.successDescription')}</p>
    </AuthFormBox>
  );
};

ConfirmEmail.propTypes = {
  match: PropTypes.object.isRequired,
  isFetching: PropTypes.bool,
  confirmEmail: PropTypes.func.isRequired,
};

ConfirmEmail.defaultProps = {
  isFetching: false,
};

export default ConfirmEmail;
