import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { useTranslation } from 'react-i18next';

const Notification = ({ reloadViews, hideModal, error, userOrganisation }) => {
  const handleClick = event => {
    event.preventDefault();
    reloadViews({ organisationId: userOrganisation.id });
    hideModal();
  };

  const { t } = useTranslation('integrations');

  return error ? (
    <span>
      {t('common.failureNotification')} {error}
    </span>
  ) : (
    <span
      css={css`
        > a {
          color: inherit !important;
          text-decoration: underline;
        }
      `}
    >
      {t('common.successNotification')}{' '}
      <a href="#" onClick={handleClick}>
        {t('common.successNotificationAction')}
      </a>
    </span>
  );
};

Notification.propTypes = {
  reloadViews: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  error: PropTypes.string,
  userOrganisation: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

Notification.defaultProps = {
  error: '',
};

export default Notification;
