import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import * as actions from './actions';
import * as transactionActions from '../transaction/actions';

export const trackEventEpic = (action$) =>
  action$.pipe(
    ofType(transactionActions.FETCH_TRANSACTION_SUCCESS),
    switchMap(({ payload }) => {
      return of(
        actions.trackEvent({dataKey: "Quote Accepted", dataValue: payload})
      );
    }),
  );

export default combineEpics(
  trackEventEpic,
);
