import React from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import { css } from '@emotion/core';
import { AVAILABLE_COUNTRIES } from '../../../../constants';
import Label from '../../atoms/Label';

const OptionComponent = ({ data }) => (
  <div
    className="f32"
    css={css`
      display: flex;
      flex-direction: row;
      align-items: center;
      .flag {
        transform: scale(0.6);
      }
    `}
  >
    <i className={`flag ${data.code}`} />
    <span>{data.label}</span>
  </div>
);

OptionComponent.propTypes = {
  data: PropTypes.shape({
    code: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
};

const Option = props => {
  return (
    <components.Option {...props}>
      <OptionComponent {...props} />
    </components.Option>
  );
};

const SingleValue = props => {
  return (
    <components.SingleValue {...props}>
      <OptionComponent {...props} />
    </components.SingleValue>
  );
};

const CountrySelect = ({ useList, placeholder, label, field, form }) => {
  const { name, value, onBlur } = field;
  const { setFieldValue, errors, touched } = form;

  const options = AVAILABLE_COUNTRIES[useList];

  return (
    <div
      css={css`
        margin-bottom: 1rem;
      `}
    >
      {label && <Label text={label} htmlFor={name} />}
      <Select
        options={options}
        value={options ? options.find(option => option.value === value) : ''}
        components={{ Option, SingleValue }}
        placeholder={placeholder}
        onBlur={onBlur}
        onChange={option => setFieldValue(name, option.value)}
      />
      {errors[name] && touched[name] && (
        <div className="error">{errors[name]}</div>
      )}
    </div>
  );
};

CountrySelect.propTypes = {
  useList: PropTypes.oneOf(['customer', 'debtor', 'platform']),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
    onBlur: PropTypes.func,
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func,
    errors: PropTypes.object,
    touched: PropTypes.object,
  }).isRequired,
};

CountrySelect.defaultProps = {
  useList: 'customer',
  placeholder: '',
  label: '',
};

export default CountrySelect;
