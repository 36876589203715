import { of, concat } from 'rxjs';
import {
  map,
  switchMap,
  concatMap,
  catchError,
  delay,
  filter,
} from 'rxjs/operators';
import { ofType, combineEpics } from 'redux-observable';
import { push } from 'connected-react-router';
import { fetchUser } from '@redux/modules/user';
import { createSession, destroySession } from '@redux/modules/session';
import * as actions from './actions';

export const loginEpic = (action$, _, { observableRequest }) =>
  action$.pipe(
    ofType(actions.AUTH_LOGIN),
    switchMap(({ payload }) =>
      observableRequest({
        url: 'auth/login/',
        config: {
          method: 'POST',
          body: payload,
        },
      }).pipe(
        concatMap(({ response }) =>
          of(
            actions.loginSuccess(),
            createSession({ token: response.key }),
            fetchUser(),
            push('/'),
          ),
        ),
        catchError(({ response }) =>
          concat(of(actions.loginFailure(response)), of(destroySession())),
        ),
      ),
    ),
  );

export const registrationEpic = (action$, _, { observableRequest }) =>
  action$.pipe(
    ofType(actions.AUTH_REGISTRATION),
    switchMap(({ payload }) =>
      observableRequest({
        url: 'auth/registration/customer/',
        config: {
          method: 'POST',
          body: payload,
        },
      }).pipe(
        concatMap(() =>
          of(actions.registrationSuccess(), push('/register/check-email')),
        ),
        catchError(({ response }) => of(actions.registrationFailure(response))),
      ),
    ),
  );

export const resetPasswordEpic = (action$, _, { observableRequest }) =>
  action$.pipe(
    ofType(actions.AUTH_PASSWORD_RESET),
    switchMap(({ payload }) =>
      observableRequest({
        url: 'auth/password/reset/',
        config: {
          method: 'POST',
          body: payload,
        },
      }).pipe(
        concatMap(() =>
          of(actions.passwordResetSuccess(), push('/reset/check-email')),
        ),
        catchError(({ response }) =>
          concat(
            of(actions.passwordResetFailure(response)),
            of(push('/reset/error')),
          ),
        ),
      ),
    ),
  );

export const choosePasswordEpic = combineEpics(
  (action$, _, { observableRequest }) =>
    action$.pipe(
      ofType(actions.AUTH_PASSWORD_CHOOSE),
      switchMap(({ payload }) =>
        observableRequest({
          url: 'auth/password/reset/confirm/',
          config: {
            method: 'POST',
            body: payload,
          },
        }).pipe(
          concatMap(() =>
            of(
              actions.passwordConfirmSuccess(),
              push('/reset/password-changed'),
            ),
          ),
          catchError(({ response }) =>
            concat(
              of(actions.passwordConfirmFailure(response)),
              of(push('/reset/error')),
            ),
          ),
        ),
      ),
    ),
  action$ =>
    action$.pipe(
      ofType(actions.AUTH_PASSWORD_CHOOSE_SUCCESS),
      delay(3000),
      map(() => push('/login')),
    ),
);

export const confirmEmailEpic = combineEpics(
  (action$, _, { observableRequest }) =>
    action$.pipe(
      ofType(actions.AUTH_CONFIRM_EMAIL),
      switchMap(({ payload }) =>
        observableRequest({
          url: 'auth/registration/verify-email/',
          config: {
            method: 'POST',
            body: payload,
          },
        }).pipe(
          concatMap(({ response }) =>
            of(
              actions.confirmEmailSuccess(),
              createSession({ token: response.token }),
              fetchUser(),
            ),
          ),
          catchError(({ response }) =>
            concat(
              of(actions.confirmEmailFailure(response)),
              of(push('/confirm-email/error')),
            ),
          ),
        ),
      ),
    ),
  action$ =>
    action$.pipe(
      ofType(actions.AUTH_CONFIRM_EMAIL_SUCCESS),
      delay(3000),
      map(() => push('/')),
    ),
);

export const logoutEpic = action$ =>
  action$.pipe(
    ofType(actions.AUTH_LOGOUT),
    concatMap(() => of(destroySession(), actions.logoutSuccess(), push('/'))),
  );

export const rehydrateUserEpic = action$ =>
  action$.pipe(
    ofType('persist/REHYDRATE'),
    filter(({ payload }) => payload && payload.session.aut),
    map(() => fetchUser()),
  );

export default combineEpics(
  loginEpic,
  logoutEpic,
  registrationEpic,
  resetPasswordEpic,
  choosePasswordEpic,
  confirmEmailEpic,
  rehydrateUserEpic,
);
