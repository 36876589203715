import { createPrefix, createAction } from '@hokodo/core/lib/actionHelpers';

const prefix = createPrefix('ui');

export const FLASH_SHOW = prefix('FLASH_SHOW');
export const showFlash = createAction(FLASH_SHOW);

export const FLASH_TIMEOUT = prefix('FLASH_TIMEOUT');
export const timeoutFlash = createAction(FLASH_TIMEOUT);

export const FLASH_HIDE = prefix('FLASH_HIDE');
export const hideFlash = createAction(FLASH_HIDE);

export const FLASH_DELETE = prefix('FLASH_DELETE');
export const deleteFlash = createAction(FLASH_DELETE);

export const initialState = {};

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FLASH_SHOW: {
      const id = `fm${Date.now()}`;
      const {
        dismissable = true,
        content = '',
        timeout = false,
        type: messageType = 'info',
      } = payload;

      return {
        ...state,
        [id]: {
          id,
          visible: true,
          dismissable,
          content,
          timeout,
          type: messageType,
        },
      };
    }

    case FLASH_TIMEOUT:
      return {
        ...state,
        [payload]: {
          ...state[payload],
          timeout: false,
        },
      };

    case FLASH_HIDE:
      return {
        ...state,
        [payload]: {
          ...state[payload],
          visible: false,
        },
      };

    case FLASH_DELETE: {
      const { [payload]: omit, ...rest } = state;
      return rest;
    }

    default:
      return state;
  }
};

export default reducer;
