import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { pluck } from 'rxjs/operators';
import { css } from '@emotion/core';
import { breakpoints } from '@styles/theme';
import {
  Context,
  observableRequest,
  Checkout as CoreCheckout,
} from '@hokodo/core';
import CheckoutSummary from './CheckoutSummary';

const Checkout = ({
  transaction,
  token,
  setHasPurchasedPolicy,
  push,
  organisation,
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const [serverErrors, setServerErrors] = useState(null);

  const fetchPolicy = ({ quoteId }) => {
    setIsFetching(true);
    observableRequest({
      url: 'policies/',
      token,
      config: {
        method: 'POST',
        body: {
          quote: quoteId,
        },
      },
    })
      .pipe(pluck('response'))
      .subscribe(
        () => {
          setIsFetching(false);
          setHasPurchasedPolicy();
          push(`/policy/${transaction.id}`);
        },
        error => {
          setIsFetching(false);
          setServerErrors(error);
        },
      );
  };

  return (
    <Context.Consumer>
      {({ lang }) => (
        <div
          css={css`
            display: flex;
            flex-direction: column;

            @media (min-width: ${breakpoints.lg}) {
              flex-direction: row;
            }
          `}
        >
          <div
            css={css`
              flex: 0 0 60%;
              padding: 0 1rem;
            `}
          >
            <CoreCheckout
              fetchPolicy={fetchPolicy}
              serverErrors={serverErrors}
              isFetching={isFetching}
              transaction={transaction}
              onPollingSuccess={() => {
                setHasPurchasedPolicy();
                push(`/policy/${transaction.id}`);
              }}
              onPollingFailure={() => {}}
              organisationId={(organisation && organisation.id) || null}
            />
          </div>

          <div
            css={css`
              flex: 0 0 40%;
              padding: 0 1rem;
            `}
          >
            <CheckoutSummary transaction={transaction} lang={lang} />
          </div>
        </div>
      )}
    </Context.Consumer>
  );
};

Checkout.propTypes = {
  transaction: PropTypes.shape({
    id: PropTypes.string,
    quote: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  token: PropTypes.string.isRequired,
  setHasPurchasedPolicy: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  organisation: PropTypes.shape({
    id: PropTypes.string,
  }),
};

Checkout.defaultProps = {
  organisation: null,
};

export default Checkout;
