import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import Input from '../../atoms/Input';
import Label from '../../atoms/Label';
import Icon from '../../atoms/Icon';

const PasswordField = ({ label, placeholder, form, field, disabled }) => {
  const { name, value = '', onBlur, onChange } = field;
  const { errors, touched } = form;

  const [reveal, setReveal] = useState(false);

  const handleReveal = event => {
    event.preventDefault();
    setReveal(!reveal);
  };

  return (
    <div
      css={css`
        margin-bottom: 1rem;
      `}
    >
      {label && <Label text={label} htmlFor={name} />}
      <div
        css={css`
          position: relative;
          a {
            position: absolute;
            right: 6px;
            top: 12px;
          }
        `}
      >
        <Input
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          type={reveal ? 'text' : 'password'}
          disabled={disabled}
        />
        <a href="#" role="button" onClick={handleReveal}>
          <Icon type={reveal ? 'eye-off' : 'eye'} />
        </a>
      </div>
      {errors[name] && touched[name] && (
        <div className="error">{errors[name]}</div>
      )}
    </div>
  );
};

PasswordField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.object,
    touched: PropTypes.object,
  }).isRequired,
  disabled: PropTypes.bool,
};

PasswordField.defaultProps = {
  label: '',
  placeholder: '',
  disabled: false,
};

export default PasswordField;
