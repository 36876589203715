import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field } from 'formik';
import { css } from '@emotion/core';
import { TextField, Button } from '@hokodo/core';
import { getValidationSchema } from '@hokodo/core/lib/formHelpers';
import { border } from '@styles/theme';
import { FORM_STEPS } from '@redux/modules/claim';

const CustomerDetails = ({ details, handleSubmit, isLocked, lang }) => {
  const { t } = useTranslation('notify');

  const {
    debtor_contact_name,
    debtor_address,
    debtor_contact_phone,
    debtor_city,
    debtor_contact_email,
    debtor_country,
    debtor_postcode,
    creditor_contact_name,
    creditor_contact_email,
    creditor_contact_phone,
  } = details;

  return (
    <Formik
      initialValues={{
        debtor_contact_name,
        debtor_address,
        debtor_contact_phone,
        debtor_city,
        debtor_contact_email,
        debtor_country,
        debtor_postcode,
        creditor_contact_name,
        creditor_contact_email,
        creditor_contact_phone,
        nextStep: '',
      }}
      onSubmit={values => {
        const { nextStep, ...rest } = values;
        handleSubmit(rest, nextStep);
      }}
      validationSchema={getValidationSchema('CustomerDetailsSchema', lang)}
    >
      {({ setFieldValue, submitForm }) => {
        return (
          <Form>
            <div
              css={css`
                display: grid;
                grid-row-gap: 2rem;
              `}
            >
              <div
                css={css`
                  display: grid;
                  grid-template-columns: 1fr 1fr;
                  grid-column-gap: 2rem;
                `}
              >
                <div>
                  <Field
                    name="debtor_contact_name"
                    label={t('steps.customerDetails.debtor_contact_name')}
                    component={TextField}
                    disabled={isLocked}
                  />

                  <Field
                    name="debtor_contact_phone"
                    label={t('steps.customerDetails.debtor_contact_phone')}
                    component={TextField}
                    disabled={isLocked}
                  />

                  <Field
                    name="debtor_contact_email"
                    label={t('steps.customerDetails.debtor_contact_email')}
                    component={TextField}
                    disabled={isLocked}
                  />
                </div>

                <div>
                  <Field
                    name="debtor_address"
                    label={t('steps.customerDetails.debtor_address')}
                    component={TextField}
                    disabled={isLocked}
                  />

                  <Field
                    name="debtor_city"
                    label={t('steps.customerDetails.debtor_city')}
                    component={TextField}
                    disabled={isLocked}
                  />

                  <Field
                    name="debtor_country"
                    label={t('steps.customerDetails.debtor_country')}
                    component={TextField}
                    disabled={isLocked}
                  />

                  <Field
                    name="debtor_postcode"
                    label={t('steps.customerDetails.debtor_postcode')}
                    component={TextField}
                    disabled={isLocked}
                  />
                </div>
              </div>

              <div
                css={css`
                  display: grid;
                  grid-template-columns: 1fr 1fr;
                  grid-column-gap: 2rem;
                  border-top: ${border.default};
                  padding-top: 2rem;
                  margin-top: 1rem;
                `}
              >
                <div>
                  <Field
                    name="creditor_contact_name"
                    label={t('steps.customerDetails.creditor_contact_name')}
                    component={TextField}
                    disabled={isLocked}
                  />

                  <Field
                    name="creditor_contact_phone"
                    label={t('steps.customerDetails.creditor_contact_phone')}
                    component={TextField}
                    disabled={isLocked}
                  />
                </div>

                <div>
                  <Field
                    name="creditor_contact_email"
                    label={t('steps.customerDetails.creditor_contact_email')}
                    component={TextField}
                    disabled={isLocked}
                  />
                </div>
              </div>

              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                `}
              >
                <Button
                  type="button"
                  onClick={() => {
                    setFieldValue('nextStep', FORM_STEPS.questions, false);
                    submitForm();
                  }}
                >
                  {t('modal.controls.continue')}
                </Button>

                <ul
                  css={css`
                    display: flex;
                    margin-top: 0.5rem;
                    li {
                      margin: 0 0.25rem;
                    }
                  `}
                >
                  <li>
                    <a
                      href="#"
                      rel="button"
                      onClick={event => {
                        event.preventDefault();
                        setFieldValue('nextStep', '', false);
                        submitForm();
                      }}
                    >
                      {t('modal.controls.save')}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

CustomerDetails.propTypes = {
  details: PropTypes.shape({
    debtor_contact_name: PropTypes.string,
    debtor_address: PropTypes.string,
    debtor_contact_phone: PropTypes.string,
    debtor_city: PropTypes.string,
    debtor_contact_email: PropTypes.string,
    debtor_country: PropTypes.string,
    debtor_postcode: PropTypes.string,
    creditor_contact_name: PropTypes.string,
    creditor_contact_email: PropTypes.string,
    creditor_contact_phone: PropTypes.string,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isLocked: PropTypes.bool.isRequired,
  lang: PropTypes.string.isRequired,
};

export default CustomerDetails;
