import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import { Context } from '@hokodo/core';
import { getHasAmountToPayFromQuote } from '@hokodo/core/lib/checkoutHelpers';
import { formatLocaleCurrency } from '@hokodo/core/lib/currencyHelpers';
import PromotionQuoteSummaryWatermark from './PromotionsQuoteSummaryWatermark';

const PromotionQuoteSummaryDiscountText = ({ quote }) => {
  const { price, currency, total_price } = quote;
  const { discount_amount } = price;
  const { t } = useTranslation('quoteSummary');
  return discount_amount ? (
    <Context.Consumer>
      {({ lang, theme: { colors } }) => {
        const hasAmountToPay = getHasAmountToPayFromQuote(quote);
        const promotionsWaterMark = hasAmountToPay ? null : (
          <PromotionQuoteSummaryWatermark />
        );
        const formatCurrency = (cur, num) =>
          formatLocaleCurrency(lang, cur, num);
        const promotionsTextStyle = {
          color: colors.primary,
        };
        return (
          <div
            css={css`
              div {
                display: flex;
                padding: 0.25rem 0;
                span {
                  width: 50%;
                }
              }

              em {
                font-size: 1.25rem;
              }
            `}
          >
            {promotionsWaterMark}
            <div id="quote-summary-discount-text" style={promotionsTextStyle}>
              <span>
                <em>{t('promotion')}</em>
              </span>
              <span>
                <em>{formatCurrency(currency, total_price)}</em>
              </span>
            </div>
            <div
              id="quote-summary-discount-subtext"
              style={promotionsTextStyle}
            >
              <span>{t('promotionSubText')}</span>
            </div>
          </div>
        );
      }}
    </Context.Consumer>
  ) : null;
};

PromotionQuoteSummaryDiscountText.propTypes = {
  quote: PropTypes.shape(),
};

PromotionQuoteSummaryDiscountText.defaultProps = {
  quote: null,
};

export default PromotionQuoteSummaryDiscountText;
