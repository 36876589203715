import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { pluck } from 'rxjs/operators';
import observableRequest from '../../lib/requestHelpers';
import { selectPlatformUser } from '../redux/modules/session/selectors';

const useSetupPaymentIntent = ({ organisationId, token }) => {
  const [error, setError] = useState(null);
  const [secret, setSecret] = useState(null);
  const [stripeKey, setStripeKey] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const platformUserId = useSelector(state => selectPlatformUser(state));

  useEffect(() => {
    setError(null);
    setSecret(null);
    setIsFetching(true);
    const subscription = observableRequest({
      url: `organisations/${organisationId}/stripe_payment_methods/setup_intent`,
      token,
      config: {
        method: 'POST',
        body: { user: platformUserId },
      },
    })
      .pipe(pluck('response'))
      .subscribe(
        response => {
          setSecret(response.client_secret);
          setStripeKey(response.publishable_key);
        },
        e => {
          setError(e.response ? e.response : e);
        },
        () => {
          setIsFetching(false);
        },
      );
    return () => {
      subscription.unsubscribe();
    };
  }, [organisationId]);

  return { error, secret, stripeKey, isFetching };
};

export default useSetupPaymentIntent;
