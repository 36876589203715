import { of, timer, from } from 'rxjs';
import {
  map,
  tap,
  last,
  ignoreElements,
  debounceTime,
  mergeMap,
  switchMap,
  catchError,
  filter,
  groupBy,
} from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import { push } from 'connected-react-router';
import { selectSessionAuthToken } from '@redux/modules/session';
import { selectViews, selectViewQuery } from './selectors';
import * as actions from './actions';

export default combineEpics(
  (action$, store$, { observableRequest }) =>
    action$.pipe(
      ofType(actions.FETCH_COLLECTION),
      filter(
        action => action.payload && action.payload.query && action.payload.view,
      ),
      groupBy(
        ({ payload }) => payload.view,
        f => f,
        group$ =>
          group$.pipe(
            // close existing streams if no event of a grouped action
            // is emitted 5 seconds in a row (prevents memory leaks)
            switchMap(() => timer(5000)),
          ),
      ),
      mergeMap(actionsGroupedByView$ =>
        actionsGroupedByView$.pipe(
          switchMap(({ payload }) => {
            const token = selectSessionAuthToken(store$.value);
            const { view, query } = payload;
            return observableRequest({
              url: `transactions/${query}`,
              token,
            }).pipe(
              map(({ response }) =>
                actions.fetchCollectionSuccess({ view, response }),
              ),
              catchError(({ response }) =>
                of(actions.fetchCollectionFailure({ view, response })),
              ),
            );
          }),
        ),
      ),
    ),

  (action$, store$) =>
    action$.pipe(
      ofType(actions.RELOAD_VIEWS),
      filter(action => action.payload && action.payload.organisationId),
      mergeMap(({ payload }) => {
        const { organisationId, successCallback } = payload;
        const views = selectViews(store$.value);
        return from(
          views.map(({ name }) =>
            actions.fetchCollection({
              view: name,
              query: selectViewQuery(store$.value, { name, organisationId }),
            }),
          ),
        ).pipe(
          last(),
          tap(() => {
            if (typeof successCallback !== 'undefined') {
              successCallback();
            }
          }),
          ignoreElements(),
        );
      }),
    ),

  (action$, store$, { observableRequest }) =>
    action$.pipe(
      ofType(actions.FETCH_ITEM),
      switchMap(({ payload }) => {
        const { transactionId } = payload;
        const token = selectSessionAuthToken(store$.value);
        return observableRequest({
          url: `transactions/${transactionId}/?expand=policy,quote,debtor,creditor,owner`,
          token,
        }).pipe(
          map(({ response }) => actions.fetchItemSuccess(response)),
          catchError(({ response }) =>
            of(actions.fetchItemFailure({ transactionId, response })),
          ),
        );
      }),
    ),

  (action$, store$, { observableRequest }) =>
    action$.pipe(
      ofType(actions.GET_QUOTE),
      switchMap(({ payload }) => {
        const { transactionId, redirect = false } = payload;
        const token = selectSessionAuthToken(store$.value);
        return observableRequest({
          url: 'quotes/',
          token,
          config: {
            method: 'POST',
            body: { transaction: transactionId },
          },
        }).pipe(
          mergeMap(({ response }) => {
            return redirect
              ? of(
                  actions.getQuoteSuccess(response),
                  push(`/quote/${transactionId}`),
                )
              : of(actions.getQuoteSuccess(response));
          }),
          catchError(({ response }) =>
            of(actions.getQuoteFailure({ transactionId, response })),
          ),
        );
      }),
    ),

  (action$, store$, { observableRequest }) =>
    action$.pipe(
      ofType(actions.MATCH_DEBTOR),
      switchMap(({ payload }) => {
        const { transaction, newDebtor } = payload;
        const token = selectSessionAuthToken(store$.value);
        return observableRequest({
          url: `transactions/${transaction.id}/match_debtor`,
          token,
          config: {
            method: 'PUT',
            body: {
              debtor: newDebtor.id,
            },
          },
        }).pipe(
          map(() => actions.matchDebtorSuccess({ transaction, newDebtor })),
          catchError(({ response }) =>
            of(actions.matchDebtorFailure(response)),
          ),
        );
      }),
    ),

  action$ =>
    action$.pipe(
      ofType(actions.MATCH_DEBTOR),
      map(() => ({
        type: 'hoko/ui/MODAL_SHOW',
        payload: {
          isLoader: true,
          content: 'Matching and updating debtor companies. Please wait.',
        },
      })),
    ),

  action$ =>
    action$.pipe(
      ofType(actions.MATCH_DEBTOR_SUCCESS),
      mergeMap(({ payload }) => {
        return of(
          { type: 'hoko/ui/MODAL_HIDE' },
          actions.updateMatchedDebtors(payload),
        );
      }),
    ),

  action$ =>
    action$.pipe(
      ofType(actions.MATCH_DEBTOR_FAILURE),
      map(() => ({ type: 'hoko/ui/MODAL_HIDE' })),
    ),

  (action$, store$, { observableRequest }) =>
    action$.pipe(
      ofType(actions.MARK_PAYMENT_RECEIVED),
      switchMap(({ payload }) => {
        const token = selectSessionAuthToken(store$.value);
        const { transactionId } = payload;
        return observableRequest({
          url: `transactions/${transactionId}/paid/`,
          token,
          config: { method: 'PUT' },
        }).pipe(
          mergeMap(() =>
            of(
              actions.markPaymentReceivedSuccess(),
              actions.fetchItem({ transactionId }),
            ),
          ),
          catchError(({ response }) =>
            of(actions.markPaymentReceivedFailure(response)),
          ),
        );
      }),
    ),

  action$ =>
    action$.pipe(
      ofType(actions.FETCH_COLLECTION_SUCCESS),
      debounceTime(3000),
      mergeMap(() => of(actions.fetchAllCollectionsSuccess())),
    ),
);
