import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field } from 'formik';
import { Button, Icon, TextField, DateField } from '@hokodo/core';
import { formatYMD } from '@hokodo/core/lib/datetimeHelpers';
import { css } from '@emotion/core';
import { border, padding, colors } from '@styles/theme';

const CustomFilters = ({ name, data, setFilters, active: isActive }) => {
  const { t } = useTranslation('dashboard');

  const { filters } = data;

  const [active, setActive] = useState(isActive);

  const getInitialValues = values => {
    const {
      debtor__name__icontains = '',
      gross_amount__gte = '',
      gross_amount__lte = '',
      issue_date__gte = '',
      issue_date__lte = '',
      due_date__gte = '',
      due_date__lte = '',
    } = values;

    return {
      debtor__name__icontains,
      gross_amount__gte,
      gross_amount__lte,
      issue_date__gte: issue_date__gte ? new Date(issue_date__gte) : null,
      issue_date__lte: issue_date__lte ? new Date(issue_date__lte) : null,
      due_date__gte: due_date__gte ? new Date(due_date__gte) : null,
      due_date__lte: due_date__lte ? new Date(due_date__lte) : null,
    };
  };

  return (
    <Fragment>
      {active && (
        <div>
          <Formik
            initialValues={getInitialValues(filters || {})}
            onSubmit={(values, { setSubmitting }) => {
              const selectedFilters = Object.keys(values).reduce((acc, key) => {
                const val = values[key];
                if (val) {
                  acc[key] = typeof val === 'string' ? val : formatYMD(val);
                }
                return acc;
              }, {});
              setFilters({ view: name, filters: selectedFilters });
              setSubmitting(false);
            }}
            onReset={(_, fb) => {
              setFilters({ view: name, filters: {} });
              fb.setFieldValue('debtor__name__icontains', '');
            }}
            render={({ isSubmitting }) => {
              return (
                <div
                  css={css`
                    padding: ${padding.default};
                    background-color: ${colors.white};
                    border-bottom: ${border.default};
                    div.row {
                      display: grid;
                      grid-column-gap: 2rem;
                      grid-template-columns: 1fr 1fr;
                    }
                    div.fieldset {
                      > div {
                        display: grid;
                        grid-column-gap: 2rem;
                        grid-template-columns: 1fr 1fr;
                      }
                      span {
                        display: block;
                        margin-bottom: 0.5rem;
                        font-weight: 600;
                      }
                    }
                  `}
                >
                  <Form>
                    <div className="row">
                      <Field
                        name="debtor__name__icontains"
                        label={t('filter.customer')}
                        component={TextField}
                      />

                      <div className="fieldset">
                        <span>{t('filter.amount')}</span>
                        <div>
                          <Field
                            name="gross_amount__gte"
                            placeholder={t('filter.rangeSelection.from')}
                            component={TextField}
                          />

                          <Field
                            name="gross_amount__lte"
                            placeholder={t('filter.rangeSelection.to')}
                            component={TextField}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="fieldset">
                        <span>{t('filter.issueDate')}</span>
                        <div>
                          <Field
                            name="issue_date__gte"
                            placeholder={t('filter.rangeSelection.from')}
                            component={DateField}
                          />

                          <Field
                            name="issue_date__lte"
                            placeholder={t('filter.rangeSelection.to')}
                            component={DateField}
                          />
                        </div>
                      </div>

                      <div className="fieldset">
                        <span>{t('filter.dueDate')}</span>
                        <div>
                          <Field
                            name="due_date__gte"
                            placeholder={t('filter.rangeSelection.from')}
                            component={DateField}
                          />

                          <Field
                            name="due_date__lte"
                            placeholder={t('filter.rangeSelection.to')}
                            component={DateField}
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      css={css`
                        display: flex;
                        justify-content: flex-end;
                        > button:last-of-type {
                          margin-left: 1rem;
                        }
                      `}
                    >
                      <Button type="reset">{t('filter.clear')}</Button>

                      <Button disabled={isSubmitting} type="submit">
                        {t('filter.apply')}
                      </Button>
                    </div>
                  </Form>
                </div>
              );
            }}
          />
        </div>
      )}

      <div
        css={css`
          color: ${colors.secondary};
          text-align: center;
          padding: 0.5rem 0;
          border-bottom: ${border.default};
        `}
      >
        <a
          href="#"
          role="button"
          onClick={event => {
            event.preventDefault();
            setActive(!active);
          }}
          tabIndex="0"
        >
          {t('filter.filter')}
        </a>
        <Icon type={active ? 'chevron-up' : 'chevron-down'} />
      </div>
    </Fragment>
  );
};

CustomFilters.propTypes = {
  name: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  active: PropTypes.bool,
};

CustomFilters.defaultProps = {
  active: false,
};

export default CustomFilters;
