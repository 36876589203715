export const NONPAYMENT_NOTIFICATION = {
  GB: { earliest: 45, latest: 75 },
};

export const DOCUMENT_TYPES = {
  GB: {
    invoice: 'invoice',
    statement: 'statement',
    proof_order: 'proof_order',
    proof_delivery: 'proof_delivery',
    shipping: 'shipping',
    correspondence: 'correspondence',
    other: 'other',
  },
};

export const COLLECTION_PERC = {
  GB: 6,
  FR: 8,
  DE: 8,
};

export const AVAILABLE_INTEGRATIONS = {
  GB: ['kashflow', 'quickbooks', 'xero'],
  FR: [],
  DE: [],
};

export const SUPPORT_CONTACT = {
  GB: { email: 'support@hokodo.co', phone: '+44 203 974 1620' },
  FR: { email: 'support@hokodo.co', phone: '+ 33 9 74 59 51 37' },
  DE: { email: 'support@hokodo.co', phone: '+44 203 974 1620' },
};

export const HELP_URL = {
  GB: 'https://customer.hokodo.co/help',
  FR: 'https://customer.hokodo.co/help',
  DE: 'https://customer-sb.hokodo.co/help',
};

export const FAQ_URL = {
  GB: 'https://customer.hokodo.co/help',
  FR: 'https://customer.hokodo.co/help',
  DE: 'https://customer-sb.hokodo.co/help',
};

export const FOOTER_STATEMENT = {
  GB: `Hokodo Services Ltd. is an Appointed Representative of 
      Innovative Risk Ltd. Hokodo's Invoice Protection is 
      underwritten by certain underwriters at Lloyd’s of London.`,
  FR: `Hokodo SAS est immatriculée auprès de l’Organisme pour le 
      registre des intermédiaires en Assurance (ORIAS) sous le 
      numéro 19 000 1909. Hokodo opère en partenariat avec SCOR 
      par l’intermédiaire de Lloyd’s Brussels, Tour Bastion – 
      étage 14, 5 place du Champ de Mars, 1050 Bruxelles, 
      Belgique. L’assurance est fournie par Lloyd’s Brussels.`,
  DE: `Hokodo SAS ist bei ORIAS (Organisme pour le Registre des 
      Intermédiaires en Assurance) unter der Nummer 19001909 als 
      Versicherungsvermittler registriert. Hokodos 
      Rechnungsabsicherung wird von ausgewählten Underwritern bei 
      Lloyd's Brussels versichert.`,
};

export const TERMS_CONDITIONS = {
  GB: 'https://static.hokodo.co/shared/hokodotermsandconditions.pdf',
  FR: 'https://static.hokodo.co/shared/Conditions_generales.pdf',
  DE: 'https://static.hokodo.co/shared/hokodotermsandconditions.pdf',
};

export const PRIVACY_POLICY = {
  GB: 'https://static.hokodo.co/shared/hokodoprivacypolicy.pdf',
  FR: 'https://static.hokodo.co/shared/Politique_de_confidentialite.pdf',
  DE: 'https://static.hokodo.co/shared/hokodoprivacypolicy.pdf',
};

export const SPECIMEN_POLICY = {
  GB: 'https://static.hokodo.co/shared/specimen-policy-invoice-protection.pdf',
  FR: 'https://static.hokodo.co/shared/Contrat_type.pdf',
  DE: 'https://static.hokodo.co/shared/musterpolicen.pdf',
};

export const STRIPE_URL = {
  GB: 'https://stripe.com',
  FR: 'https://stripe.com',
  DE: 'https://stripe.com',
};

export const CHECKOUT_LEGALS = {
  GB: { stripe: true, cover: true, accept: false },
  FR: { stripe: true, cover: false, accept: true },
  DE: { stripe: true, cover: true, accept: false },
};

export const MONTH_NAMES = {
  GB: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  FR: [
    'janvier',
    'février',
    'mars',
    'avril',
    'mai',
    'juin',
    'juillet',
    'août',
    'septembre',
    'octobre',
    'novembre',
    'décembre',
  ],
  DE: [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ],
};

export const CANCELLATION_WINDOW = {
  GB: '72 hours',
  FR: '48h',
};

export const INVOICE_ELIGIBILITY_LIMIT = {
  GB: { amount: 20000, currency: 'GBP' },
  FR: { amount: 25000, currency: 'EUR' },
};
