/* eslint-disable no-bitwise */
const shiftColor = (color, amount) => {
  let h = '';
  let c = color;

  if (color[0] === '#') {
    c = color.slice(1);
    h = '#';
  }

  const n = parseInt(c, 16);

  let r = (n >> 16) + amount;
  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  let b = ((n >> 8) & 0x00ff) + amount;
  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  let g = (n & 0x0000ff) + amount;
  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return `${h}${(g | (b << 8) | (r << 16)).toString(16)}`;
};

export const colors = {
  black: '#333333',
  white: '#ffffff',
  lightGrey: '#ababab',
  midGrey: '#6c757d',
  primary: '#ca8a2a',
  secondary: '#72909b',
  revampCTA: '#ca8a2a',
  revampLightGrey: '#efefef',
  revampSecondary: '#002c52',
  revampPrimary: '#ca8a2a',
  tertiary: '#e6e7e8',
  accent: '#002c52',
  border: 'rgba(0,0,0,.1)',
  screen: 'rgba(241,244,246,.84)',
  warning: {
    text: '#e4961b',
    bg: shiftColor('#e4961b', 80),
    border: shiftColor('#e4961b', 80),
  },
  error: {
    text: '#e02020',
    bg: shiftColor('#e02020', 90),
    border: shiftColor('#e02020', 20),
  },
  success: {
    text: '#498F00',
    bg: shiftColor('#498F00', 80),
    border: shiftColor('#498F00', 80),
  },
  info: {
    text: '#498F00',
    bg: shiftColor('#498F00', 80),
    border: shiftColor('#498F00', 80),
  },
};

export const fontSize = {
  default: '.875rem',
  input: '1rem',
};

export const radius = {
  default: '0.25rem',
  modal: '1rem',
};

export const border = {
  default: `1px solid ${colors.border}`,
};

export const shadow = {
  default: '0 2px 4px 0 rgba(161,161,161,0.5)',
};

export const padding = {
  default: '.75rem',
  input: '.7rem',
  button: '.5rem',
  tooltip: '.5rem',
  card: '.75rem 1rem',
  menu: '.5rem 1rem',
  modal: '1rem 2rem 2rem',
  flash: '.5rem 1rem',
};

export const breakpoints = {
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
};

export const zIndex = {
  footer: 10,
  matchCompany: 20,
  dropdownMenu: 30,
  tooltip: 40,
  modal: 50,
  flash: 60,
};
