import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import Heading from '../../atoms/Heading';
import Tooltip from '../../molecules/Tooltip';
import Context from '../../../Context';
import CrossSVG from '../../../../assets/img/cross-icon.svg';
import TickSVG from '../../../../assets/img/tick-icon.svg';

const Section = ({ sectionStyles, children }) => {
  const Styled = styled.section`
    ul {
      margin-top: 1rem;
      li {
        position: relative;
        margin: 1rem 0 1rem 1.5rem;
        line-height: 1.5;
        ::before {
          content: '';
          display: block;
          width: 1rem;
          height: 1rem;
          background-size: 100%;
          background-repeat: no-repeat;
          position: absolute;
          left: -1.5rem;
          top: 2px;
        }
      }
    }
    ${sectionStyles}
  `;

  return <Styled>{children}</Styled>;
};

Section.propTypes = {
  sectionStyles: PropTypes.object,
  children: PropTypes.node,
};

Section.defaultProps = {
  sectionStyles: null,
  children: null,
};

const KeyFeatures = ({ sectionStyles, components: { tick, cross } }) => {
  const { t } = useTranslation('keyFeatures');

  const Ticked =
    tick ||
    styled.li`
      ::before {
        background-image: url(${TickSVG});
      }
    `;

  const Crossed =
    cross ||
    styled.li`
      ::before {
        background-image: url(${CrossSVG});
        background-size: 10px !important;
        background-position: center !important;
      }
    `;

  return (
    <Context.Consumer>
      {({ applicationContext: { COLLECTION_PERC } }) => (
        <Fragment>
          <Section sectionStyles={sectionStyles}>
            <div>
              <Heading level={5} uppercase>
                {t('heading1')}
              </Heading>
            </div>

            <ul>
              <Ticked>{t('p1')}</Ticked>
              <Ticked>{t('p2')}</Ticked>
              <Ticked>{t('p3', { COLLECTION_PERC })}</Ticked>
              <Ticked>{t('p4')}</Ticked>
            </ul>
          </Section>

          <Section sectionStyles={sectionStyles}>
            <div>
              <Heading level={5} uppercase>
                {t('heading2')}
              </Heading>
            </div>

            <ul>
              <Crossed>
                {t('n1')} <Tooltip>{t('t1')}</Tooltip>
              </Crossed>
              <Crossed>
                {t('n2')} <Tooltip>{t('t2')}</Tooltip>
              </Crossed>
              <Crossed>
                {t('n3')} <Tooltip>{t('t3')}</Tooltip>
              </Crossed>
              <Crossed>
                {t('n4')} <Tooltip>{t('t4')}</Tooltip>
              </Crossed>
              <Crossed>
                {t('n5')} <Tooltip>{t('t5')}</Tooltip>
              </Crossed>
              <Crossed>
                {t('n6')} <Tooltip>{t('t6')}</Tooltip>
              </Crossed>
            </ul>
          </Section>
        </Fragment>
      )}
    </Context.Consumer>
  );
};

KeyFeatures.propTypes = {
  components: PropTypes.shape({
    tick: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string,
      PropTypes.elementType,
    ]),
    cross: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string,
      PropTypes.elementType,
    ]),
  }),
  sectionStyles: PropTypes.object,
};

KeyFeatures.defaultProps = {
  components: {
    tick: null,
    cross: null,
  },
  sectionStyles: null,
};

export default KeyFeatures;
