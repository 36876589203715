import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Subject } from 'rxjs';
import { debounceTime, pluck } from 'rxjs/operators';
import { observableRequest, Icon } from '@hokodo/core';
import { css } from '@emotion/core';
import { colors, zIndex, shadow, radius } from '@styles/theme';
import MatchCompanyResult from './MatchCompanyResult';
import MatchCompanySearch from './MatchCompanySearch';

class MatchCompany extends Component {
  constructor(props) {
    super(props);

    const {
      transaction: { debtor, policy },
    } = props;

    this.subject$ = new Subject().pipe(debounceTime(300));

    this.state = {
      active: false,
      results: [],
      isMatched: !!debtor,
      hasPolicy: !!policy,
    };
  }

  componentDidMount() {
    this.subscription = this.subject$.subscribe(debounced =>
      this.search({ query: debounced }),
    );
  }

  componentWillUnmount() {
    if (this.subscription) this.subscription.unsubscribe();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      transaction: { debtor, policy },
    } = nextProps;

    return {
      ...prevState,
      isMatched: !!debtor,
      hasPolicy: !!policy,
    };
  }

  deactivate = () => {
    this.setState({
      active: false,
      results: [],
    });
  };

  handleActivate = event => {
    const { active, hasPolicy } = this.state;
    if (active || hasPolicy) return;
    event.stopPropagation();
    this.setState({ active: true });
    const { debtor, debtor_name } = this.props.transaction;
    const query = debtor ? debtor.name || '' : debtor_name || '';
    if (query) this.search({ query });
  };

  handleDeactivate = event => {
    event.stopPropagation();
    this.deactivate();
  };

  handleSelect = selection => {
    this.lookup({
      limit: 1,
      country: 'GB',
      name: selection.title,
      reg_number: selection.company_number,
    });
    this.deactivate();
  };

  onSearchSuccess = response => {
    this.setState(() => ({
      results: response.items,
    }));
  };

  onLookupSuccess = response => {
    const { matchDebtor, transaction } = this.props;
    const { matches } = response;
    matchDebtor({ transaction, newDebtor: matches[0] });
  };

  onError = response => {
    console.error(response);
  };

  search = ({ query }) => {
    observableRequest({
      url: `search?q=${query}`,
      api: 'standalone',
    })
      .pipe(pluck('response'))
      .subscribe(this.onSearchSuccess, this.onError);
  };

  lookup = ({ limit, ...rest }) => {
    observableRequest({
      url: `companies/search?limit=1`,
      config: {
        method: 'POST',
        body: rest,
      },
    })
      .pipe(pluck('response'))
      .subscribe(this.onLookupSuccess, this.onError);
  };

  onInputChange = query => {
    if (!query) return;
    this.subject$.next(query);
  };

  render() {
    const { transaction } = this.props;
    const { debtor, debtor_name } = transaction;
    const display = debtor ? debtor.name || '--' : debtor_name || '--';

    const {
      handleDeactivate,
      handleActivate,
      handleSelect,
      onInputChange,
    } = this;

    const { active, results, isMatched, hasPolicy } = this.state;

    return (
      <div
        css={css`
          position: relative;
        `}
      >
        <div
          role="switch"
          tabIndex="0"
          onClick={handleDeactivate}
          css={css`
            display: ${active ? 'block' : 'none'};
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: ${zIndex.matchCompany};
            cursor: default;
          `}
        />
        <button
          type="button"
          onClick={handleActivate}
          css={css`
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            color: #e4961b;
            border: none;
            background: ${colors.white};
            text-align: left;
            cursor: pointer;
            ${isMatched && 'color: #252e33;'}
            font: inherit;
            padding: 0;
          `}
        >
          {!isMatched && <Icon type="alert-circle" size={18} />}
          <span
            css={css`
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              padding-right: 0.25rem;
            `}
          >
            {display}
          </span>
          {!hasPolicy && <Icon type="chevron-down" size={18} />}
        </button>
        {active ? (
          <div
            role="switch"
            tabIndex="0"
            onClick={event => {
              event.stopPropagation();
            }}
            css={css`
              position: absolute;
              z-index: ${zIndex.matchCompany + 1};
              width: 288px;
              border-radius: ${radius.default};
              background-color: ${colors.white};
              box-shadow: ${shadow.default};
            `}
          >
            <MatchCompanySearch onInputChange={onInputChange} />
            <div
              css={css`
                width: 288px;
                max-height: 284px;
                overflow-y: auto;
              `}
            >
              {results.map(company => (
                <MatchCompanyResult
                  key={company.company_number}
                  company={company}
                  onSelect={handleSelect}
                />
              ))}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

MatchCompany.propTypes = {
  transaction: PropTypes.shape().isRequired,
  matchDebtor: PropTypes.func.isRequired,
};

export default MatchCompany;
