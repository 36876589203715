import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Logo, Heading, Tooltip, Context } from '@hokodo/core';
import { getSymbolFromCurrency } from '@hokodo/core/lib/currencyHelpers';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { border } from '@styles/theme';
import { FORM_STEPS } from '@redux/modules/claim';

const Styled = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat(6, 16.67%);
`;

const ClaimHeader = ({ transaction, currentStep }) => {
  const {
    currency,
    number,
    debtor,
    issue_date,
    due_date,
    net_amount,
    gross_amount,
  } = transaction;

  const { t } = useTranslation('notify');

  const currencySymbol = getSymbolFromCurrency(currency);

  const activeHeadingProps = {
    underline: true,
  };

  const headings = {
    [FORM_STEPS.customer]: t('modal.progressHeadings.customerDetails'),
    [FORM_STEPS.questions]: t('modal.progressHeadings.questions'),
    [FORM_STEPS.documents]: t('modal.progressHeadings.uploadDocuments'),
    [FORM_STEPS.banking]: t('modal.progressHeadings.bankAccount'),
    [FORM_STEPS.confirmation]: t('modal.progressHeadings.finalConfirmation'),
    [FORM_STEPS.final]: t('modal.progressHeadings.submitted'),
  };

  return (
    <Context.Consumer>
      {({ applicationContext: { COLLECTION_PERC } }) => (
        <div
          css={css`
            display: grid;
            grid-row-gap: 2rem;
          `}
        >
          <div>
            <div
              css={css`
                display: flex;
                align-items: end;
                > div {
                  margin: 0 0 0 -0.5rem;
                }
              `}
            >
              <Logo width={124} />
              <Heading level={5}>{t('modal.title')}</Heading>
            </div>

            <p>{t('modal.subtitle')}</p>

            <div>
              {t('modal.description', { COLLECTION_PERC })}
              <Tooltip>{t('modal.tooltip')}</Tooltip>
            </div>

            <p>{t('modal.instruct1')}</p>
          </div>

          <div
            css={css`
              border-top: ${border.default};
              border-bottom: ${border.default};
              padding: 1rem 0;
            `}
          >
            <Styled>
              {/*
                To ensure the table columns fit the full width of the modal,
                adjust grid-template-columns above according to the number of columns.
                (e.g. for 5 columns use grid-template-columns: repeat(5, 20%); )
              */}
              <div>{t('modal.tableColumnHeadings.invoiceNumber')}</div>
              <div>{t('modal.tableColumnHeadings.customerName')}</div>
              <div>{t('modal.tableColumnHeadings.issueDate')}</div>
              <div>{t('modal.tableColumnHeadings.dueDate')}</div>
              <div>{t('modal.tableColumnHeadings.amountNet')}</div>
              <div>{t('modal.tableColumnHeadings.amountGross')}</div>
            </Styled>

            <Styled>
              <div>{number || ''}</div>
              <div>{debtor.name}</div>
              <div>{issue_date}</div>
              <div>{due_date}</div>
              <div>{`${currencySymbol}${Number(net_amount).toFixed(2) ||
                ''}`}</div>
              <div>{`${currencySymbol}${Number(gross_amount).toFixed(2) ||
                ''}`}</div>
            </Styled>
          </div>

          <ul
            css={css`
              display: flex;
              border-bottom: ${border.default};
              margin-bottom: 1rem;
              li {
                margin: 0;
                margin-right: 1rem;
                h5 {
                  margin: 0;
                }
              }
              li:last-of-type {
                margin-right: 0;
              }
            `}
          >
            {Object.keys(headings).map(key => {
              const props = key === currentStep ? activeHeadingProps : {};
              return (
                <li key={key}>
                  {React.createElement(
                    Heading,
                    { level: 5, ...props },
                    headings[key],
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </Context.Consumer>
  );
};

ClaimHeader.propTypes = {
  transaction: PropTypes.shape({
    currency: PropTypes.string,
    number: PropTypes.string,
    debtor: PropTypes.object,
    issue_date: PropTypes.string,
    due_date: PropTypes.string,
    net_amount: PropTypes.string,
    gross_amount: PropTypes.string,
  }).isRequired,
  currentStep: PropTypes.string.isRequired,
};

export default ClaimHeader;
