import React from 'react';
import { css } from '@emotion/core';
import { APPLICATION_CONSTANTS, StatusIcon } from '@hokodo/core';

const { POLICY_STATUSES } = APPLICATION_CONSTANTS;

const StatusTip = () => {
  return (
    <div
      css={css`
        > * {
          margin-bottom: 0.5rem;
        }
      `}
    >
      <StatusIcon status={POLICY_STATUSES.paid} />
      <StatusIcon status={POLICY_STATUSES.active} />
      <StatusIcon status={POLICY_STATUSES.late} />
      <StatusIcon status={POLICY_STATUSES.under_collection} />
      <StatusIcon status={POLICY_STATUSES.cancelled} />
      <StatusIcon status={POLICY_STATUSES.late_can_handover} />
      <StatusIcon status={POLICY_STATUSES.not_collectable} />
      <StatusIcon status={POLICY_STATUSES.dispute_pursual} />
      <StatusIcon status={POLICY_STATUSES.claim_submitted} />
      <StatusIcon status={POLICY_STATUSES.not_insured} />
    </div>
  );
};

export default StatusTip;
