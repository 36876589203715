import { createPrefix, createAction } from '@hokodo/core/lib/actionHelpers';

const prefix = createPrefix('transactions');

export const FETCH_COLLECTION = prefix('FETCH_COLLECTION');
export const FETCH_ALL_COLLECTIONS_SUCCESS = prefix(
  'FETCH_ALL_COLLECTIONS_SUCCESS',
);
export const FETCH_COLLECTION_SUCCESS = prefix('FETCH_COLLECTION_SUCCESS');
export const FETCH_COLLECTION_FAILURE = prefix('FETCH_COLLECTION_FAILURE');

export const fetchCollection = createAction(FETCH_COLLECTION);
export const fetchAllCollectionsSuccess = createAction(
  FETCH_ALL_COLLECTIONS_SUCCESS,
);
export const fetchCollectionSuccess = createAction(FETCH_COLLECTION_SUCCESS);
export const fetchCollectionFailure = createAction(FETCH_COLLECTION_FAILURE);

export const RELOAD_VIEWS = prefix('RELOAD_VIEWS');
export const reloadViews = createAction(RELOAD_VIEWS);

export const FETCH_ITEM = prefix('FETCH_ITEM');
export const FETCH_ITEM_SUCCESS = prefix('FETCH_ITEM_SUCCESS');
export const FETCH_ITEM_FAILURE = prefix('FETCH_ITEM_FAILURE');

export const fetchItem = createAction(FETCH_ITEM);
export const fetchItemSuccess = createAction(FETCH_ITEM_SUCCESS);
export const fetchItemFailure = createAction(FETCH_ITEM_FAILURE);

export const MATCH_DEBTOR = prefix('MATCH_DEBTOR');
export const MATCH_DEBTOR_SUCCESS = prefix('MATCH_DEBTOR_SUCCESS');
export const MATCH_DEBTOR_FAILURE = prefix('MATCH_DEBTOR_FAILURE');

export const matchDebtor = createAction(MATCH_DEBTOR);
export const matchDebtorSuccess = createAction(MATCH_DEBTOR_SUCCESS);
export const matchDebtorFailure = createAction(MATCH_DEBTOR_FAILURE);

export const UPDATE_MATCHED_DEBTORS = prefix('UPDATE_MATCHED_DEBTORS');
export const updateMatchedDebtors = createAction(UPDATE_MATCHED_DEBTORS);

export const SET_PAGE_SIZE = prefix('SET_PAGE_SIZE');
export const setPageSize = createAction(SET_PAGE_SIZE);

export const SET_ORDER_BY = prefix('SET_ORDER_BY');
export const setOrderBy = createAction(SET_ORDER_BY);

export const SET_FILTERS = prefix('SET_FILTERS');
export const setFilters = createAction(SET_FILTERS);

export const GET_QUOTE = prefix('GET_QUOTE');
export const GET_QUOTE_SUCCESS = prefix('GET_QUOTE_SUCCESS');
export const GET_QUOTE_FAILURE = prefix('GET_QUOTE_FAILURE');

export const getQuote = createAction(GET_QUOTE);
export const getQuoteSuccess = createAction(GET_QUOTE_SUCCESS);
export const getQuoteFailure = createAction(GET_QUOTE_FAILURE);

export const MARK_PAYMENT_RECEIVED = prefix('MARK_PAYMENT_RECEIVED');
export const MARK_PAYMENT_RECEIVED_SUCCESS = prefix(
  'MARK_PAYMENT_RECEIVED_SUCCESS',
);
export const MARK_PAYMENT_RECEIVED_FAILURE = prefix(
  'MARK_PAYMENT_RECEIVED_FAILURE',
);

export const markPaymentReceived = createAction(MARK_PAYMENT_RECEIVED);
export const markPaymentReceivedSuccess = createAction(
  MARK_PAYMENT_RECEIVED_SUCCESS,
);
export const markPaymentReceivedFailure = createAction(
  MARK_PAYMENT_RECEIVED_FAILURE,
);

export const SET_ACTIVE_VIEW = prefix('SET_ACTIVE_VIEW');
export const setActiveView = createAction(SET_ACTIVE_VIEW);
