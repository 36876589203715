import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import Context from '../../../Context';
import HokodoShield from '../../../../assets/img/hokodo_shield.svg';

const Card = ({
  children,
  borderColor,
  backgroundColor,
  title,
  footer,
  footerBackgroundColor,
  headerBackgroundColor,
  headerColor,
  footerColor,
  logo,
  titleBodySeparator,
}) => (
  <Context.Consumer>
    {({ theme: { colors, radius, padding } }) => (
      <Fragment>
        {title && (
          <div
            css={css`
              position: relative;
              background-color: ${headerBackgroundColor || colors.white};
              border: 1px solid
                ${borderColor || headerBackgroundColor || colors.white};
              border-top-left-radius: ${radius.default};
              border-top-right-radius: ${radius.default};
              padding: ${padding.card};
              color: ${headerColor || colors.black};
              > div {
                display: flex;
                align-items: center;
                height: 58px;
                span {
                  font-size: 24px;
                }
                img {
                  width: 42px;
                  margin-right: 1rem;
                }
              }
            `}
          >
            <div>
              {logo && <img src={HokodoShield} alt="" />}
              <span>{title}</span>
            </div>
              {titleBodySeparator && <hr />}
          </div>
        )}
        <div
          css={css`
            background-color: ${backgroundColor || colors.white};
            border: 1px solid ${borderColor || backgroundColor || colors.white};
            border-radius: ${radius.default};
            ${title && 'border-top-left-radius: 0; border-top-right-radius: 0;'}
            ${footer &&
              'border-bottom-left-radius: 0; border-bootom-right-radius: 0;'}
            padding: ${padding.card};
            ${!footer && 'margin: 0 0 1rem;'}
          `}
        >
          {children}
        </div>
        {footer && (
          <div
            css={css`
              background-color: ${footerBackgroundColor || colors.white};
              border: 1px solid
                ${borderColor || footerBackgroundColor || colors.white};
              border-bottom-left-radius: ${radius.default};
              border-bottom-right-radius: ${radius.default};
              padding: ${padding.card};
              margin: 0 0 1rem;
              color: ${footerColor || colors.black};
            `}
          >
            {footer}
          </div>
        )}
      </Fragment>
    )}
  </Context.Consumer>
);

Card.propTypes = {
  children: PropTypes.node,
  borderColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  title: PropTypes.string,
  titleBodySeparator: PropTypes.bool,
  footer: PropTypes.node,
  footerBackgroundColor: PropTypes.string,
  headerBackgroundColor: PropTypes.string,
  footerColor: PropTypes.string,
  headerColor: PropTypes.string,
  logo: PropTypes.bool,
};

Card.defaultProps = {
  children: '',
  borderColor: null,
  backgroundColor: null,
  title: null,
  titleBodySeparator: true,
  footer: null,
  footerBackgroundColor: '#002c52',
  headerBackgroundColor: null,
  footerColor: '#fff',
  headerColor: null,
  logo: false,
};

export default Card;
