import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { Link } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  Context,
  Button,
  Heading,
  AuthFormBox,
  TextField,
  PasswordField,
  ServerErrors,
} from '@hokodo/core';
import { getValidationSchema } from '@hokodo/core/lib/formHelpers';

const RegisterForm = ({ registration, isFetching, serverErrors, user }) => {
  const { t } = useTranslation('auth');

  return (
    <Context.Consumer>
      {({ lang }) => (
        <AuthFormBox>
          <Heading level={5} centered>
            {t('register.welcome')}
          </Heading>

          {serverErrors && <ServerErrors errors={serverErrors} />}

          <Formik
            initialValues={{
              email: user ? user.email || '' : '',
              password: '',
              full_name: user ? user.full_name || '' : '',
            }}
            validationSchema={getValidationSchema('RegistrationSchema', lang)}
            onSubmit={(values, { setSubmitting }) => {
              registration(values);
              setSubmitting(false);
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <Field
                  placeholder={t('register.placeholderFullName')}
                  name="full_name"
                  component={TextField}
                />

                <Field
                  placeholder={t('register.placeholderEmail')}
                  name="email"
                  component={TextField}
                />

                <Field
                  placeholder={t('register.placeholderPassword')}
                  name="password"
                  component={PasswordField}
                />

                <Button
                  type="submit"
                  block
                  disabled={isFetching || isSubmitting}
                >
                  {t('register.submit')}
                </Button>

                <p
                  css={css`
                    margin-top: 1rem;
                  `}
                >
                  {t('register.loginLinkText1')}{' '}
                  <Link to="/login">{t('register.loginLinkText2')}</Link>
                </p>
              </Form>
            )}
          </Formik>
        </AuthFormBox>
      )}
    </Context.Consumer>
  );
};

RegisterForm.propTypes = {
  user: PropTypes.shape({
    full_name: PropTypes.string,
    email: PropTypes.string,
  }),
  registration: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  serverErrors: PropTypes.object,
};

RegisterForm.defaultProps = {
  isFetching: false,
  serverErrors: null,
  user: null,
};

export default RegisterForm;
