import React, { Fragment } from 'react';
import Heading from '../../atoms/Heading';

const NotFound = () => (
  <Fragment>
    <Heading level={1}>Page not found</Heading>
    <p>The page you are looking for does not exist.</p>
  </Fragment>
);

export default NotFound;
