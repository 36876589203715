import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import Label from '../../atoms/Label';

const RadioField = ({ options, form, field, stacked, disabled, label }) => {
  const { name, value, onBlur } = field;
  const { errors, touched, setFieldValue } = form;

  const onChange = event => {
    let val;
    switch (event.target.value) {
      case 'true':
        val = true;
        break;
      case 'false':
        val = false;
        break;
      default:
        val = event.target.value;
    }
    setFieldValue(name, val);
  };

  return (
    <div>
      {label && <Label text={label} htmlFor={name} />}
      <div
        css={css`
          display: flex;
          flex-direction: ${stacked ? 'column' : 'row'};
          margin-bottom: 1rem;
        `}
      >
        {options.map(({ label: lbl, value: val }) => (
          <label htmlFor={`${name}-${lbl}`} key={`${name}-${lbl}`}>
            <input
              id={`${name}-${lbl}`}
              type="radio"
              name={name}
              value={val}
              checked={val === value}
              onBlur={onBlur}
              onChange={onChange}
              disabled={disabled}
            />
            {lbl}
          </label>
        ))}
      </div>
      {errors[name] && touched[name] && (
        <div className="error">{errors[name]}</div>
      )}
    </div>
  );
};

RadioField.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    }),
  ),
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    onBlur: PropTypes.func,
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func,
    errors: PropTypes.object,
    touched: PropTypes.object,
  }).isRequired,
  stacked: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
};

RadioField.defaultProps = {
  options: [],
  stacked: false,
  disabled: false,
  label: '',
};

export default RadioField;
