import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import {
  Context,
  observableRequest,
  TextField,
  CompanyField,
  Heading,
  Button,
} from '@hokodo/core';
import { getRegnum } from '@hokodo/core/lib/companyHelpers';
import { getValidationSchema } from '@hokodo/core/lib/formHelpers';

const OrganisationSettings = ({ membership, token, fetchUser }) => {
  const { t } = useTranslation('account');

  const getInitialValues = values => {
    const { company, company_name, company_country, company_regnum } = values;

    return {
      company: {
        match: company || null,
        name: company ? company.name : company_name || '',
        country: company ? company.country : company_country || 'GB',
        regnum: company ? getRegnum(company.identifiers) : company_regnum || '',
      },
      name: company ? company.name : company_name || '',
      regnum: company ? getRegnum(company.identifiers) : company_regnum || '',
    };
  };

  return membership ? (
    <Context.Consumer>
      {({ lang }) => {
        const { organisation, role } = membership;

        const isEditable =
          role === 'admin' && !organisation.has_purchased_policy;

        return (
          <section>
            <Formik
              initialValues={getInitialValues(organisation)}
              validationSchema={getValidationSchema(
                'OrganisationSettingsSchema',
                lang,
              )}
              onSubmit={(values, { setSubmitting }) => {
                const { company } = values;
                observableRequest({
                  url: `organisations/${organisation.id}`,
                  token,
                  config: {
                    method: 'PATCH',
                    body: { company: company.match.id },
                  },
                }).subscribe(
                  () => {
                    setSubmitting(false);
                    fetchUser();
                  },
                  ({ response }) => {
                    setSubmitting(false);
                    console.log(response);
                  },
                );
              }}
            >
              {({ isSubmitting }) => {
                return (
                  <Form>
                    <div
                      css={css`
                        display: flex;
                        justify-content: space-between;
                      `}
                    >
                      <Heading level={5} underline>
                        {t('companyForm.title')}
                      </Heading>

                      {isEditable && (
                        <Button type="submit" disabled={isSubmitting}>
                          {t('saveButton')}
                        </Button>
                      )}
                    </div>

                    <div
                      css={css`
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        grid-column-gap: 2rem;
                      `}
                    >
                      <div>
                        {isEditable ? (
                          <Field
                            name="company"
                            label={t('companyForm.companyName')}
                            component={CompanyField}
                          />
                        ) : (
                          <Field
                            name="name"
                            label={t('companyForm.companyName')}
                            component={TextField}
                            disabled
                          />
                        )}
                      </div>

                      <div>
                        <Field
                          name="regnum"
                          label={t('companyForm.companyRegistrationNumber')}
                          component={TextField}
                          disabled
                        />
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </section>
        );
      }}
    </Context.Consumer>
  ) : null;
};

OrganisationSettings.propTypes = {
  membership: PropTypes.shape({
    organisation: PropTypes.object,
    role: PropTypes.string,
    platform: PropTypes.string,
  }).isRequired,
  token: PropTypes.string.isRequired,
  fetchUser: PropTypes.func.isRequired,
};

export default OrganisationSettings;
