import { ajax } from 'rxjs/ajax';
import {
  ROOT_API,
  STANDALONE_API,
  AUTHORIZATION_KEY,
} from '../src/environment';

const getUrl = ({ api, url }) => {
  switch (api) {
    case 'standalone':
      return `${STANDALONE_API}/${url}`;
    default:
      return `${ROOT_API}/${url}`;
  }
};

export default ({
  url,
  config = {},
  headers = {},
  token = '',
  api = 'root',
}) => {
  const authHeader = token
    ? {
        Authorization: `${AUTHORIZATION_KEY} ${token}`,
      }
    : {};
  return ajax({
    url: getUrl({ api, url }),
    type: 'json',
    headers: {
      'Content-Type': 'application/json',
      ...headers,
      ...authHeader,
    },
    ...config,
  });
};
