import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import Label from '../../atoms/Label';
import Textarea from '../../atoms/Textarea';

const TextareaField = ({ label, form, field, disabled }) => {
  const { name, value, onBlur, onChange } = field;
  const { errors, touched } = form;

  return (
    <div
      css={css`
        margin-bottom: 1rem;
      `}
    >
      {label && <Label text={label} htmlFor={name} />}
      <Textarea
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        value={value}
      />
      {errors[name] && touched[name] && (
        <div className="error">{errors[name]}</div>
      )}
    </div>
  );
};

TextareaField.propTypes = {
  label: PropTypes.string,
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.object,
    touched: PropTypes.object,
  }).isRequired,
  disabled: PropTypes.bool,
};

TextareaField.defaultProps = {
  label: '',
  disabled: false,
};

export default TextareaField;
