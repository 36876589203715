import { createSelector } from 'reselect';
import { getProps } from '@hokodo/core/lib/selectorHelpers';

export const selectPageSize = state => state.transactions.pageSize;

export const selectTransaction = (state, props) =>
  state.transactions.byId[props.transactionId] || null;

export const selectInvoiceErrors = state =>
  state.transactions.errors.invoice || null;

export const selectActiveView = state => state.transactions.activeView;

export const selectViews = createSelector(
  state => state.transactions.views,
  views => Object.keys(views).map(key => views[key]),
);

export const selectView = createSelector(
  (state, { name }) => state.transactions.views[name],
  data => ({
    ...data,
    collection: Object.keys(data.byId).map(key => data.byId[key]),
  }),
);

export const selectViewQuery = createSelector(
  getProps,
  selectPageSize,
  (state, props) => selectView(state, props),
  ({ organisationId }, pageSize, data) => {
    const {
      orderBy: { param, desc },
      status,
      filters,
    } = data;

    return `?expand=policy,debtor,quote,creditor&limit=${pageSize}&organisation=${organisationId}&ordering=${
      desc ? '-' : ''
    }${param}${status ? `&policy_status=${status}` : ''}${Object.keys(filters)
      .map(key => ({
        key,
        value: filters[key],
      }))
      .reduce((acc, { key, value }) => `${acc}&${key}=${value}`, '')}`;
  },
);
