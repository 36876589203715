import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import { colors, radius, border } from '@styles/theme';
import { Button, Icon } from '@hokodo/core';

const PaginationControl = ({ data, fetchCollection, name }) => {
  const { next, previous } = data;

  const handlePrevious = () => {
    fetchCollection({ view: name, query: previous });
  };

  const handleNext = () => {
    fetchCollection({ view: name, query: next });
  };

  const { t } = useTranslation('common');

  return (
    <nav
      css={css`
        ul {
          display: flex;
          justify-content: center;
        }

        li:first-of-type button {
          border-radius: ${radius.default} 0 0 ${radius.default};
        }
        li:last-of-type button {
          border-radius: 0 ${radius.default} ${radius.default} 0;
          border-left: none;
        }
        button {
          background-color: ${colors.white};
          border: ${border.default};
          color: ${colors.primary};
          &:disabled {
            background-color: #eee;
          }
          padding: 0.6rem 0.7rem 0.3rem;
        }

        .sr-only {
          display: none;
          @media only speech {
            display: inline;
          }
        }
      `}
    >
      <ul>
        <li>
          <Button
            onClick={handlePrevious}
            aria-label={t('pagination.previous')}
            disabled={!previous}
          >
            <span aria-hidden="true">
              <Icon size={20} type="chevrons-left" />
            </span>
            <span className="sr-only">{t('pagination.previous')}</span>
          </Button>
        </li>

        <li>
          <Button
            onClick={handleNext}
            aria-label={t('pagination.next')}
            disabled={!next}
          >
            <span aria-hidden="true">
              <Icon size={20} type="chevrons-right" />
            </span>
            <span className="sr-only">{t('pagination.next')}</span>
          </Button>
        </li>
      </ul>
    </nav>
  );
};

PaginationControl.propTypes = {
  data: PropTypes.shape({
    next: PropTypes.string,
    previous: PropTypes.string,
  }).isRequired,
  fetchCollection: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default PaginationControl;
