import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  passwordReset,
  passwordConfirm,
  selectIsFetching,
  selectResetPasswordErrors,
} from '@redux/modules/auth';
import Component from './Component';

export default withRouter(
  connect(
    state => ({
      isFetching: selectIsFetching(state),
      serverErrors: selectResetPasswordErrors(state),
    }),
    {
      passwordReset,
      passwordConfirm,
    },
  )(Component),
);
