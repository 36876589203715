import { connect } from 'react-redux';
import {
  selectCanCreateTransactions,
  selectUserOrganisation,
} from '@redux/modules/user';
import { selectViews, selectActiveView } from '@redux/modules/transactions';
import Transactions from './Transactions';

export default connect(state => ({
  canCreateTransactions: selectCanCreateTransactions(state),
  userOrganisation: selectUserOrganisation(state),
  views: selectViews(state),
  activeView: selectActiveView(state),
}))(Transactions);
