import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Checklist, Button, Card } from '@hokodo/core';

const QuoteChecklist = ({ onChecklistComplete }) => {
  const { t } = useTranslation('checkout');

  const onClick = () => {
    onChecklistComplete();
  };

  return (
    <Card>
      <Checklist />
      <Button onClick={onClick}>{t('buttonAccept')}</Button>
    </Card>
  );
};

QuoteChecklist.propTypes = {
  onChecklistComplete: PropTypes.func.isRequired,
};

export default QuoteChecklist;
