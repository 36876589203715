import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { css } from '@emotion/core';
import { useTranslation } from 'react-i18next';

const SelectPaymentMethod = ({ paymentMethods, onChange, label }) => {
  const { t } = useTranslation('checkout');
  const options = [
    ...paymentMethods.results
      .filter(({ type }) => !!type)
      .map(({ remote_id, card }) => ({
        value: remote_id,
        label: t('payment.cardEnding', { last4: card.last4 }),
      })),
    { id: null, label: t('payment.addCard') },
  ];

  return (
    <div
      css={css`
        > div {
          margin: 1rem 0;
        }

        label {
          display: block;
          margin-bottom: 0.5rem;
          font-weight: 600;
        }
      `}
    >
      <label>{label || t('payment.select')}</label>
      <Select defaultValue={options[0]} options={options} onChange={onChange} />
    </div>
  );
};

SelectPaymentMethod.propTypes = {
  paymentMethods: PropTypes.shape({
    results: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        remote_id: PropTypes.string,
        type: PropTypes.string,
        card: PropTypes.shape({
          brand: PropTypes.string,
          exp_month: PropTypes.int,
          exp_year: PropTypes.int,
          last4: PropTypes.string,
        }),
      }),
    ),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
};

SelectPaymentMethod.defaultProps = {
  label: null,
};

export default SelectPaymentMethod;
