import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import Label from '../../atoms/Label';
import Checkbox from '../../atoms/Checkbox';

const CheckboxField = ({ label, form, field, disabled }) => {
  const { name, value, onBlur } = field;
  const { errors, touched, setFieldValue } = form;

  const onChange = val => {
    setFieldValue(name, val);
  };

  return (
    <div
      css={css`
        margin-bottom: 1rem;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          label {
            margin: 0 0 0 0.5rem;
          }
        `}
      >
        <Checkbox
          onBlur={onBlur}
          onChange={onChange}
          checked={Boolean(value)}
          disabled={disabled}
        />
        {label && <Label text={label} htmlFor={name} />}
      </div>

      {errors[name] && touched[name] && (
        <div className="error">{errors[name]}</div>
      )}
    </div>
  );
};

CheckboxField.propTypes = {
  label: PropTypes.string,
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    onBlur: PropTypes.func,
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.object,
    touched: PropTypes.object,
    setFieldValue: PropTypes.func,
  }).isRequired,
  disabled: PropTypes.bool,
};

CheckboxField.defaultProps = {
  label: '',
  disabled: false,
};

export default CheckboxField;
