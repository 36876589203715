import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { css } from '@emotion/core';
import { inputFormats } from '../../../../../lib/datetimeHelpers';
import Label from '../../atoms/Label';
import Context from '../../../Context';

import 'react-datepicker/dist/react-datepicker.css';

const DateField = ({ label, placeholder, field, form, onFocus }) => {
  const { name, value, onBlur } = field;
  const { setFieldValue, errors, touched } = form;

  const onChange = date => {
    setFieldValue(name, date);
  };

  return (
    <Context.Consumer>
      {({ lang, theme: { border, padding, radius, fontSize } }) => (
        <div
          css={css`
            margin-bottom: 1rem;
          `}
        >
          {label && <Label text={label} htmlFor={name} />}
          <div
            css={css`
              input {
                padding: ${padding.input};
                border: ${border.default};
                border-radius: ${radius.default};
                font: inherit;
                font-size: ${fontSize.input};
                width: 100%;
              }
            `}
          >
            <DatePicker
              placeholderText={placeholder}
              dateFormat={inputFormats[lang.substring(0, 2)]}
              selected={value}
              onChange={onChange}
              onBlur={onBlur}
              onFocus={onFocus}
            />
          </div>
          {errors[name] && touched[name] && (
            <div className="error">{errors[name]}</div>
          )}
        </div>
      )}
    </Context.Consumer>
  );
};

DateField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.object,
    onBlur: PropTypes.func,
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func,
    errors: PropTypes.object,
    touched: PropTypes.object,
  }).isRequired,
  onFocus: PropTypes.func,
};

DateField.defaultProps = {
  label: '',
  placeholder: '',
  onFocus: f => f,
};

export default DateField;
