import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { css } from '@emotion/core';
import { colors, shadow } from '@styles/theme';
import { Logo, LanguageSelect } from '@hokodo/core';
import MembershipSelect from './MembershipSelect';
import UserMenu from './UserMenu';

const Header = ({ hasSessionUser, userMemberships }) => {
  const { t } = useTranslation('header');

  return (
    <header
      css={css`
        margin-bottom: 2rem;
        background-color: ${colors.white};
        box-shadow: ${shadow.default};
      `}
    >
      <div className="container">
        <nav
          css={css`
            height: 4rem;
            display: flex;
            align-items: center;
          `}
        >
          <Logo width={110} />
          <ul
            css={css`
              width: 100%;
              display: flex;
              justify-content: flex-end;
              align-items: center;
              > li {
                margin: 0 1rem;
                > a {
                  color: ${colors.secondary};
                  text-transform: uppercase;
                  font-weight: 600;
                  &:hover {
                    text-decoration: none;
                  }
                }
              }
            `}
          >
            {hasSessionUser ? (
              <Fragment>
                <li>
                  <Link to="/">{t('nav.dashboard')}</Link>
                </li>
                <li>
                  <UserMenu />
                </li>
                {userMemberships.length > 1 && (
                  <li>
                    <MembershipSelect />
                  </li>
                )}
                <li>
                  <Link to="/help">{t('nav.help')}</Link>
                </li>
              </Fragment>
            ) : (
              <Fragment>
                <li>
                  <Link to="/login">{t('nav.login')}</Link>
                </li>
                <li>
                  <Link to="/register">{t('nav.register')}</Link>
                </li>
              </Fragment>
            )}
          </ul>
          <LanguageSelect />
        </nav>
      </div>
    </header>
  );
};

Header.propTypes = {
  hasSessionUser: PropTypes.bool,
  userMemberships: PropTypes.array.isRequired,
};

Header.defaultProps = {
  hasSessionUser: false,
};

export default Header;
