import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Heading from '../../atoms/Heading';

const PolicySummary = ({ transaction }) => {
  const { t } = useTranslation('editInvoice');

  const { policy } = transaction;

  return (
    <Fragment>
      <Heading level={4}>{t('purchased.heading')}</Heading>
      <p>{t('purchased.p1', { policy: policy.number })}</p>
    </Fragment>
  );
};

PolicySummary.propTypes = {
  transaction: PropTypes.shape({
    policy: PropTypes.shape({
      number: PropTypes.string,
    }),
  }).isRequired,
};

export default PolicySummary;
