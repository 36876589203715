import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import { Context } from '@hokodo/core';
import { FORM_STEPS } from '@redux/modules/claim';
import ClaimHeader from './ClaimHeader';
import CustomerDetails from './Forms/CustomerDetails';
import QualifyingQuestions from './Forms/QualifyingQuestions';
import BankAccountDetails from './Forms/BankAccountDetails';
import FinalConfirmation from './Forms/FinalConfirmation';
import Documents from './Documents';

const Claim = ({
  transaction,
  claim,
  fetchClaim,
  createClaim,
  updateClaim,
  setFormStep,
  hideModal,
}) => {
  const { details, isFetching, sent, currentStep } = claim;

  const { t } = useTranslation('notify');

  useEffect(() => {
    const { policy } = transaction;
    if (policy.collection) {
      fetchClaim({ id: policy.collection });
    } else {
      createClaim({ policyId: policy.id });
    }
  }, []);

  const handleSubmit = (values, nextStep) => {
    if (sent) {
      if (nextStep) {
        setFormStep(nextStep);
      } else {
        hideModal();
      }
      return;
    }
    updateClaim({
      id: claim.id,
      values,
      nextStep,
      transactionId: transaction.id,
    });
  };

  const handleSetPage = next => {
    setFormStep(next);
  };

  const steps = {
    [FORM_STEPS.customer]: CustomerDetails,
    [FORM_STEPS.questions]: QualifyingQuestions,
    [FORM_STEPS.banking]: BankAccountDetails,
    [FORM_STEPS.documents]: () => (
      <Documents
        policyId={transaction.policy.id}
        handleSetPage={handleSetPage}
        hideModal={hideModal}
      />
    ),
    [FORM_STEPS.confirmation]: FinalConfirmation,
    [FORM_STEPS.final]: () => <div>{t('steps.submitted.heading')}</div>,
  };

  return (
    <Context.Consumer>
      {({ lang }) => (
        <div
          css={css`
            padding: 1rem 2rem;
          `}
        >
          <ClaimHeader transaction={transaction} currentStep={currentStep} />
          <div>
            {!isFetching &&
              claim &&
              claim.details &&
              React.createElement(steps[currentStep], {
                details,
                handleSubmit,
                handleSetPage,
                isLocked: !!sent,
                lang,
              })}
          </div>
        </div>
      )}
    </Context.Consumer>
  );
};

Claim.propTypes = {
  claim: PropTypes.object,
  transaction: PropTypes.object.isRequired,
  createClaim: PropTypes.func.isRequired,
  fetchClaim: PropTypes.func.isRequired,
  updateClaim: PropTypes.func.isRequired,
  setFormStep: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
};

Claim.defaultProps = {
  claim: null,
};

export default Claim;
