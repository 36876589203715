import { isLangSupported } from './localisationHelpers';
import { addSeparators } from './currencyHelpers';

const ILL_DEFINED_DATA = ['', null, 'undefined'];

const isIllDefined = v => ILL_DEFINED_DATA.includes(v);

const isNumber = v => {
  if (isIllDefined(v)) return false;
  if (typeof v !== 'number' && typeof v !== 'string') return false;
  return !Number.isNaN(Number(v)) && !Number.isNaN(parseFloat(v));
};

const getLocaleNumberSeparators = (lang = 'en-GB') => {
  switch (lang) {
    case 'de-DE':
      return ['.', ','];
    case 'fr-FR':
      return [' ', ','];
    default:
      return [',', '.'];
  }
};

/*
  `removeLocaleFormatting` uses split + join as groupSep and decSep,
   can contain regex meta chars (e.g. dot, full-stop or period).
  .replace would be more performant but split + join is more readable.
* */
const removeLocaleFormatting = (v, groupSep, decSep) =>
  +v
    .split(groupSep)
    .join('')
    .split(decSep)
    .join('.')
    .replace(/ /g, '');

export const parseLocaleNumber = (v, lang = '') => {
  /*
   Given a localized number, and a locale, parse the number!
   If a valid number cannot be parsed, null is returned.
  */

  /*
  1. If value is `ill defined`, return null
  This check ensures any ill defined data cannot be coerced to a valid number
  */
  if (isIllDefined(v)) return null;
  const vStr = v.toString();

  /* 2. If language is not supported, return null */
  if (!isLangSupported(lang)) return null;

  /* 3. Otherwise, get the localized group, decimal separators, and parse the number */
  const [groupSep, decSep] = getLocaleNumberSeparators(lang);
  const parsedNumber = removeLocaleFormatting(vStr, groupSep, decSep);

  if (isNumber(parsedNumber)) return parsedNumber;

  /* 4. If a valid (non-localized) number was provided, coerce and return it */
  if (isNumber(vStr)) return +v;

  /*
    5. Finally, let's attempt to de-localize the number assuming an English locale.
    This covers the case where we have a French or German speaker,
    that writes numbers with English formatting
  */
  if (lang === 'en-GB') return null;
  const [enGroupSep, enDecSep] = getLocaleNumberSeparators();
  const parsedEnglishNumber = removeLocaleFormatting(
    vStr,
    enGroupSep,
    enDecSep,
  );
  if (isNumber(parsedEnglishNumber)) return parsedEnglishNumber;

  return null;
};

export const formatLocaleNumber = (number, lang = 'en-GB') => {
  const nStr = String(Number(number).toFixed(2));

  switch (lang) {
    case 'de-DE':
      return `${addSeparators({ nStr, sep: '.', dec: ',' })}`;
    case 'fr-FR':
      return `${addSeparators({ nStr, sep: ' ', dec: ',' })}`;
    default:
      return `${addSeparators({ nStr })}`;
  }
};

export default {
  formatLocaleNumber,
  parseLocaleNumber,
};
