import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { ofType, combineEpics } from 'redux-observable';
import { selectSessionAuthToken } from '@redux/modules/session';

import { selectUserProfile } from './selectors';
import * as actions from './actions';

export const fetchUserEpic = (action$, store$, { observableRequest }) =>
  action$.pipe(
    ofType(actions.FETCH_USER),
    switchMap(() => {
      const token = selectSessionAuthToken(store$.value);
      return observableRequest({
        url: 'me/?expand=memberships.organisation',
        token,
      }).pipe(
        map(({ response }) => actions.fetchUserSuccess(response)),
        catchError(({ response }) => of(actions.fetchUserFailure(response))),
      );
    }),
  );

export const updateUserProfileEpic = (action$, store$, { observableRequest }) =>
  action$.pipe(
    ofType(actions.UPDATE_USER_PROFILE),
    switchMap(({ payload }) => {
      const token = selectSessionAuthToken(store$.value);
      return observableRequest({
        url: 'me/',
        token,
        config: {
          method: 'PATCH',
          body: payload,
        },
      }).pipe(
        map(({ response }) => actions.updateUserProfileSuccess(response)),
        catchError(({ response }) =>
          of(actions.updateUserProfileFailure(response)),
        ),
      );
    }),
  );

export const setUserProfileMetadataEpic = (action$, store$) =>
  action$.pipe(
    ofType(actions.SET_USER_PROFILE_METADATA),
    map(({ payload }) => {
      const meta = selectUserProfile(store$.value).meta_data || {};
      return actions.updateUserProfile({ meta_data: { ...meta, ...payload } });
    }),
  );

export const setCurrentMembershipEpic = action$ =>
  action$.pipe(
    ofType(actions.SET_CURRENT_MEMBERSHIP),
    map(({ payload }) =>
      actions.setUserProfileMetadata({ organisation: payload.organisation.id }),
    ),
  );

export default combineEpics(
  fetchUserEpic,
  updateUserProfileEpic,
  setUserProfileMetadataEpic,
  setCurrentMembershipEpic,
);
