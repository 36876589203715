import { FETCH_ALL_COLLECTIONS_SUCCESS } from '@redux/modules/transactions';

import {
  IMPORT_KASHFLOW_INVOICES_SUCCESS,
  OAUTH_SYNCHRONISE_SUCCESS,
} from '@redux/modules/integrations';

import {
  AUTH_CONFIRM_EMAIL_FAILURE,
  AUTH_CONFIRM_EMAIL_SUCCESS
} from '@redux/modules/auth';

import { FETCH_USER_SUCCESS } from '@redux/modules/user';

export const middlewareConfig = {
  actions: [
    {
      type: [AUTH_CONFIRM_EMAIL_FAILURE],
      dataKey: 'account_activation_failure',
      dataValue: true,
      method: 'identify',
    },
    {
      type: [AUTH_CONFIRM_EMAIL_SUCCESS],
      dataKey: 'account_activation_success',
      dataValue: true,
      method: 'identify',
    },
    {
      type: [OAUTH_SYNCHRONISE_SUCCESS],
      dataKey: 'invoices_synced',
      dataValue: true,
      method: 'identify',
    },
    {
      type: [IMPORT_KASHFLOW_INVOICES_SUCCESS, OAUTH_SYNCHRONISE_SUCCESS],
      dataKey: 'invoices_synced',
      dataValue: true,
      method: 'identify',
    },
    {
      type: FETCH_ALL_COLLECTIONS_SUCCESS,
      dataKey: 'number_of_recent_invoices',
      dataValue: (_, store) => {
        try {
          return store.getState().transactions.views.recent.count;
        } catch {
          return null;
        }
      },
      method: 'identify',
    },
    {
      type: FETCH_USER_SUCCESS,
      dataKey: 'is_email_verified',
      dataValue: true,
      method: 'identify',
    },
  ],
};
