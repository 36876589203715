import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { colors, border, radius, padding } from '@styles/theme';
import DropdownMenu from '@components/DropdownMenu';
import LinkText from '../LinkText';

const MembershipSelect = ({
  memberships,
  userOrganisation,
  userPlatform,
  setCurrentMembership,
}) => {
  if (userOrganisation) {
    const unmatched = 'unknown company';

    const linkText = `${
      userOrganisation.company
        ? userOrganisation.company.name
        : userOrganisation.company_name || unmatched
    } (${userPlatform})`;

    return (
      <div
        css={css`
          border: ${border.default};
          border-radius: ${radius.default};
          padding: ${padding.button};
        `}
      >
        <DropdownMenu
          text={<LinkText>{linkText}</LinkText>}
          selfClosing
          position="right"
        >
          <ul
            css={css`
              white-space: nowrap;
              text-transform: uppercase;
              padding: 0 0 0.5rem;
              > li {
                margin: 1rem 0;
                padding: 0 1rem;
                > a {
                  color: ${colors.secondary};
                }
              }
              li:first-of-type {
                margin: 0 !important;
                padding: 1rem !important;
                color: ${colors.lightGrey};
                background-color: #f1f4f6;
              }
            `}
          >
            <li>{linkText}</li>
            {memberships.reduce((acc, { organisation, platform }) => {
              if (organisation.id !== userOrganisation.id)
                acc.push(
                  <li key={organisation.id}>
                    <a
                      href="#"
                      onClick={() => {
                        setCurrentMembership({ organisation });
                      }}
                    >
                      {`${
                        organisation.company
                          ? organisation.company.name
                          : organisation.company_name || unmatched
                      } (${platform})`}
                    </a>
                  </li>,
                );
              return acc;
            }, [])}
          </ul>
        </DropdownMenu>
      </div>
    );
  }

  return null;
};

MembershipSelect.propTypes = {
  memberships: PropTypes.array.isRequired,
  userOrganisation: PropTypes.shape({
    company: PropTypes.shape({
      name: PropTypes.string,
    }),
    company_name: PropTypes.string,
    id: PropTypes.string,
  }),
  userPlatform: PropTypes.string,
  setCurrentMembership: PropTypes.func.isRequired,
};

MembershipSelect.defaultProps = {
  userOrganisation: null,
  userPlatform: '',
};

export default MembershipSelect;
