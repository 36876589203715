import { useEffect, useReducer } from 'react';
import { pluck } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { createAction } from '../../lib/actionHelpers';
import observableRequest from '../../lib/requestHelpers';

const fetchSuccess = createAction('FETCH_SUCCESS');
const fetchError = createAction('FETCH_ERROR');

const initialState = {
  error: null,
  paymentMethods: null,
  isFetching: false,
};

export const formatDefaultPaymentMethodState = (defaultMethod, methods) => {
  return methods.map(m => ({ ...m, is_default: m.id === defaultMethod }));
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'FETCH_SUCCESS':
      return {
        ...initialState,
        paymentMethods: payload,
      };
    case 'FETCH_ERROR':
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    default:
      return state;
  }
};

const usePaymentMethods = ({ organisationId, token, stripeKey }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const fetchPaymentMethods = () => {
    observableRequest({
      url: `organisations/${organisationId}/stripe_payment_methods/`,
      token,
    })
      .pipe(pluck('response'))
      .subscribe(
        response => {
          dispatch(fetchSuccess(response));
        },
        e => {
          if (
            e.response &&
            e.response.detail &&
            e.response.detail.match(
              /Organisation(.*?)does not have access to payment method functionality/i,
            )
          ) {
            dispatch(fetchSuccess(null));
          } else {
            dispatch(fetchError(e.response ? e.response : e));
          }
        },
      );
  };

  const updateSavedPaymentMethods = (orgId, client_secret) => {
    /* Notify the backend to add a new payment method to the current organisation */
    const { paymentMethods } = state;
    observableRequest({
      url: `organisations/${orgId}/stripe_payment_methods/`,
      token,
      config: {
        method: 'POST',
        body: {
          intent_id: client_secret.split('_secret')[0],
          is_default: false,
        },
      },
    })
      .pipe(pluck('response'))
      .subscribe(
        response => {
          dispatch(
            fetchSuccess({
              ...paymentMethods,
              results: [...paymentMethods.results, response],
            }),
          );
        },
        e => {
          dispatch(fetchError({ [e.type]: e.message }));
        },
      );
  };

  const addPaymentMethod = ({ id, client_secret }) => {
    /* Adding a payment method requires 2 API calls
     *   1. Push the raw payment method data to Stripe
     *   2. Push this setup intent to the backend so it can attach the new payment method to the current organisation
     *  */
    ajax({
      url: encodeURI(
        `https://api.stripe.com/v1/setup_intents/${id}?expand[]=payment_method&client_secret=${client_secret}`,
      ),
      type: 'json',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${stripeKey}`,
      },
    })
      .pipe(pluck('response'))
      .subscribe(
        () => updateSavedPaymentMethods(organisationId, client_secret),
        e => {
          dispatch(fetchError({ [e.type]: e.message }));
        },
      );
  };

  const setDefaultPaymentMethod = id => {
    const { paymentMethods } = state;
    observableRequest({
      url: `organisations/${organisationId}/stripe_payment_methods/${id}`,
      token,
      config: {
        method: 'PATCH',
        body: {
          is_default: true,
        },
      },
    })
      .pipe(pluck('response'))
      .subscribe(
        () => {
          dispatch(
            fetchSuccess({
              ...paymentMethods,
              results: formatDefaultPaymentMethodState(
                id,
                paymentMethods.results,
              ),
            }),
          );
        },
        e => {
          dispatch(fetchError(e.response ? e.response : e));
        },
      );
  };

  const removePaymentMethod = id => {
    const { paymentMethods } = state;
    observableRequest({
      url: `organisations/${organisationId}/stripe_payment_methods/${id}`,
      token,
      config: {
        method: 'DELETE',
      },
    }).subscribe(
      () => {
        dispatch(
          fetchSuccess({
            ...paymentMethods,
            results: paymentMethods.results.filter(pm => pm.id !== id),
          }),
        );
      },
      e => {
        dispatch(fetchError(e.response ? e.response : e));
      },
    );
  };

  useEffect(() => {
    fetchPaymentMethods();
  }, [organisationId]);

  return {
    ...state,
    fetchPaymentMethods,
    addPaymentMethod,
    removePaymentMethod,
    setDefaultPaymentMethod,
  };
};

export default usePaymentMethods;
