/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';

const Logo = ({ width }) => (
  <div
    css={css`
      width: ${width}px;
      margin: auto;
    `}
  >
    <svg
      version="1.1"
      id="Calque_1"
      x="0px"
      y="0px"
      width="100%"
      height="auto"
      viewBox="0 0 311.812 113.386"
      enableBackground="new 0 0 311.812 113.386"
      xmlSpace="preserve"
    >
      <path
        d="M54.333,30.744v17.55c0,2.774-2.256,5.03-5.03,5.03H36.235c-2.774,0-5.03-2.256-5.03-5.03v-17.55h-3.229h-3.229v17.55
    c0,3.242,1.355,6.168,3.522,8.26c-2.167,2.091-3.522,5.018-3.522,8.259v17.549h3.229h3.229V64.813c0-2.774,2.256-5.03,5.03-5.03
    h13.067c2.774,0,5.03,2.256,5.03,5.03v17.549h3.229h3.23V64.813c0-3.241-1.355-6.168-3.522-8.259
    c2.167-2.092,3.522-5.019,3.522-8.26v-17.55h-3.23H54.333z"
      />
      <path
        d="M96.146,29.263H83.079c-6.335,0-11.489,5.153-11.489,11.489v31.603c0,6.335,5.154,11.488,11.489,11.488h13.067
    c6.335,0,11.489-5.153,11.489-11.488V40.752C107.635,34.416,102.481,29.263,96.146,29.263z M101.176,72.355
    c0,2.774-2.257,5.031-5.03,5.031H83.079c-2.773,0-5.03-2.257-5.03-5.031V40.752c0-2.774,2.257-5.031,5.03-5.031h13.067
    c2.773,0,5.03,2.257,5.03,5.031V72.355z"
      />
      <path
        d="M186.332,29.263h-13.068c-6.334,0-11.488,5.153-11.488,11.489v31.603c0,6.335,5.154,11.488,11.488,11.488h13.068
    c6.334,0,11.488-5.153,11.488-11.488V40.752C197.82,34.416,192.666,29.263,186.332,29.263z M191.36,72.355
    c0,2.774-2.255,5.031-5.028,5.031h-13.068c-2.773,0-5.028-2.257-5.028-5.031V40.752c0-2.774,2.255-5.031,5.028-5.031h13.068
    c2.773,0,5.028,2.257,5.028,5.031V72.355z"
      />
      <path
        d="M277.02,29.263h-13.066c-6.335,0-11.489,5.153-11.489,11.489v31.603c0,6.335,5.154,11.488,11.489,11.488h13.066
    c6.336,0,11.49-5.153,11.49-11.488V40.752C288.51,34.416,283.355,29.263,277.02,29.263z M282.051,72.355
    c0,2.774-2.257,5.031-5.031,5.031h-13.066c-2.773,0-5.03-2.257-5.03-5.031V40.752c0-2.774,2.257-5.031,5.03-5.031h13.066
    c2.774,0,5.031,2.257,5.031,5.031V72.355z"
      />
      <path
        d="M232.039,29.263h-24.557v54.581h24.557c6.336,0,11.49-5.153,11.49-11.488V40.752
    C243.529,34.416,238.375,29.263,232.039,29.263z M237.069,72.355c0,2.774-2.255,5.031-5.03,5.031h-18.097V35.721h18.097
    c2.775,0,5.03,2.257,5.03,5.031V72.355z"
      />
      <path
        d="M132.535,57.023v-0.008h-0.606h-0.804h-1.603c-2.773,0-5.03-2.256-5.03-5.03V41.829v-7.393v-3.692h-3.229h-3.229v3.692
    v17.549v30.376h3.229h3.229V62.298c1.522,0.746,3.225,1.176,5.03,1.176h2.407c1.369,0,2.484,0.033,3.441,0.163
    c3.454,0.465,4.817,2.192,8.344,8.225c0.453,0.774,0.941,1.62,1.476,2.544c1.309,2.267,2.794,4.835,4.645,7.884l2.76-1.675
    l2.76-1.676c-1.814-2.989-3.28-5.526-4.574-7.765C144.944,61.071,142.683,57.17,132.535,57.023z"
      />
      <rect
        x="24.738"
        y="88.622"
        fill="#C98A29"
        width="36.038"
        height="2.153"
      />
      <rect
        x="118.033"
        y="22.331"
        fill="#C98A29"
        width="36.037"
        height="2.153"
      />
      <path d="M152.289,34.485l-6.059-2.237l-7.986,21.629c2.469,0.394,4.36,1.173,5.997,2.403L152.289,34.485z" />
    </svg>
  </div>
);

Logo.propTypes = {
  width: PropTypes.number,
};

Logo.defaultProps = {
  width: 170,
};

export default Logo;
