import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import { Formik, Form, Field } from 'formik';
import { FORM_STEPS } from '@redux/modules/claim';
import { TextField, TextareaField, BooleanField, Button } from '@hokodo/core';

const QualifyingQuestions = ({ details, handleSubmit, isLocked }) => {
  const { t } = useTranslation('notify');

  const {
    disputed,
    partial_payment,
    other_invoices,
    reasons,
    actions_taken,
    comments,
  } = details;

  return (
    <Formik
      initialValues={{
        disputed,
        has_partial_payment: Number(partial_payment) > 0,
        partial_payment,
        other_invoices,
        reasons,
        actions_taken,
        comments,
        nextStep: '',
      }}
      onSubmit={values => {
        const { nextStep, ...rest } = values;
        handleSubmit(rest, nextStep);
      }}
    >
      {({ values, submitForm, setFieldValue }) => {
        return (
          <Form>
            <div
              css={css`
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 2rem;
              `}
            >
              <div>
                <Field
                  name="disputed"
                  component={BooleanField}
                  disabled={isLocked}
                  label={t('steps.questions.disputed')}
                />

                <Field
                  name="has_partial_payment"
                  component={BooleanField}
                  disabled={isLocked}
                  label={t('steps.questions.has_partial_payment')}
                />

                {values.has_partial_payment && (
                  <Field
                    name="partial_payment"
                    component={TextField}
                    disabled={isLocked}
                    label={t('steps.questions.partial_payment')}
                  />
                )}

                <Field
                  name="other_invoices"
                  component={BooleanField}
                  disabled={isLocked}
                  label={t('steps.questions.other_invoices')}
                />
              </div>

              <div>
                <Field
                  name="reasons"
                  component={TextField}
                  disabled={isLocked}
                  label={t('steps.questions.reasons')}
                />

                <Field
                  name="actions_taken"
                  component={TextareaField}
                  disabled={isLocked}
                  label={t('steps.questions.actions_taken')}
                />

                <Field
                  name="comments"
                  component={TextareaField}
                  disabled={isLocked}
                  label={t('steps.questions.comments')}
                />
              </div>
            </div>

            <div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 2rem;0
              `}
            >
              <Button
                type="button"
                onClick={() => {
                  setFieldValue('nextStep', FORM_STEPS.documents, false);
                  submitForm();
                }}
              >
                {t('modal.controls.continue')}
              </Button>

              <ul
                css={css`
                  display: flex;
                  margin-top: 0.5rem;
                  li {
                    margin: 0 0.25rem;
                  }
                `}
              >
                <li>
                  <a
                    href="#"
                    rel="button"
                    onClick={event => {
                      event.preventDefault();
                      setFieldValue('nextStep', FORM_STEPS.customer, false);
                      submitForm();
                    }}
                  >
                    {t('modal.controls.previous')}
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    rel="button"
                    onClick={event => {
                      event.preventDefault();
                      setFieldValue('nextStep', '', false);
                      submitForm();
                    }}
                  >
                    {t('modal.controls.save')}
                  </a>
                </li>
              </ul>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

QualifyingQuestions.propTypes = {
  details: PropTypes.shape({
    disputed: PropTypes.bool,
    has_partial_payment: PropTypes.bool,
    partial_payment: PropTypes.string,
    other_invoices: PropTypes.bool,
    reasons: PropTypes.string,
    actions_taken: PropTypes.string,
    comments: PropTypes.string,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isLocked: PropTypes.bool.isRequired,
};

export default QualifyingQuestions;
