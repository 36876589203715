import { createSelector } from 'reselect';
import { getProps } from '@hokodo/core/lib/selectorHelpers';

export const selectUserProfile = state => state.user.profile;

/* */
export const selectUserOrganisation = state =>
  state.user.currentMembership ? state.user.currentMembership.organisation : {};
export const selectUserPlatform = state =>
  state.user.currentMembership ? state.user.currentMembership.platform : {};
export const selectUserMemberships = state => state.user.memberships;
export const selectUserOrganisationHasPolicy = state =>
  state.user.currentMembership
    ? state.user.currentMembership.organisation.has_purchased_policy
    : false;
export const selectIsFetching = state => state.user.isFetching;
export const selectCurrentMembership = state => state.user.currentMembership;
export const selectCanCreateTransactions = createSelector(
  selectCurrentMembership,
  membership => (membership ? membership.can_add_invoices : false),
);
export const selectMembershipsByPlatform = createSelector(
  getProps,
  selectUserMemberships,
  ({ platform }, memberships) => {
    return memberships.filter(
      m => m.platform && m.platform.toLowerCase() === platform,
    );
  },
);

export const selectCurrentMembershipCountry = createSelector(
  selectCurrentMembership,
  membership => {
    try {
      return membership.organisation.company.country;
    } catch (e) {
      return null;
    }
  },
);
