import i18n from 'i18next';
import { getSupportedLanguage } from '../../../lib/localisationHelpers';
import {
  getEmailFromPayload,
  pushEventDataToContorta,
} from './segmentDataManagerHelpers';
import { selectSessionAuthToken } from '../modules/session/selectors';
import { selectQuote } from '../modules/transaction/selectors';

export default class SegmentDataManager {
  /**
   * The purpose of this class is to process and push data to Segment.
   * Usage:
   *    const segmentDataManager = new SegmentDataManager(app, SEGMENT_KEY);
   *    segmentDataManager.syncUserDataWithSegment(catchAction, action, store);
   *
   *    See segmentMiddleware for more information on catchAction.
   *
   * Segment Data:
   *  Two types of data are pushed to Segment:
   *  1) User Analytics events such as (Payment Page Reached, FAQ Click)
   *  2) User Attributes such as is_email_verified, invoice_synced which are used in Product Tours
   *
   *  User Analytics events
   *    i) This data is pushed to Segment (via Contorta) using pushEventDataToContorta
   *  User Attributes
   *    i) This data is pushed directly to Segment, to instantaneously load Product Tours (using Intercom)
   *    ii) Intercom is not blocked by most Ad Blockers
   *
   * Note:
   *    1. If any requests are submitted prior to Segment loading, these requests will be queued.
   *      i) Each request is formatted (store based values calculated) before being added to the queue.
   *
   * For more information on how we use Segment see the wiki below
   *    https://gitlab.com/hokodo/handbook/-/wikis/User-Analytics
   */
  constructor({ app, segmentKey }) {
    this.app = app.name;
    this.segmentKey = segmentKey;
    this.queuedSegmentRequests = []; // Queue any requests that occur before Segment (window.analytics) has loaded
    this.sessionToken = null;
    this.trackingId = null;
    this.configureSegmentInterval = null;
    this.segmentConfigured = false;
    this.acceptedSegmentMethods = ['identify', 'track', 'page', 'group'];
    this.portals = ['cegid-portal', 'futrli-portal', 'hokodo-portal'];
    this.configureSegment(); // Attempt to configure Segment (makes testing easier)
    this.addEventListeners();
  }

  configureSegment = () => {
    if (!this.segmentConfigured && this.isReadyToConfigureSegment()) {
      window.analytics.load(this.segmentKey);
      window.analytics.page();
      this.processQueuedRequests();
      clearInterval(this.configureSegmentInterval);
      this.segmentConfigured = true;
    }
  };

  addEventListeners = () => {
    this.configureSegmentInterval = setInterval(this.configureSegment, 1000);
  };

  isReadyToConfigureSegment = () => {
    /* We are ready to configure Segment when the following conditions are true:
     * 1. Segment is available (window.analytics=true)
     * 2. In the case of the portals (Cegid, Futrli, Hokodo): this.trackingId must be set (not null)
     */
    if (this.portals.includes(this.app)) {
      return window.analytics && this.hasTrackingId();
    }
    if (this.app === 'hokodo-customer') {
      return window.analytics;
    }
    return null;
  };

  hasTrackingId = () => {
    /*
     * Checks if the store contains a relevant tracking id.
     * Note: This is package dependent
     */
    if (this.portals.includes(this.app)) {
      return this.trackingId !== null;
    }
    if (this.app === 'hokodo-customer') {
      return true;
    }
    return null;
  };

  syncUserDataWithSegment = (catchAction, action, store) => {
    /* This is the entry point for all analytics related events (see segmentMiddleware.js for usage) */
    // 1. Check if we should process incoming event
    if (!this.acceptIncomingEvent(catchAction, action, store)) {
      return null;
    }

    // 2a. Set sessionToken if not already set (required by pushEventDataToContorta)
    if (this.sessionToken === null) {
      try {
        this.sessionToken = selectSessionAuthToken(store.getState());
      } catch {
        this.sessionToken = null;
      }
    }

    // 2b. Set tracking if not already set
    if (this.trackingId === null) {
      this.trackingId = this.getTrackingIdFromActionPayload(action);
    }

    // 3a. Push data to Segment if we're ready (see isReadyToConfigureSegment)
    if (this.segmentConfigured) {
      const formattedRequestData = this.formatSegmentRequestData(
        catchAction,
        action,
        store,
      );
      return this.pushDataToSegment(formattedRequestData);
    }
    // 3b. If Segment is not configured, add the request to a queue
    this.queuedSegmentRequests.push({
      params: [catchAction, action, store],
    });
    // Attempt to Configure Segment (needed for tests)
    return this.configureSegment();
  };

  pushDataToSegment = data => {
    const { method } = data;
    switch (method) {
      case 'track':
        pushEventDataToContorta(
          data.event,
          data.trackingId,
          data.traits,
          this.sessionToken,
        );
        return null;
      case 'identify':
        window.analytics.identify(data.userId, data.userAttributes);
        return null;
      default:
        return null;
    }
  };

  processQueuedRequests = () => {
    this.queuedSegmentRequests.forEach(request => {
      const formattedRequestData = this.formatSegmentRequestData(
        ...request.params,
      );
      this.pushDataToSegment(formattedRequestData);
    });
  };

  formatSegmentRequestData = (catchAction, action, store) => {
    const { method } = catchAction;
    switch (method) {
      case 'track':
        return this.formatEventTrackingRequest(catchAction, action, store);
      case 'identify':
        return this.formatUpdateUserAttributesRequest(
          catchAction,
          action,
          store,
        );
      default:
        return null;
    }
  };

  formatEventTrackingRequest = (catchAction, action, store) => {
    const { dataKey, method } = catchAction;
    const traits = {
      platform: this.app,
      quote_id: this.getQuoteIdFromStore(store),
    };
    const event =
      typeof dataKey === 'function' ? dataKey(action, store) : dataKey;

    return {
      method,
      trackingId: this.trackingId,
      traits,
      event,
    };
  };

  formatUpdateUserAttributesRequest = (catchAction, action, store) => {
    const { dataKey, dataValue, method } = catchAction;
    const email = getEmailFromPayload(action.payload || {}, store);
    const userAttributes = {
      userId: email,
      email,
      platform: this.app,
      lang: getSupportedLanguage(i18n.language),
      [dataKey]: this.evaluateActionDataValue(dataValue, action, store),
    };
    return {
      method,
      userId: email,
      userAttributes,
    };
  };

  acceptIncomingEvent = (catchAction, action, store) => {
    /*
     * Accept Incoming Event if the following criteria are satisfied:
     *  1. Action method is an accepted method (e.g. 'identify', 'track', 'page', 'group')
     *  2. Action value is true or evaluates to true
     *     - If action value is a function, it will be evaluated based on the latest data in the Store
     */
    const { method } = catchAction;
    const dataValue = this.evaluateActionDataValue(
      catchAction.dataValue,
      action,
      store,
    );
    return this.acceptedSegmentMethods.includes(method) && dataValue !== null;
  };

  evaluateActionDataValue = (dataValue, action, store) => {
    /*
     * dataValue can be a string, bool, int, function, etc
     * If dataValue is a function, the function is evaluated and returned.
     */
    return typeof dataValue === 'function'
      ? dataValue(action, store)
      : dataValue;
  };

  getTrackingIdFromActionPayload = action => {
    try {
      return action.payload.dataValue.owner.id;
    } catch {
      return null;
    }
  };

  getQuoteIdFromStore = store => {
    /*
     * Why do we need a try, catch for selectQuote?
     *  Assume a user has 2 Quotes which qualify for a Promotional Offer.
     *  The user views both Quotes in the Quote Portal
     *  After completing the purchase of Quote 1, the user attempts to purchase Quote 2
     *  The policies endpoint will return a 400, Quote expired error
     *  If the user refreshes the page, quote==null and an exception is thrown
     *
     */
    try {
      return selectQuote(store.getState()).id;
    } catch {
      return null;
    }
  };
}
