/* eslint-disable import/prefer-default-export */
import * as Yup from 'yup';
import { getClaimsFormsAcceptedCollectionsTermsSchema } from '@hokodo/core/lib/portalConfigurationHelpers';
import { parseLocaleNumber } from './localeNumberHelpers';

const getMessages = lang => {
  const messages = {
    'en-GB': {
      required: 'This field is required.',
      email: 'Please enter a valid email.',
      numeric: 'Please enter a valid number.',
      short: 'Too short.',
      long: 'Too long.',
      password: 'Please create a password between 8 and 24 characters.',
    },
    'fr-FR': {
      required: 'Ce champ est obligatoire.',
      email: 'Veuillez saisir une adresse email valide.',
      numeric: 'Veuillez saisir un numéro valide.',
      short: 'Trop court.',
      long: 'Trop long.',
      password: 'Votre mot de passe doit contenir entre 8 et 24 caractères.',
    },
    'de-DE': {
      required: 'Dies ist ein Pflichtfeld.',
      email: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
      numeric: 'Bitte geben Sie eine gültige Zahl ein.',
      short: 'Zu kurz.',
      long: 'Zu lange.',
      password: 'Bitte erstellen Sie ein Passwort zwischen 8 und 24 Zeichen.',
    },
  };
  return messages[lang];
};

export const getValidationSchema = (schema, lang = 'en-GB') => {
  const messages = getMessages(lang);

  const formatNumber = (o, v) => parseLocaleNumber(v, lang);

  const schemas = {
    // auth
    LoginSchema: Yup.object().shape({
      email: Yup.string()
        .email(messages.email)
        .required(messages.required),
      password: Yup.string().required(messages.required),
    }),

    RegistrationSchema: Yup.object().shape({
      full_name: Yup.string()
        .min(2, messages.short)
        .max(50, messages.long)
        .required(messages.required),
      email: Yup.string()
        .email(messages.email)
        .required(messages.required),
      password: Yup.string()
        .min(8, messages.short)
        .max(50, messages.long)
        .required(messages.required),
    }),

    ResetPasswordSchema: Yup.object().shape({
      email: Yup.string()
        .email(messages.email)
        .required(messages.required),
    }),

    ChoosePasswordSchema: Yup.object().shape({
      password: Yup.string()
        .min(8, messages.short)
        .max(50, messages.long)
        .required(messages.required),
    }),

    // user account
    ContactDetailsSchema: Yup.object().shape({
      email: Yup.string().email(messages.email),
    }),

    OrganisationSettingsSchema: Yup.object().shape({
      company: Yup.object()
        .shape({
          match: Yup.object()
            .nullable()
            .required(messages.required),
        })
        .nullable()
        .required(messages.required),
    }),

    OauthSchema: Yup.object().shape({
      companies: Yup.array()
        .of(
          Yup.object()
            .shape({
              match: Yup.object()
                .nullable()
                .required(messages.required),
            })
            .nullable()
            .required(messages.required),
        )
        .required(messages.required),
    }),

    // add / edit invoice
    InvoiceSchema: Yup.object().shape({
      debtor: Yup.object()
        .shape({
          match: Yup.object()
            .nullable()
            .required(messages.required),
        })
        .nullable()
        .required(messages.required),
      creditor: Yup.object()
        .shape({
          match: Yup.object()
            .nullable()
            .required(messages.required),
        })
        .nullable()
        .required(messages.required),
      currency: Yup.string().required(messages.required),
      issue_date: Yup.date()
        .nullable()
        .required(messages.required),
      due_date: Yup.date()
        .nullable()
        .required(messages.required),
      net_amount: Yup.number()
        .transform(formatNumber)
        .typeError(messages.numeric)
        .required(messages.required),
    }),

    // claim / notify non-payment
    QuoteChecklistSchema: Yup.object().shape({
      issuedIn30Days: Yup.bool()
        .nullable()
        .required(messages.required),
      customerCanPay: Yup.bool()
        .nullable()
        .required(messages.required),
      outstandingInvoices: Yup.bool()
        .nullable()
        .required(messages.required),
      max45DaysOverdue: Yup.bool()
        .nullable()
        .when('outstandingInvoices', {
          is: true,
          then: Yup.bool().required(messages.required),
          otherwise: Yup.bool(),
        }),
      otherInsurance: Yup.bool()
        .nullable()
        .required(messages.required),
    }),

    BankDetailsSchema: Yup.object().shape({
      bank_account_name: Yup.string().required(messages.required),
      bank_account_number: Yup.string().required(messages.required),
      bank_account_sortcode: Yup.string().required(messages.required),
      bank_account_email: Yup.string()
        .email(messages.email)
        .required(messages.required),
    }),

    CustomerDetailsSchema: Yup.object().shape({
      debtor_contact_name: Yup.string().required(messages.required),
      debtor_address: Yup.string().required(messages.required),
      debtor_contact_phone: Yup.string().required(messages.required),
      debtor_city: Yup.string().required(messages.required),
      debtor_contact_email: Yup.string()
        .email(messages.email)
        .required(messages.required),
      debtor_country: Yup.string().required(messages.required),
      debtor_postcode: Yup.string().required(messages.required),
      creditor_contact_name: Yup.string().required(messages.required),
      creditor_contact_email: Yup.string()
        .email(messages.email)
        .required(messages.required),
      creditor_contact_phone: Yup.string().required(messages.required),
    }),

    FinalConfirmationSchema: Yup.object().shape({
      confirmed_correct: Yup.bool().oneOf([true], messages.required),
      accepted_collections_terms: getClaimsFormsAcceptedCollectionsTermsSchema(
        messages,
      ),
    }),

    // integrations
    KashflowLoginSchema: Yup.object().shape({
      username: Yup.string().required(messages.required),
      password: Yup.string().required(messages.required),
    }),
  };

  return schemas[schema];
};

export default {
  getValidationSchema,
};
