import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { css } from '@emotion/core';
import { pluck } from 'rxjs/operators';
import SectionHeader from '../../molecules/SectionHeader/SectionHeader';
import { selectTransaction } from '../../../../redux/modules/transaction';
import { formatLocaleCurrency } from '../../../../../lib/currencyHelpers';
import { numberOfDaysBetweenTwoDates } from '../../../../../lib/datetimeHelpers';
import Card from '../../atoms/Card';
import Context from '../../../Context';
import observableRequest from '../../../../../lib/requestHelpers';
import { selectSessionAuthToken } from '../../../../redux/modules/session/selectors';
import billSvg from '../../../../assets/img/bill.svg';
import timeSvg from '../../../../assets/img/hourglass.svg';
import sendSvg from '../../../../assets/img/send.svg';
import cashSvg from '../../../../assets/img/money.svg';
import shieldSvg from '../../../../assets/img/hokodo_shield.svg';
import phoneSvg from '../../../../assets/img/phone_blue.svg';

const ProtectionTimeline = ({ policy }) => {
  const { t } = useTranslation('protectionTimeline');
  const transaction = useSelector(state => selectTransaction(state));
  const token = useSelector(state => selectSessionAuthToken(state));
  const [timeline, setTimeline] = useState();
  let isMounted = true;

  const fetchTimeline = id => {
    // TODO: Move fetchTimeline to external file
    observableRequest({
      url: `transactions/${id}/timeline`,
      token,
      config: {
        method: 'GET',
      },
    })
      .pipe(pluck('response'))
      .subscribe(
        response => {
          if(isMounted) setTimeline(response.timeline);
        },
        e => {
          console.log(`Error fetching timeline!: ${JSON.stringify(e)}`);
        },
      );
  };

  useEffect(() => {
    if (transaction) fetchTimeline(transaction.id);
    return () => {isMounted = false;};
  }, [transaction]);

  return transaction && transaction.quote && timeline ? (
    <Context.Consumer>
      {({
        lang,
        theme: { colors },
        applicationContext: { MONTH_NAMES, FAQ_URL },
      }) => {
        const {
          quote: {
            insured: { currency: insured_currency, insured_amount },
            insured_pc_net,
          },
        } = transaction;

        const {
          issue_date,
          due_date,
          collections_handover_date,
          potential_reimbursement_date,
        } = timeline;

        const formatCurrency = (cur, num) =>
          formatLocaleCurrency(lang, cur, num);

        const issueDate =  new Date(issue_date);
        const dueDate = new Date(due_date);
        const earliestNotificationDate = new Date(collections_handover_date);
        const potentialReimbursementDate = new Date(potential_reimbursement_date);

        const invoiceTermsDays = numberOfDaysBetweenTwoDates(
          issueDate,
          dueDate,
        );
        const earliestNotificationDays = numberOfDaysBetweenTwoDates(
          dueDate,
          earliestNotificationDate,
        );

        const formatHeading = d =>
          `${d.getDate()} ${MONTH_NAMES[d.getMonth()]}`;

        const formatDate = d => `${d.getDate()} ${MONTH_NAMES[d.getMonth()]}`;

        const items = policy
          ? [
              {
                key: 'p-item1',
                icon: phoneSvg,
                heading: formatHeading(dueDate),
                interpolate: { earliestNotificationDays, due_date: formatDate(dueDate) },
              },
              {
                key: 'p-item2',
                icon: shieldSvg,
                heading: formatHeading(earliestNotificationDate),
                interpolate: {
                  collections_handover_date: formatDate(earliestNotificationDate),
                },
              },
              {
                key: 'p-item3',
                icon: cashSvg,
                heading: `${formatHeading(potentialReimbursementDate)}*`,
                interpolate: {
                  insured_pc_net,
                  insured_amount: formatCurrency(
                    insured_currency,
                    insured_amount,
                  ),
                },
              },
            ]
          : [
              {
                key: 'item1',
                icon: billSvg,
                heading: formatHeading(issueDate),
                interpolate: { invoiceTermsDays },
              },
              {
                key: 'item2',
                icon: timeSvg,
                heading: formatHeading(dueDate),
                interpolate: { earliestNotificationDays },
              },
              {
                key: 'item3',
                icon: sendSvg,
                heading: formatHeading(earliestNotificationDate),
                interpolate: {
                  earliestNotificationDate: formatDate(earliestNotificationDate)
                },
              },
              {
                key: 'item4',
                icon: cashSvg,
                heading: `${formatHeading(potentialReimbursementDate)}*`,
                interpolate: {
                  insured_pc_net,
                  insured_amount: formatCurrency(
                    insured_currency,
                    insured_amount,
                  ),
                },
              },
            ];

        return (
          <div
            css={css`
              margin: 4rem auto;
            `}
          >
            <SectionHeader content={t(policy ? 'p-title' : 'title')} />
            <Card>
              <div
                css={css`
                  margin-top: 2rem;
                  display: flex;
                  > div {
                    flex: 1;
                    padding: 0.5rem;
                    position: relative;
                    &::before {
                      content: ' ';
                      position: absolute;
                      background: rgb(127, 150, 169);
                      top: 104px;
                      left: 0;
                      right: 0;
                      height: 8px;
                    }
                    &:first-of-type {
                      &::before {
                        left: 50%;
                      }
                    }
                    &:last-of-type {
                      &::before {
                        right: 50%;
                      }
                    }

                    div {
                      &.heading {
                        text-align: center;
                        color: ${colors.revampPrimary};
                        font-weight: 600;
                        font-size: 22px;
                      }
                      &.title {
                        text-align: center;
                        font-weight: 600;
                        font-size: 20px;
                        margin: 0.5rem 0;
                      }
                      &.description {
                        strong {
                          font-weight: 600;
                          color: #ca8a2a;
                          &.red {
                            color: #ff5c35;
                          }
                        }
                        em {
                          font-weight: 600;
                        }
                      }
                      &.image {
                        text-align: center;
                        > div {
                          width: 20px;
                          height: 20px;
                          background: rgb(127, 150, 169);
                          border-radius: 50%;
                          margin: 16px auto;
                        }
                        img {
                          height: 67px;
                        }
                      }
                    }
                  }
                `}
              >
                {items.map(({ key, interpolate, heading, icon }) => (
                  <div key={key}>
                    <div className="image">
                      <img src={icon} alt="" />
                      <div />
                    </div>
                    <div className="heading">{heading}</div>
                    <div className="title">{t(`${key}.title`)}</div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: t(`${key}.description`, {
                          interpolation: { escapeValue: false },
                          ...interpolate,
                        }),
                      }}
                      className="description"
                    />
                  </div>
                ))}
              </div>

              <div
                css={css`
                  margin-top: 1rem;
                  color: #a5a5a5;
                  font-style: italic;
                  font-size: 14px;
                `}
              >
                {t('footnote')}
              </div>
              <div
                css={css`
                  margin: 1rem auto 4rem auto;
                  text-align: center;
                  text-decoration: underline;
                  > a {
                    color: ${colors.revampSecondary};
                  }
                `}
              >
                <a href={FAQ_URL} target="_blank" rel="noopener noreferrer">
                  {t('footnoteLinkText')}
                </a>
              </div>
            </Card>
          </div>
        );
      }}
    </Context.Consumer>
  ) : null;
};

ProtectionTimeline.propTypes = {
  policy: PropTypes.bool,
};

ProtectionTimeline.defaultProps = {
  policy: false,
};

export default ProtectionTimeline;
