import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Subject } from 'rxjs';
import { debounceTime, pluck, switchMap } from 'rxjs/operators';
import { css } from '@emotion/core';
import { getDefaultCompanySearchCountry } from "@hokodo/core/lib/portalConfigurationHelpers";
import observableRequest from '../../../../../lib/requestHelpers';
import Input from '../../atoms/Input';
import Label from '../../atoms/Label';
import SearchResult from './SearchResult';
import LocaleSelect from './LocaleSelect';
import Context from '../../../Context';

class CompanyField extends Component {
  constructor(props) {
    super(props);

    this.subject$ = new Subject();

    const { match, name, country } = this.props.field.value;

    const initialQuery = match ? match.name : name || '';
    const initialCountry = match
      ? match.country
      : country || getDefaultCompanySearchCountry();

    this.state = {
      results: [],
      query: initialQuery,
      country: initialCountry,
      searchCompleted: false,
      isSearching: false,
      isMatching: false,
      searchActive: false,
      countrySelectActive: false,
    };
  }

  componentDidMount() {
    this.subscription = this.subject$
      .pipe(
        debounceTime(300),
        switchMap(config => {
          this.setState({ isSearching: true });
          return observableRequest(config).pipe(pluck('response'));
        }),
      )
      .subscribe(this.onSearchSuccess, this.onError);
  }

  componentWillUnmount() {
    if (this.subscription) this.subscription.unsubscribe();
  }

  getSearchMode = () => {
    return this.state.country === 'GB' ? 'ext' : 'int';
  };

  getRequestConfig = query => {
    const { country } = this.state;
    return this.getSearchMode() === 'ext'
      ? {
          url: `search?q=${query}`,
          api: 'standalone',
        }
      : {
          url: 'companies/search/',
          config: {
            method: 'POST',
            body: {
              country,
              name: query,
            },
          },
        };
  };

  deactivate = () => {
    this.setState({
      searchActive: false,
      searchCompleted: false,
      results: [],
      countrySelectActive: false,
    });
  };

  handleActivate = () => {
    const { searchActive, query } = this.state;
    if (searchActive) return;
    this.setState(
      {
        searchActive: true,
        searchCompleted: false,
        countrySelectActive: false,
      },
      () => {
        if (query) this.subject$.next(this.getRequestConfig(query));
      },
    );
  };

  handleDeactivate = event => {
    event.stopPropagation();
    this.deactivate();
  };

  handleSelect = selection => {
    if (this.getSearchMode() === 'ext') {
      this.setState({ isMatching: true }, () => {
        this.lookup({
          limit: 1,
          country: this.state.country,
          name: selection.title,
          reg_number: selection.company_number,
        });
      });
    } else {
      const { form, field } = this.props;
      const { name, regnum, country, match: omit, ...rest } = field.value;
      this.setState({ query: selection.name }, () => {
        form.setFieldValue(field.name, {
          match: selection,
          name,
          regnum,
          country,
          ...rest,
        });
      });
    }
    this.deactivate();
  };

  onSearchSuccess = response => {
    this.setState(() => ({
      isSearching: false,
      searchCompleted: true,
      results:
        this.getSearchMode() === 'ext'
          ? response.items.map(
              ({
                title: name,
                address_snippet: address,
                company_number,
                ...rest
              }) => ({
                ...rest,
                company_number,
                key: company_number,
                name,
                address,
              }),
            )
          : response.matches.map(({ id, ...rest }) => ({
              id,
              key: id,
              ...rest,
            })),
    }));
  };

  onLookupSuccess = response => {
    const { form, field } = this.props;
    const { name, regnum, country, match: omit, ...rest } = field.value;
    const { matches } = response;
    const match = matches[0];

    this.setState(
      {
        query: match.name,
        isMatching: false,
      },
      () => {
        form.setFieldValue(field.name, {
          match,
          name,
          regnum,
          country,
          ...rest,
        });
      },
    );
  };

  onError = response => {
    console.error(response);
    this.setState({ isSearching: false, searchCompleted: false });
  };

  lookup = ({ limit, ...rest }) => {
    observableRequest({
      url: `companies/search?limit=1`,
      config: {
        method: 'POST',
        body: rest,
      },
    })
      .pipe(pluck('response'))
      .subscribe(this.onLookupSuccess, this.onError);
  };

  onInputChange = event => {
    const query = event.target.value;
    this.setState({ query }, () => {
      if (query) this.subject$.next(this.getRequestConfig(query));
    });
  };

  handleFocus = event => {
    this.props.onFocus();
    this.handleActivate(event);
  };

  toggleCountrySelect = () => {
    if (this.state.searchActive) return;
    this.setState(state => ({
      countrySelectActive: !state.countrySelectActive,
    }));
  };

  handleCountrySelect = country => {
    if (country !== this.state.country)
      this.setState({ country }, () => {
        this.handleActivate();
      });
  };

  render() {
    const {
      handleDeactivate,
      handleFocus,
      handleSelect,
      onInputChange,
      toggleCountrySelect,
    } = this;

    const {
      searchActive,
      searchCompleted,
      results,
      query,
      country,
      isSearching,
      isMatching,
      countrySelectActive,
    } = this.state;
    const { label, placeholder, field, form } = this.props;

    const { name } = field;
    const { errors, touched } = form;

    return (
      <Context.Consumer>
        {({ theme: { colors, zIndex, radius, border } }) => (
          <div
            css={css`
              margin-bottom: 1rem;
            `}
          >
            {label && <Label text={label} htmlFor={name} />}
            <div
              css={css`
                position: relative;
                ::before {
                  z-index: ${zIndex.matchCompany + 2};
                }
              `}
            >
              <div
                role="switch"
                tabIndex="0"
                onClick={handleDeactivate}
                css={css`
                  display: ${searchActive || countrySelectActive
                    ? 'block'
                    : 'none'};
                  position: fixed;
                  top: 0;
                  right: 0;
                  bottom: 0;
                  left: 0;
                  z-index: ${zIndex.matchCompany};
                  cursor: default;
                `}
              />
              <div
                css={css`
                  display: flex;
                  position: relative;
                `}
              >
                <div
                  role="switch"
                  tabIndex="0"
                  onClick={event => {
                    event.preventDefault();
                    event.stopPropagation();
                    toggleCountrySelect();
                  }}
                  css={css`
                    border: ${border.default};
                    border-radius: ${radius.default} 0 0 ${radius.default};
                    border-right: none;
                    padding: 0 0.5rem;
                    background-color: ${colors.white};
                    outline: none;
                    .flag {
                      transform: scale(0.6);
                      margin-top: 5px;
                    }
                  `}
                  className="f32"
                >
                  <i
                    className={`flag ${country ? country.toLowerCase() : ''}`}
                  />
                </div>

                {countrySelectActive && (
                  <LocaleSelect onClick={this.handleCountrySelect} />
                )}

                <Input
                  placeholder={placeholder}
                  onChange={onInputChange}
                  onFocus={handleFocus}
                  value={query}
                  disabled={isMatching}
                  styles={css`
                    border-radius: 0 ${radius.default} ${radius.default} 0;
                  `}
                />

                {searchActive && (
                  <SearchResult
                    searchCompleted={searchCompleted}
                    onClick={handleSelect}
                    isSearching={isSearching}
                    results={results}
                    hasQuery={!!query}
                  />
                )}
              </div>
            </div>
            {errors[name] && touched[name] && (
              <div className="error">
                {typeof errors[name] === 'string'
                  ? errors[name]
                  : errors[name].hasOwnProperty('match')
                  ? errors[name].match
                  : ''}
              </div>
            )}
          </div>
        )}
      </Context.Consumer>
    );
  }
}

CompanyField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.shape({
      match: PropTypes.object,
      name: PropTypes.string,
      country: PropTypes.string,
      regnum: PropTypes.string,
    }),
    onBlur: PropTypes.func,
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func,
    errors: PropTypes.object,
    touched: PropTypes.object,
  }).isRequired,
  onFocus: PropTypes.func,
};

CompanyField.defaultProps = {
  label: '',
  placeholder: '',
  onFocus: f => f,
};

export default CompanyField;
