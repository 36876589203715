import { of } from 'rxjs';
import { map, concatMap, switchMap, catchError } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import { selectSessionAuthToken } from '@redux/modules/session';
import { fetchItem } from '@redux/modules/transactions';
import { hideModal } from '@redux/modules/ui/modal';
import * as actions from './actions';
import { FORM_STEPS } from './reducer';

export default combineEpics(
  (action$, store$, { observableRequest }) =>
    action$.pipe(
      ofType(actions.FETCH_CLAIM),
      switchMap(({ payload }) => {
        const token = selectSessionAuthToken(store$.value);
        return observableRequest({
          url: `collections/${payload.id}`,
          token,
        }).pipe(
          map(({ response }) => actions.fetchClaimSuccess(response)),
          catchError(({ response }) => of(actions.fetchClaimFailure(response))),
        );
      }),
    ),

  (action$, store$, { observableRequest }) =>
    action$.pipe(
      ofType(actions.CREATE_CLAIM),
      switchMap(({ payload }) => {
        const token = selectSessionAuthToken(store$.value);
        return observableRequest({
          url: 'collections/',
          token,
          config: {
            method: 'POST',
            body: {
              policy: payload.policyId,
            },
          },
        }).pipe(
          map(({ response }) => actions.createClaimSuccess(response)),
          catchError(({ response }) =>
            of(actions.createClaimFailure(response)),
          ),
        );
      }),
    ),

  (action$, store$, { observableRequest }) =>
    action$.pipe(
      ofType(actions.UPDATE_CLAIM),
      switchMap(({ payload }) => {
        const { id, values, nextStep, transactionId } = payload;
        const token = selectSessionAuthToken(store$.value);
        return observableRequest({
          url: `collections/${id}`,
          token,
          config: {
            method: 'PATCH',
            body: values,
          },
        }).pipe(
          concatMap(({ response }) =>
            of(
              actions.updateClaimSuccess(response),
              nextStep && FORM_STEPS[nextStep] === 'final'
                ? actions.submitClaim({ id, nextStep, transactionId })
                : nextStep
                ? actions.setFormStep(nextStep)
                : hideModal(),
            ),
          ),
          catchError(({ response }) =>
            of(actions.updateClaimFailure(response)),
          ),
        );
      }),
    ),

  (action$, store$, { observableRequest }) =>
    action$.pipe(
      ofType(actions.SUBMIT_CLAIM),
      switchMap(({ payload }) => {
        const { id, nextStep, transactionId } = payload;
        const token = selectSessionAuthToken(store$.value);
        return observableRequest({
          url: `collections/${id}/send/`,
          token,
          config: {
            method: 'PUT',
          },
        }).pipe(
          concatMap(({ response }) =>
            of(
              fetchItem({ transactionId }),
              actions.submitClaimSuccess(response),
              nextStep ? actions.setFormStep(nextStep) : hideModal(),
            ),
          ),
          catchError(({ response }) =>
            of(actions.submitClaimFailure(response)),
          ),
        );
      }),
    ),
);
