import { createPrefix, createAction } from '@hokodo/core/lib/actionHelpers';

const prefix = createPrefix('ui');

export const MODAL_SHOW = prefix('MODAL_SHOW');
export const showModal = createAction(MODAL_SHOW);
export const MODAL_HIDE = prefix('MODAL_HIDE');
export const hideModal = createAction(MODAL_HIDE);

export const initialState = {
  active: false,
  content: '',
  isLoader: false,
};

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case MODAL_SHOW: {
      const { content = null, isLoader = false } = payload;
      return {
        active: true,
        content,
        isLoader,
      };
    }

    case MODAL_HIDE:
      return initialState;

    default:
      return state;
  }
};

export const selectModal = state => state.ui.modal;

export default reducer;
