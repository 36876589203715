import { connect } from 'react-redux';
import { selectSessionAuthToken } from '@redux/modules/session';
import { fetchUser } from '@redux/modules/user';
import CompanyMatch from './CompanyMatch';

export default connect(
  state => ({
    token: selectSessionAuthToken(state),
  }),
  { fetchUser },
)(CompanyMatch);
