import { createPrefix, createAction } from '@hokodo/core/lib/actionHelpers';

const prefix = createPrefix('integrations');

export const IMPORT_KASHFLOW_INVOICES = prefix('IMPORT_KASHFLOW_INVOICES');
export const IMPORT_KASHFLOW_INVOICES_SUCCESS = prefix(
  'IMPORT_KASHFLOW_INVOICES_SUCCESS',
);
export const IMPORT_KASHFLOW_INVOICES_FAILURE = prefix(
  'IMPORT_KASHFLOW_INVOICES_FAILURE',
);

export const importKashflowInvoices = createAction(IMPORT_KASHFLOW_INVOICES);
export const importKashflowInvoicesSuccess = createAction(
  IMPORT_KASHFLOW_INVOICES_SUCCESS,
);
export const importKashflowInvoicesFailure = createAction(
  IMPORT_KASHFLOW_INVOICES_FAILURE,
);

export const OAUTH_SYNCHRONISE = prefix('OAUTH_SYNCHRONISE');
export const OAUTH_SYNCHRONISE_SUCCESS = prefix('OAUTH_SYNCHRONISE_SUCCESS');
export const OAUTH_SYNCHRONISE_FAILURE = prefix('OAUTH_SYNCHRONISE_FAILURE');

export const oauthSynchronise = createAction(OAUTH_SYNCHRONISE);
export const oauthSynchroniseSuccess = createAction(OAUTH_SYNCHRONISE_SUCCESS);
export const oauthSynchroniseFailure = createAction(OAUTH_SYNCHRONISE_FAILURE);

export const OAUTH_CONNECT = prefix('OAUTH_CONNECT');
export const OAUTH_CONNECT_SUCCESS = prefix('OAUTH_CONNECT_SUCCESS');
export const OAUTH_CONNECT_FAILURE = prefix('OAUTH_CONNECT_FAILURE');

export const oauthConnect = createAction(OAUTH_CONNECT);
export const oauthConnectSuccess = createAction(OAUTH_CONNECT_SUCCESS);
export const oauthConnectFailure = createAction(OAUTH_CONNECT_FAILURE);
