import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import Logo from '../../atoms/Logo';

const PartnershipLogo = ({ children }) => {
  const { t } = useTranslation('common');

  return (
    <div
      css={css`
        display: flex;
        align-items: center;

        > span {
          white-space: nowrap;
        }
      `}
    >
      <Logo width={110} />
      <span>{t('logoPartner')}</span>
      {children}
    </div>
  );
};

PartnershipLogo.propTypes = {
  children: PropTypes.node,
};

PartnershipLogo.defaultProps = {
  children: <div />,
};

export default PartnershipLogo;
