// SUPPORTED_LOCALES would be a more accurate name but lang used for consistency
const SUPPORTED_LANGS = ['en-GB', 'fr-FR', 'de-DE'];

export const isLangSupported = (lang = '') => SUPPORTED_LANGS.includes(lang);

export const getSupportedLanguage = (lang = '') => {
  switch (lang.substring(0, 2)) {
    case 'fr':
      return 'fr-FR';
    case 'de':
      return 'de-DE';
    default:
      return 'en-GB';
  }
};

export default {
  getSupportedLanguage,
  isLangSupported,
};
