import * as actions from './actions';

export const FORM_STEPS = {
  customer: 'customer',
  questions: 'questions',
  documents: 'documents',
  banking: 'banking',
  confirmation: 'confirmation',
  final: 'final',
};

export const initialState = {
  id: null,
  created: null,
  modified: null,
  sent: null,
  policy: null,
  details: {
    creditor_contact_name: '',
    creditor_contact_email: '',
    creditor_contact_phone: '',
    debtor_address: '',
    debtor_city: '',
    debtor_postcode: '',
    debtor_country: '',
    debtor_website: '',
    debtor_contact_name: '',
    debtor_contact_phone: '',
    debtor_contact_email: '',
    actions_taken: '',
    has_partial_payment: false,
    partial_payment: '',
    disputed: false,
    other_invoices: false,
    reasons: '',
    comments: '',
    bank_account_name: '',
    bank_account_sortcode: '',
    bank_account_number: '',
    bank_account_email: '',
    confirmed_correct: false,
    collections_terms_acceptance: false,
    collect_fees_from_debtor: true,
  },
  currentStep: FORM_STEPS.customer,
  isFetching: false,
  error: {},
};

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actions.SET_FORM_STEP:
      return {
        ...state,
        currentStep: payload,
      };

    case actions.CREATE_CLAIM:
    case actions.FETCH_CLAIM:
      return {
        ...initialState,
        isFetching: true,
      };

    case actions.CREATE_CLAIM_SUCCESS:
    case actions.FETCH_CLAIM_SUCCESS: {
      const {
        id,
        created,
        modified,
        sent,
        policy,
        collect_fees_from_debtor,
        confirmed_correct,
        accepted_collections_terms,
        ...rest
      } = payload;

      return {
        ...state,
        isFetching: false,
        id,
        created,
        modified,
        sent,
        policy,
        details: {
          ...rest,
          collect_fees_from_debtor: !!collect_fees_from_debtor,
          confirmed_correct: !!confirmed_correct,
          accepted_collections_terms: !!accepted_collections_terms,
        },
      };
    }

    case actions.CREATE_CLAIM_FAILURE:
    case actions.FETCH_CLAIM_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    case actions.UPDATE_CLAIM:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
};

export const selectClaim = state => state.claim || null;

export default reducer;
