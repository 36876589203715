import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field } from 'formik';
import { css } from '@emotion/core';
import { TextField, Button } from '@hokodo/core';
import { getValidationSchema } from '@hokodo/core/lib/formHelpers';
import { FORM_STEPS } from '@redux/modules/claim';

const BankDetails = ({ details, handleSubmit, isLocked, lang }) => {
  const { t } = useTranslation('notify');

  const {
    bank_account_name,
    bank_account_number,
    bank_account_sortcode,
    bank_account_email,
  } = details;

  return (
    <Formik
      initialValues={{
        bank_account_name,
        bank_account_number,
        bank_account_sortcode,
        bank_account_email,
        nextStep: '',
      }}
      onSubmit={values => {
        const { nextStep, ...rest } = values;
        handleSubmit(rest, nextStep);
      }}
      validationSchema={getValidationSchema('BankDetailsSchema', lang)}
    >
      {({ setFieldValue, submitForm }) => {
        return (
          <Form>
            <div
              css={css`
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 2rem;
              `}
            >
              <div>
                <Field
                  name="bank_account_name"
                  label={t('steps.bankAccount.bank_account_name')}
                  component={TextField}
                  disabled={isLocked}
                />

                <Field
                  name="bank_account_sortcode"
                  label={t('steps.bankAccount.bank_account_sortcode')}
                  component={TextField}
                  disabled={isLocked}
                />
              </div>

              <div>
                <Field
                  name="bank_account_number"
                  label={t('steps.bankAccount.bank_account_number')}
                  component={TextField}
                  disabled={isLocked}
                />

                <Field
                  name="bank_account_email"
                  label={t('steps.bankAccount.bank_account_email')}
                  component={TextField}
                  disabled={isLocked}
                />
              </div>
            </div>

            <div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 2rem;
              `}
            >
              <Button
                type="button"
                onClick={() => {
                  setFieldValue('nextStep', FORM_STEPS.confirmation, false);
                  submitForm();
                }}
              >
                {t('modal.controls.continue')}
              </Button>

              <ul
                css={css`
                  display: flex;
                  margin-top: 0.5rem;
                  li {
                    margin: 0 0.25rem;
                  }
                `}
              >
                <li>
                  <a
                    href="#"
                    rel="button"
                    onClick={event => {
                      event.preventDefault();
                      setFieldValue('nextStep', FORM_STEPS.documents, false);
                      submitForm();
                    }}
                  >
                    {t('modal.controls.previous')}
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    rel="button"
                    onClick={event => {
                      event.preventDefault();
                      setFieldValue('nextStep', '', false);
                      submitForm();
                    }}
                  >
                    {t('modal.controls.save')}
                  </a>
                </li>
              </ul>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

BankDetails.propTypes = {
  details: PropTypes.shape({
    bank_account_name: PropTypes.string,
    bank_account_number: PropTypes.string,
    bank_account_sortcode: PropTypes.string,
    bank_account_email: PropTypes.string,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isLocked: PropTypes.bool.isRequired,
  lang: PropTypes.string.isRequired,
};

export default BankDetails;
