import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { useTranslation } from 'react-i18next';
import { POLICY_STATUSES } from '../../../../constants';
import Icon from '../../atoms/Icon';

const getIconFromStatus = status => {
  const { t } = useTranslation('invoiceStatuses');

  switch (status) {
    case POLICY_STATUSES.active:
      return {
        label: t('not_yet_paid'),
        type: 'slash',
        color: '#6ac259',
      };

    case POLICY_STATUSES.late:
      return {
        label: t('overdue'),
        type: 'slash',
        color: '#f5a622',
      };

    case POLICY_STATUSES.late_can_handover:
      return {
        label: t('handover_to_collections'),
        type: 'alert-triangle',
        color: '#e91616',
      };

    case POLICY_STATUSES.paid_distributor:
    case POLICY_STATUSES.paid:
      return {
        label: t('paid'),
        type: 'check-circle',
        color: '#6ac259',
      };

    case POLICY_STATUSES.elapsed:
    case POLICY_STATUSES.claim_approved:
    case POLICY_STATUSES.claim_rejected:
    case POLICY_STATUSES.recovery_installments:
    case POLICY_STATUSES.recovery_underway:
    case POLICY_STATUSES.recovery_complete:
    case POLICY_STATUSES.recovery_rejected:
    case POLICY_STATUSES.cancelled:
    case POLICY_STATUSES.other:
      return {
        label: t('closed'),
        type: 'check-circle',
        color: '#a8a8a8',
      };

    case POLICY_STATUSES.under_collection:
    case POLICY_STATUSES.pay_installments:
    case POLICY_STATUSES.under_collection_installments:
    case POLICY_STATUSES.under_collection_dispute_solved:
      return {
        label: t('collection_in_progress'),
        type: 'settings',
        color: '#4a90e2',
      };

    case POLICY_STATUSES.not_collectable:
    case POLICY_STATUSES.not_collectable_dispute:
      return {
        label: t('respond_to_email'),
        type: 'alert-triangle',
        color: '#f5a622',
      };

    case POLICY_STATUSES.dispute_pursual:
      return {
        label: t('on_hold_dispute'),
        type: 'alert-triangle',
        color: '#f5a622',
      };

    case POLICY_STATUSES.claim_submitted:
      return {
        label: t('claim_in_progress'),
        type: 'settings',
        color: '#4a90e2',
      };

    case POLICY_STATUSES.not_insured:
      return {
        label: t('not_insured'),
        type: 'watch',
        color: '#a8a8a8',
      };

    default:
      return {
        label: 'Unkown policy status',
        type: 'unknown',
        color: '#a8a8a8',
      };
  }
};

const StatusIcon = ({ status }) => {
  const { label, type, color = '' } = getIconFromStatus(status);

  return (
    <div
      css={css`
        display: flex;
        i {
          margin-right: 0.25rem;
        }
      `}
    >
      <Icon type={type} color={color} size={16} />
      <span>{label}</span>
    </div>
  );
};

StatusIcon.propTypes = {
  status: PropTypes.string,
};

StatusIcon.defaultProps = {
  status: 'unknown',
};

export default StatusIcon;
