import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { useTranslation } from 'react-i18next';

const MatchCompanySearch = ({ onInputChange }) => {
  const onChange = event => {
    onInputChange(event.target.value);
  };

  const { t } = useTranslation('common');

  return (
    <div
      css={css`
        padding: 12px 0 12px 12px;
        font-size: 12px;
        line-height: 16px;
      `}
    >
      <p
        css={css`
          font-weight: bold;
          margin: 0;
        `}
      >
        {t('companyMatch.title')}
      </p>
      <input
        css={css`
          width: 100%;
          border: none;
          &::placeholder {
            font-size: inherit;
          }
        `}
        placeholder={t('companyMatch.placeholder')}
        onChange={onChange}
      />
    </div>
  );
};

MatchCompanySearch.propTypes = {
  onInputChange: PropTypes.func.isRequired,
};

export default MatchCompanySearch;
