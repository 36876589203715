import { QUOTE_STATUSES } from '../../../constants';
import * as actions from './actions';

export const initialState = {
  transaction: null,
  error: null,
  isFetching: false,
  clientCountry: '',
  organisation: null,
};

export const getClientCountryFromQuote = quote =>
  quote.client ? quote.client.country || '' : '';

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actions.FETCH_TRANSACTION:
    case actions.FETCH_QUOTE:
    case actions.FETCH_POLICY:
      return {
        ...state,
        isFetching: true,
      };

    case actions.FETCH_TRANSACTION_SUCCESS:
      return {
        ...state,
        transaction: payload,
        clientCountry: payload.quote
          ? getClientCountryFromQuote(payload.quote)
          : '',
        isFetching: false,
        error: null,
      };

    case actions.FETCH_QUOTE_SUCCESS:
      return {
        ...state,
        transaction: {
          ...state.transaction,
          quote: payload,
        },
        clientCountry: getClientCountryFromQuote(payload),
        isFetching: false,
        error: null,
      };

    case actions.FETCH_POLICY_SUCCESS:
      return {
        ...state,
        transaction: {
          ...state.transaction,
          quote: {
            ...state.transaction.quote,
            status: QUOTE_STATUSES.accepted,
          },
          policy: payload,
        },
        isFetching: false,
        error: null,
      };

    case actions.FETCH_TRANSACTION_FAILURE:
    case actions.FETCH_QUOTE_FAILURE:
    case actions.FETCH_POLICY_FAILURE:
      return {
        ...state,
        error: payload,
        isFetching: false,
      };

    default:
      return state;
  }
};
