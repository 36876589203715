import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Subject } from 'rxjs';
import { pluck, switchMap } from 'rxjs/operators';
import { connect } from 'react-redux';
import { selectSessionAuthToken } from '@redux/modules/session';
import { observableRequest, Heading } from '@hokodo/core';

/* eslint-disable react/no-this-in-sfc */
export default (WrapComponent, routeProps) => {
  class WithTransaction extends React.Component {
    constructor(props) {
      super(props);
      this.subject$ = new Subject();
      this.state = {
        // isFetching: false,
        transaction: null,
        error: null,
      };
    }

    componentDidMount() {
      const { match, token } = this.props;
      const { transactionId } = match.params;

      this.subscription = this.subject$
        .pipe(
          switchMap(() => {
            // this.setState({ isFetching: true });
            return observableRequest({
              url: `transactions/${transactionId}/?expand=policy,quote,debtor,creditor,owner`,
              token,
            }).pipe(pluck('response'));
          }),
        )
        .subscribe(this.onSuccess, this.onError);

      this.subject$.next();
    }

    componentWillUnmount() {
      if (this.subscription) this.subscription.unsubscribe();
    }

    onSuccess = response => {
      this.setState({ transaction: response });
    };

    onError = error => {
      this.setState({ error: error.response });
    };

    render() {
      const { transaction, error } = this.state;
      if (error)
        return (
          <Fragment>
            <Heading level={1}>Transaction not found</Heading>
            <p>The transaction you are looking for does not exist.</p>
          </Fragment>
        );
      return transaction ? <WrapComponent transaction={transaction} /> : null;
    }
  }

  WithTransaction.propTypes = {
    token: PropTypes.string.isRequired,
    match: PropTypes.shape({
      params: PropTypes.object,
    }).isRequired,
  };

  const Component = connect(state => ({
    token: selectSessionAuthToken(state),
  }))(WithTransaction);
  return <Component {...routeProps} />;
};
