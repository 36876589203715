import { ignoreElements } from 'rxjs/operators';
import observableRequest from '../../../lib/requestHelpers';
import { BOTS } from '../../constants';

export const botDetected = () => {
  return BOTS.some(bot =>
    window.navigator.userAgent.toLowerCase().includes(bot),
  );
};

export const getEmailFromPayload = (payload, store) => {
  try {
    return 'email' in payload
      ? payload.email
      : store.getState().user.profile.email;
  } catch {
    return null;
  }
};

export const pushEventDataToContorta = (event, trackingId, traits, token) => {
  return (
    observableRequest({
      url: 'client-events/',
      token,
      config: {
        method: 'POST',
        body: {
          tracking_id: trackingId,
          event,
          traits,
        },
      },
    })
      // We log all errors in Contorta, so let's ignore the response
      .subscribe(ignoreElements)
  );
};
