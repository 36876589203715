import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import Checkout from '../Checkout';
import Header from '../../organisms/Header';
import Footer from '../../organisms/Footer';
import ProtectionSummary from '../../organisms/ProtectionSummary';
import SectionHeader from '../../molecules/SectionHeader/SectionHeader';
import ProtectionFeatures from '../../organisms/ProtectionFeatures';
import ProtectionTimeline from '../../organisms/ProtectionTimeline';
import DetailsSummary from '../../organisms/DetailsSummary';
import ProgressIndicator from '../../molecules/ProgressIndicator';
import Card from '../../atoms/Card';
import Button from '../../atoms/Button';
import Context from '../../../Context';
import ProtectionDeclined from '../../organisms/ProtectionDeclined';
import Policy from '../../organisms/Policy';

const PortalLayout = ({ transaction, isFetching, partnerLogo, mainLogo }) => {
  const { t } = useTranslation('layout');

  const debtorName = trns => {
    if (!trns) return null;
    return trns.debtor ? trns.debtor.name : trns.debtor_name;
  };

  return (
    <Context.Consumer>
      {({ queryString, theme: { breakpoints, padding, colors, radius } }) => {
        const wrapperStyle = css`
          display: grid;

          @media (min-width: ${breakpoints.lg}) {
            grid-template-columns: 1fr 1fr;
          }

          > div {
            padding: 0 ${padding.default};
          }
        `;

        return (
          <Fragment>
            <Header partnerLogo={partnerLogo} mainLogo={mainLogo} />
            <ProgressIndicator
              pages={[
                { pathname: '', title: t('progress.details') },
                { pathname: 'checkout', title: t('progress.checkout') },
                { pathname: 'policy', title: t('progress.confirmation') },
              ]}
            />
            <div id="add_header" />
            <main className="container">
              <Switch>
                <Route path="/declined" component={ProtectionDeclined} />
                <Route path="/policy" component={Policy} />
                <Route path="/checkout">
                  {transaction && transaction.quote ? (
                    <Fragment>
                      <SectionHeader content={t('prePurchaseSectionHeader', { debtorName: debtorName(transaction) })} />
                      <div
                        css={css`
                          ${wrapperStyle}
                        `}
                      >
                        <div>
                          <ProtectionSummary />
                        </div>
                        <div>
                          <Checkout
                            transaction={transaction}
                            isFetching={isFetching}
                          />
                        </div>
                      </div>

                      <div
                        css={css`
                          padding: 0 ${padding.default};
                        `}
                      >
                        <ProtectionFeatures />
                      </div>
                    </Fragment>
                  ) : null}
                </Route>

                <Route>
                  <SectionHeader content={t('prePurchaseSectionHeader', { debtorName: debtorName(transaction) })} />
                  <Fragment>
                    <div
                      css={css`
                        ${wrapperStyle}
                        @media (min-width: ${breakpoints.lg}) {
                          grid-template-columns: 1fr 2fr;
                        }
                      `}
                    >
                      <div>
                        <DetailsSummary />
                      </div>

                      <div>
                        <ProtectionSummary discountBanner />
                        <div
                          css={css`
                            position: relative;
                            margin-top: 2rem;
                            &::before {
                              content: ' ';
                              height: 32px;
                              width: 32px;
                              background: ${colors.white};
                              transform: rotate(45deg);
                              position: absolute;
                              top: -12px;
                              left: 0;
                              right: 0;
                              margin: auto;
                              border-radius: ${radius.default};
                            }
                          `}
                        >
                          <Card>
                            <div
                              css={css`
                                padding: 1rem 0;
                                > div {
                                  display: grid;
                                  grid-template-columns: 1fr 1fr;
                                  > div {
                                    margin: 0 2rem;
                                  }
                                }
                              `}
                            >
                              <div>
                                <div>
                                  <Button
                                    css={css`
                                      border-radius: 1.5rem;
                                      border: solid 1px
                                        ${colors.revampSecondary};
                                      background-color: ${colors.white};
                                      color: ${colors.revampSecondary};
                                    `}
                                    block
                                    onClick={() => {window.open("about:blank", "_self", "");}}
                                  >
                                    {t('buttons.close')}
                                  </Button>
                                </div>
                                <div>
                                  <Link to={`/checkout${queryString}`}>
                                    <Button
                                      css={css`
                                        border-radius: 1.5rem;
                                        border: solid 1px ${colors.revampCTA};
                                        background-color: ${colors.revampCTA};
                                        color: ${colors.white};
                                      `}
                                      block
                                    >
                                      {t('buttons.checkout')}
                                    </Button>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </Card>
                        </div>
                      </div>
                    </div>

                    <div
                      css={css`
                        padding: 0 ${padding.default};
                      `}
                    >
                      <ProtectionTimeline />
                    </div>
                  </Fragment>
                </Route>
              </Switch>
            </main>
            <Footer />
          </Fragment>
        );
      }}
    </Context.Consumer>
  );
};

PortalLayout.propTypes = {
  transaction: PropTypes.object,
  isFetching: PropTypes.bool,
  partnerLogo: PropTypes.node,
  mainLogo: PropTypes.node,
};

PortalLayout.defaultProps = {
  transaction: null,
  isFetching: false,
  partnerLogo: null,
  mainLogo: null,
};

export default PortalLayout;
