import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import Label from '../../atoms/Label';
import Context from '../../../Context';

const BooleanField = ({ form, field, disabled, label }) => {
  const { name, value } = field;
  const { errors, touched, setFieldValue } = form;

  const { t } = useTranslation('common');

  const [state, setState] = useState(value);

  const updateValue = val => {
    setState(val);
    setFieldValue(name, val);
  };

  return (
    <Context.Consumer>
      {({ theme: { colors, radius, padding } }) => (
        <div>
          {label && <Label text={label} htmlFor={name} />}
          <div
            css={css`
              display: flex;
              margin-bottom: 1rem;
              button {
                cursor: pointer;
                background-color: ${colors.tertiary};
                color: ${colors.black};
                padding: ${padding.button};
                border: none;
                border-radius: ${radius.default};
                font-size: inherit;
                height: 2.5rem;
                width: 2.5rem;
                margin-right: 0.5rem;
                &.selected {
                  background-color: ${colors.primary};
                  color: ${colors.white};
                }
                &:disabled {
                  cursor: normal;
                }
                &:last-of-type {
                  margin-right: 0;
                }
              }
            `}
          >
            <button
              type="button"
              className={state ? 'selected' : ''}
              onClick={() => {
                updateValue(true);
              }}
              disabled={disabled}
            >
              {t('yes')}
            </button>

            <button
              type="button"
              className={
                state ? '' : typeof state === 'boolean' ? 'selected' : ''
              }
              onClick={() => {
                updateValue(false);
              }}
              disabled={disabled}
            >
              {t('no')}
            </button>
          </div>
          {errors[name] && touched[name] && (
            <div className="error">{errors[name]}</div>
          )}
        </div>
      )}
    </Context.Consumer>
  );
};

BooleanField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    // onBlur: PropTypes.func,
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func,
    errors: PropTypes.object,
    touched: PropTypes.object,
  }).isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string,
};

BooleanField.defaultProps = {
  disabled: false,
  label: '',
};

export default BooleanField;
