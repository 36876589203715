import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';

const MatchCompanyResult = ({ company, onSelect }) => {
  const handleClick = event => {
    event.stopPropagation();
    onSelect(company);
  };

  const { title, address_snippet: address } = company;

  return (
    <div
      role="button"
      tabIndex="0"
      onClick={handleClick}
      css={css`
        padding: 8px 0 8px 12px;
        border-top: 2px solid #e6ebf0;
        line-height: 16px;
      `}
    >
      <div
        css={css`
          font-size: 14px;
        `}
      >
        {title || '-'}
      </div>
      <div
        css={css`
          font-size: 11px;
          color: #a6acb3;
        `}
      >
        {address}
      </div>
    </div>
  );
};

MatchCompanyResult.propTypes = {
  company: PropTypes.shape().isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default MatchCompanyResult;
