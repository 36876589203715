import React, { Component, Fragment } from 'react';
import i18n from 'i18next';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import enGB from 'date-fns/locale/en-GB';
import fr from 'date-fns/locale/fr';
import de from 'date-fns/locale/de';
import '@stripe/stripe-js';
import { Global, css } from '@emotion/core';
import { Context, CONTEXT_CONSTANTS } from '@hokodo/core';
import globalStyles from '@styles/global';
import * as theme from '@styles/theme';
import {
  MULTI_LANGUAGE_ENABLED,
  DEFAULT_CONTEXT,
  AVAILABLE_CONTEXTS,
} from '@environment';
import Layout from '@components/Layout';
import flagStyles from '@assets/flags/styles';
import { getSupportedLanguage } from '@hokodo/core/lib/localisationHelpers';

registerLocale('en-GB', enGB);
registerLocale('fr-FR', fr);
registerLocale('de-DE', de);

const getAppContext = requested => {
  const availableContexts = AVAILABLE_CONTEXTS;
  const constants = CONTEXT_CONSTANTS;

  const additionalContextKeys = Object.keys(constants).reduce((acc, key) => {
    acc[key] = constants[key][requested] || constants[key][DEFAULT_CONTEXT];
    return acc;
  }, {});
  return {
    ...availableContexts[DEFAULT_CONTEXT],
    ...availableContexts[requested.toUpperCase()],
    ...additionalContextKeys,
  };
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: getSupportedLanguage(i18n.language),
      locale: DEFAULT_CONTEXT,
    };
  }

  static getDerivedStateFromProps = props => {
    return {
      locale: props.currentMembershipCountry || DEFAULT_CONTEXT,
    };
  };

  setLanguage = requested => {
    const lang = getSupportedLanguage(requested);
    i18n.changeLanguage(lang);
    setDefaultLocale(lang);
    this.setState({ lang });
  };

  render() {
    const { lang, locale } = this.state;
    const applicationContext = getAppContext(locale);

    return (
      <Context.Provider
        value={{
          features: {
            multiLanguage: MULTI_LANGUAGE_ENABLED,
          },
          applicationContext,
          setLanguage: this.setLanguage,
          lang,
          locale,
          theme,
          LEGACY_CHECKOUT: true,
        }}
      >
        <Fragment>
          <Global
            styles={css`
              ${globalStyles}
              ${flagStyles}
            `}
          />
          <Layout />
        </Fragment>
      </Context.Provider>
    );
  }
}

export default App;
