import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthFormBox, NotFound } from '@hokodo/core';
import withUserLookup from '@decorators/withUserLookup';
import RegisterForm from './Form';

const Register = ({ match }) => {
  const { t } = useTranslation('auth');

  return (
    <Switch>
      <Route
        exact
        path={match.path}
        component={routeProps => withUserLookup(RegisterForm, routeProps)}
      />

      <Route
        exact
        path={`${match.path}/check-email`}
        render={() => (
          <AuthFormBox title={t('register.successTitle')}>
            {t('register.successDescription')}
          </AuthFormBox>
        )}
      />

      <Route component={NotFound} />
    </Switch>
  );
};

Register.propTypes = {
  match: PropTypes.object.isRequired,
};

export default Register;
