import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import { formatLocaleCurrency } from '../../../../../lib/currencyHelpers';
import { getHasAmountToPayFromQuote } from '../../../../../lib/checkoutHelpers';
import { selectTransaction } from '../../../../redux/modules/transaction';
import Context from '../../../Context';
import Card from '../../atoms/Card';
import Heading from '../../atoms/Heading';
import Tooltip from '../../atoms/Tooltip';
import BulletArrow from '../../../../assets/img/bullet-arrow-gold.svg';

const ProtectionSummary = ({ discountBanner }) => {
  const transaction = useSelector(state => selectTransaction(state));
  const { t } = useTranslation('protectionSummary');

  // TODO: Replace 'offered' with applicationContext.QUOTE_STATUSES.offered
  const hasOfferedQuote = quote => quote.status === 'offered';

  return transaction &&
  transaction.quote &&
  hasOfferedQuote(transaction.quote) ? (
    <Context.Consumer>
      {({
          lang,
          theme: { colors },
          applicationContext: { CANCELLATION_WINDOW },
        }) => {
        const { quote } = transaction;
        const {
          price: {
            currency,
            total_price,
            total_price_without_discount,
            discount_amount,
            // premium,
            ipt,
            discounts,
          },
          insured: { currency: insured_currency, insured_amount },
          insured_pc_net,
        } = quote;

        const formatCurrency = (cur, num) =>
          formatLocaleCurrency(lang, cur, num);

        const hasAmountToPay = getHasAmountToPayFromQuote(quote);

        const footer = (
          <div
            css={css`
                > div.grid {
                  margin-bottom: 1rem;
                  display: grid;
                  grid-template-columns: 1fr 1fr 2fr;
                  span {
                    align-self: center;
                  }
                }
              `}
          >
            {discount_amount ? (
              <>
                <div className="grid">
                  <span
                    css={css`
                      font-weight: 500;
                    `}
                  >
                    {t('footer.total')}
                  </span>
                  <span
                    css={css`
                      font-size: 20px;
                    `}
                  >
                    {formatCurrency(currency, total_price_without_discount)}
                  </span>
                </div>
                <div className="grid">
                  <span>{t('footer.discount_amount')}</span>
                  <span>{`-${formatCurrency(currency, discount_amount)}`}</span>
                </div>
              </>
            ) : null}

            <div
              className="grid"
              css={css`
                  padding: 1rem 0;
               `}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: t(
                    discount_amount
                      ? 'footer.total_price_after_reduction'
                      : 'footer.total_price',
                    {
                      interpolation: { escapeValue: false },
                    },
                  ),
                }}
                css={css`
                  line-height: 1.2;
                  font-size: 18px;
                  strong {
                    font-weight: 500;
                    display: block;
                  }
                `}
              />
              <span
                css={css`
                    font-size: 28px;
                  `}
              >
                {formatCurrency(currency, total_price)}
                <span>
                  <Tooltip
                    title={t('tooltips.protectionSummaryPremium.title', {
                      total_price: formatCurrency(currency, total_price),
                    })}
                    description={t(
                      'tooltips.protectionSummaryPremium.description',
                      {
                        insured_pc_net,
                        insured_amount: formatCurrency(
                          currency,
                          insured_amount,
                        ),
                      },
                    )}
                  />
                </span>
              </span>

              {hasAmountToPay ? null : (
                <span
                  css={css`
                      font-size: 14px;
                    `}
                  dangerouslySetInnerHTML={{
                    __html: t('footer.remaining_credit', {
                      interpolation: { escapeValue: false },
                      remaining: formatCurrency(
                        discounts[0].remaining_credits_amount_after_currency,
                        discounts[0].remaining_credits_amount_after,
                      ),
                    }),
                  }}
                />
              )}
            </div>

            {hasAmountToPay ? (
              <div
                css={css`
                    font-size: 14px;
                    margin-bottom: 0.5rem;
                  `}
              >
                <span>{t('footer.tax')}</span>
                <span />
                <span>{formatCurrency(currency, ipt)}</span>
              </div>
            ) : null}

            <p
              css={css`
                  font-size: 14px;
                `}
            >
              {t('footer.cancellation', {
                cancellation_window: CANCELLATION_WINDOW,
              })}
            </p>
          </div>
        );

        return (
          <div>
            <Card
              title={t('title')}
              footer={footer}
              titleBodySeparator={false}
              logo
            >
              {discountBanner && discounts.length ? (
                <>
                  <div
                    css={css`
                        background: ${colors.accent};
                        color: ${colors.white};
                        padding: 0.75rem 0;
                        text-align: center;
                        font-size: 1.25rem;
                        margin: -1rem -1rem 1rem -1rem;
                      `}
                  >
                    {discounts[0].discount_reason}
                  </div>
                  <div
                    css={css`
                        padding-top: 1rem;
                      `}
                  >
                    <Heading level={4}>{t('listTitle')}</Heading>
                  </div>
                </>
              ) : (
                <Heading level={4}>{t('listTitle')}</Heading>
              )}

              <ul
                css={css`
                    li {
                      margin: 0 0 1rem 1rem;
                      list-style-type: disc;
                      color: ${colors.primary};
                      &:last-of-type {
                        list-style-image: url(${BulletArrow});
                        margin-left: 2rem;
                      }
  
                      span {
                        display: block;
                        color: ${colors.black};
                        &:first-of-type {
                          font-size: 18px;
                          font-weight: 500;
                          strong {
                            font-weight: 500;
                            color: ${colors.primary};
                          }
                        }
                      }
                    }
                  `}
              >
                <li>
                  <span>{t('listItem1.title')}</span>
                  <span>{t('listItem1.description')}</span>
                </li>

                <li>
                  <span>{t('listItem2.title')}</span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t('listItem2.description', {
                        interpolation: { escapeValue: false },
                        insured_pc_net,
                        insured_amount: formatCurrency(
                          insured_currency,
                          insured_amount,
                        ),
                      }),
                    }}
                  />
                </li>

                <li>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t('listItem3.title', {
                        interpolation: { escapeValue: false },
                        insured_amount: formatCurrency(
                          insured_currency,
                          insured_amount,
                        ),
                      }),
                    }}
                  />
                  <span>
                    <Tooltip
                      title={t(
                        'tooltips.protectionSummaryInsuredAmount.title',
                        {
                          insured_amount: formatCurrency(
                            currency,
                            insured_amount,
                          ),
                        },
                      )}
                      description={t(
                        'tooltips.protectionSummaryInsuredAmount.description',
                        {
                          insured_pc_net,
                          insured_amount: formatCurrency(
                            currency,
                            insured_amount,
                          ),
                        },
                      )}
                    />
                  </span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t('listItem3.description', {
                        interpolation: { escapeValue: false },
                        insured_pc_net,
                        insured_amount: formatCurrency(
                          insured_currency,
                          insured_amount,
                        ),
                      }),
                    }}
                  />
                </li>
              </ul>
            </Card>
          </div>
        );
      }}
    </Context.Consumer>
  ) : null;
};

ProtectionSummary.propTypes = {
  discountBanner: PropTypes.bool,
};

ProtectionSummary.defaultProps = {
  discountBanner: false,
};

export default ProtectionSummary;
