import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { useTranslation } from 'react-i18next';
import { Button, Context } from '@hokodo/core';

const SavedPaymentMethods = ({
  paymentMethods,
  onRemovePaymentMethod,
  onDefaultPaymentMethod,
  readonly,
}) => {
  const { t } = useTranslation('account');
  const { results } = paymentMethods;

  return (
    <Context.Consumer>
      {({ theme: { border } }) => (
        <div
          css={css`
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 2rem;
            margin-bottom: 1rem;
          `}
        >
          <div>
            {results
              .filter(({ type }) => !!type)
              .map(({ id, card, is_default }) => (
                <div
                  key={id}
                  css={css`
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-column-gap: 2rem;
                    align-items: center;
                    border-bottom: ${border.default};
                    padding: 0.25rem 0.5rem;
                  `}
                >
                  <span
                    css={css`
                      justify-self: start;
                      font-weight: bold;
                    `}
                  >
                    {`${t('paymentSettings.cardEnding', {
                      last4: card ? card.last4 : '',
                    })} ${
                      is_default
                        ? `(${t('paymentSettings.currentDefault')})`
                        : ''
                    }`}
                  </span>
                  {readonly ? (
                    <div />
                  ) : (
                    <div
                      css={css`
                        justify-self: end;
                        button {
                          margin: 0 0.25rem;
                        }
                      `}
                    >
                      {is_default ? (
                        <span>&nbsp;</span>
                      ) : (
                        <Button
                          size="small"
                          onClick={() => {
                            onDefaultPaymentMethod(id);
                          }}
                        >
                          {t('paymentSettings.setDefault')}
                        </Button>
                      )}
                      <Button
                        size="small"
                        onClick={() => {
                          onRemovePaymentMethod(id);
                        }}
                      >
                        {t('paymentSettings.removeItem')}
                      </Button>
                    </div>
                  )}
                </div>
              ))}
          </div>
        </div>
      )}
    </Context.Consumer>
  );
};

SavedPaymentMethods.propTypes = {
  paymentMethods: PropTypes.shape({
    results: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        remote_id: PropTypes.string, // This field is unused
        type: PropTypes.string,
        card: PropTypes.shape({
          brand: PropTypes.string,
          exp_month: PropTypes.int,
          exp_year: PropTypes.int,
          last4: PropTypes.string,
        }),
        is_default: PropTypes.bool,
      }),
    ),
  }).isRequired,
  onDefaultPaymentMethod: PropTypes.func.isRequired,
  onRemovePaymentMethod: PropTypes.func.isRequired,
  readonly: PropTypes.bool,
};

SavedPaymentMethods.defaultProps = {
  readonly: true,
};

export default SavedPaymentMethods;
