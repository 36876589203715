import * as actions from './actions';
import * as selectors from './selectors';
import { reducer } from './reducer';
import epic from './epic';

export * from './actions';
export * from './selectors';

export default {
  ...actions,
  ...selectors,
  reducer,
  epic,
};
