import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import Heading from '../../atoms/Heading';
import VisaSVG from '../../../../assets/img/visa.svg';
import AmexSVG from '../../../../assets/img/amex.svg';
import DiscoverSVG from '../../../../assets/img/discover.svg';
import MastercardSVG from '../../../../assets/img/mastercard.svg';

const AcceptedCards = ({ headingText, headingLevel }) => {
  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      `}
    >
      {headingText && <Heading level={headingLevel}>{headingText}</Heading>}
      <ul
        css={css`
          display: flex;
          li {
            margin: 0 0.25rem;
          }
        `}
      >
        <li>
          <img alt="" src={VisaSVG} />
        </li>
        <li>
          <img alt="" src={AmexSVG} />
        </li>
        <li>
          <img alt="" src={DiscoverSVG} />
        </li>
        <li>
          <img alt="" src={MastercardSVG} />
        </li>
      </ul>
    </div>
  );
};

AcceptedCards.propTypes = {
  headingLevel: PropTypes.number,
  headingText: PropTypes.string,
};

AcceptedCards.defaultProps = {
  headingLevel: 4,
  headingText: null,
};

export default AcceptedCards;
