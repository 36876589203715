import { QUOTE_STATUSES, TRANSACTION_STATUSES } from '../src/constants';

export const getTransactionStatus = transaction => {
  const timeNow = new Date().getTime();

  // do we already have a policy?
  if (transaction.policy) return TRANSACTION_STATUSES.protected;

  // do we have a quote?
  if (transaction.quote) {
    const isQuoteValid = transaction.quote.valid_until
      ? new Date(transaction.quote.valid_until).getTime() - timeNow > 0
      : false;

    // quote offered and has not expired
    if (transaction.quote.status === QUOTE_STATUSES.offered && isQuoteValid)
      return TRANSACTION_STATUSES.checked;

    // quote offered but has expired
    if (transaction.quote.status === QUOTE_STATUSES.offered && !isQuoteValid)
      return TRANSACTION_STATUSES.editable;

    // api notifies quote has expired
    if (transaction.quote.status === QUOTE_STATUSES.expired)
      return TRANSACTION_STATUSES.editable;

    // quote is declined
    if (transaction.quote.status === QUOTE_STATUSES.declined)
      return TRANSACTION_STATUSES.declined;
  }

  return TRANSACTION_STATUSES.editable;
};

export const getOrganisationFromTransaction = transaction => {
  try {
    const { id } = transaction.owner;
    if (id.match(/^org-/)) return id;
  } catch (e) {
    return null;
  }
  return null;
};

export default {
  getTransactionStatus,
  getOrganisationFromTransaction,
};
