/* eslint-disable max-len */
export default {
  invoiceProtection: {
    img: {
      src: 'protect-cash.svg',
      alt: 'amount collection',
    },
    text: `
### Invoice protection
You will be protected if your customer becomes insolvent or fails to settle your invoice. 

The protection covers up to 90% of the total amount (ex. VAT) and only valid invoices


`,
  },
  onlyValid: {
    img: {
      src: 'amount-insolvency.svg',
      alt: 'amount insolvency',
    },
    text: `
### Only valid invoices
If your customer disputes the invoice then this dispute will needs to be settled in your favour before we'll pay a claim.
`,
  },
  proofOfPruchase: {
    img: {
      src: 'buyer-agreement.svg',
      alt: 'buyer insolvent',
    },
    text: `
### Proof of purchase
You'll need a purchase order or other confirmation of order. If verbal, we can still insure you but we won't pay a claim if the invoice is disputed.
`,
  },
  existingDistress: {
    img: {
      src: 'buyer-insolvent.svg',
      alt: 'amount collection',
    },
    text: `
### Customer in distress?
If you are aware that this customer is already insolvent, in financial distress, unlikely to pay or has other invoices over 45 days overdue with you then you should not purchase cover.
`,
  },
  recentInvoice: {
    img: {
      src: 'date-invoice.svg',
      alt: 'date invoice',
    },
    text: `
### Recent invoice
The invoice must have been issued within 30 days of the date when you finished supplying the goods or services.

Do not forget to pass your invoice to us for collection once it reaches 45 days past due date, we will collect it for you.
`,
  },
  passOnTime: {
    img: {
      src: 'collection-invoice.svg',
      alt: 'collection invoice insurer',
    },
    text: `
### Pass it to us on time
If your invoice reaches 45 days past due date, make sure you hand it to us for debt collection. We'll remind you when it's time to do this. that way we ensure you'll be eligible to claim 90% of the outstanding amount if collection fails.
    `,
  },
  carefulDelivery: {
    img: {
      src: 'product-transit.svg',
      alt: 'date invoice',
    },
    text: `
### Careful with delivery
Damage or loss of goods in transit is not covered and your customer could dispute the validity of the invoice.
    `,
  },
  weCollectPayments: {
    img: {
      src: 'amount-insolvency.svg',
      alt: 'amount collection',
    },
    text: `
### We collect payments
Pass your invoice to us for collection once it reaches 45 days past due date, we will collect it for you.

Use of our collections service at a preferential rate  of {{COLLECTION_PERC}}% + VAT success fee on balance successfully collected.
    `,
  },
  readPolicyTerms: {
    img: {
      src: 'buyer-agreement.svg',
      alt: 'amount collection',
    },
    text: `
### Check our policy terms

Please make sure you read and understand [how invoice protection works]({{FAQ_URL}}) and the \
[specimen policy]({{SPECIMEN_POLICY}})
to ensure it meets your needs.

You are free to cancel your policy within the first 72 hours an receive a full refund.
    `,
  },
};
