import React from 'react';
import { css } from '@emotion/core';
import { useTranslation } from 'react-i18next';
import { SupportLink, Context } from '@hokodo/core';

const Footer = () => {
  const { t } = useTranslation('footer');

  return (
    <Context.Consumer>
      {({
        applicationContext: {
          TERMS_CONDITIONS,
          PRIVACY_POLICY,
          FOOTER_STATEMENT,
        },
        theme: { zIndex, colors },
      }) => (
        <footer
          css={css`
            display: flex;
            align-items: center;
            position: absolute;
            bottom: 0;
            width: 100%;
            z-index: ${zIndex.footer};
            font-size: 0.75rem;
            > div {
              margin: 1.5rem auto 1.5rem auto;
              color: ${colors.black};
              a {
                color: ${colors.black};
                text-decoration: underline;
              }
            }
          `}
        >
          <div className="container">
            <p>
              &copy;
              {new Date().getFullYear()} Hokodo.{' '}
              <a
                href={TERMS_CONDITIONS}
                rel="noopener noreferrer"
                target="_blank"
              >
                {t('terms')}
              </a>
              {' | '}
              <a
                href={PRIVACY_POLICY}
                rel="noopener noreferrer"
                target="_blank"
              >
                {t('privacy')}
              </a>
              {' | '}
              <SupportLink>{t('contact')}</SupportLink>
            </p>

            <p>{FOOTER_STATEMENT}</p>
          </div>
        </footer>
      )}
    </Context.Consumer>
  );
};

export default Footer;
