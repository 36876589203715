import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import { getRegnum } from '../../../../../lib/companyHelpers';
import { formatLocaleCurrency } from '../../../../../lib/currencyHelpers';
import { formatLocaleDate } from '../../../../../lib/datetimeHelpers';
import { selectTransaction } from '../../../../redux/modules/transaction';
import Context from '../../../Context';
import Card from '../../atoms/Card';
import Tooltip from '../../atoms/Tooltip';
import Heading from '../../atoms/Heading';


const DetailsSummary = () => {
  const transaction = useSelector(state => selectTransaction(state));
  const { t } = useTranslation('detailsSummary');

  return transaction ? (
    <Context.Consumer>
      {({ lang }) => {
        const {
          creditor,
          creditor_name,
          creditor_regnum,
          currency,
          net_amount,
          gross_amount,
          number,
          issue_date,
        } = transaction;
        const formatCurrency = (cur, num) =>
          formatLocaleCurrency(lang, cur, num);

        return (
          <Card title={t('title')}>
            <Heading level={4}>
              {`${t('labelInvoiceNumber')} ${number}`}
            </Heading>
            <p>
              {`${t('labelIssueDate')} ${formatLocaleDate(
                lang,
                new Date(issue_date),
              )}`}
            </p>
            <div>
              <ul
                css={css`
                  li {
                    list-style-type: disc;
                    margin-left: 1rem;
                    div {
                      display: grid;
                      grid-template-columns: 1fr 1fr;
                      span {
                        align-self: center;
                        &:first-of-type {
                          font-size: 18px;
                        }
                      }
                    }
                  }
                `}
              >
                <li>
                  <div>
                    <span>{t('labelCompanyName')}</span>
                    <span>{creditor ? creditor.name : creditor_name}</span>
                  </div>
                </li>
                <li>
                  <div>
                    <span>{t('labelRegNum')}</span>
                    <span>
                      {creditor
                        ? getRegnum(creditor.identifiers)
                        : creditor_regnum}
                    </span>
                  </div>
                </li>
                <li>
                  <div>
                    <span>{t('labelInvoiceNet')}</span>
                    <span>{formatCurrency(currency, net_amount)}
                      <Tooltip
                        title={t('tooltips.detailSummaryNetAmount.title')}
                        description={t('tooltips.detailSummaryNetAmount.description')}
                        interactive={false}
                      />
                    </span>
                  </div>
                </li>
                <li>
                  <div>
                    <span>{t('labelInvoiceGross')}</span>
                    <span>{formatCurrency(currency, gross_amount)}</span>
                  </div>
                </li>
              </ul>
            </div>
          </Card>
        );
      }}
    </Context.Consumer>
  ) : null;
};

export default DetailsSummary;
