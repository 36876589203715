import React from 'react';
import { history } from '@environment';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { ErrorBoundary, NotFound } from '@hokodo/core';
import Modal from '@components/Modal';
import Flash from '@components/Flash';
import Header from '@components/Header';
import Footer from '@components/Footer';
import PrivateRoute from '@components/PrivateRoute';
import Dashboard from '@components/Dashboard';
import Invoice from '@components/Invoice';
import Quote from '@components/Quote';
import Policy from '@components/Policy';
import Login from '@components/Login';
import Register from '@components/Register';
import ConfirmEmail from '@components/ConfirmEmail';
import ResetPassword from '@components/ResetPassword';
import UserAccount from '@components/UserAccount';
import Help from '@components/Help';
import OauthReturn from '@components/integrations/common/OauthReturn';
import withTransaction from '@decorators/withTransaction';

export const Layout = () => (
  <ConnectedRouter history={history}>
    <ErrorBoundary>
      <Header />
      <main className="container">
        <Switch>
          <PrivateRoute auth path="/" exact component={Dashboard} />
          <PrivateRoute auth path="/invoice" exact component={Invoice} />
          <PrivateRoute
            auth
            path="/invoice/:transactionId"
            exact
            component={routeProps => withTransaction(Invoice, routeProps)}
          />
          <PrivateRoute
            auth
            path="/quote/:transactionId"
            exact
            component={routeProps => withTransaction(Quote, routeProps)}
          />
          <PrivateRoute
            auth
            path="/policy/:transactionId"
            exact
            component={routeProps => withTransaction(Policy, routeProps)}
          />
          <PrivateRoute auth path="/account" exact component={UserAccount} />
          <PrivateRoute
            noauth
            redirectPath="/"
            path="/register"
            component={Register}
          />
          <PrivateRoute
            noauth
            redirectPath="/"
            path="/login"
            component={Login}
          />
          <PrivateRoute
            noauth
            redirectPath="/"
            path="/confirm-email"
            component={ConfirmEmail}
          />
          <PrivateRoute
            noauth
            redirectPath="/"
            path="/reset"
            component={ResetPassword}
          />
          <Route path="/help" exact component={Help} />
          <PrivateRoute
            auth
            path="/quickbooks_connected"
            exact
            component={matchProps => (
              <OauthReturn platform="quickbooks" {...matchProps} />
            )}
          />
          <PrivateRoute
            auth
            path="/xero_connected"
            exact
            component={matchProps => (
              <OauthReturn platform="xero" {...matchProps} />
            )}
          />
          <Route component={NotFound} />
        </Switch>
      </main>
      <Footer />
      <Modal />
      <Flash />
    </ErrorBoundary>
  </ConnectedRouter>
);

export default Layout;
