export const zeroPad = n => {
  return `00${n}`.slice(-2);
};

export const formatYMD = date => {
  return `${date.getFullYear()}-${zeroPad(date.getMonth() + 1)}-${zeroPad(
    date.getDate(),
  )}`;
};

export const inputFormats = {
  gb: 'dd/MM/yyyy',
  fr: 'dd/MM/yyyy',
  de: 'dd.MM.yyyy',
};

export const formatLocaleDate = (lang, date, incTime = false) => {
  const time = `${zeroPad(date.getHours())}:${zeroPad(date.getMinutes())}`;
  switch (lang) {
    case 'de-DE':
      return `${zeroPad(date.getDate())}.${zeroPad(
        date.getMonth() + 1,
      )}.${date.getFullYear()}${incTime ? ` ${time}` : ''}`;
    default:
      return `${zeroPad(date.getDate())}/${zeroPad(
        date.getMonth() + 1,
      )}/${date.getFullYear()}${incTime ? ` ${time}` : ''}`;
  }
};

export const addDays = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const numberOfDaysBetweenTwoDates = (firstDate, secondDate) => {
  return Math.round((secondDate - firstDate) / (1000 * 60 * 60 * 24));
};

export default {
  zeroPad,
  formatYMD,
  inputFormats,
  formatLocaleDate,
  addDays,
  numberOfDaysBetweenTwoDates,
};
