import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { QuoteSummary, KeyFeatures, Context } from '@hokodo/core';
import QuoteChecklist from './QuoteChecklist';

const QuotePreCheckout = ({ transaction, onChecklistComplete }) => (
  <Context.Consumer>
    {({ theme: { breakpoints } }) => (
      <div>
        <div
          css={css`
            display: flex;
            flex-direction: column;

            @media (min-width: ${breakpoints.lg}) {
              flex-direction: row;
            }
          `}
        >
          <div
            css={css`
              flex: 0 0 50%;
              padding: 0 1rem;
            `}
          >
            <KeyFeatures />
          </div>

          <div
            css={css`
              flex: 0 0 50%;
              padding: 0 1rem;
            `}
          >
            <QuoteSummary transaction={transaction} />
            <QuoteChecklist onChecklistComplete={onChecklistComplete} />
          </div>
        </div>
      </div>
    )}
  </Context.Consumer>
);

QuotePreCheckout.propTypes = {
  transaction: PropTypes.object.isRequired,
  onChecklistComplete: PropTypes.func.isRequired,
};

export default QuotePreCheckout;
