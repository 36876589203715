import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
  fetchPolicy,
  fetchPolicySuccess,
  fetchPolicyFailure,
} from '../../../../redux/modules/transaction';
import { trackEvent } from '../../../../redux/modules/tracking/actions';
import {
  selectSessionAuthToken,
  selectSessionQuery,
  selectPlatformUser,
} from '../../../../redux/modules/session/selectors';

import Checkout from './Checkout';

export default connect(
  (state, props) => ({
    token: selectSessionAuthToken(state),
    queryString: selectSessionQuery(state),
    organisationId:
      props.organisationId || state.transaction.organisation || null,
    platformUserId: selectPlatformUser(state),
  }),
  (dispatch, props) => ({
    fetchPolicy: props.fetchPolicy
      ? props.fetchPolicy
      : payload => dispatch(fetchPolicy(payload)),
    fetchPolicySuccess: payload => {
      dispatch(fetchPolicySuccess(payload));
    },
    fetchPolicyFailure: payload => {
      dispatch(fetchPolicyFailure(payload));
    },
    trackStripeCheckoutError: payload => {
      dispatch(
        trackEvent({
          dataKey: 'Stripe Checkout Error',
          dataValue: payload,
        }),
      );
    },
    push: payload => dispatch(push(payload)),
  }),
)(Checkout);
