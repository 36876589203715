import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import { Link } from 'react-router-dom';
import { Heading, Button, SupportLink } from '@hokodo/core';

const QuoteDeclined = ({ transaction }) => {
  const { t } = useTranslation('editInvoice');

  return (
    <div>
      <Heading level={4}>{t('declined.heading')}</Heading>
      <p>{t('declined.p1')}</p>
      <p>
        {t('declined.p2')} <SupportLink />
      </p>
      <div
        css={css`
          display: flex;
          align-items: center;
          button {
            margin-right: 1rem;
          }
        `}
      >
        <Link to={`/invoice/${transaction.id}`}>
          <Button>{t('declined.buttonRetry')}</Button>
        </Link>
        <Link to="/">{t('declined.backToDashboard')}</Link>
      </div>
    </div>
  );
};

QuoteDeclined.propTypes = {
  transaction: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

export default QuoteDeclined;
