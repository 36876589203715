import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import Context from '../../../Context';

const Input = ({
  type,
  placeholder,
  name,
  onChange,
  value,
  disabled,
  onFocus,
  styles,
}) => (
  <Context.Consumer>
    {({ theme: { padding, radius, border, fontSize } }) => {
      const base = css`
        border: ${border.default};
        border-radius: ${radius.default};
        padding: ${padding.input};
        font: inherit;
        font-size: ${fontSize.input};
        width: 100%;
      `;

      return (
        <input
          css={[base, styles]}
          name={name}
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          disabled={disabled}
          onFocus={onFocus}
        />
      );
    }}
  </Context.Consumer>
);

Input.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  onFocus: PropTypes.func,
  styles: PropTypes.object,
};

Input.defaultProps = {
  name: '',
  type: 'text',
  placeholder: '',
  value: '',
  onChange: f => f,
  disabled: false,
  onFocus: f => f,
  styles: css``,
};

export default Input;
