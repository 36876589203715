import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { history, isDev, EXPERIMENTAL_FEATURES } from '@environment';
import { observableRequest, segmentMiddleware } from '@hokodo/core';
import { middlewareConfig as segmentConfig } from '@config/segment';
import ui from './modules/ui';
import session from './modules/session';
import auth, { epic as authEpic } from './modules/auth';
import transactions, { epic as transactionsEpic } from './modules/transactions';
import claim, { epic as claimEpic } from './modules/claim';
import integrations, { epic as integrationsEpic } from './modules/integrations';
import user, { epic as userEpic } from './modules/user';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['session'],
};

const rootEpic = combineEpics(
  authEpic,
  claimEpic,
  integrationsEpic,
  transactionsEpic,
  userEpic,
);

const rootReducer = combineReducers({
  auth,
  claim,
  integrations,
  session,
  transactions,
  ui,
  user,
  router: connectRouter(history),
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const epicMiddleware = createEpicMiddleware({
  dependencies: { observableRequest },
});

let middleware = [epicMiddleware, routerMiddleware(history)];
if (EXPERIMENTAL_FEATURES) {
  middleware = [...middleware, segmentMiddleware(segmentConfig)];
}

const enableHMR = store => {
  if (isDev() && module.hot) {
    module.hot.accept('./index', () => {
      store.replaceReducer(rootReducer);
    });
  }
};

export default () => {
  const composeEnhancers = isDev() ? composeWithDevTools({}) : compose;

  const store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(...middleware)),
  );

  const persistor = persistStore(store);

  epicMiddleware.run(rootEpic);

  enableHMR(store);

  return { store, persistor };
};
