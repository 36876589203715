import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { pluck } from 'rxjs/operators';
import { Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import {
  observableRequest,
  APPLICATION_CONSTANTS,
  Heading,
  ServerErrors,
} from '@hokodo/core';
import { getTransactionStatus } from '@hokodo/core/lib/transactionHelpers';
import Form from './Form';

const { TRANSACTION_STATUSES } = APPLICATION_CONSTANTS;

class Invoice extends Component {
  state = {
    isFetching: false,
    serverErrors: null,
    redirectUrl: null,
  };

  saveTransaction = payload => {
    const { token, userOrganisation } = this.props;
    const owner = userOrganisation ? userOrganisation.id : null;
    const { transactionId, nextStep, ...rest } = payload;
    const method = transactionId ? 'PATCH' : 'POST';
    const url = transactionId
      ? `transactions/${transactionId}/`
      : 'transactions';

    this.setState({ isFetching: true });

    observableRequest({
      url,
      token,
      config: {
        method,
        body: { owner, ...rest },
      },
    })
      .pipe(pluck('response'))
      .subscribe(
        nextStep === 'quote'
          ? response => {
              this.getQuote(response);
            }
          : () => {
              this.setState({
                isFetching: false,
                redirectUrl: '/',
              });
            },
        this.onError,
      );
  };

  getQuote = ({ id }) => {
    const { token } = this.props;
    observableRequest({
      url: 'quotes/',
      token,
      config: {
        method: 'POST',
        body: { transaction: id },
      },
    })
      .pipe(pluck('response'))
      .subscribe(() => {
        this.setState({
          isFetching: false,
          redirectUrl: `/quote/${id}`,
        });
      }, this.onError);
  };

  onError = error => {
    this.setState({
      isFetching: false,
      serverErrors: error.response,
    });
  };

  render() {
    const { userOrganisation, transaction, t } = this.props;
    const { isFetching, serverErrors, redirectUrl } = this.state;

    if (redirectUrl) return <Redirect to={redirectUrl} />;

    if (
      transaction &&
      getTransactionStatus(transaction) === TRANSACTION_STATUSES.protected
    )
      return <Redirect to={`/policy/${transaction.id}`} />;

    return userOrganisation ? (
      <Fragment>
        <Heading level={1}>
          {t(transaction ? 'add.editHeading' : 'add.heading')}
        </Heading>
        {serverErrors && <ServerErrors errors={serverErrors} />}
        <Form
          action={this.saveTransaction}
          transaction={transaction}
          isFetching={isFetching}
        />
      </Fragment>
    ) : null;
  }
}

Invoice.propTypes = {
  transaction: PropTypes.object,
  token: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  userOrganisation: PropTypes.shape({
    id: PropTypes.string,
  }),
};

Invoice.defaultProps = {
  transaction: null,
  userOrganisation: null,
};

export default withTranslation('editInvoice')(Invoice);
