import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';

const SVG = ({ children, size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    {children}
  </svg>
);

SVG.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.number,
};

SVG.defaultProps = {
  size: 16,
};

/* eslint-disable max-len */
const Icon = ({ type, color, ...rest }) => {
  const colorStyle = color ? `color: ${color};` : '';
  return (
    <i
      css={css`
        ${colorStyle}
      `}
    >
      {(() => {
        switch (type) {
          case 'alert-circle':
            return (
              <SVG {...rest}>
                <circle cx="12" cy="12" r="10" />
                <line x1="12" y1="8" x2="12" y2="12" />
                <line x1="12" y1="16" x2="12" y2="16" />
              </SVG>
            );

          case 'alert-triangle':
            return (
              <SVG {...rest}>
                <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z" />
                <line x1="12" y1="9" x2="12" y2="13" />
                <line x1="12" y1="17" x2="12" y2="17" />
              </SVG>
            );

          case 'check':
            return (
              <SVG {...rest}>
                <polyline points="20 6 9 17 4 12" />
              </SVG>
            );

          case 'check-circle':
            return (
              <SVG {...rest}>
                <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                <polyline points="22 4 12 14.01 9 11.01" />
              </SVG>
            );

          case 'chevron-up':
            return (
              <SVG {...rest}>
                <polyline points="18 15 12 9 6 15" />
              </SVG>
            );

          case 'chevron-right':
            return (
              <SVG {...rest}>
                <polyline points="9 18 15 12 9 6" />
              </SVG>
            );

          case 'chevron-down':
            return (
              <SVG {...rest}>
                <polyline points="6 9 12 15 18 9" />
              </SVG>
            );

          case 'chevron-left':
            return (
              <SVG {...rest}>
                <polyline points="15 18 9 12 15 6" />
              </SVG>
            );

          case 'chevrons-right':
            return (
              <SVG {...rest}>
                <polyline points="13 17 18 12 13 7" />
                <polyline points="6 17 11 12 6 7" />
              </SVG>
            );

          case 'chevrons-left':
            return (
              <SVG {...rest}>
                <polyline points="11 17 6 12 11 7" />
                <polyline points="18 17 13 12 18 7" />
              </SVG>
            );

          case 'edit':
            return (
              <SVG {...rest}>
                <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
                <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
              </SVG>
            );

          case 'eye':
            return (
              <SVG {...rest}>
                <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
                <circle cx="12" cy="12" r="3" />
              </SVG>
            );

          case 'eye-off':
            return (
              <SVG {...rest}>
                <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24" />
                <line x1="1" y1="1" x2="23" y2="23" />
              </SVG>
            );

          case 'file':
            return (
              <SVG {...rest}>
                <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z" />
                <polyline points="13 2 13 9 20 9" />
              </SVG>
            );

          case 'help-circle':
            return (
              <SVG {...rest}>
                <circle cx="12" cy="12" r="10" />
                <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
                <line x1="12" y1="17" x2="12" y2="17" />
              </SVG>
            );

          case 'mail':
            return (
              <SVG {...rest}>
                <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
                <polyline points="22,6 12,13 2,6" />
              </SVG>
            );

          case 'phone':
            return (
              <SVG {...rest}>
                <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
              </SVG>
            );

          case 'refresh-cw':
            return (
              <SVG {...rest}>
                <polyline points="23 4 23 10 17 10" />
                <polyline points="1 20 1 14 7 14" />
                <path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15" />
              </SVG>
            );

          case 'settings':
            return (
              <SVG {...rest}>
                <circle cx="12" cy="12" r="3" />
                <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z" />
              </SVG>
            );

          case 'slash':
            return (
              <SVG {...rest}>
                <circle cx="12" cy="12" r="10" />
                <line x1="4.93" y1="4.93" x2="19.07" y2="19.07" />
              </SVG>
            );

          case 'upload':
            return (
              <SVG {...rest}>
                <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                <polyline points="17 8 12 3 7 8" />
                <line x1="12" y1="3" x2="12" y2="15" />
              </SVG>
            );

          case 'user':
            return (
              <SVG {...rest}>
                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                <circle cx="12" cy="7" r="4" />
              </SVG>
            );

          case 'watch':
            return (
              <SVG {...rest}>
                <circle cx="12" cy="12" r="7" />
                <polyline points="12 9 12 12 13.5 13.5" />
                <path d="M16.51 17.35l-.35 3.83a2 2 0 0 1-2 1.82H9.83a2 2 0 0 1-2-1.82l-.35-3.83m.01-10.7l.35-3.83A2 2 0 0 1 9.83 1h4.35a2 2 0 0 1 2 1.82l.35 3.83" />
              </SVG>
            );

          case 'x':
            return (
              <SVG {...rest}>
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </SVG>
            );

          default:
            return null;
        }
      })()}
    </i>
  );
};

Icon.propTypes = {
  type: PropTypes.string,
  color: PropTypes.string,
};

Icon.defaultProps = {
  type: '',
  color: '',
};

export default Icon;
