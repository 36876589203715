import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import Heading from '../../atoms/Heading';
import Icon from '../../atoms/Icon';
import Context from '../../../Context';

const AccordionItem = ({ active, content, index, onClick }) => {
  const handleClick = event => {
    event.preventDefault();
    onClick(index);
  };

  return (
    <Context.Consumer>
      {({ theme: { border } }) => (
        <div
          css={css`
            border-top: ${border.default};
          `}
        >
          <button
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
              padding: 0.5rem 0;
              border: none;
              background-color: transparent;
              cursor: pointer;
              max-height: none;
              > * {
                margin: 0;
                text-align: left;
              }
            `}
            type="button"
            onClick={handleClick}
          >
            <Heading level={2}>{content.title}</Heading>
            <Icon size={24} type={active ? 'chevron-up' : 'chevron-down'} />
          </button>

          {active && (
            <div
              css={css`
                padding: 1rem 0;
              `}
            >
              {content.body}
            </div>
          )}
        </div>
      )}
    </Context.Consumer>
  );
};

AccordionItem.propTypes = {
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  content: PropTypes.shape({
    title: PropTypes.string,
    body: PropTypes.node,
  }),
  index: PropTypes.number.isRequired,
};

AccordionItem.defaultProps = {
  content: {
    title: '',
    body: '',
  },
};

export default AccordionItem;
