import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import Context from '../../../Context';

const WrappedElement = ({ color, underline, uppercase, children }) => (
  <Context.Consumer>
    {({ theme: { colors } }) => (
      <span
        css={css`
          display: inline-block;
          color: ${color || 'inherit'};
          ${uppercase && 'text-transform: uppercase;'}
          ${underline &&
            `
            padding-bottom: .5rem;
            border-bottom: 3px solid ${colors.primary};
          `}
        `}
      >
        {children}
      </span>
    )}
  </Context.Consumer>
);

WrappedElement.propTypes = {
  children: PropTypes.node.isRequired,
  underline: PropTypes.bool,
  uppercase: PropTypes.bool,
  color: PropTypes.string,
};

WrappedElement.defaultProps = {
  underline: false,
  uppercase: false,
  color: '',
};

const Heading = ({ level, centered, ...rest }) => {
  const props = centered
    ? {
        style: { textAlign: 'center' },
      }
    : {};
  return React.createElement(`h${level}`, props, WrappedElement(rest));
};

Heading.propTypes = {
  level: PropTypes.oneOf([1, 2, 3, 4, 5]),
  centered: PropTypes.bool,
};
Heading.defaultProps = {
  level: 1,
  centered: false,
};

export default Heading;
