import * as actions from './actions';

export const initialState = {
  aut: '',
};

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actions.CREATE_SESSION:
      return {
        ...state,
        aut: payload.token,
      };

    case actions.DESTROY_SESSION:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
