import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  login,
  selectLoginErrors,
  selectIsFetching,
} from '@redux/modules/auth';
import Login from './Login';

export default withRouter(
  connect(
    state => ({
      isFetching: selectIsFetching(state),
      serverErrors: selectLoginErrors(state),
    }),
    {
      login,
    },
  )(Login),
);
