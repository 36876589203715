import React from 'react';
import PropTypes from 'prop-types';
import Context from '../../../Context';

const LinkButton = ({ lang, label, value, onClick }) => {
  const handleClick = event => {
    event.preventDefault();
    onClick(value);
  };
  return lang === value ? (
    <span>{label}</span>
  ) : (
    <a href="#" role="button" onClick={handleClick}>
      {label}
    </a>
  );
};

LinkButton.propTypes = {
  lang: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

const Component = ({ lang, setLanguage }) => {
  const handleClick = value => {
    setLanguage(value);
  };

  return (
    <ul>
      <li>
        <LinkButton
          label="English"
          value="en-GB"
          lang={lang}
          onClick={handleClick}
        />
      </li>
      <li>
        <LinkButton
          label="Français"
          value="fr-FR"
          lang={lang}
          onClick={handleClick}
        />
      </li>
      <li>
        <LinkButton
          label="Deutsch"
          value="de-DE"
          lang={lang}
          onClick={handleClick}
        />
      </li>
    </ul>
  );
};

Component.propTypes = {
  lang: PropTypes.string.isRequired,
  setLanguage: PropTypes.func.isRequired,
};

const LanguageSelect = () => (
  <Context.Consumer>
    {({ features, lang, setLanguage }) =>
      features.multiLanguage ? (
        <Component lang={lang} setLanguage={setLanguage} />
      ) : null
    }
  </Context.Consumer>
);

export default LanguageSelect;
