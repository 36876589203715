import React from 'react';
import { useTranslation } from 'react-i18next';
import OauthSync from '../common/OauthSync';
import logo from './quickbooks.png';
import step from './step.png';

const Quickbooks = () => {
  const { t } = useTranslation('integrations');

  return (
    <OauthSync platform="quickbooks" logo={logo}>
      <p>{t('quickbooks.connect.step1')}</p>
      <img alt="" src={step} />
      <p>{t('quickbooks.connect.step2')}</p>
    </OauthSync>
  );
};

export default Quickbooks;
