import { createPrefix, createAction } from '@hokodo/core/lib/actionHelpers';

const prefix = createPrefix('claim');

export const FETCH_CLAIM = prefix('FETCH_CLAIM');
export const FETCH_CLAIM_SUCCESS = prefix('FETCH_CLAIM_SUCCESS');
export const FETCH_CLAIM_FAILURE = prefix('FETCH_CLAIM_FAILURE');

export const fetchClaim = createAction(FETCH_CLAIM);
export const fetchClaimSuccess = createAction(FETCH_CLAIM_SUCCESS);
export const fetchClaimFailure = createAction(FETCH_CLAIM_FAILURE);

export const CREATE_CLAIM = prefix('CREATE_CLAIM');
export const CREATE_CLAIM_SUCCESS = prefix('CREATE_CLAIM_SUCCESS');
export const CREATE_CLAIM_FAILURE = prefix('CREATE_CLAIM_FAILURE');

export const createClaim = createAction(CREATE_CLAIM);
export const createClaimSuccess = createAction(CREATE_CLAIM_SUCCESS);
export const createClaimFailure = createAction(CREATE_CLAIM_FAILURE);

export const UPDATE_CLAIM = prefix('UPDATE_CLAIM');
export const UPDATE_CLAIM_SUCCESS = prefix('UPDATE_CLAIM_SUCCESS');
export const UPDATE_CLAIM_FAILURE = prefix('UPDATE_CLAIM_FAILURE');

export const updateClaim = createAction(UPDATE_CLAIM);
export const updateClaimSuccess = createAction(UPDATE_CLAIM_SUCCESS);
export const updateClaimFailure = createAction(UPDATE_CLAIM_FAILURE);

export const SUBMIT_CLAIM = prefix('SUBMIT_CLAIM');
export const SUBMIT_CLAIM_SUCCESS = prefix('SUBMIT_CLAIM_SUCCESS');
export const SUBMIT_CLAIM_FAILURE = prefix('SUBMIT_CLAIM_FAILURE');

export const submitClaim = createAction(SUBMIT_CLAIM);
export const submitClaimSuccess = createAction(SUBMIT_CLAIM_SUCCESS);
export const submitClaimFailure = createAction(SUBMIT_CLAIM_FAILURE);

export const SET_FORM_STEP = prefix('SET_FORM_STEP');
export const setFormStep = createAction(SET_FORM_STEP);
