import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import {
  useStripe,
  useElements,
  CardNumberElement,
} from '@stripe/react-stripe-js';
import {
  Button,
  CardPaymentMethod,
  ServerErrors,
  useSetupPaymentIntent,
} from '@hokodo/core';
import { createAction } from '@hokodo/core/lib/actionHelpers';

const initialState = {
  stripeFetching: false,
  errors: {
    stripeErrors: null,
    fieldErrors: null,
  },
  fieldValues: {
    cardname: '',
    postcode: '',
    saveCardDetails: false,
  },
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'SET_STRIPE_FETCHING':
      return { ...state, stripeFetching: payload };
    case 'SET_ERRORS':
      return {
        ...state,
        errors: {
          ...state.errors,
          ...payload,
        },
      };
    case 'SET_FIELD_VALUES':
      return {
        ...state,
        fieldValues: {
          ...state.fieldValues,
          ...payload,
        },
      };
    default:
      return state;
  }
};

const setStripeFetching = createAction('SET_STRIPE_FETCHING');
const setErrors = createAction('SET_ERRORS');
const setFieldValues = createAction('SET_FIELD_VALUES');

const AddCardPaymentMethod = ({
  organisationId,
  token,
  hideModal,
  onAddPaymentMethod,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const { t } = useTranslation(['checkout', 'account']);

  const { error: fetchSecretError, secret } = useSetupPaymentIntent({
    organisationId,
    token,
  });

  const [state, dispatch] = useReducer(reducer, initialState);

  const onError = e => {
    const formattedErr = { [e.type]: e.message };
    dispatch(setStripeFetching(false));
    dispatch(
      setErrors({
        stripeErrors: formattedErr,
      }),
    );
  };

  const onSubmit = async () => {
    const { cardname, postcode } = state.fieldValues;
    if (!cardname.length || !postcode.length) {
      dispatch(
        setErrors({
          fieldErrors: {
            cardname: cardname.length ? null : t('payment.errorCardname'),
            postcode: postcode.length ? null : t('payment.errorPostcode'),
          },
        }),
      );
      return;
    }

    dispatch(
      setErrors({
        fieldErrors: null,
        stripeErrors: null,
      }),
    );
    dispatch(setStripeFetching(true));

    const cardElement = elements.getElement(CardNumberElement);

    const { setupIntent, error } = await stripe.confirmCardSetup(secret, {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: cardname,
          address: {
            postal_code: postcode,
          },
        },
      },
    });
    if (error) {
      dispatch(setStripeFetching(false));
      onError(error);
    }
    if (setupIntent) {
      hideModal();
      onAddPaymentMethod(setupIntent);
    }
  };

  const combinedErrors = () => {
    const {
      fieldErrors = {},
      stripeErrors = {},
      serverErrors = {},
    } = state.errors;
    const messages = { ...fieldErrors, ...stripeErrors, ...serverErrors };
    return Object.keys(messages).length ? (
      <ServerErrors errors={messages} />
    ) : null;
  };

  const onFieldValueChange = value => {
    dispatch(setFieldValues(value));
  };

  if (fetchSecretError) {
    return <ServerErrors errors={fetchSecretError} />;
  }

  return secret ? (
    <div
      css={css`
        max-width: 650px;
        margin: auto;
      `}
    >
      {combinedErrors()}

      <CardPaymentMethod
        errors={state.errors}
        setFieldValue={onFieldValueChange}
        fieldValues={state.fieldValues}
      />

      <div
        css={css`
          text-align: right;
          button:first-of-type {
            margin: 0 0.5rem 0 0;
          }
        `}
      >
        <Button
          disabled={!stripe || state.stripeFetching}
          className={state.stripeFetching ? 'spinner' : ''}
          onClick={onSubmit}
        >
          {t('account:paymentSettings.submit')}
        </Button>
        <Button
          disabled={state.stripeFetching}
          onClick={() => {
            hideModal();
          }}
        >
          {t('account:paymentSettings.cancel')}
        </Button>
      </div>
    </div>
  ) : null;
};

AddCardPaymentMethod.propTypes = {
  organisationId: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  hideModal: PropTypes.func.isRequired,
  onAddPaymentMethod: PropTypes.func.isRequired,
};

AddCardPaymentMethod.defaultProps = {};

export default AddCardPaymentMethod;
