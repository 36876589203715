/* eslint-disable react/jsx-boolean-value */

import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import Tippy from '@tippyjs/react';
import Context from '../../../Context';
import Icon from '../Icon';
import 'tippy.js/dist/tippy.css';


const Tooltip = ({ title, description, interactive }) => {
  /*
   This component is based on the package below
   https://github.com/atomiks/tippyjs-react

   Unfortunately, there is a similarly named package: https://github.com/tvkhoa/react-tippy

   Note:
     Ideally all Tooltips should be interactive.
     However, in certain cases this can introduce some styling problems (see DetailsSummary).
     For now, set <Tooltip interactive={false} /> in these cases.

     Some Tooltips should contain formatting such as bullet points.
     In the future, we will include a content prop, which
     will pass component directly to Tippy's own content prop for rendering.

  */
  return (
    <Context.Consumer>
      {() => {
        return (
          <Tippy
            className="custom-react-tippy-theme"
            allowHTML={true}
            interactive={interactive}
            arrow
            content={(
              <div css={css`
                > header {
                  font-weight: bold;
                  margin-bottom: 1rem;
                }
                `}>
                <header>{title}</header>
                <p>{description}</p>
              </div>
            )}
          >
            <span>
              <Icon type='help-circle' />
            </span>
          </Tippy>
        );
      }}
    </Context.Consumer>
  );
};

Tooltip.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  interactive: PropTypes.bool,
};

Tooltip.defaultProps = {
  title: "",
  description: "",
  interactive: true,
};


export default Tooltip;
