import { connect } from 'react-redux';
import { selectModal, hideModal, showModal } from '@redux/modules/ui/modal';
import Modal from './Modal';

export default connect(
  state => ({
    modal: selectModal(state),
  }),
  {
    showModal,
    hideModal,
  },
)(Modal);
