/* eslint-disable max-len */
export default {
  invoiceProtection: {
    img: {
      src: 'protect-cash.svg',
      alt: 'amount collection',
    },
    text: `
### Rechnungsabsicherung 
Sie sind abgesichert, wenn Ihr Kunde zahlungsunfähig wird oder die Rechnung nicht begleicht.

Der Schutz deckt bis zu 90% des Gesamtbetrags Ihrer Rechnung (exkl. MwSt.) und nur rechtsgültige Rechnungen ab.


`,
  },
  onlyValid: {
    img: {
      src: 'amount-insolvency.svg',
      alt: 'amount insolvency',
    },
    text: `
### Nur rechtsgültige Rechnungen 
Wenn Ihr Kunde die Rechnung bestreitet, muss dieser Streit zu Ihren Gunsten beigelegt werden, bevor wir eine Forderung bezahlen.
`,
  },
  proofOfPruchase: {
    img: {
      src: 'buyer-agreement.svg',
      alt: 'buyer insolvent',
    },
    text: `
### Kaufbelege
Sie benötigen einen Kaufbeleg oder eine andere Auftragsbestätigung. Bei mündlichem Nachweis können wir Sie trotzdem absichern. Wir werden jedoch keine Forderung begleichen, wenn die Rechnung strittig ist.
`,
  },
  existingDistress: {
    img: {
      src: 'buyer-insolvent.svg',
      alt: 'amount collection',
    },
    text: `
### Kunde in Notlage? 
Wenn Ihnen bekannt ist, dass Ihr Kunde insolvent ist, sich in finanzieller Notlage befindet, wahrscheinlich zahlungsunfähig ist oder andere Rechnungen, die mehr als 45 Tage überfällig sind bei Ihnen hat, sollten Sie keine Rechnungsabsicherung abschließen.
`,
  },
  recentInvoice: {
    img: {
      src: 'date-invoice.svg',
      alt: 'date invoice',
    },
    text: `
### Aktuelle Rechnung 
Die Rechnung muss innerhalb von 30 Tagen ab dem Datum, an dem Sie die Lieferung der Waren oder Dienstleistungen abgeschlossen haben, ausgestellt worden sein. 

Vergessen Sie nicht, Ihre Rechnung an unseren Inkassopartner zu übergeben, sobald das Fälligkeitsdatum 45 Tage überschritten wurde. 
`,
  },
  passOnTime: {
    img: {
      src: 'collection-invoice.svg',
      alt: 'collection invoice insurer',
    },
    text: `
### Übergeben Sie Ihre Rechnungen pünktlich 
Wenn Ihre Rechnung 45 Tage über das Fälligkeitsdatum hinausgeht, übergeben Sie diese umgehend an unseren Inkassopartner. Wir werden Sie hieran erinnern. So stellen wir sicher, dass Sie berechtigt sind, 90 % des ausstehenden Betrags einzufordern, wenn die Eintreibung durch das Inkasso fehlschlägt.
    `,
  },
  carefulDelivery: {
    img: {
      src: 'product-transit.svg',
      alt: 'date invoice',
    },
    text: `
### Vorsicht bei der Lieferung
Die Beschädigung oder der Verlust von Waren auf dem Transportweg ist nicht abgesichert. Ihr Kunde könnte die Gültigkeit der Rechnung in diesem Fall anfechten.
    `,
  },
  weCollectPayments: {
    img: {
      src: 'amount-insolvency.svg',
      alt: 'amount collection',
    },
    text: `
### Wir kümmern uns um die Bezahlung Ihrer Rechnung
Geben Sie Ihre Rechnung zur Eintreibung einfach an uns weiter, sobald sie 45 Tage über das Fälligkeitsdatum hinausgeht. Wir kümmern uns um alles weitere.

Nutzen Sie unseren Inkassodienst zu einem Vorzugstarif von {{COLLECTION_PERC}}% + MwSt. Erfolgsgebühr bei erfolgreichem Inkasso.
    `,
  },
  readPolicyTerms: {
    img: {
      src: 'buyer-agreement.svg',
      alt: 'amount collection',
    },
    text: `
### Prüfen Sie unsere Versicherungsbedingungen

Bitte stellen Sie sicher, dass Sie über die [Bedingungen]({{FAQ_URL}}) und \
[Inhalte]({{SPECIMEN_POLICY}})
der Rechnungsabsicherung informiert sind.

Sie können Ihre Police innerhalb der ersten 72 Stunden stornieren. In diesem Fall erhalten Sie die volle Rückerstattung.
    `,
  },
};
