import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import { padding, border } from '@styles/theme';
import { Heading, Button } from '@hokodo/core';
import CompanyMatch from '../CompanyMatch';
import ConnectedList from '../ConnectedList';

const OauthSync = ({
  platform,
  oauthSynchronise,
  oauthConnect,
  isFetching,
  error,
  platformMemberships,
  setCurrentMembership,
  children,
  logo,
}) => {
  const { t } = useTranslation('integrations');

  const handleImportClick = () => {
    setCurrentMembership({ organisation: platformMemberships[0].organisation });
    oauthSynchronise({ platform });
  };

  const handleConnectClick = () => {
    oauthConnect({ platform });
  };

  const ErrorMessage = () =>
    error && error.hasOwnProperty('detail') ? <p>{error.detail}</p> : null;

  if (platformMemberships) {
    const unmatched = platformMemberships.filter(m => !m.organisation.company);

    return (
      <div
        css={css`
          padding: ${padding.modal};
        `}
      >
        <Heading level={1}>{t(`${platform}.title`)}</Heading>

        <div
          css={css`
            display: flex;
            > div {
              width: 50%;
            }
          `}
        >
          <div>
            {platformMemberships.length ? (
              unmatched.length ? (
                <CompanyMatch unmatchedMemberships={unmatched} />
              ) : (
                <Fragment>
                  <Heading level={5}>{t(`${platform}.sync.title`)}</Heading>

                  <ErrorMessage />

                  <Button
                    onClick={handleImportClick}
                    disabled={isFetching}
                    className={isFetching ? 'spinner' : ''}
                  >
                    {isFetching
                      ? t(`${platform}.sync.buttonSyncActive`)
                      : t(`${platform}.sync.buttonSync`)}
                  </Button>

                  <ConnectedList
                    platformMemberships={platformMemberships}
                    title={t(`${platform}.sync.connectedListTitle`)}
                  />

                  <Button onClick={handleConnectClick} disabled={isFetching}>
                    {t(`${platform}.sync.buttonConnect`)}
                  </Button>
                </Fragment>
              )
            ) : (
              <Fragment>
                <Heading level={5}>{t(`${platform}.connect.title`)}</Heading>

                <ErrorMessage />

                <p>{t(`${platform}.connect.description`)}</p>

                <div
                  css={css`
                    > p {
                      font-weight: 600;
                    }
                    > img {
                      border: ${border.default};
                      margin-bottom: 2rem;
                      width: 340px;
                    }
                  `}
                >
                  {children}
                </div>

                <Button onClick={handleConnectClick} disabled={isFetching}>
                  {t(`${platform}.connect.buttonConnect`)}
                </Button>
              </Fragment>
            )}
          </div>

          <div
            css={css`
              text-align: center;
            `}
          >
            {logo && (
              <img
                alt=""
                src={logo}
                css={css`
                  width: auto;
                  max-width: 200px;
                `}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
  return null;
};

OauthSync.propTypes = {
  platform: PropTypes.string.isRequired,
  oauthSynchronise: PropTypes.func.isRequired,
  oauthConnect: PropTypes.func.isRequired,
  error: PropTypes.shape({
    detail: PropTypes.any,
  }),
  isFetching: PropTypes.bool,
  platformMemberships: PropTypes.array,
  setCurrentMembership: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  logo: PropTypes.node,
};

OauthSync.defaultProps = {
  error: null,
  platformMemberships: null,
  logo: null,
  isFetching: false,
};

export default OauthSync;
