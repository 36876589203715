import { createPrefix, createAction } from '../../../../lib/actionHelpers';

const prefix = createPrefix('transaction');

export const FETCH_TRANSACTION = prefix('FETCH_TRANSACTION');
export const FETCH_TRANSACTION_SUCCESS = prefix('FETCH_TRANSACTION_SUCCESS');
export const FETCH_TRANSACTION_FAILURE = prefix('FETCH_TRANSACTION_FAILURE');

export const fetchTransaction = createAction(FETCH_TRANSACTION);
export const fetchTransactionSuccess = createAction(FETCH_TRANSACTION_SUCCESS);
export const fetchTransactionFailure = createAction(FETCH_TRANSACTION_FAILURE);

export const UPDATE_TRANSACTION = prefix('UPDATE_TRANSACTION');
export const UPDATE_TRANSACTION_SUCCESS = prefix('UPDATE_TRANSACTION_SUCCESS');
export const UPDATE_TRANSACTION_FAILURE = prefix('UPDATE_TRANSACTION_FAILURE');

export const updateTransaction = createAction(UPDATE_TRANSACTION);
export const updateTransactionSuccess = createAction(
  UPDATE_TRANSACTION_SUCCESS,
);
export const updateTransactionFailure = createAction(
  UPDATE_TRANSACTION_FAILURE,
);

export const FETCH_QUOTE = prefix('FETCH_QUOTE');
export const FETCH_QUOTE_SUCCESS = prefix('FETCH_QUOTE_SUCCESS');
export const FETCH_QUOTE_FAILURE = prefix('FETCH_QUOTE_FAILURE');
export const FETCH_EXPANDED_QUOTE = prefix('FETCH_EXPANDED_QUOTE');
export const FETCH_EXPIRED_QUOTE = prefix('FETCH_EXPIRED_QUOTE');

export const fetchQuote = createAction(FETCH_QUOTE);
export const fetchQuoteSuccess = createAction(FETCH_QUOTE_SUCCESS);
export const fetchQuoteFailure = createAction(FETCH_QUOTE_FAILURE);
export const fetchExpandedQuote = createAction(FETCH_EXPANDED_QUOTE);
export const fetchExpiredQuote = createAction(FETCH_EXPIRED_QUOTE);

export const FETCH_POLICY = prefix('FETCH_POLICY');
export const FETCH_POLICY_SUCCESS = prefix('FETCH_POLICY_SUCCESS');
export const FETCH_POLICY_FAILURE = prefix('FETCH_POLICY_FAILURE');

export const fetchPolicy = createAction(FETCH_POLICY);
export const fetchPolicySuccess = createAction(FETCH_POLICY_SUCCESS);
export const fetchPolicyFailure = createAction(FETCH_POLICY_FAILURE);
