import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field } from 'formik';
import {
  Context,
  TextField,
  PasswordField,
  Heading,
  Button,
} from '@hokodo/core';
import { getValidationSchema } from '@hokodo/core/lib/formHelpers';
import { css } from '@emotion/core';
import { colors, radius } from '@styles/theme';
import demoScreen from '@assets/img/kashflow_screen.png';

const Kashflow = ({ importInvoices, isFetching, error }) => {
  const { t } = useTranslation('integrations');

  return (
    <Context.Consumer>
      {({ lang }) => (
        <div
          css={css`
            display: flex;
            > div {
              width: 50%;
              padding: 1rem;
            }
          `}
        >
          <div>
            <Heading level={1}>{t('kashflow.title')}</Heading>
            <p>{t('kashflow.info')}</p>
            <div
              css={css`
                color: ${colors.warning.text};
                border: 1px solid ${colors.warning.text};
                background-color: ${colors.warning.bg};
                margin-bottom: 1rem;
                border-radius: ${radius.default};
                padding: 1rem;
              `}
            >
              <Heading level={5}>{t('kashflow.alertTitle')}</Heading>
              <div
                dangerouslySetInnerHTML={{
                  __html: t('kashflow.alert', {
                    interpolation: { escapeValue: false },
                  }),
                }}
              />
            </div>
            <Formik
              initialValues={{
                username: '',
                password: '',
              }}
              validationSchema={getValidationSchema(
                'KashflowLoginSchema',
                lang,
              )}
              onSubmit={(values, { setSubmitting }) => {
                importInvoices(values);
                setSubmitting(false);
              }}
              render={({ isSubmitting }) => {
                return (
                  <Form>
                    <Field
                      name="username"
                      label={t('kashflow.userName')}
                      component={TextField}
                    />

                    <Field
                      name="password"
                      label={t('kashflow.password')}
                      component={PasswordField}
                    />

                    {error && error.hasOwnProperty('detail') && (
                      <p
                        css={css`
                          color: ${colors.error.text};
                        `}
                      >
                        {error.detail}
                      </p>
                    )}

                    <Button
                      disabled={isFetching || isSubmitting}
                      type="submit"
                      className={isFetching ? 'spinner' : ''}
                    >
                      {isFetching
                        ? t('common.importButtonFetching')
                        : t('common.importButton')}
                    </Button>
                  </Form>
                );
              }}
            />
          </div>

          <div
            css={css`
              background-color: #d73660;
              overflow: hidden;
            `}
          >
            <img alt="Demo screen" src={demoScreen} />
          </div>
        </div>
      )}
    </Context.Consumer>
  );
};

Kashflow.propTypes = {
  importInvoices: PropTypes.func.isRequired,
  error: PropTypes.object,
  isFetching: PropTypes.bool.isRequired,
};

Kashflow.defaultProps = {
  error: null,
};

export default Kashflow;
