import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import Icon from '../../atoms/Icon';
import Context from '../../../Context';

const Tooltip = ({ children, element }) => {
  const [active, setActive] = useState(false);

  const handleActivate = () => {
    setActive(!active);
  };

  return (
    <Context.Consumer>
      {({ theme: { colors, border, shadow, radius, padding, zIndex } }) => (
        <div
          onMouseOver={handleActivate}
          onMouseOut={handleActivate}
          onFocus={handleActivate}
          onBlur={handleActivate}
          css={css`
            position: relative;
            display: ${element ? 'block' : 'inline-block'};
            color: inherit;
          `}
        >
          {element || <Icon type="help-circle" />}
          {active && (
            <div
              className="tooltip"
              css={css`
                position: absolute;
                z-index: ${zIndex.tooltip};
                left: calc(200px / -2 + 8px);
                ${element &&
                  `
                left: auto;
                top: 20px;
                right: 10px;
                `}
                width: 200px;
                background-color: ${colors.white};
                border-radius: ${radius.default};
                border: ${border.default};
                padding: ${padding.tooltip};
                box-shadow: ${shadow.default};
                text-align: left;
              `}
            >
              {children}
            </div>
          )}
        </div>
      )}
    </Context.Consumer>
  );
};

Tooltip.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  element: PropTypes.node,
};

Tooltip.defaultProps = {
  children: '',
  element: null,
};

export default Tooltip;
