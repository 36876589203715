/* eslint-disable max-len */
export default [
  {
    title: 'Quels sont les risques couverts par HOKODO ?',
    body: `
Nous vous protégeons contre le risque de non-paiement de vos factures ou de faillite de l’un de vos clients.

En revanche, nous ne vous protégeons pas contre :

* Le non-paiement d'une facture faisant l’objet d’un litige : vous devrez régler le litige avec votre client avant que nous puissions traiter votre réclamation ;
* Les pertes ou les dommages subis par les marchandises en transit ;
* Le non-paiement d’une facture en raison de mesures gouvernementales, telle que la guerre, la réquisition de biens, le contrôle des changes, etc ;
* Les réclamations revêtant un caractère frauduleux ou malhonnête ;
* Les réclamations qui nous exposeraient à des sanctions ou nous obligeraient à enfreindre la réglementation de lutte contre le blanchiment d’argent.
    `,
  },
  {
    title: 'Comment ça marche ?',
    body: `
* Une fois votre facture arrivée à échéance, vous tentez de récupérer le montant qui vous est dû comme à votre habitude.
* Si votre client n’a toujours pas réglé votre facture dans les 45 jours suivant la date d’échéance, nous vous invitons à transmettre votre facture à notre prestataire de recouvrement qui tentera de recouvrer la dette pour votre compte.
* En cas de succès, le montant collecté vous sera versé déduction faite des frais de recouvrement ({{COLLECTION_PERC}}% + TVA). Dans l’éventualité où notre prestataire de recouvrement serait en mesure de récupérer ces frais auprès de votre client, vous ne serez pas redevable de ces derniers et le montant collecté vous sera versé en intégralité.
* Dans l’éventualité où notre prestataire de recouvrement ne parviendrait pas à collecter les sommes dues dans un délai de 60 jours suivant la transmission de votre facture, vous serez alors indemnisés à hauteur de 90% du montant HT de la facture.
    `,
  },
  {
    title: 'Qui est en charge du recouvrement ?',
    body: `
* Le service de recouvrement est fourni par notre prestataire de recouvrement. Il est obligatoire d’utiliser le prestataire de recouvrement de notre choix afin de pouvoir effectuer une réclamation. Si cela ne vous convenait pas, nous vous recommandons de ne pas procéder à l’achat d’une police d’assurance auprès d’Hokodo.
* Si votre acheteur ne procède pas au règlement de votre facture dans les 45 jours suivant sa date d’échéance, vous bénéficiez de 30 jours pour confier le recouvrement du montant dû à notre prestataire de recouvrement. Cela équivaut à 75 jours de retard pendant lesquels vous pouvez, si vous le souhaitez, tenter de recouvrer les sommes dues sans intervention de notre part.
* Veuillez noter que votre demande d’indemnisation ne sera recevable que si elle a bien été effectuée dans les délais impartis, soit dans les 30 jours suivant le retard de paiement de 45 jours.
* Si le montant dû est recouvré en tout ou partie par notre prestataire de recouvrement, le montant collecté vous sera versé, déduction faite des frais de recouvrement associés ({{COLLECTION_PERC}}% du montant de la facture + TVA).
* Si malgré les efforts entrepris par notre Prestataire de Recouvrement, le montant de la créance assurée ne peut être collecté dans son intégralité, nous vous verserons alors une indemnité équivalente à 90% de la valeur nette de la facture.
    `,
  },
  {
    title: 'Que faire en cas de litige ?',
    body: `
* Il se peut que votre acheteur conteste la facture qui lui a été transmise. Les raisons de cette contestation peuvent être diverses : il peut s’agir par exemple d’une livraison défectueuse, de mauvaise qualité, ou de marchandises non conformes à la commande.
* En premier lieu, nous vous recommandons de chercher une solution amiable avec votre acheteur. Si la facture n’a pas été réglée dans les 45 jours suivant sa date d’échéance, vous devrez alors confier le recouvrement de la totalité du montant dû par l’acheteur à notre prestataire de recouvrement, et lui faire part du litige en cours. Notre prestataire de recouvrement tentera de résoudre le litige dans le cadre de leur processus de recouvrement et, dans la plupart des cas, sera en mesure de parvenir à une solution amiable.
* Si le litige ne peut être résolu à l’amiable, vous devrez sans doute engager une procédure judiciaire à l'encontre de votre débiteur afin de démontrer que la créance est valide et exécutoire. Notre prestataire de recouvrement saura vous guider dans vos démarches et vous présenter les différentes options qui s’offrent à vous.
* Tant que le litige n’est pas résolu, vous ne pourrez pas faire de réclamation. Attention, pour l’action en paiement de l’indemnité, le délai de prescription d'un an court à compter de la date d'échéance de votre créance. 
* Une fois le litige résolu, et ce de quelque manière que ce soit, votre garantie sera de nouveau valable.
* Si votre acheteur ne parvient toujours pas à vous régler le montant dû au titre de la créance assurée, nous vous verserons une indemnité pouvant s’élever à hauteur de 90% de la valeur nette de la facture, ou du montant convenu entre vous, lequel étant le plus faible.
    `,
  },
  {
    title: 'Quelles sont mes obligations ?',
    body: `
* Vous devez nous fournir une facture valide, émise dans les 30 jours suivant la livraison des biens et/ou des services. Celle-ci doit être accompagnée d’une preuve que l’Acheteur a confirmé son achat, tel qu’un bon de commande, une confirmation écrite et/ou un contrat liant les parties.
  * En cas d’accord verbal entre vous et votre client, vous pouvez procéder à l’achat d’une police d’assurance, cependant, nous ne serons pas en mesure de vous indemniser si votre client conteste le fait même d’avoir effectué une commande.

* Vous devez confirmer que l’acheteur n’était pas, à votre connaissance, insolvable ou dans l’incapacité de payer votre facture au moment de l'achat de la police, et qu’il n’était pas débiteur à votre égard de tout ou partie du montant d’une facture antérieure demeurée impayée plus de 45 jours au-delà de son échéance.
  * Nous n’attendons pas de vous que vous meniez une enquête approfondie sur la solvabilité de votre client ; mais, si dans le cadre de vos vérifications et procédures de contrôles habituels, vous découvrez que celui-ci ne sera probablement pas en mesure de régler le montant dû au titre de la facture émise, vous ne devrez pas procéder à l’achat d’une police d’assurance CashProtect auprès d’Hokodo.

* Vous devez prendre toutes les mesures nécessaires en vue d'obtenir le paiement par l'acheteur et prévenir et minimiser les pertes. Cela inclut de ne pas accepter de prolonger les délais de paiement accordés à votre acheteur, ou de mettre en place un échéancier de paiement.
* Enfin, vous devez à notre demande exercer toutes les mesures raisonnables que nous pourrions vous demander de prendre, y compris le transfert ou la cession de la créance assurée dans le cadre de notre procédure de recouvrement.
    `,
  },
  {
    title: 'Qui couvre mon contrat d’assurance ?',
    body: `
Votre assurance est couverte par Lloyd’s Brussels, une unité de SCOR Global P&C, le segment non-vie du groupe SCOR.
    `,
  },
  {
    title: 'Qu’advient-il de la TVA ?',
    body: `
* Hokodo couvre 90% de votre facture (hors TVA).
* En cas de créance impayée, vous pourrez récupérer la TVA. Votre comptable saura vous guider dans vos démarches.
    `,
  },
  {
    title:
      'Ai-je besoin d’un bon de commande ou d’une confirmation écrite de mon client afin de souscrire à la police d’assurance CashProtect ?',
    body: `
Aucune confirmation écrite n’est nécessaire afin de procéder à l’achat d’une police d’assurance auprès d’Hokodo. Toutefois, en cas d’accord verbal entre vous et votre client, nous ne serons pas en mesure de vous indemniser si votre client conteste le fait même d’avoir effectué une commande.
    `,
  },
  {
    title: 'Je souhaite avoir davantage d’informations concernant HOKODO',
    body: `
HOKODO : 

* est une Société par actions simplifiée, immatriculé au Registre du Commerce et des Sociétés sous le numéro 847 859 352, dont le siège social est situé au 39 rue de la chaussée d’antin 75009 Paris
* est inscrite au Registre des intermédiaires en assurance, banque et finance (ORIAS) sous le numéro d’immatriculation 19001909 en qualité de Courtier d’Assurance ou de Réassurance (COA)
* ne détient pas de holding représentant 10% ou plus des droits de vote ou du capital de notre assureur, et notre assureur ne détient pas de holding représentant 10% ou plus des droits de vote ou du capital de Hokodo SAS
* agit pour et au nom des assureurs tels que désignés dans nos Conditions Générales et Particulières.
    `,
  },
  {
    title: 'Comment Hokodo se rémunère ?',
    body: `
* Hokodo se charge de faire l’intermédiaire entre vous et l’assureur. Vous n’avez rien à faire et cela n’engendre pas de frais supplémentaires pour vous.
* Hokodo perçoit de la part de l’assureur une commission correspondant à un pourcentage de la prime totale versée par l’assuré; ainsi qu’une commission sur les bénéfices basée sur la rentabilité des polices d’assurance souscrites.
    `,
  },
  {
    title: 'D’autres questions ?',
    body: `
Vous pouvez télécharger un contrat-type en cliquant sur le lien suivant : [Contrat-type]({{SPECIMEN_POLICY}})

Vous pouvez nous contacter par email ou par téléphone en utilisant les coordonnées présentes en bas de page
    `,
  },
];
