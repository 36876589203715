import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { Formik, Form, Field } from 'formik';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Context,
  Button,
  Heading,
  AuthFormBox,
  TextField,
  PasswordField,
  ServerErrors,
} from '@hokodo/core';
import { getValidationSchema } from '@hokodo/core/lib/formHelpers';

const Login = ({ login, isFetching, serverErrors }) => {
  const { t } = useTranslation('auth');

  return (
    <Context.Consumer>
      {({ lang }) => (
        <AuthFormBox>
          <Heading level={5} centered>
            {t('login.loginConfirm')}
          </Heading>
          {serverErrors && <ServerErrors errors={serverErrors} />}
          <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={getValidationSchema('LoginSchema', lang)}
            onSubmit={(values, { setSubmitting }) => {
              login(values);
              setSubmitting(false);
            }}
            render={({ isSubmitting }) => (
              <Form>
                <Field
                  placeholder={t('login.placeholderEmail')}
                  name="email"
                  component={TextField}
                />

                <Field
                  placeholder={t('login.placeholderPassword')}
                  name="password"
                  component={PasswordField}
                />

                <p>
                  <Link to="/reset">{t('login.forgotLinkText')}</Link>
                </p>

                <Button
                  type="submit"
                  disabled={isSubmitting || isFetching}
                  block
                >
                  {t('login.submit')}
                </Button>

                <p
                  css={css`
                    margin-top: 1rem;
                  `}
                >
                  <Link to="/register">{t('login.registerLinkText')}</Link>
                </p>
              </Form>
            )}
          />
        </AuthFormBox>
      )}
    </Context.Consumer>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  serverErrors: PropTypes.object,
};

Login.defaultProps = {
  serverErrors: null,
};

export default Login;
