import { connect } from 'react-redux';
import { selectPageSize, setPageSize } from '@redux/modules/transactions';
import PageSize from './PageSize';

export default connect(
  state => ({
    pageSize: selectPageSize(state),
  }),
  {
    setPageSize,
  },
)(PageSize);
