import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AccordionItem from './AccordionItem';

const Accordion = ({ items }) => {
  const [activeItems, setActiveItems] = useState(
    Array(items.length).fill(false),
  );

  const handleToggleActive = index => {
    const a = [...activeItems];
    a[index] = !activeItems[index];
    setActiveItems(a);
  };

  return (
    <div>
      {items.map((item, index) => (
        <AccordionItem
          // eslint-disable-next-line react/no-array-index-key
          key={`item-${index}`}
          active={activeItems[index]}
          index={index}
          content={item}
          onClick={handleToggleActive}
        />
      ))}
    </div>
  );
};

Accordion.propTypes = {
  items: PropTypes.array,
};

Accordion.defaultProps = {
  items: [],
};

export default Accordion;
