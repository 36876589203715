import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import Logo from '../../atoms/Logo';
import LogoPartnership from '../../molecules/LogoPartnership';
import Context from '../../../Context';
import phoneSvg from '../../../../assets/img/phonegold.svg';

const Header = ({ partnerLogo, mainLogo }) => {
  const { t } = useTranslation('header');

  return (
    <Context.Consumer>
      {({ theme: { colors }, applicationContext: { SUPPORT_CONTACT } }) => (
        <header
          css={css`
            margin-bottom: 1px;
            background-color: ${colors.white};
          `}
        >
          <div className="container">
            <div
              css={css`
                height: 98px;
                display: flex;
                align-items: center;
              `}
            >
              {mainLogo ? (
                <div>{mainLogo}</div>
              ) : partnerLogo ? (
                <LogoPartnership>{partnerLogo}</LogoPartnership>
              ) : (
                <Logo width={110} />
              )}
              <div
                css={css`
                  flex: 1;
                  text-align: center;
                  span {
                    font-size: 0.85rem;
                    display: block;
                    &:first-of-type {
                      font-size: 1.5rem;
                      font-weight: 700;
                    }
                  }
                `}
              >
                <span>{t('title')}</span>
                <span>{t('subtitle')}</span>
              </div>
              <div
                css={css`
                  display: flex;
                  line-height: 1;
                  img {
                    margin-right: 0.5rem;
                  }
                  span {
                    display: block;
                    color: ${colors.primary};
                    font-size: 12px;
                    &:first-of-type {
                      font-size: 18px;
                      font-weight: 700;
                    }
                  }
                `}
              >
                <img alt="" src={phoneSvg} />
                <div>
                  <span>{SUPPORT_CONTACT.phone}</span>
                  <span>{t('supportHours')}</span>
                </div>
              </div>
            </div>
          </div>
        </header>
      )}
    </Context.Consumer>
  );
};

Header.propTypes = {
  partnerLogo: PropTypes.node,
  mainLogo: PropTypes.node,
};

Header.defaultProps = {
  partnerLogo: null,
  mainLogo: null,
};

export default Header;
