import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import Context from '../../../Context';

const ProgressIndicator = ({ pathname, pages }) => {
  // TODO: Remove hardcoded pathnames
  const isActiveTab = (page, path) => {
    if (page === '') return true;
    if (page === 'checkout') return path !== '';
    if (page === 'policy') return path === 'policy';
    return false;
  };

  const progressBarWidthPercentage = path => {
    switch (path) {
      case 'checkout':
        return '50%';
      case 'policy':
        return '100%';
      default:
        return '25%';
    }
  };

  const validForRoute = () => {
    /* eslint-disable no-restricted-globals */
    return !self.location.pathname.match(/declined/);
  };

  return validForRoute() ? (
    <Context.Consumer>
      {({ theme: { colors } }) => {
        return (
          <header
            css={css`
              margin-bottom: 1px;
              background-color: ${colors.white};
              padding-bottom: 0.5rem;
              border: 1px solid ${colors.white};
            `}
          >
            <div
              css={css`
                margin: 5rem auto -3.25rem auto;
                background-color: ${colors.revampLightGrey};
                justify-content: flex-start;
                border-radius: 100px;
                align-items: center;
                position: relative;
                display: flex;
                height: 10px;
                width: 400px;
                z-index: 1;
              `}
            >
              <div
                css={css`
                  animation: load 3s normal forwards;
                  box-shadow: 0 10px 40px -10px #fff;
                  border-radius: 100px;
                  background: ${colors.revampPrimary};
                  height: 10px;
                  width: ${progressBarWidthPercentage(pathname)};
                `}
              >
                {}
              </div>
            </div>
            <ul
              css={css`
                display: flex;
                justify-content: space-between;
                padding: 2rem 2rem 3rem;
                background-color: ${colors.white};
                width: 100%;
                max-width: 500px;
                position: relative;
                left: 0;
                right: 0;
                margin: auto;
                &::before {
                  z-index: 1;
                  content: ' ';
                  width: calc(100% - 10rem);
                  position: absolute;
                  top: 3rem;
                  left: 0;
                  right: 0;
                  margin: auto;
                }
                > li {
                  display: inline-flex;
                  flex-direction: column;
                  align-items: center;
                  position: relative;
                  > i {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    width: 2rem;
                    height: 2rem;
                    background-color: ${colors.revampLightGrey};
                    z-index: 2;
                    &.active {
                      background-color: ${colors.revampPrimary};
                    }
                  }
                  > span {
                    position: absolute;
                    top: 2rem;
                    &.active {
                      color: ${colors.revampPrimary};
                    }
                  }
                }
              `}
            >
              {pages.map(page => {
                const className = isActiveTab(page.pathname, pathname)
                  ? 'active'
                  : '';
                return (
                  <li key={`path${page.pathname}`}>
                    <i className={className}>{}</i>
                    <span className={className}>{page.title}</span>
                  </li>
                );
              })}
            </ul>
          </header>
        );
      }}
    </Context.Consumer>
  ) : null;
};

ProgressIndicator.propTypes = {
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      pathname: PropTypes.string,
      title: PropTypes.string,
    }),
  ).isRequired,
  pathname: PropTypes.string,
};

ProgressIndicator.defaultProps = {
  pathname: '',
};

export default ProgressIndicator;
