import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import Logo from '../../atoms/Logo';
import Context from '../../../Context';

const AuthFormBox = ({ children, logo }) => (
  <Context.Consumer>
    {({ theme: { colors } }) => (
      <div
        css={css`
          background-color: ${colors.white};
          padding: 2rem 0 4rem;
        `}
      >
        <div
          css={css`
            max-width: 420px;
            margin: auto;
          `}
        >
          {logo && <Logo />}
          <div>{children}</div>
        </div>
      </div>
    )}
  </Context.Consumer>
);

AuthFormBox.propTypes = {
  children: PropTypes.node.isRequired,
  logo: PropTypes.bool,
};

AuthFormBox.defaultProps = {
  logo: true,
};

export default AuthFormBox;
