import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { css } from '@emotion/core';
import { colors, padding } from '@styles/theme';
import DropdownMenu from '@components/DropdownMenu';
import LinkText from '../LinkText';

const UserMenu = ({ logout }) => {
  const { t } = useTranslation('header');

  return (
    <DropdownMenu
      text={<LinkText iconType="user">{t('nav.account')}</LinkText>}
      selfClosing
      hideIndicator
      position="right"
    >
      <ul
        css={css`
          white-space: nowrap;
          text-transform: uppercase;
          padding: ${padding.menu};
          > li {
            margin: 1rem 0;
            > a {
              color: ${colors.secondary};
            }
          }
        `}
      >
        <li>
          <Link to="/account">{t('userMenu.editDetails')}</Link>
        </li>
        <li>
          <a href="#" onClick={logout}>
            {t('userMenu.logout')}
          </a>
        </li>
      </ul>
    </DropdownMenu>
  );
};

UserMenu.propTypes = {
  logout: PropTypes.func.isRequired,
};

export default UserMenu;
