import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import { breakpoints, colors, border } from '@styles/theme';
import { Context, Tooltip, Icon } from '@hokodo/core';
import StatusTip from './StatusTip';

const ViewHeadings = ({ name, data, setOrderBy }) => {
  const { t } = useTranslation('dashboard');

  return (
    <Context.Consumer>
      {({ lang }) => {
        const headings = [
          {
            label: t('tableColumnHeadings.invoiceNumber'),
            value: 'number',
            defaultSortDirection: 'desc',
          },
          {
            label: t('tableColumnHeadings.customerName'),
            value: 'debtor__name',
            tip: t('tableColumnHeadings.tipCustomerName'),
            defaultSortDirection: 'asc',
          },
          {
            label: t('tableColumnHeadings.issueDate'),
            value: 'issue_date',
            defaultSortDirection: 'desc',
          },
          {
            label: t('tableColumnHeadings.dueDate'),
            value: 'due_date',
            defaultSortDirection: 'desc',
          },
          {
            label: t('tableColumnHeadings.amountNet'),
            value: 'net_amount',
            tip:
              lang === 'fr-FR' || lang === 'de-DE'
                ? null
                : t('tableColumnHeadings.tipAmountNet'),
            defaultSortDirection: 'desc',
          },
          {
            label: t('tableColumnHeadings.status'),
            value: 'status',
            tip: <StatusTip />,
            defaultSortDirection: 'asc',
          },
        ];

        const {
          orderBy: { param, desc },
        } = data;

        const onClick = (value, defaultSortDirection) => {
          let d = defaultSortDirection === 'desc';
          if (value === param) {
            d = !desc;
          }
          setOrderBy({ view: name, orderBy: { param: value, desc: d } });
        };

        return (
          <div
            css={css`
              display: grid;
              grid-template-rows: auto;
              grid-template-columns: 2fr 4fr 2fr 2fr 2fr 3fr 3fr 1fr;
              border-bottom: ${border.default};

              > div {
                align-self: center;
                padding: 0.5rem 1rem;
              }

              @media (max-width: ${breakpoints.lg}) {
                display: none;
              }
            `}
          >
            {headings.map(heading => {
              const { value, label, tip, defaultSortDirection } = heading;
              return (
                <div
                  key={`${name}-${value}`}
                  css={css`
                    color: ${colors.secondary};
                  `}
                >
                  <a
                    href="#"
                    role="button"
                    onClick={event => {
                      event.preventDefault();
                      onClick(value, defaultSortDirection);
                    }}
                    tabIndex="0"
                    value={value}
                  >
                    {label}
                  </a>
                  {tip && <Tooltip>{tip}</Tooltip>}
                  {value === param && (
                    <Icon type={desc ? 'chevron-down' : 'chevron-up'} />
                  )}
                </div>
              );
            })}
          </div>
        );
      }}
    </Context.Consumer>
  );
};

ViewHeadings.propTypes = {
  name: PropTypes.string.isRequired,
  data: PropTypes.shape({
    orderBy: PropTypes.shape({
      param: PropTypes.string,
      desc: PropTypes.bool,
    }),
  }).isRequired,
  setOrderBy: PropTypes.func.isRequired,
};

export default ViewHeadings;
