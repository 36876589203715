import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import { colors, border, radius, padding } from '@styles/theme';
import { Heading } from '@hokodo/core';
import UserProfile from './UserProfile';
import OrganisationSettings from './OrganisationSettings';
import PaymentSettings from './PaymentSettings';

const UserAccount = ({ currentMembership, profile, ...rest }) => {
  const { t } = useTranslation('account');

  return (
    <div
      css={css`
        > section {
          background-color: ${colors.white};
          border-radius: ${radius.default};
          padding: ${padding.default};
          border: ${border.default};
          margin: 2rem auto;
        }
      `}
    >
      <Heading level={1}>{t('title')}</Heading>

      <p>{t('description')}</p>

      {currentMembership && (
        <Fragment>
          <OrganisationSettings
            membership={currentMembership}
            key={currentMembership.organisation.id}
            {...rest}
          />

          <PaymentSettings membership={currentMembership} {...rest} />
        </Fragment>
      )}

      {profile && (
        <section>
          <UserProfile profile={profile} {...rest} />
        </section>
      )}
    </div>
  );
};

UserAccount.propTypes = {
  currentMembership: PropTypes.object,
  profile: PropTypes.object,
};

UserAccount.defaultProps = {
  currentMembership: null,
  profile: null,
};

export default UserAccount;
