import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import {
  Icon,
  Button,
  Input,
  Label,
  Heading,
  Context,
  ServerErrors,
} from '@hokodo/core';
import observableRequest from '@hokodo/core/lib/requestHelpers';
import { border, colors, padding, radius, fontSize } from '@styles/theme';
import { ROOT_API } from '@environment';
import { FORM_STEPS } from '@redux/modules/claim';
import Select from 'react-select';

const extractFilenameFromPath = path =>
  path.substring(path.lastIndexOf('/') + 1, path.indexOf('?'));

const DocumentLineItem = ({ document, handleClick }) => {
  const { id, file, type, description } = document;

  const onClick = () => {
    handleClick(id);
  };

  const { t } = useTranslation('notify');

  return (
    <Context.Consumer>
      {({ applicationContext: { DOCUMENT_TYPES } }) => (
        <div
          css={css`
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-column-gap: 1rem;
            margin-bottom: 0.5rem;
            padding-bottom: 0.5rem;
            border-bottom: ${border.default};
            > div {
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              align-self: center;
            }
          `}
        >
          <div>
            <a href={file} target="_blank" rel="noopener noreferrer">
              {extractFilenameFromPath(file)}
            </a>
          </div>
          <div>{t(`document_types.${DOCUMENT_TYPES[type]}`)}</div>
          <div>{description}</div>
          <div>
            <Button onClick={onClick}>
              {t('steps.uploadDocuments.remove')}
            </Button>
          </div>
        </div>
      )}
    </Context.Consumer>
  );
};

DocumentLineItem.propTypes = {
  document: PropTypes.shape({
    id: PropTypes.string,
    file: PropTypes.string,
    type: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  handleClick: PropTypes.func.isRequired,
};

const Documents = ({ policyId, token, handleSetPage, hideModal }) => {
  const initialState = { file: null, fileType: null, description: '' };

  const [selected, setSelected] = useState(initialState);
  const [documents, setDocuments] = useState({});
  const [serverErrors, setServerErrors] = useState(null);

  const { t } = useTranslation('notify');

  useEffect(() => {
    observableRequest({
      url: `collectiondocuments?policy_id=${policyId}`,
      token,
    })
      .pipe(pluck('response'))
      .subscribe(
        response => {
          if (response && response.results) {
            setDocuments(
              response.results.reduce((acc, doc) => {
                acc[doc.id] = doc;
                return acc;
              }, {}),
            );
          }
        },
        error => setServerErrors(error),
      );
  }, []);

  const handleFileSelect = event => {
    setSelected({ ...selected, file: event.target.files[0] });
  };

  const handleFileType = option => {
    setSelected({ ...selected, fileType: option.value });
  };

  const handleDescription = event => {
    setSelected({ ...selected, description: event.target.value });
  };

  const handleCreateDocument = event => {
    event.preventDefault();

    setServerErrors(null);

    if (selected.file.size > 5000000) {
      setServerErrors({ fileSize: [t('steps.uploadDocuments.overLimit')] });
      return;
    }
    const formData = new FormData();
    formData.append('file', selected.file);
    formData.append('policy', policyId);
    formData.append('type', selected.fileType || 'other');
    formData.append('description', selected.description);

    from(
      fetch(`${ROOT_API}/collectiondocuments`, {
        method: 'POST',
        body: formData,
        headers: { Authorization: `Token ${token}` },
      }).then(response => response.json()),
    ).subscribe(
      response => {
        setDocuments({
          ...documents,
          [response.id]: response,
        });
        setSelected(initialState);
      },
      error => setServerErrors(error),
    );
  };

  const handleDestroyDocument = documentId => {
    observableRequest({
      url: `collectiondocuments/${documentId}`,
      token,
      config: { method: 'DELETE' },
    }).subscribe(() => {
      const { [documentId]: omit, ...rest } = documents;
      setDocuments({ ...rest });
    });
  };

  return (
    <Context.Consumer>
      {({ applicationContext: { DOCUMENT_TYPES } }) => {
        const fileTypeOptions = Object.keys(DOCUMENT_TYPES).map(key => ({
          value: key,
          label: t(`document_types.${DOCUMENT_TYPES[key]}`),
        }));

        const documentsCollection = Object.keys(documents).map(key => ({
          key,
          document: documents[key],
        }));

        return (
          <Fragment>
            <Heading level={5}>{t('steps.uploadDocuments.heading')}</Heading>
            {serverErrors && <ServerErrors errors={serverErrors} />}
            <div
              css={css`
                display: grid;
                grid-row-gap: 2rem;
              `}
            >
              {documentsCollection.length ? (
                <div>
                  <div
                    css={css`
                      display: grid;
                      grid-template-columns: repeat(4, 1fr);
                      grid-column-gap: 1rem;
                      > div {
                        font-weight: 600;
                      }
                    `}
                  >
                    <div>{t('steps.uploadDocuments.document')}</div>
                    <div>{t('steps.uploadDocuments.type')}</div>
                    <div>{t('steps.uploadDocuments.description')}</div>
                    <div />
                  </div>
                  {documentsCollection.map(({ key, document }) => (
                    <DocumentLineItem
                      key={key}
                      document={document}
                      handleClick={handleDestroyDocument}
                    />
                  ))}
                </div>
              ) : null}

              <form onSubmit={handleCreateDocument}>
                <div
                  css={css`
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-column-gap: 2rem;
                  `}
                >
                  <div>
                    <div
                      css={css`
                        margin-bottom: 1rem;
                      `}
                    >
                      <Label
                        htmlFor="file"
                        text={t('steps.uploadDocuments.file')}
                      />
                      <div
                        css={css`
                          position: relative;
                          padding: ${padding.input};
                          border: ${border.default};
                          border-radius: ${radius.default};
                          background-color: ${colors.white};
                          i {
                            position: absolute;
                            right: 0.5rem;
                          }
                          span {
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            max-width: 90%;
                            display: inline-block;
                            font-size: ${fontSize.input};
                          }
                          input {
                            position: absolute;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                            opacity: 0;
                            width: 100%;
                          }
                        `}
                      >
                        <Icon type="upload" />
                        <span>{selected.file ? selected.file.name : ''}</span>
                        <input
                          type="file"
                          id="file"
                          onChange={handleFileSelect}
                          // eslint-disable-next-line max-len
                          accept=".jpg,.jpeg,.png,.tiff,.pdf,.pdfx,application/pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        />
                      </div>
                    </div>

                    <div
                      css={css`
                        margin-bottom: 1rem;
                      `}
                    >
                      <Label
                        htmlFor="description"
                        text={t('steps.uploadDocuments.description')}
                      />
                      <Input
                        id="description"
                        value={selected.description}
                        onChange={handleDescription}
                      />
                    </div>
                  </div>

                  <div
                    css={css`
                      position: relative;
                    `}
                  >
                    <div
                      css={css`
                        margin-bottom: 1rem;
                      `}
                    >
                      <Label
                        htmlFor="documentType"
                        text={t('steps.uploadDocuments.documentType')}
                      />
                      <Select
                        id="documentType"
                        options={fileTypeOptions}
                        value={fileTypeOptions.find(
                          option => option.value === selected.fileType,
                        )}
                        onChange={handleFileType}
                      />
                    </div>

                    <div
                      css={css`
                        position: absolute;
                        bottom: 1rem;
                      `}
                    >
                      <Button type="submit" disabled={!selected.file}>
                        {t('steps.uploadDocuments.attachDocument')}
                      </Button>
                    </div>
                  </div>
                </div>
              </form>

              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  margin-top: 2rem;
                `}
              >
                <Button
                  type="button"
                  onClick={() => {
                    handleSetPage(FORM_STEPS.banking);
                  }}
                >
                  {t('modal.controls.continue')}
                </Button>

                <ul
                  css={css`
                    display: flex;
                    margin-top: 0.5rem;
                    li {
                      margin: 0 0.25rem;
                    }
                  `}
                >
                  <li>
                    <a
                      href="#"
                      rel="button"
                      onClick={event => {
                        event.preventDefault();
                        handleSetPage(FORM_STEPS.questions);
                      }}
                    >
                      {t('modal.controls.previous')}
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      rel="button"
                      onClick={event => {
                        event.preventDefault();
                        hideModal();
                      }}
                    >
                      {t('modal.controls.save')}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </Fragment>
        );
      }}
    </Context.Consumer>
  );
};

Documents.propTypes = {
  policyId: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  handleSetPage: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
};

export default Documents;
