import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { useTranslation } from 'react-i18next';
import Context from '../../../../Context';

const SearchResultItem = ({ company, onClick }) => {
  const handleClick = event => {
    event.stopPropagation();
    onClick(company);
  };

  const { name, address } = company;

  return (
    <div
      role="button"
      tabIndex="0"
      onClick={handleClick}
      css={css`
        padding: 8px 0 8px 12px;
        border-top: 2px solid #e6ebf0;
        line-height: 16px;
      `}
    >
      <div
        css={css`
          font-size: 14px;
        `}
      >
        {name || '-'}
      </div>
      <div
        css={css`
          font-size: 11px;
          color: #a6acb3;
        `}
      >
        {address}
      </div>
    </div>
  );
};

SearchResultItem.propTypes = {
  company: PropTypes.shape().isRequired,
  onClick: PropTypes.func.isRequired,
};

const SearchResult = ({
  results,
  hasQuery,
  isSearching,
  searchCompleted,
  onClick,
}) => {
  const { t } = useTranslation('common');

  return (
    <Context.Consumer>
      {({ theme: { zIndex, radius, colors, shadow } }) => (
        <div
          role="switch"
          tabIndex="0"
          onClick={event => {
            event.stopPropagation();
          }}
          css={css`
            position: absolute;
            top: 2.75rem;
            width: 100%;
            z-index: ${zIndex.matchCompany + 1};
            border-radius: ${radius.default};
            background-color: ${colors.white};
            box-shadow: ${shadow.default};
          `}
        >
          {results.length ? (
            <div
              css={css`
                width: 100%;
                max-height: 284px;
                overflow-y: auto;
              `}
            >
              {results.map(company => (
                <SearchResultItem
                  key={company.key}
                  company={company}
                  onClick={onClick}
                />
              ))}
            </div>
          ) : (
            <div
              css={css`
                display: flex;
                align-items: center;
                height: 2rem;
                padding-left: 12px;
              `}
              className={isSearching ? 'spinner' : ''}
            >
              {hasQuery
                ? isSearching
                  ? ''
                  : searchCompleted
                  ? t('companySelect.emptySet')
                  : ''
                : t('companySelect.placeholder')}
            </div>
          )}
        </div>
      )}
    </Context.Consumer>
  );
};

SearchResult.propTypes = {
  results: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClick: PropTypes.func.isRequired,
  hasQuery: PropTypes.bool,
  isSearching: PropTypes.bool,
  searchCompleted: PropTypes.bool,
};

SearchResult.defaultProps = {
  hasQuery: false,
  isSearching: false,
  searchCompleted: false,
};

export { SearchResultItem, SearchResult, SearchResult as default };
