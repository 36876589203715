import { connect } from 'react-redux';
import {
  oauthConnect,
  oauthSynchronise,
  selectIsFetching,
  selectError,
} from '@redux/modules/integrations';
import {
  selectMembershipsByPlatform,
  setCurrentMembership,
} from '@redux/modules/user';
import OauthSync from './OauthSync';

export default connect(
  (state, props) => ({
    platformMemberships: selectMembershipsByPlatform(state, props),
    isFetching: selectIsFetching(state),
    error: selectError(state),
  }),
  {
    oauthSynchronise,
    oauthConnect,
    setCurrentMembership,
  },
)(OauthSync);
