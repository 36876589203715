import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';

const Label = ({ text, htmlFor }) => (
  <label
    css={css`
      display: inline-block;
      font-weight: 600;
      margin-bottom: 0.5rem;
    `}
    htmlFor={htmlFor}
  >
    {text}
  </label>
);

Label.propTypes = {
  text: PropTypes.string,
  htmlFor: PropTypes.string,
};

Label.defaultProps = {
  text: '',
  htmlFor: '',
};

export default Label;
