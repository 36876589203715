import { connect } from 'react-redux';
import { fetchCollection, selectView } from '@redux/modules/transactions';
import PaginationControl from './PaginationControl';

export default connect(
  (state, props) => ({
    data: selectView(state, props),
  }),
  {
    fetchCollection,
  },
)(PaginationControl);
