import { connect } from 'react-redux';
import { selectView, setOrderBy } from '@redux/modules/transactions';
import { selectUserOrganisation } from '@redux/modules/user';
import ViewHeadings from './ViewHeadings';

export default connect(
  (state, props) => ({
    userOrganisation: selectUserOrganisation(state),
    data: selectView(state, props),
  }),
  {
    setOrderBy,
  },
)(ViewHeadings);
