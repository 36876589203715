import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field } from 'formik';
import {
  Context,
  Button,
  Heading,
  PasswordField,
  AuthFormBox,
} from '@hokodo/core';
import { getValidationSchema } from '@hokodo/core/lib/formHelpers';

const ChoosePassword = ({ match, isFetching, passwordConfirm }) => {
  const { t } = useTranslation('auth');

  return (
    <Context.Consumer>
      {({ lang }) => (
        <AuthFormBox>
          <Heading centered level={5}>
            {t('reset.heading')}
          </Heading>
          <p>{t('confirmReset.instructStep1')}</p>
          <Formik
            initialValues={{ password: '' }}
            validationSchema={getValidationSchema('ChoosePasswordSchema', lang)}
            onSubmit={(values, { setSubmitting }) => {
              const { password } = values;
              const { uid, token } = match.params;
              passwordConfirm({
                new_password: password,
                uid,
                token,
              });
              setSubmitting(false);
            }}
            render={({ isSubmitting }) => (
              <Form>
                <Field
                  name="password"
                  placeholder={t('confirmReset.placeholderPassword')}
                  component={PasswordField}
                />

                <Button
                  type="submit"
                  block
                  disabled={isFetching || isSubmitting}
                >
                  {t('confirmReset.submit')}
                </Button>
              </Form>
            )}
          />
        </AuthFormBox>
      )}
    </Context.Consumer>
  );
};

ChoosePassword.propTypes = {
  match: PropTypes.object.isRequired,
  passwordConfirm: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
};

ChoosePassword.defaultProps = {
  isFetching: false,
};

export default ChoosePassword;
