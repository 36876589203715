import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { APPLICATION_CONSTANTS, Context, Icon, StatusIcon } from '@hokodo/core';
import { formatLocaleCurrency } from '@hokodo/core/lib/currencyHelpers';
import { formatLocaleDate } from '@hokodo/core/lib/datetimeHelpers';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { breakpoints, colors } from '@styles/theme';
import MatchCompany from '@components/MatchCompany';
import ActionButton from '../../ActionButton';
import ViewItemExpanded from '../ViewItemExpanded';

const { POLICY_STATUSES } = APPLICATION_CONSTANTS;

const Styled = styled.div`
  display: grid;
  grid-template-columns: 2fr 4fr 2fr 2fr 2fr 3fr 3fr 1fr;
  grid-template-areas: 'number customer issue due amount status cta expand';
  grid-row-gap: 1rem;
  height: 3.7rem;
  background: #fefefe;
  border-bottom: 1px solid #dee2e6;

  .inlineButton {
    display: none;
  }

  > div {
    align-self: center;
    padding: 0 0.5rem;
    min-width: 0;
    .label {
      color: ${colors.secondary};
      display: none;
    }

    &[data-content='number'] {
      grid-area: number;
    }

    &[data-content='customer'] {
      grid-area: customer;
    }

    &[data-content='issue'] {
      grid-area: issue;
    }

    &[data-content='due'] {
      grid-area: due;
    }

    &[data-content='amount'] {
      grid-area: amount;
    }

    &[data-content='status'] {
      grid-area: status;
    }

    &[data-content='cta'] {
      grid-area: cta;
    }

    &[data-content='expand'] {
      grid-area: expand;
    }
  }

  @media (max-width: ${breakpoints.lg}) {
    grid-template-rows: auto auto auto auto;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'customer customer'
      'amount due'
      'status cta'
      'expand expand';
    grid-row-gap: 0;
    height: auto;

    .inlineButton {
      display: inline;
    }

    .blockButton {
      display: none;
    }

    > div {
      padding: 0.5rem;
      .label {
        display: block;
      }

      &[data-content='issue'],
      &[data-content='number'] {
        display: none;
      }

      &[data-content='cta'],
      &[data-content='due'] {
        text-align: right;
      }

      &[data-content='expand'] {
        text-align: center;
      }
    }
  }
`;

const ViewItem = ({ transaction }) => {
  const {
    number,
    issue_date,
    due_date,
    currency,
    net_amount,
    policy,
    debtor,
    debtor_name,
    source,
  } = transaction;

  const [expanded, setExpanded] = useState(false);

  const handleExpand = event => {
    event.stopPropagation();
    setExpanded(!expanded);
  };

  const policyStatus = policy ? policy.status : POLICY_STATUSES.not_insured;

  const { t } = useTranslation('dashboard');

  const overflowStyles = `
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `;

  return (
    <Context.Consumer>
      {({ lang }) => (
        <Fragment>
          <Styled onClick={handleExpand}>
            <div data-content="number">
              <div
                css={css`
                  ${overflowStyles}
                `}
              >
                {number}
              </div>
            </div>
            <div data-content="customer">
              <span className="label">
                {t('tableColumnHeadings.customerName')}
              </span>
              {source ? (
                <MatchCompany transaction={transaction} />
              ) : (
                <div
                  css={css`
                    ${overflowStyles}
                  `}
                >
                  {debtor ? debtor.name : debtor_name || ''}
                </div>
              )}
            </div>
            <div data-content="issue">
              <span className="label">
                {t('tableColumnHeadings.issueDate')}
              </span>
              {formatLocaleDate(lang, new Date(issue_date))}
            </div>
            <div data-content="due">
              <span className="label">{t('tableColumnHeadings.dueDate')}</span>
              {formatLocaleDate(lang, new Date(due_date))}
            </div>
            <div data-content="amount">
              <span className="label">
                {t('tableColumnHeadings.amountNet')}
              </span>
              {formatLocaleCurrency(lang, currency, net_amount)}
            </div>
            <div data-content="status">
              <StatusIcon status={policyStatus} />
            </div>
            <div data-content="cta">
              <ActionButton
                className="blockButton"
                block
                transaction={transaction}
                lang={lang}
              />
              <ActionButton
                className="inlineButton"
                transaction={transaction}
                lang={lang}
              />
            </div>
            <div data-content="expand">
              <Icon type={expanded ? 'chevron-up' : 'chevron-down'} />
            </div>
          </Styled>
          {expanded && <ViewItemExpanded transaction={transaction} />}
        </Fragment>
      )}
    </Context.Consumer>
  );
};

ViewItem.propTypes = {
  transaction: PropTypes.shape({
    number: PropTypes.string,
    issue_date: PropTypes.string,
    due_date: PropTypes.string,
    currency: PropTypes.string,
    net_amount: PropTypes.string,
    policy: PropTypes.shape({
      status: PropTypes.string,
    }),
    debtor: PropTypes.shape({
      name: PropTypes.string,
    }),
    debtor_name: PropTypes.string,
    source: PropTypes.string,
  }).isRequired,
};

export default ViewItem;
