import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import { CheckboxField, RadioField, Button, Context } from '@hokodo/core';
import { getValidationSchema } from '@hokodo/core/lib/formHelpers';
import { Formik, Form, Field } from 'formik';
import { FORM_STEPS } from '@redux/modules/claim';

const FinalConfirmation = ({
  details,
  handleSubmit,
  isLocked,
  handleSetPage,
  lang,
}) => {
  const { t } = useTranslation('notify');

  const {
    confirmed_correct,
    accepted_collections_terms,
    collect_fees_from_debtor,
  } = details;

  return (
    <Context.Consumer>
      {({ applicationContext: { COLLECTION_PERC } }) => {
        const options = [
          {
            label: t('steps.finalConfirmation.collect_fees_from_debtor_yes'),
            value: true,
          },
          {
            label: t('steps.finalConfirmation.collect_fees_from_debtor_no', {
              COLLECTION_PERC,
            }),
            value: false,
          },
        ];

        return (
          <Formik
            initialValues={{
              confirmed_correct,
              accepted_collections_terms,
              collect_fees_from_debtor,
              nextStep: '',
            }}
            onSubmit={values => {
              const now = new Date().toISOString();
              const { nextStep, ...rest } = values;
              handleSubmit(
                {
                  ...rest,
                  confirmed_correct: values.confirmed_correct ? now : null,
                  accepted_collections_terms: values.accepted_collections_terms
                    ? now
                    : null,
                },
                nextStep,
              );
            }}
            validationSchema={getValidationSchema(
              'FinalConfirmationSchema',
              lang,
            )}
          >
            {({ setFieldValue, submitForm }) => {
              return (
                <Form>
                  <p>{t('steps.finalConfirmation.heading')}</p>
                  <div>
                    <Field
                      name="confirmed_correct"
                      label={t('steps.finalConfirmation.confirm_correct')}
                      component={CheckboxField}
                      disabled={isLocked}
                    />
                  </div>

                  <p
                    dangerouslySetInnerHTML={{
                      __html: t(
                        'steps.finalConfirmation.collections_terms_description',
                        {
                          interpolation: { escapeValue: false },
                        },
                      ),
                    }}
                  />
                  <div>
                    <Field
                      name="accepted_collections_terms"
                      label={t(
                        'steps.finalConfirmation.collections_terms_acceptance',
                      )}
                      component={CheckboxField}
                      disabled={isLocked}
                    />
                  </div>

                  <p>
                    {t(
                      'steps.finalConfirmation.collect_fees_from_debtor_description',
                      { COLLECTION_PERC },
                    )}
                  </p>
                  <div>
                    <Field
                      name="collect_fees_from_debtor"
                      options={options}
                      component={RadioField}
                      stacked
                      disabled={isLocked}
                    />
                  </div>

                  <div
                    css={css`
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      margin-top: 2rem;
                    `}
                  >
                    <Button
                      type="button"
                      onClick={() => {
                        setFieldValue('nextStep', FORM_STEPS.final, false);
                        submitForm();
                      }}
                    >
                      {t('modal.controls.continue')}
                    </Button>

                    <ul
                      css={css`
                        display: flex;
                        margin-top: 0.5rem;
                        li {
                          margin: 0 0.25rem;
                        }
                      `}
                    >
                      <li>
                        <a
                          href="#"
                          rel="button"
                          onClick={event => {
                            event.preventDefault();
                            handleSetPage(FORM_STEPS.banking);
                          }}
                        >
                          {t('modal.controls.previous')}
                        </a>
                      </li>
                    </ul>
                  </div>
                </Form>
              );
            }}
          </Formik>
        );
      }}
    </Context.Consumer>
  );
};

FinalConfirmation.propTypes = {
  details: PropTypes.shape({
    confirmed_correct: PropTypes.bool,
    accepted_collections_terms: PropTypes.bool,
    collect_fees_from_debtor: PropTypes.bool,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isLocked: PropTypes.bool.isRequired,
  handleSetPage: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
};

export default FinalConfirmation;
