import { connect } from 'react-redux';
import { selectView, setFilters } from '@redux/modules/transactions';
import FilterControls from './FilterControls';

export default connect(
  (state, props) => ({
    data: selectView(state, props),
  }),
  {
    setFilters,
  },
)(FilterControls);
