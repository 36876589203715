import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import { formatLocaleCurrency } from '../../../../../lib/currencyHelpers';
import Heading from '../../atoms/Heading';
import Card from '../../atoms/Card';
import Context from '../../../Context';
import PromotionQuoteSummaryDiscountText from '../Promotions/PromotionsQuoteSummaryDiscountText';

const QuoteSummary = props => {
  const { transaction } = props;
  if (transaction && transaction.quote) {
    const {
      net_amount,
      currency: transactionCurrency,
      quote: {
        total_price,
        premium,
        ipt,
        insured_pc_net,
        currency: quoteCurrency,
        insured: { currency: insuredCurrency, insured_amount },
      },
    } = transaction;

    const { t } = useTranslation('quoteSummary');

    return (
      <Context.Consumer>
        {({ lang, theme: { colors } }) => {
          const formatCurrency = (cur, num) =>
            formatLocaleCurrency(lang, cur, num);

          return (
            <Card {...props}>
              <div
                css={css`
                  position: relative;
                  > div {
                    display: flex;
                    justify-content: space-between;
                    margin: 0 0 0.5rem;
                  }
                `}
              >
                <Heading level={3}>{t('title')}</Heading>

                <Heading level={4}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t('description', {
                        interpolation: { escapeValue: false },
                        insured: formatCurrency(
                          insuredCurrency,
                          insured_amount,
                        ),
                        pc: insured_pc_net,
                        net: formatCurrency(transactionCurrency, net_amount),
                      }),
                    }}
                    css={css`
                      > strong {
                        font-weight: 800;
                      }
                    `}
                  />
                </Heading>

                <div
                  css={css`
                    font-size: 1rem;
                    color: ${colors.primary};
                    span:last-of-type {
                      font-weight: 600;
                    }
                  `}
                >
                  <span>{t('total')}</span>
                  <span>{formatCurrency(quoteCurrency, total_price)}</span>
                </div>

                <div>
                  <span>{t('premium')}</span>
                  <span>{formatCurrency(quoteCurrency, premium)}</span>
                </div>

                <div>
                  <span>{t('tax')}</span>
                  <span>{formatCurrency(quoteCurrency, ipt)}</span>
                </div>

                <PromotionQuoteSummaryDiscountText quote={transaction.quote} />
              </div>
            </Card>
          );
        }}
      </Context.Consumer>
    );
  }
  return null;
};

QuoteSummary.propTypes = {
  transaction: PropTypes.shape({
    net_amount: PropTypes.string,
    quote: PropTypes.shape(),
    currency: PropTypes.string,
  }),
};

QuoteSummary.defaultProps = {
  transaction: null,
};

export default QuoteSummary;
