import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import AnchorTrackEvent from '../../molecules/AnchorTrackEvent';
import Card from '../../atoms/Card';
import Heading from '../../atoms/Heading';
import Context from '../../../Context';
import tickSvg from '../../../../assets/img/tick.svg';
import croixSvg from '../../../../assets/img/croix.svg';
import downloadSvg from '../../../../assets/img/download.svg';
import Tooltip from '../../atoms/Tooltip';
import SectionHeader from '../../molecules/SectionHeader/SectionHeader';


const ProtectionFeatures = () => {
  const { t } = useTranslation('protectionFeatures');

  return (
    <Context.Consumer>
      {({
        theme: { border, colors },
        applicationContext: { SPECIMEN_POLICY, HELP_URL },
      }) => {
        return (
          <div>
            <SectionHeader content={t('header')} />
            <Card>
              <Heading level={3}>{t('title')}</Heading>
              <table
                css={css`
                  width: 100%;
                  thead {
                    td {
                      font-size: 20px;
                    }
                  }
                  tbody {
                    tr {
                      border-bottom: ${border.default};
                      &:nth-of-type(1) {
                        border-top: ${border.default};
                      }
                      &:nth-of-type(odd) {
                        background: rgba(202, 138, 42, 0.05);
                      }
                    }
                    td {
                      font-size: 18px;
                      vertical-align: middle;
                      &:first-of-type {
                        text-align: left;
                        white-space: normal;
                      }
                      > div {
                        font-size: 12px;
                        font-weight: 300;
                      }
                    }
                  }
                  td {
                    text-align: center;
                    padding: 1rem;
                    white-space: nowrap;
                    border-right: ${border.default};
                  }
                `}
              >
                <thead>
                  <tr>
                    <td />
                    <td>{t('covered')}</td>
                    <td>{t('notCovered')}</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{t('item1.title')}
                      <Tooltip
                        title={t('tooltips.protectionFeaturesInsolvability.title')}
                        description={t('tooltips.protectionFeaturesInsolvability.description')}
                      />
                    </td>
                    <td>
                      <img alt="" src={tickSvg} />
                    </td>
                    <td />
                  </tr>

                  <tr>
                    <td>{t('item2.title')}
                      <Tooltip
                        title={t('tooltips.protectionFeaturesPaymentDefault.title')}
                        description={t('tooltips.protectionFeaturesPaymentDefault.description')}
                      />
                    </td>
                    <td>
                      <img alt="" src={tickSvg} />
                    </td>
                    <td />
                  </tr>

                  <tr>
                    <td>{t('item3.title')}
                      <Tooltip
                        title={t('tooltips.protectionFeaturesCollections.title')}
                        description={t('tooltips.protectionFeaturesCollections.description')}
                      />
                    </td>
                    <td>
                      <img alt="" src={tickSvg} />
                    </td>
                    <td />
                  </tr>

                  <tr>
                    <td>{t('item4.title')}
                      <Tooltip
                        title={t('tooltips.protectionFeaturesDisputedInvoice.title')}
                        description={t('tooltips.protectionFeaturesDisputedInvoice.description')}
                      />
                    </td>
                    <td>
                      <img alt="" src={tickSvg} />
                      <div>{t('item4.covered')}</div>
                    </td>
                    <td>
                      <img alt="" src={croixSvg} />
                      <div>{t('item4.notCovered')}</div>
                    </td>
                  </tr>

                  <tr>
                    <td>{t('item5.title')}
                      <Tooltip
                        title={t('tooltips.protectionFeaturesLateInvoiceIssued.title')}
                        description={t('tooltips.protectionFeaturesLateInvoiceIssued.description')}
                      />
                    </td>
                    <td />
                    <td>
                      <img alt="" src={croixSvg} />
                    </td>
                  </tr>

                  <tr>
                    <td>{t('item6.title')}
                      <Tooltip
                        title={t('tooltips.protectionFeaturesPreExistingLatePayment.title')}
                        description={t('tooltips.protectionFeaturesPreExistingLatePayment.description')}
                      />
                    </td>
                    <td />
                    <td>
                      <img alt="" src={croixSvg} />
                    </td>
                  </tr>
                </tbody>
              </table>
              <p
                dangerouslySetInnerHTML={{
                  __html: t('noTopUp', {
                    interpolation: { escapeValue: false },
                  }),
                }}
                css={css`
                  padding: 1rem;
                  strong {
                    color: #ff5c35;
                  }
                `}
              />
              <Tooltip
                title={t('tooltips.protectionFeaturesPreExistingInsurancePolicy.title')}
                description={t('tooltips.protectionFeaturesPreExistingInsurancePolicy.description')}
              />
              <ul
                css={css`
                  display: flex;
                  justify-content: space-between;
                  padding: 0 1rem;
                  li {
                    a {
                      color: ${colors.black};
                      text-decoration: underline;
                      display: flex;
                    }
                    img {
                      margin-right: 0.5rem;
                      margin-top: 1px;
                    }
                  }
                `}
              >
                <li>
                  <AnchorTrackEvent
                    target="_blank"
                    rel="noopener noreferrer"
                    href={SPECIMEN_POLICY}
                    trackingEventName="Specimen Policy Click"
                  >
                    <img alt="" src={downloadSvg} />
                    <span>{t('specemin')}</span>
                  </AnchorTrackEvent>
                </li>
                <li>
                  <AnchorTrackEvent
                    target="_blank"
                    rel="noopener noreferrer"
                    href={HELP_URL}
                    trackingEventName="FAQ Click"
                  >
                    {t('help')}
                  </AnchorTrackEvent>
                </li>
              </ul>
            </Card>
          </div>
        );
      }}
    </Context.Consumer>
  );
};

export default ProtectionFeatures;
