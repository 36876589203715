import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import { Context } from '@hokodo/core';
import DropdownMenu from '@components/DropdownMenu';
import ModalContent from '@components/integrations/common/ModalContent';

const ImportMenu = ({ showModal }) => {
  const { t } = useTranslation('dashboard');

  return (
    <Context.Consumer>
      {({
        applicationContext: { AVAILABLE_INTEGRATIONS = [] },
        theme: { padding },
      }) => {
        const platforms = [
          { platform: 'kashflow', title: t('addInvoiceMenu.importKashFlow') },
          {
            platform: 'quickbooks',
            title: t('addInvoiceMenu.importQuickBooks'),
          },
          { platform: 'xero', title: t('addInvoiceMenu.importXero') },
        ];

        const options = platforms.filter(p =>
          AVAILABLE_INTEGRATIONS.includes(p.platform),
        );

        return options.length ? (
          <div>
            <DropdownMenu
              text={t('importInvoices')}
              selfClosing
              triggerStyle="button"
              position="right"
            >
              <ul
                css={css`
                  white-space: nowrap;
                  text-transform: uppercase;
                  padding: ${padding.menu};
                  li {
                    margin: 1rem 0;
                  }
                `}
              >
                {options.map(({ platform, title }) => (
                  <li key={platform}>
                    <a
                      href="#"
                      role="button"
                      onClick={event => {
                        event.preventDefault();
                        showModal({
                          content: <ModalContent platform={platform} />,
                        });
                      }}
                    >
                      {title}
                    </a>
                  </li>
                ))}
              </ul>
            </DropdownMenu>
          </div>
        ) : null;
      }}
    </Context.Consumer>
  );
};

ImportMenu.propTypes = {
  showModal: PropTypes.func.isRequired,
};

export default ImportMenu;
