import React from 'react';
import PropTypes from 'prop-types';
import MessageBox from '../MessageBox';

const ServerErrors = ({ errors }) => {
  const messages = Object.keys(errors).reduce((acc, key) => {
    if (typeof errors[key] === 'string') {
      return [...acc, errors[key]];
    }
    if (Array.isArray(errors[key])) {
      return [...acc, ...errors[key]];
    }
    return acc;
  }, []);

  return (
    <MessageBox type="error">
      <ul>
        {messages.map((message, index) => (
          <li key={index}>{message}</li> // eslint-disable-line react/no-array-index-key
        ))}
      </ul>
    </MessageBox>
  );
};

ServerErrors.propTypes = {
  errors: PropTypes.object,
};

ServerErrors.defaultProps = {
  errors: {},
};

export default ServerErrors;
