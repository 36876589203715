import { connect } from 'react-redux';
import { selectView, setActiveView } from '@redux/modules/transactions';
import ViewTab from './ViewTab';

export default connect(
  (state, props) => ({
    data: selectView(state, props),
  }),
  {
    setActiveView,
  },
)(ViewTab);
