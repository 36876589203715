import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({
  component: Component,
  sessionHasUser,
  redirectPath,
  auth,
  noauth,
  ...rest
}) => {
  const allowed = (auth && sessionHasUser) || (noauth && !sessionHasUser);
  return (
    <Route
      {...rest}
      render={routeProps =>
        allowed ? (
          <Component {...routeProps} />
        ) : (
          <Redirect
            to={{
              pathname: redirectPath,
              state: { from: routeProps.location },
            }}
          />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  redirectPath: PropTypes.string,
  sessionHasUser: PropTypes.bool,
  auth: PropTypes.bool,
  noauth: PropTypes.bool,
};

PrivateRoute.defaultProps = {
  redirectPath: '/login',
  sessionHasUser: false,
  auth: false,
  noauth: false,
};

export default PrivateRoute;
