import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import Heading from '../../atoms/Heading';
import Button from '../../atoms/Button';

const ServerError = ({ errorMessage, transaction, fetchExpiredQuote }) => {
  const { t } = useTranslation('error');

  const isRecoverable = errorMessage.quote
    ? errorMessage.quote.indexOf('Quote is expired.') !== -1 &&
      transaction &&
      transaction.id
    : false;

  const handleClick = () => {
    fetchExpiredQuote({ transactionId: transaction.id });
  };

  return (
    <div
      css={css`
        padding-top: 4rem;
        display: flex;
        flex-direction: column;
        align-items: center;
      `}
    >
      <Heading level={3}>
        {isRecoverable ? t('quoteExpiredTitle') : t('title')}
      </Heading>
      {isRecoverable ? (
        <p>{t('quoteExpiredDescription')}</p>
      ) : (
        <p
          dangerouslySetInnerHTML={{
            __html: t('description', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      )}
      {isRecoverable ? (
        <Button onClick={handleClick}>{t('quoteExpiredButton')}</Button>
      ) : null}
    </div>
  );
};

ServerError.propTypes = {
  transaction: PropTypes.shape({
    id: PropTypes.string,
  }),
  errorMessage: PropTypes.any.isRequired,
  fetchExpiredQuote: PropTypes.func.isRequired,
};

ServerError.defaultProps = {
  transaction: null,
};

export default ServerError;
