import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { css } from '@emotion/core';
import Label from '../../atoms/Label';

const SelectField = ({ label, placeholder, options, field, form }) => {
  const { name, value, onBlur } = field;
  const { setFieldValue, errors, touched } = form;

  return (
    <div
      css={css`
        margin-bottom: 1rem;
      `}
    >
      {label && <Label text={label} htmlFor={name} />}
      <Select
        name={name}
        options={options}
        value={options ? options.find(option => option.value === value) : ''}
        onChange={option => setFieldValue(name, option.value)}
        onBlur={onBlur}
        placeholder={placeholder}
      />
      {errors[name] && touched[name] && (
        <div className="error">{errors[name]}</div>
      )}
    </div>
  );
};

SelectField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
    onBlur: PropTypes.func,
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func,
    errors: PropTypes.object,
    touched: PropTypes.object,
  }).isRequired,
};

SelectField.defaultProps = {
  label: '',
  placeholder: '',
  options: [],
};

export default SelectField;
