import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field } from 'formik';
import { css } from '@emotion/core';
import CompanyField from '../../molecules/CompanyField';
import DateField from '../../molecules/DateField';
import TextField from '../../molecules/TextField';
import Heading from '../../atoms/Heading';
import Button from '../../atoms/Button';
import Icon from '../../atoms/Icon';
import Card from '../../atoms/Card';
import Context from '../../../Context';
import {
  formatLocaleDate,
  formatYMD,
} from '../../../../../lib/datetimeHelpers';
import { getRegnum } from '../../../../../lib/companyHelpers';

const getPayload = (section, values) => {
  const { debtor, creditor, issue_date, due_date, ...rest } = values;
  switch (section) {
    case 'creditor': {
      const { id, name, country } = creditor.match;
      return { creditor: id, creditor_name: name, creditor_country: country };
    }
    case 'debtor': {
      const { id, name, country } = debtor.match;
      return { debtor: id, debtor_name: name, debtor_country: country };
    }
    default:
      return {
        issue_date: formatYMD(issue_date),
        due_date: formatYMD(due_date),
        ...rest,
      };
  }
};

const Details = ({ transaction, updateTransaction, isEditable }) => {
  if (transaction) {
    const { t } = useTranslation('details');
    const [activeForms, setActiveForms] = useState({
      creditor: false,
      debtor: false,
      invoice: false,
    });

    const {
      id: transactionId,
      creditor,
      debtor,
      owner,
      net_amount,
      issue_date,
      due_date,
      number,
    } = transaction;

    const creditorSection = creditor
      ? {
          id: 'creditor',
          heading: t('creditor.heading'),
          fields: [
            {
              label: t('creditor.labelCompanyName'),
              value: { match: creditor },
              component: CompanyField,
              name: 'creditor',
              fieldType: 'company',
            },
            {
              label: t('creditor.labelName'),
              value: owner.name,
              component: TextField,
              name: 'ownerName',
              disabled: true,
            },
            {
              label: t('creditor.labelEmail'),
              value: owner.email,
              component: TextField,
              name: 'ownerEmail',
              disabled: true,
            },
          ],
        }
      : { fields: [] };

    const debtorSection = debtor
      ? {
          id: 'debtor',
          heading: t('debtor.heading'),
          fields: [
            {
              label: t('debtor.labelCompanyName'),
              value: { match: debtor },
              component: CompanyField,
              name: 'debtor',
              fieldType: 'company',
            },
            {
              label: t('debtor.labelCompanyCountry'),
              value: debtor.country,
              component: TextField,
              name: 'debtor_country',
              disabled: true,
            },
            {
              label: t('debtor.labelCompanyReg'),
              value: getRegnum(debtor.identifiers),
              component: TextField,
              name: 'debtor_regnum',
              disabled: true,
            },
          ],
        }
      : { fields: [] };

    const invoiceSection = {
      id: 'invoice',
      heading: t('invoice.heading'),
      fields: [
        {
          label: t('invoice.labelNetAmount'),
          value: net_amount,
          name: 'net_amount',
          component: TextField,
        },
        {
          label: t('invoice.labelInvoiceNumber'),
          value: number,
          name: 'number',
          component: TextField,
        },
        {
          label: t('invoice.labelIssueDate'),
          value: new Date(issue_date),
          name: 'issue_date',
          component: DateField,
          fieldType: 'date',
        },
        {
          label: t('invoice.labelDueDate'),
          value: new Date(due_date),
          name: 'due_date',
          component: DateField,
          fieldType: 'date',
        },
      ],
    };

    const sections = isEditable
      ? [creditorSection, debtorSection, invoiceSection]
      : [debtorSection, invoiceSection];

    return (
      <Context.Consumer>
        {({ lang, queryString, theme: { padding, border } }) => (
          <Fragment>
            <Card>
              <div
                css={css`
                  font-size: 1rem;
                  > section:not(:first-of-type) {
                    border-top: ${border.default};
                    padding-top: 1.5rem;
                    margin-top: 1.5rem;
                  }
                `}
              >
                {sections.map(({ id, heading, fields }) => (
                  <section key={id}>
                    <div
                      css={css`
                        display: flex;
                        align-items: baseline;
                        > a {
                          margin-left: ${padding.default};
                        }
                      `}
                    >
                      <Heading level={3}>{heading}</Heading>
                      {isEditable && !activeForms[id] && (
                        <a
                          href="#"
                          onClick={() => {
                            setActiveForms({ ...activeForms, [id]: true });
                          }}
                        >
                          <Icon type="edit" />
                          {t('common.edit')}
                        </a>
                      )}
                    </div>

                    {activeForms[id] ? (
                      <div>
                        <Formik
                          enableReinitialize
                          initialValues={fields.reduce(
                            (acc, { name, value }) => {
                              acc[name] = value;
                              return acc;
                            },
                            {},
                          )}
                          onSubmit={(values, { setSubmitting }) => {
                            updateTransaction({
                              transactionId,
                              ...getPayload(id, values),
                            });
                            setSubmitting(false);
                            setActiveForms({ ...activeForms, [id]: false });
                          }}
                          onReset={() => {
                            setActiveForms({ ...activeForms, [id]: false });
                          }}
                        >
                          {({ isSubmitting }) => (
                            <Form>
                              {fields.map(({ value, name, ...fieldProps }) =>
                                React.createElement(Field, {
                                  name,
                                  key: `f-${name}`,
                                  ...fieldProps,
                                }),
                              )}
                              <div
                                css={css`
                                  > button {
                                    margin: ${padding.default};
                                    margin-left: 0;
                                  }
                                `}
                              >
                                <Button type="submit" disabled={isSubmitting}>
                                  {t('common.submit')}
                                </Button>
                                <Button type="reset">
                                  {t('common.reset')}
                                </Button>
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    ) : (
                      <div
                        css={css`
                          > div {
                            display: flex;
                            padding: ${padding.default} 0;
                            span {
                              width: 50%;
                            }
                          }
                        `}
                      >
                        {fields.map(({ label, value, name, fieldType }) => (
                          <div key={`s-${name}`}>
                            <span className="label">{label}</span>
                            <span>
                              {(() => {
                                switch (fieldType) {
                                  case 'date':
                                    return formatLocaleDate(lang, value);
                                  case 'company':
                                    return value.match.name;
                                  default:
                                    return value;
                                }
                              })()}
                            </span>
                          </div>
                        ))}
                      </div>
                    )}
                  </section>
                ))}
              </div>
            </Card>

            <Link to={`/checkout${queryString}`}>
              <Button block>{t('nextButtonText')}</Button>
            </Link>
          </Fragment>
        )}
      </Context.Consumer>
    );
  }
  return null;
};

Details.propTypes = {
  transaction: PropTypes.shape({
    id: PropTypes.string,
    creditor: PropTypes.shape({
      name: PropTypes.string,
      country: PropTypes.string,
      identifiers: PropTypes.array,
    }),
    debtor: PropTypes.shape({
      name: PropTypes.string,
      country: PropTypes.string,
      identifiers: PropTypes.array,
    }),
    owner: PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
    }),
    net_amount: PropTypes.string,
    issue_date: PropTypes.string,
    due_date: PropTypes.string,
    number: PropTypes.string,
  }),
  updateTransaction: PropTypes.func.isRequired,
  isEditable: PropTypes.bool,
};

Details.defaultProps = {
  transaction: null,
  isEditable: false,
};

export default Details;
